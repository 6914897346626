import {
  useDialog,
  FocusScope,
  OverlayContainer,
  AriaOverlayProps as OverlayProps,
  OverlayProvider,
  useModal,
  useOverlay,
  usePreventScroll,
} from 'react-aria';
import { useOverlayTriggerState } from '@react-stately/overlays';
import React, { useContext, useMemo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Box, BoxOwnProps } from '../box/box';
import './modal.css';
import classNames from 'classnames';
import { boxStyles } from '../box/box.css';
import { marginPaddingStyles } from '../css/common.css';
import FullScreenHeader from '../fullscreenheader/fullscreenheader';

export { useOverlayTriggerState, OverlayProvider };
const ModalContext = React.createContext<{
  placement?: 'right';
  hasContext: boolean;
}>({
  placement: undefined,
  hasContext: false,
});
function ModalInner(
  props: OverlayProps & {
    title: string;
    children: React.ReactNode;
    autoFocus?: boolean;
    size?: 'sm' | 'md' | 'lg' | 'full';
    placement?: 'right';
    status?: 'error' | 'info' | 'warning' | 'success';
    onBackPress?: () => void;
    containerRef: React.RefObject<HTMLDivElement>;
    showDefaultHeader?: boolean;
  }
) {
  const {
    title,
    children,
    autoFocus = true,
    placement,
    containerRef,
    showDefaultHeader = true,
  } = props;
  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = containerRef;
  const { overlayProps } = useOverlay(props, ref);
  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();
  // Get props for the dialog and its title
  const { dialogProps } = useDialog(props as never, ref);
  const modalContext = useMemo(() => {
    return {
      placement,
      hasContext: true,
    };
  }, [placement]);
  return (
    <OverlayContainer>
      <Box
        as="div"
        zIndex="50"
        overflowX="hidden"
        overflowY="hidden"
        left="0"
        bottom="0"
        right="0"
        top="0"
        style={{ backgroundColor: 'rgba(31, 41, 55, 0.6)' }}
        position="fixed"
        onClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
        }}
      >
        <FocusScope contain restoreFocus autoFocus={autoFocus}>
          <ModalContext.Provider value={modalContext}>
            <Box
              as="div"
              position="relative"
              width="auto"
              display={placement ? undefined : 'flex'}
              height={placement ? 'screen' : undefined}
              overflow={placement ? 'auto' : undefined}
              alignItems={placement ? undefined : 'center'}
              paddingLeft={
                placement
                  ? undefined
                  : {
                      sm: '0',
                      xs: '0',
                      md: '2',
                      lg: '2',
                      xl: '2',
                      '2xl': '2',
                    }
              }
              marginY={placement ? undefined : '10'}
              marginX={placement ? undefined : 'auto'}
              marginLeft={placement === 'right' ? 'auto' : undefined}
              style={{
                // this allows us to center align the inner content
                // NOTE: we remove the margins
                minHeight: 'calc(100% - (5rem * 2))',
                maxWidth:
                  props.size === 'full'
                    ? '100vw'
                    : props.size === 'lg'
                    ? '1152px'
                    : props.size === 'md'
                    ? '896px'
                    : '576px',
              }}
            >
              <Box
                as="div"
                {...overlayProps}
                {...dialogProps}
                {...modalProps}
                ref={ref}
                backgroundColor="surfaceDefault"
                width="full"
                minHeight={placement ? 'screen' : undefined}
                rounded={placement ? undefined : 'lg'}
              >
                {showDefaultHeader ? (
                  <ModalHeader
                    className="relative"
                    title={title}
                    onClose={props.onClose}
                  />
                ) : null}
                {children}
              </Box>
            </Box>
          </ModalContext.Provider>
        </FocusScope>
      </Box>
    </OverlayContainer>
  );
}
export function Modal(
  props: Omit<React.ComponentProps<typeof ModalInner>, 'containerRef'>
) {
  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = useRef<HTMLDivElement>(null);
  const { placement } = props;
  return (
    <CSSTransition
      in={props.isOpen}
      timeout={100}
      nodeRef={ref}
      classNames={
        placement === 'right'
          ? {
              // SLIDE-in-out
              // enter start position
              enter: 'right-enter',
              enterActive: 'right-enter-active',
              // enter end position
              enterDone: 'right-enter-done',
              // exiting position
              exitActive: 'right-exit-active',
            }
          : {
              // FADE-in-out
              // enter start position
              enter: 'enter',
              enterActive: 'enter-active',
              // enter end position
              enterDone: 'enter-done',
              // exiting position
              exitActive: 'exit-active',
            }
      }
      unmountOnExit
    >
      <ModalInner {...props} containerRef={ref} />
    </CSSTransition>
  );
}

export function ModalBody({
  className,
  autoMaxHeight,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  autoMaxHeight?: boolean;
}) {
  const { placement } = useContext(ModalContext);
  return (
    <div
      className={classNames(
        boxStyles({ overflow: 'auto' }),
        marginPaddingStyles({ paddingY: '2.5', paddingX: '4' })
      )}
      style={{
        maxHeight: 'calc(100vh - 80px * 0.4 - 73px - 98px)',
        height:
          placement === 'right' ? 'calc(100vh - 80px * 0.4 - 73px - 98px)' : '',
      }}
      {...props}
    />
  );
}

function ModalHeader({
  onClose,
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  onClose?: () => void;
}) {
  return (
    <FullScreenHeader
      headingText={props.title || ''}
      borderBottomWidth="1"
      onClickCross={onClose}
      borderColor="borderSeparator"
      position="sticky"
      top="0"
      zIndex="10"
    />
  );
}

export function ModalFooter({
  className,
  onlyActions = true,
  actionsLayout,
  children,
  ref,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  BoxOwnProps & {
    onlyActions?: boolean;
    actionsLayout?: 'auto' | 'row';
  }) {
  return (
    <Box
      borderTopWidth="1"
      borderColor="borderSeparator"
      paddingY="2"
      paddingX="5"
      display="flex"
      justifyContent="end"
      position="sticky"
      backgroundColor="surfaceDefault"
      bottom="0"
      gap="4"
      {...props}
    >
      {children}
    </Box>
  );
}
