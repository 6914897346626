import React, { useMemo, useRef, useState } from 'react';
import {
  useBranches,
  useBranchesForFilter,
  useBusinessByForFilter,
  useRentByForFilter,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  Amount,
  BaseOption,
  Box,
  Button,
  EmptyState,
  FileIcon,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  MultiSelect,
  PlusIcon,
  ReturnIcon,
  RightCaratIcon,
  SearchIcon,
  Select,
  SingleSelect,
  SkeletonRows,
  SpinnerIcon,
  Stack,
  Tag,
  Text,
  TextInput,
  toastQueue,
  useOverlayTriggerState,
} from '@nbfc-expense-tool/ui';
import { BRANCH_LEVELS } from '../../Branches';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  ErrorResponse,
  IndianStatesAndUTs,
  addNewBranch,
} from '@nbfc-expense-tool/data-store/utils';
import { useUser } from '@nbfc-expense-tool/data-store/auth';
import { checkForSinglePermission } from '@nbfc-expense-tool/data-store/permissions';
import { useNavigate } from 'react-router-dom';
import { BulkImportButton } from '../../Common';

const STATES_FOR_FILTERS = IndianStatesAndUTs.map((stateOrUt) => {
  return {
    label: stateOrUt,
    value: stateOrUt,
  };
});

export default function BranchesPage() {
  const navigate = useNavigate();
  const idPrefix = 'branches-page';
  const { user } = useUser();
  const {
    params,
    loading,
    branches,
    lastPage,
    perPage,
    canGoBack,
    canGoNext,
    syncingData,
    totalBranches,
    hasAppliedFilters,
    current: currentPage,
    resetFilters,
    handlePageChange,
    handleParamChange,
    exportExcel,
    reportLoading,
  } = useBranches();

  const { branches: branchesFilters } = useBranchesForFilter();
  const { rentByList } = useRentByForFilter();
  const { businessByList } = useBusinessByForFilter();

  const businessByListForFilters = useMemo(() => {
    return businessByList?.length
      ? businessByList.map((businessBy) => {
          return {
            label: businessBy,
            value: businessBy,
          };
        })
      : [];
  }, [businessByList]);

  const rentByListForFilters = useMemo(() => {
    return rentByList?.length
      ? rentByList.map((rentBy) => {
          return {
            label: rentBy,
            value: rentBy,
          };
        })
      : [];
  }, [rentByList]);

  const branchesForFilters = useMemo(() => {
    return branchesFilters?.length
      ? branchesFilters.map((branch) => {
          return {
            label: `${branch.name} - ${branch.branch_code}`,
            value: branch.id.toString(),
          };
        })
      : [];
  }, [branchesFilters]);

  const canAddBranch = useMemo(() => {
    return checkForSinglePermission(
      'createBranch',
      user?.roles[0]?.permissions
    );
  }, [user?.roles]);

  return !branches?.length && !hasAppliedFilters ? (
    <Stack paddingTop="10" alignItems="center" justifyContent="center">
      {loading === 'in_progress' ? (
        <Inline gap="4">
          <SpinnerIcon size="3" color="iconMedium" />
          <Text variation="b2">Loading...</Text>
        </Inline>
      ) : (
        <EmptyState
          renderIcon={(props) => <FileIcon {...props} />}
          title="No Branches Found!"
          subText={
            canAddBranch
              ? `Tap ‘Add New’ to create your first branch`
              : undefined
          }
          renderButton={() => (
            <Inline gap="4">
              <BulkImportButton entityType="branches" />
              {canAddBranch && (
                <AddNewBranchInModal
                  branches={branchesForFilters}
                  rentByList={rentByListForFilters}
                  businessByList={businessByListForFilters}
                  onSuccess={() => {
                    resetFilters();
                  }}
                >
                  {({ onAdd }) => (
                    <Button
                      id={`${idPrefix}-empty-state-add-new-branch`}
                      title="Add New"
                      onClick={onAdd}
                      leftIcon={(props) => <PlusIcon {...props} />}
                    />
                  )}
                </AddNewBranchInModal>
              )}
            </Inline>
          )}
        />
      )}
    </Stack>
  ) : (
    <Stack flex="1" paddingX="4" paddingY="5" gap="8">
      <Inline alignItems="center" justifyContent="between">
        <Text as="h2" variation="h2">
          Branches
        </Text>
        <Inline gap="4">
          {canAddBranch ? (
            <Inline gap="4">
              <BulkImportButton entityType="branches" />
              <AddNewBranchInModal
                branches={branchesForFilters}
                rentByList={rentByListForFilters}
                businessByList={businessByListForFilters}
                onSuccess={() => {
                  resetFilters();
                }}
              >
                {({ onAdd }) => (
                  <Button
                    id={`${idPrefix}-add-new-branch`}
                    title="Add New"
                    onClick={onAdd}
                    leftIcon={(props) => <PlusIcon {...props} />}
                  />
                )}
              </AddNewBranchInModal>
            </Inline>
          ) : null}
        </Inline>
      </Inline>
      <Stack gap="6">
        <Inline justifyContent={'between'}>
          <Inline gap="4">
            <MultiSelect
              id={`${idPrefix}-select-state`}
              label="State"
              value={params.state}
              actionBtnTitle="Show Results"
              onSave={(values) => {
                handleParamChange('state', values);
              }}
              options={STATES_FOR_FILTERS}
            />
            <MultiSelect
              id={`${idPrefix}-select-parent-branch`}
              label="Parent Branch"
              value={params.parent_branch_id}
              actionBtnTitle="Show Results"
              onSave={(values) => {
                handleParamChange('parent_branch_id', values);
              }}
              options={branchesForFilters}
            />
            <Select
              id={`${idPrefix}-select-branch-level`}
              label="Branch Level"
              value={params.branch_level?.toString()}
              actionBtnTitle="Show Results"
              cancelBtnTitle="Clear"
              onSave={(option) => {
                if (option?.value) {
                  handleParamChange('branch_level', Number(option.value));
                }
              }}
              onCancel={() => handleParamChange('branch_level', undefined)}
              options={BRANCH_LEVELS}
            />
            <MultiSelect
              id={`${idPrefix}-select-business-by`}
              label="Business By"
              value={params.business_by}
              actionBtnTitle="Show Results"
              onSave={(values) => {
                handleParamChange('business_by', values);
              }}
              options={businessByListForFilters}
            />
            <MultiSelect
              id={`${idPrefix}-select-rent-by`}
              label="Rent By"
              value={params.rent_by}
              actionBtnTitle="Show Results"
              onSave={(values) => {
                handleParamChange('rent_by', values);
              }}
              options={rentByListForFilters}
            />
          </Inline>
          <Button
            id={`${idPrefix}-export-excel-button`}
            title="Export To Excel"
            onClick={exportExcel}
            type="outlined"
            state={reportLoading ? 'loading' : undefined}
            leftIcon={(props) => <ReturnIcon {...props} />}
          />
        </Inline>
        <Box
          borderWidth="1"
          borderColor="borderSeparator"
          rounded="md"
          paddingTop="2.5"
          backgroundColor="surfaceDefault"
        >
          <Stack gap="6">
            <Inline
              paddingX="2.5"
              gap="6"
              alignItems="center"
              justifyContent="between"
            >
              <Box style={{ width: 372 }}>
                <TextInput
                  id={`${idPrefix}-search-input`}
                  fullWidth
                  leftIcon={(props) => <SearchIcon {...props} />}
                  onChange={(value) => {
                    handleParamChange('q', value);
                  }}
                  placeholder="Search by branch name, code, city, pincode"
                />
              </Box>

              <Inline alignItems="center" gap="6">
                {totalBranches ? (
                  <Text color="textMedium" variation="c1">
                    Showing{' '}
                    {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}-
                    {lastPage === currentPage
                      ? totalBranches
                      : totalBranches < perPage
                      ? totalBranches
                      : currentPage * perPage}{' '}
                    of {totalBranches}
                  </Text>
                ) : null}
                <Inline gap="2">
                  <Box
                    id={`${idPrefix}-previous-page`}
                    as="button"
                    disabled={!canGoBack}
                    backgroundColor="transparent"
                    onClick={() => handlePageChange('previous')}
                  >
                    <RightCaratIcon
                      cursor="pointer"
                      size="2.5"
                      rotate="180"
                      color={canGoBack ? 'iconMedium' : 'iconLowest'}
                    />
                  </Box>
                  <Box
                    as="hr"
                    width="px"
                    height="2.5"
                    backgroundColor="borderSeparator"
                  />
                  <Box
                    id={`${idPrefix}-next-page`}
                    as="button"
                    disabled={!canGoNext}
                    backgroundColor="transparent"
                    onClick={() => handlePageChange('next')}
                  >
                    <RightCaratIcon
                      cursor="pointer"
                      size="2.5"
                      color={canGoNext ? 'iconMedium' : 'iconLowest'}
                    />
                  </Box>
                </Inline>
              </Inline>
            </Inline>
            <Box as="table" width="full" position="relative">
              <Box as="thead" bgColor="surfaceNeutralLowest">
                <Box as="tr">
                  <Box
                    as="th"
                    position="sticky"
                    paddingY="1.5"
                    paddingX="2"
                    style={{
                      width: 100,
                    }}
                    top="0"
                    textAlign="left"
                    bgColor="surfaceNeutralLowest"
                  >
                    <Text variation="c1">Branch Details</Text>
                  </Box>
                  <Box
                    as="th"
                    position="sticky"
                    paddingY="1.5"
                    paddingX="2"
                    style={{
                      width: 160,
                    }}
                    top="0"
                    textAlign="left"
                    bgColor="surfaceNeutralLowest"
                  >
                    <Text variation="c1">Parent Branch</Text>
                  </Box>
                  <Box
                    as="th"
                    position="sticky"
                    paddingY="1.5"
                    paddingX="2"
                    style={{
                      width: 70,
                    }}
                    top="0"
                    textAlign="left"
                    bgColor="surfaceNeutralLowest"
                  >
                    <Text variation="c1">Branch Code</Text>
                  </Box>
                  <Box
                    as="th"
                    position="sticky"
                    paddingY="1.5"
                    paddingX="2"
                    style={{
                      width: 100,
                    }}
                    top="0"
                    textAlign="left"
                    bgColor="surfaceNeutralLowest"
                  >
                    <Text variation="c1">Branch Level</Text>
                  </Box>
                  <Box
                    as="th"
                    position="sticky"
                    paddingY="1.5"
                    paddingX="2"
                    style={{
                      width: 100,
                    }}
                    top="0"
                    textAlign="right"
                    bgColor="surfaceNeutralLowest"
                  >
                    <Text variation="c1">Expense this Month</Text>
                  </Box>
                </Box>
              </Box>
              {syncingData ? (
                <SkeletonRows numOfRows={10} numOfCols={5} />
              ) : (
                <Box as="tbody">
                  {branches?.length ? (
                    branches.map(
                      ({
                        id,
                        name,
                        branch_code,
                        branch_level,
                        parent_branch,
                        address_pincode,
                        address_state,
                        business_by,
                        rent_by,
                        phone_number,
                        branch_email,
                        address_city,
                        address_line_1,
                        address_line_2,
                        paid_invoices_sum_grand_total_amount,
                      }) => (
                        <React.Fragment key={id}>
                          <Box
                            id={`${idPrefix}-${id}`}
                            as="tr"
                            cursor="pointer"
                            tabIndex={-1}
                            backgroundColor={{
                              hover: 'surfacePrimaryLowest',
                            }}
                            onClick={() =>
                              navigate(`${id}?name=${name}`, {
                                state: {
                                  id,
                                  parent_branch,
                                  branch_level,
                                  name,

                                  business_by,
                                  rent_by,
                                  phone_number,
                                  branch_email,
                                  branch_code,
                                  address_state,
                                  address_pincode,
                                  address_city,
                                  address_line_1,
                                  address_line_2,
                                },
                              })
                            }
                          >
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              textAlign="left"
                              borderBottomWidth="1"
                              borderColor="borderSeparator"
                            >
                              <Stack gap="1" style={{ width: 100 }}>
                                <Text
                                  variation="b2"
                                  className=""
                                  style={{
                                    textOverflow: 'ellipsis',
                                  }}
                                  overflow="hidden"
                                  whiteSpace="nowrap"
                                >
                                  {name}
                                </Text>
                                <Text variation="b3" color="textLow">
                                  Pin: {address_pincode}
                                </Text>
                              </Stack>
                            </Box>
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              textAlign="left"
                              borderBottomWidth="1"
                              borderColor="borderSeparator"
                            >
                              <Text variation="b2">{parent_branch?.name}</Text>
                            </Box>
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              textAlign="left"
                              borderBottomWidth="1"
                              borderColor="borderSeparator"
                            >
                              <Text variation="b2">{branch_code}</Text>
                            </Box>
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              textAlign="left"
                              borderBottomWidth="1"
                              borderColor="borderSeparator"
                            >
                              <Tag
                                status={
                                  branch_level === 5 ? 'success' : 'default'
                                }
                                title={
                                  branch_level
                                    ? `${
                                        branch_level !== 1 ? '' : 'Local'
                                      }(${branch_level})`
                                    : 'Local (1)'
                                }
                              />
                            </Box>
                            <Box
                              as="td"
                              paddingRight="3"
                              paddingLeft="2"
                              paddingY="1.5"
                              textAlign="right"
                              borderBottomWidth="1"
                              borderColor="borderSeparator"
                            >
                              <Amount
                                variation="t4"
                                color="textError"
                                amount={Number(
                                  paid_invoices_sum_grand_total_amount || 0
                                )}
                              />
                            </Box>
                          </Box>
                        </React.Fragment>
                      )
                    )
                  ) : (
                    <Box as="tr">
                      <Box as="td" colSpan={8}>
                        <EmptyState
                          renderIcon={(props) => <FileIcon {...props} />}
                          title="No Branches Found!"
                          subText={`Please try changing your applied filters!`}
                          renderButton={() => (
                            <Button
                              id={`${idPrefix}-empty-state-reset-filter`}
                              title="Reset Filters"
                              onClick={resetFilters}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}

type AddNewBranchForm = {
  name: string;
  branch_code: string;
  phone_number: string;
  branch_level: number;
  business_by: string;
  rent_by: string;
  branch_email: string | null;
  gst_number: string | null;
  address_line_1: string;
  address_line_2: string;
  address_city: string;
  address_pincode: string;
  address_state: string;
  parent_branch_id: number | null;
};

const optionalGstNoRegex =
  /^(?:|[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ][0-9a-zA-Z]{1})$/;

const stringValidation = z
  .string({ required_error: 'This field cannot be empty' })
  .nonempty('This field cannot be empty');

const addNewBranchValidationSchema = z.object({
  name: stringValidation,
  branch_code: stringValidation,
  phone_number: stringValidation,
  branch_email: z.string().optional().nullable(),
  gst_number: z
    .string()
    .regex(optionalGstNoRegex, { message: 'Enter a valid gst number' })
    .optional(),
  branch_level: z
    .number({ required_error: 'This field cannot be empty' })
    .nonnegative(),
  business_by: z.string().optional(),
  rent_by: z.string().optional(),
  address_line_1: stringValidation,
  address_line_2: z.string().optional(),
  address_city: stringValidation,
  address_pincode: stringValidation,
  address_state: stringValidation,
  parent_branch_id: z
    .number({ required_error: 'This field cannot be empty' })
    .nonnegative()
    .optional(),
});
type ButtonValues = 'saveAndAddNew' | 'saveAndClose';

const initialAddNewFormValues = {
  name: '',
  branch_code: '',
  phone_number: '',
  branch_email: null,
  gst_number: null,
  branch_level: undefined,
  business_by: '',
  rent_by: '',
  address_line_1: '',
  address_line_2: '',
  address_city: '',
  address_pincode: '',
  address_state: '',
  parent_branch_id: undefined,
};

function AddNewBranchInModal({
  branches,
  rentByList,
  businessByList,
  children,
  onSuccess,
}: {
  branches?: BaseOption[];
  rentByList?: BaseOption[];
  businessByList?: BaseOption[];
  onSuccess?: () => void;
  children: (props: { onAdd: () => void }) => React.ReactNode;
}) {
  const state = useOverlayTriggerState({});
  const idPrefix = 'add-new-branch-form';

  //Form
  const [isSubmitting, setIsSubmitting] = useState<ButtonValues | undefined>(
    undefined
  );
  const [saveAndAddNewCount, setSaveAndAddNewCount] = useState<number>(0);

  const scrollViewRef = useRef<HTMLDivElement>(null);

  const { control, handleSubmit, setError, reset } = useForm<AddNewBranchForm>({
    resolver: zodResolver(addNewBranchValidationSchema),
  });

  const addNewBranchHandler = async (
    data: AddNewBranchForm,
    type: ButtonValues
  ) => {
    setIsSubmitting(type);
    try {
      await addNewBranch({
        ...data,
        gst_number: data.gst_number?.trim() ? data.gst_number : null,
      });
      toastQueue.add(
        {
          title: 'Branch added successfully!',
          type: 'success',
        },
        {
          timeout: 2000,
        }
      );
      if (type === 'saveAndAddNew') {
        reset(initialAddNewFormValues);
        setSaveAndAddNewCount((prev) => prev + 1);
      } else {
        onSuccess?.();
        state.close();
      }
      setIsSubmitting(undefined);
    } catch (e) {
      const err = e as ErrorResponse;
      setIsSubmitting(undefined);
      if (err.errors) {
        const errorKeys = Object.keys(err.errors) as (keyof AddNewBranchForm)[];
        errorKeys.forEach((errorKey) => {
          setError(errorKey, { message: err.errors[errorKey][0] });
        });
      }
      toastQueue.add(
        {
          title: err?.message || 'Something went wrong!',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
    }
  };

  function onClose() {
    state.close();
    if (saveAndAddNewCount > 0) {
      onSuccess?.();
    }
    setSaveAndAddNewCount(0);
  }

  const scrollToTop = () => {
    scrollViewRef.current?.firstElementChild?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const scrollToBottom = () => {
    scrollViewRef.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const scrollBasedOnFormFieldsError = (err: FieldErrors<AddNewBranchForm>) => {
    if (
      err.name ||
      err.parent_branch_id ||
      err.branch_level ||
      err.branch_code ||
      err.phone_number ||
      err.business_by ||
      err.rent_by ||
      err.gst_number
    ) {
      scrollToTop();
    } else {
      scrollToBottom();
    }
  };

  return (
    <>
      {children({
        onAdd: () => {
          state.open();
        },
      })}

      <Modal
        isOpen={state.isOpen}
        onClose={onClose}
        size="full"
        title="Add New Branch"
        placement="right"
      >
        <ModalBody>
          <Stack gap="6" marginBottom="10" ref={scrollViewRef}>
            <Text variation="t3">Basic Details</Text>
            <Stack gap="12">
              <Inline gap="10">
                <Box style={{ width: 324 }}>
                  <Controller
                    control={control}
                    name="name"
                    render={(props) => {
                      const {
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      } = props;
                      return (
                        <TextInput
                          id={`${idPrefix}-enter-branch-name`}
                          name="name"
                          label="Branch Name"
                          isRequired
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          errorMessage={error?.message}
                          placeholder="Enter branch name"
                        />
                      );
                    }}
                  />
                </Box>
                <Box style={{ width: 324 }}>
                  <Controller
                    control={control}
                    name="parent_branch_id"
                    render={(props) => {
                      const {
                        field: { onChange, value },
                        fieldState: { error },
                      } = props;
                      return (
                        <SingleSelect
                          id={`${idPrefix}-select-parent-branch`}
                          options={branches}
                          onSave={(option) => {
                            onChange(Number(option.value));
                          }}
                          value={value?.toString()}
                          label="Parent Branch"
                          placeholder="Select parent branch"
                          errorMessage={error?.message}
                        />
                      );
                    }}
                  />
                </Box>
              </Inline>
              <Inline gap="10">
                <Box style={{ width: 324 }}>
                  <Controller
                    control={control}
                    name="branch_level"
                    render={(props) => {
                      const {
                        field: { onChange, value },
                        fieldState: { error },
                      } = props;
                      return (
                        <SingleSelect
                          id={`${idPrefix}-select-branch-level`}
                          options={BRANCH_LEVELS}
                          onSave={(option) => {
                            onChange(Number(option.value));
                          }}
                          value={value?.toString()}
                          label="Branch Level"
                          placeholder="Select branch level"
                          isRequired
                          errorMessage={error?.message}
                        />
                      );
                    }}
                  />
                </Box>
                <Box style={{ width: 324 }}>
                  <Controller
                    control={control}
                    name="branch_code"
                    render={(props) => {
                      const {
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      } = props;
                      return (
                        <TextInput
                          id={`${idPrefix}-enter-branch-code`}
                          name="branch_code"
                          label="Branch Code"
                          isRequired
                          fullWidth
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          errorMessage={error?.message}
                          placeholder="Enter branch code. E.g. 0048"
                        />
                      );
                    }}
                  />
                </Box>
                <Box style={{ width: 324 }}>
                  <Controller
                    control={control}
                    name="phone_number"
                    render={(props) => {
                      const {
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      } = props;
                      return (
                        <TextInput
                          id={`${idPrefix}-enter-phone-number`}
                          name="phone_number"
                          label="Branch Mobile Number"
                          isRequired
                          fullWidth
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          leftIcon={(props) => (
                            <Text variation="b2" color={props.color}>
                              +91
                            </Text>
                          )}
                          errorMessage={error?.message}
                          placeholder="Enter 10 digit number"
                        />
                      );
                    }}
                  />
                </Box>
              </Inline>
              <Inline gap="10">
                <Box style={{ width: 324 }}>
                  <Controller
                    control={control}
                    name="business_by"
                    render={(props) => {
                      const {
                        field: { onChange, value },
                        fieldState: { error },
                      } = props;
                      return (
                        <SingleSelect
                          id={`${idPrefix}-select-business-by`}
                          options={businessByList}
                          onSave={(option) => {
                            onChange(option.value);
                          }}
                          value={value}
                          removeSearch
                          label="Business By"
                          placeholder="Select Business By"
                          errorMessage={error?.message}
                        />
                      );
                    }}
                  />
                </Box>
                <Box style={{ width: 324 }}>
                  <Controller
                    control={control}
                    name="rent_by"
                    render={(props) => {
                      const {
                        field: { onChange, value },
                        fieldState: { error },
                      } = props;
                      return (
                        <SingleSelect
                          id={`${idPrefix}-select-vastu-entity`}
                          options={rentByList}
                          onSave={(option) => {
                            onChange(option.value);
                          }}
                          value={value}
                          removeSearch
                          label="Rent By"
                          placeholder="Select Rent By"
                          errorMessage={error?.message}
                        />
                      );
                    }}
                  />
                </Box>
                <Box style={{ width: 324 }}>
                  <Controller
                    control={control}
                    name="gst_number"
                    render={(props) => {
                      const {
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      } = props;
                      return (
                        <TextInput
                          id={`${idPrefix}-enter-gst-number`}
                          name="gst_number"
                          label="GST Number"
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          value={
                            value !== undefined ? String(value) : undefined
                          }
                          errorMessage={error?.message}
                          placeholder="Enter Gst Number"
                        />
                      );
                    }}
                  />
                </Box>
              </Inline>
              <Stack gap="6">
                <Text variation="t3">Address</Text>
                <Inline gap="10">
                  <Box style={{ width: 324 }}>
                    <Controller
                      control={control}
                      name="address_line_1"
                      render={(props) => {
                        const {
                          field: { onChange, onBlur, value },
                          fieldState: { error },
                        } = props;
                        return (
                          <TextInput
                            name="address_line_1"
                            label="Address Line 1"
                            isRequired
                            fullWidth
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            errorMessage={error?.message}
                            placeholder="Apartment, Room"
                          />
                        );
                      }}
                    />
                  </Box>
                  <Box style={{ width: 324 }}>
                    <Controller
                      control={control}
                      name="address_line_2"
                      render={(props) => {
                        const {
                          field: { onChange, onBlur, value },
                          fieldState: { error },
                        } = props;
                        return (
                          <TextInput
                            name="address_line_2"
                            label="Address Line 2"
                            fullWidth
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            errorMessage={error?.message}
                            placeholder="Street, Area"
                          />
                        );
                      }}
                    />
                  </Box>
                </Inline>
                <Inline gap="10">
                  <Box style={{ width: 324 }}>
                    <Controller
                      control={control}
                      name="address_pincode"
                      render={(props) => {
                        const {
                          field: { onChange, onBlur, value },
                          fieldState: { error },
                        } = props;
                        return (
                          <TextInput
                            name="address_pincode"
                            label="Pin Code"
                            isRequired
                            fullWidth
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            errorMessage={error?.message}
                            placeholder="Enter pin code"
                          />
                        );
                      }}
                    />
                  </Box>
                  <Box style={{ width: 324 }}>
                    <Controller
                      control={control}
                      name="address_city"
                      render={(props) => {
                        const {
                          field: { onChange, onBlur, value },
                          fieldState: { error },
                        } = props;
                        return (
                          <TextInput
                            name="address_city"
                            label="City"
                            isRequired
                            fullWidth
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            errorMessage={error?.message}
                            placeholder="Enter branch city"
                          />
                        );
                      }}
                    />
                  </Box>
                  <Box style={{ width: 324 }} position="relative">
                    <Controller
                      control={control}
                      name="address_state"
                      render={(props) => {
                        const {
                          field: { onChange, value },
                          fieldState: { error },
                        } = props;
                        return (
                          <SingleSelect
                            id={`${idPrefix}-select-state`}
                            options={STATES_FOR_FILTERS}
                            onSave={(option) => {
                              onChange(option.value);
                            }}
                            value={value}
                            removeSearch
                            label="State"
                            placeholder="Select State"
                            isRequired
                            errorMessage={error?.message}
                          />
                        );
                      }}
                    />
                  </Box>
                </Inline>
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            id={`${idPrefix}-save-and-close`}
            size="lg"
            type="outlined"
            title="Save & Close"
            state={
              isSubmitting === 'saveAndClose'
                ? 'loading'
                : isSubmitting
                ? 'disabled'
                : undefined
            }
            onClick={handleSubmit((data) => {
              addNewBranchHandler(data, 'saveAndClose');
            }, scrollBasedOnFormFieldsError)}
          />
          <Button
            id={`${idPrefix}-save-and-add-new`}
            size="lg"
            state={
              isSubmitting === 'saveAndAddNew'
                ? 'loading'
                : isSubmitting
                ? 'disabled'
                : undefined
            }
            title="Save & Add New"
            onClick={handleSubmit(
              (data) => addNewBranchHandler(data, 'saveAndAddNew'),
              scrollBasedOnFormFieldsError
            )}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
