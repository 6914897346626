import { Box, BoxOwnProps } from '../box/box';
import { TBoxColorStyles, TBoxStyles } from '../box/box.css';
import { Text } from '../text/text';
import { TTextStyles } from '../css/text.css';
import { IconParams } from '../../types';
import { SpinnerIcon } from '../icons/icons';

export interface ButtonProps {
  id?: string;
  title?: string;
  type?: 'filled' | 'outlined' | 'link' | 'text' | 'minorLink';
  state?: 'disabled' | 'loading';
  buttonRole?: 'default' | 'destructive' | 'success';
  size?: 'sm' | 'lg';
  leftIcon?: ({ color, size }: IconParams) => React.ReactElement;
  rightIcon?: ({ color, size }: IconParams) => React.ReactElement;
  marginTop?: BoxOwnProps['marginTop'];
  marginBottom?: BoxOwnProps['marginBottom'];
  marginLeft?: BoxOwnProps['marginLeft'];
  marginRight?: BoxOwnProps['marginRight'];
  marginX?: BoxOwnProps['marginX'];
  marginY?: BoxOwnProps['marginY'];
  width?: BoxOwnProps['width'];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const getBgColor = ({
  type,
  state,
  buttonRole,
}: ButtonProps): TBoxStyles['bgColor'] => {
  if (state === 'disabled' || state === 'loading')
    return {
      default: type === 'filled' ? 'surfaceDisabledAction' : undefined,
      hover: undefined,
    };

  switch (type) {
    case 'filled':
    default:
      switch (buttonRole) {
        case 'default':
        default:
          return {
            default: 'surfacePrimary',
            hover: 'surfacePrimaryHover',
          };
        case 'destructive':
          return {
            default: 'surfaceError',
            hover: 'surfaceErrorHover',
          };
        case 'success':
          return {
            default: 'surfaceSuccess',
            hover: 'surfaceSuccessHover',
          };
      }
    case 'outlined':
      switch (buttonRole) {
        case 'default':
        default:
          return {
            default: 'surfaceDefault',
            hover: 'surfacePrimaryLowest',
          };
        case 'destructive':
          return {
            default: 'surfaceDefault',
            hover: 'surfaceErrorLowest',
          };
        case 'success':
          return {
            default: 'surfaceDefault',
            hover: 'surfaceSuccessLowest',
          };
      }

    case 'text':
    case 'link':
    case 'minorLink':
      switch (buttonRole) {
        case 'default':
        default:
          return {
            default: 'surfaceDefault',
            hover: 'surfacePrimaryLowest',
          };
        case 'destructive':
          return {
            default: 'surfaceDefault',
            hover: 'surfaceErrorLowest',
          };
        case 'success':
          return {
            default: 'transparent',
            hover: 'surfaceSuccessLowest',
          };
      }
  }
};

const getTextColor = ({
  state,
  type,
  buttonRole,
}: ButtonProps): TBoxColorStyles['color'] => {
  if (state === 'disabled' || state === 'loading') {
    if (type === 'filled') return 'textLow';
    if (type === 'outlined') return 'textLowest';
    return 'textLowest';
  }
  switch (type) {
    case 'filled':
      return 'textOnSurface';
    case 'outlined':
    case 'link':
    case 'minorLink':
    case 'text':
      switch (buttonRole) {
        case 'default':
          return 'textPrimary';
        case 'destructive':
          return 'textError';
        case 'success':
          return 'textSuccess';
        default:
          return 'textPrimary';
      }
    default:
      return 'textOnSurface';
  }
};

const getIconColor = ({
  state,
  type,
  buttonRole,
}: ButtonProps): TBoxColorStyles['color'] => {
  if (state === 'disabled' || state === 'loading') {
    if (type === 'filled') return 'iconLow';
    if (type === 'outlined') return 'iconLowest';
    return 'iconLowest';
  }
  switch (type) {
    case 'filled':
      return 'iconOnSurface';
    case 'outlined':
    case 'link':
    case 'minorLink':
    case 'text':
      switch (buttonRole) {
        case 'default':
          return 'iconPrimary';
        case 'destructive':
          return 'iconError';
        case 'success':
          return 'iconSuccess';
        default:
          return 'iconPrimary';
      }
    default:
      return 'iconOnSurface';
  }
};

export function Button(props: ButtonProps) {
  const {
    id,
    type,
    leftIcon,
    rightIcon,
    title,
    state,
    width = 'auto',
    size,
    onClick,
    ...restProps
  } = props;

  const isLargeButton = size === 'lg';

  const getButtonStyleBasedUponType = (): BoxOwnProps & {
    iconColor: BoxOwnProps['color'];
    variation: TTextStyles['variation'];
  } => {
    switch (type) {
      case 'filled':
        return {
          bgColor: getBgColor(props),
          color: getTextColor(props),
          iconColor: getIconColor(props),
          borderWidth: '0',
          size: '2.5',
          variation: isLargeButton ? 'bt1' : 'bt2',
        };
      case 'outlined':
        return {
          bgColor: getBgColor(props),
          borderRadius: 'md',
          borderWidth: '1',
          borderColor: 'borderSeparator',
          color: getTextColor(props),
          iconColor: getIconColor(props),
          size: '2.5',
          variation: isLargeButton ? 'bt1' : 'bt2',
        };

      case 'text':
        return {
          bgColor: getBgColor(props),
          borderRadius: 'md',
          color: getTextColor(props),
          iconColor: getIconColor(props),
          borderWidth: '0',
          size: '2.5',
          variation: isLargeButton ? 'bt1' : 'bt2',
        };

      case 'link':
        return {
          bgColor: getBgColor(props),
          borderRadius: 'md',
          color: getTextColor(props),
          iconColor: getIconColor(props),
          borderWidth: '0',
          variation: 'lk',
          size: '2.5',
        };

      case 'minorLink':
        return {
          bgColor: getBgColor(props),
          borderRadius: 'md',
          color: getTextColor(props),
          iconColor: getIconColor(props),
          borderWidth: '0',
          variation: 'lk2',
          size: '1.5',
        };
      default:
        return {
          bgColor: getBgColor(props),
          color: getTextColor(props),
          iconColor: getIconColor(props),
          borderWidth: '0',
          size: '2.5',
          variation: isLargeButton ? 'bt1' : 'bt2',
        };
    }
  };

  const {
    size: iconSize,
    iconColor,
    ...boxConfig
  } = getButtonStyleBasedUponType();

  return (
    <Box
      id={id || title}
      {...boxConfig}
      as={'button'}
      onClick={
        state === 'disabled' || state === 'loading' ? undefined : onClick
      }
      width={width}
      display={'flex'}
      alignItems={'center'}
      paddingX={'2'}
      cursor="pointer"
      paddingLeft={rightIcon && '2.5'}
      paddingRight={leftIcon && '2.5'}
      paddingY={'1'}
      rounded="md"
      height={isLargeButton ? '6' : '5'}
      justifyContent="center"
      {...restProps}
    >
      {state === 'loading' ? (
        <Box marginRight={'1'} display={'flex'} alignSelf={'center'}>
          <SpinnerIcon size={'2.5'} color={iconColor} />
        </Box>
      ) : (
        !!leftIcon && (
          <Box marginRight={'1'} display={'flex'} alignSelf={'center'}>
            {leftIcon({
              color: iconColor,
              size: iconSize,
            })}
          </Box>
        )
      )}
      <Text
        style={{
          lineHeight: '20px',
        }}
        variation={boxConfig.variation}
        color={boxConfig.color}
      >
        {state === 'loading' ? title || 'Loading...' : title}
      </Text>
      {!!rightIcon && (
        <Box marginLeft={'0.5'} display={'flex'} alignSelf={'center'}>
          {state === 'loading' ? (
            <SpinnerIcon size={'2.5'} color={iconColor} />
          ) : (
            rightIcon({ color: iconColor, size: iconSize })
          )}
        </Box>
      )}
    </Box>
  );
}
