import { EmptyState, FileIcon } from '@nbfc-expense-tool/ui';
import { useUser } from '@nbfc-expense-tool/data-store/auth';
import { checkForSinglePermission } from '@nbfc-expense-tool/data-store/permissions';
import { AddNewInvoiceButton } from './AddNewInvoiceButton';

const EmptyInvoiceList: React.FC = () => {
  const { user } = useUser();
  const canAddNewInvoice = checkForSinglePermission(
    'createInvoice',
    user?.roles[0]?.permissions || null
  );

  return (
    <EmptyState
      renderIcon={(props) => <FileIcon {...props} />}
      title="No Invoices Found!"
      subText={canAddNewInvoice ? `Tap ‘Add New’ to create invoice` : undefined}
      renderButton={
        canAddNewInvoice ? () => <AddNewInvoiceButton /> : undefined
      }
    />
  );
};

export default EmptyInvoiceList;
