import {
  BaseOption,
  Box,
  Button,
  Checkbox,
  FullScreenHeader,
  Inline,
  MultiSelect,
  SingleSelect,
  Stack,
  Text,
  TextInput,
  toastQueue,
} from '@nbfc-expense-tool/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import {
  addEmployee,
  getBranchListing,
  getRolesListing,
  updateEmployee,
  AddEmployeePayload,
} from '@nbfc-expense-tool/data-store/utils';

import { parsePhoneNumber } from 'libphonenumber-js';
import { EmployeeListItem } from '@nbfc-expense-tool/data-store/dashboard';
import { FormDateInput } from '@nbfc-expense-tool/data-store/forms';

const stringValidation = z
  .string({ required_error: 'This field cannot be empty' })
  .nonempty('This field cannot be empty');

const createEmployeeSchema = z.object({
  name: stringValidation,
  code: stringValidation,
  mobile: z.string().refine(
    (value) => {
      const phoneNumber = parsePhoneNumber(value, 'IN');
      return phoneNumber ? phoneNumber.isValid() : false;
    },
    {
      message: 'Invalid phone number',
    }
  ),
  email: z
    .string()
    .email('Invalid email address')
    .nonempty('This field cannot be empty'),
  designation: z.string().nonempty('This field cannot be empty'),
  branches: z.array(z.string()).optional(),
  joiningDate: z.union([z.string().nullable(), z.undefined()]),
  exitDate: z.union([z.string().nullable(), z.undefined()]),
});

type FormInputs = {
  name: string;
  code: string;
  mobile: string;
  email: string;
  branches: string[];
  designation: string;
  joiningDate: string | null;
  exitDate: string | null;
};

const idPrefix = 'add-new-employee';

export default function AddNewEmployee() {
  const navigate = useNavigate();
  const [branchOptions, setBranchOptions] = useState<BaseOption[]>([]);
  const [roleOptions, setRoleOptions] = useState<BaseOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingAddNew, setLoadingAddNew] = useState(false);
  const [loadingUpdateAction, setLoadingUpdateAction] = useState(false);
  const location = useLocation();
  const prevEmployee: EmployeeListItem = location.state?.employeeDetails;
  const isEditFlow = prevEmployee?.id;
  const { control, handleSubmit, setValue, clearErrors } = useForm<FormInputs>({
    resolver: zodResolver(createEmployeeSchema),
  });
  const [canLogIn, setCanLogIn] = useState(
    prevEmployee ? Boolean(prevEmployee.can_login) : true
  );

  useEffect(() => {
    if (prevEmployee) {
      setValue(
        'branches',
        prevEmployee.branches.map((item) => `${item.id}`)
      );
      setValue('code', prevEmployee.employee_code);
      setValue('designation', prevEmployee.roles[0].name);
      setValue('email', prevEmployee.email);
      setValue('mobile', prevEmployee.phone_number);
      setValue('name', prevEmployee.name);
      if (prevEmployee.org_left_at) {
        setValue('exitDate', prevEmployee.org_left_at);
      }
      if (prevEmployee.org_joined_at) {
        setValue('joiningDate', prevEmployee.org_joined_at);
      }
    }
  }, [prevEmployee, setValue]);

  useEffect(() => {
    getBranchListing()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const branchesData = res.data.map((item: any) => {
          return {
            ...item,
            label: item.name,
            value: `${item.id}`,
          };
        });
        setBranchOptions(branchesData);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => null);
  }, []);

  useEffect(() => {
    getRolesListing()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rolesData = res.data.map((item: any) => {
          return {
            ...item,
            label: item.display_name,
            value: `${item.name}`,
          };
        });
        setRoleOptions(rolesData);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => null);
  }, []);

  const submitDetails = (values: FormInputs) => {
    setLoading(true);
    const addEmployeePayload: AddEmployeePayload = {
      branches: values.branches
        ? values.branches.map((item) => Number(item))
        : [],
      can_login: canLogIn,
      email: values.email,
      employee_code: values.code,
      phone_number: values.mobile,
      name: values.name,
      role: values.designation,
    };
    if (values.joiningDate) {
      addEmployeePayload['org_joined_at'] = values.joiningDate;
    }
    if (values.exitDate) {
      addEmployeePayload['org_left_at'] = values.exitDate;
    }
    addEmployee(addEmployeePayload)
      .then((res) => {
        toastQueue.add(
          {
            title: 'Employee added successfully',
            type: 'success',
          },
          {
            timeout: 2000,
          }
        );
        navigate('/home/employees');
      })
      .catch((err) => {
        toastQueue.add(
          {
            title: err?.message || 'Something Went Wrong! Please try again',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
        setLoading(false);
      });
  };

  const clearPreviousValues = () => {
    setValue('branches', []);
    setValue('code', '');
    setValue('designation', '');
    setValue('email', '');
    setValue('mobile', '');
    setValue('name', '');
  };

  const submitDetailsAndAddNew = (values: FormInputs) => {
    setLoadingAddNew(true);
    const addNewEmployeePayload: AddEmployeePayload = {
      branches: values.branches
        ? values.branches.map((item) => Number(item))
        : [],
      can_login: canLogIn,
      email: values.email,
      employee_code: values.code,
      phone_number: values.mobile,
      name: values.name,
      role: values.designation,
    };
    if (values.joiningDate) {
      addNewEmployeePayload['org_joined_at'] = values.joiningDate;
    }
    if (values.exitDate) {
      addNewEmployeePayload['org_left_at'] = values.exitDate;
    }
    addEmployee(addNewEmployeePayload)
      .then((res) => {
        toastQueue.add(
          {
            title: 'Employee added successfully',
            type: 'success',
          },
          {
            timeout: 2000,
          }
        );
        clearPreviousValues();
        setLoadingAddNew(false);
      })
      .catch((err) => {
        toastQueue.add(
          {
            title: err?.message || 'Something Went Wrong! Please try again',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
        setLoadingAddNew(false);
      });
  };

  const submitDetailsAndUpdate = (values: FormInputs) => {
    setLoadingUpdateAction(true);
    const editEmployeePayload: AddEmployeePayload = {
      branches: values.branches
        ? values.branches.map((item) => Number(item))
        : [],
      can_login: canLogIn,
      email: values.email,
      employee_code: values.code,
      phone_number: values.mobile,
      name: values.name,
      role: values.designation,
    };
    if (values.joiningDate) {
      editEmployeePayload['org_joined_at'] = values.joiningDate;
    }
    if (values.exitDate) {
      editEmployeePayload['org_left_at'] = values.exitDate;
    }
    updateEmployee(prevEmployee.id, editEmployeePayload)
      .then((res) => {
        toastQueue.add(
          {
            title: 'Employee details updated successfully',
            type: 'success',
          },
          {
            timeout: 2000,
          }
        );
        navigate(-1);
      })
      .catch((err) => {
        toastQueue.add(
          {
            title: err?.message || 'Something Went Wrong! Please try again',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
        setLoadingUpdateAction(false);
      });
  };

  return (
    <Box>
      <FullScreenHeader
        headingText={
          isEditFlow ? 'Update Employee Details' : 'Add New Employee'
        }
        onClickCross={() => navigate(-1)}
        borderBottomWidth="1"
        borderColor="borderSeparator"
        position="sticky"
        top="0"
        zIndex="10"
      />
      <Stack marginLeft="5">
        <Text variation={'t3'} marginY={'3'}>
          Name & Code
        </Text>
        <Box display={'flex'} gap={'5'}>
          <Controller
            control={control}
            name="name"
            render={(props) => {
              const {
                fieldState: { error },
                field: { onChange, onBlur, value },
              } = props;
              return (
                <TextInput
                  id={`${idPrefix}-enter-employee-name`}
                  label={'Employee Name'}
                  isRequired
                  placeholder="Enter employee name"
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                  value={value !== undefined ? String(value) : undefined}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="code"
            render={(props) => {
              const {
                fieldState: { error },
                field: { onChange, onBlur, value },
              } = props;
              return (
                <TextInput
                  id={`${idPrefix}-enter-employee-code`}
                  label={'Employee Code'}
                  isRequired
                  placeholder="Enter employee code"
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                  value={value !== undefined ? String(value) : undefined}
                />
              );
            }}
          />
        </Box>
      </Stack>

      <Stack marginLeft="5">
        <Text variation={'t3'} marginY={'3'}>
          Official Details
        </Text>
        <Box display={'flex'} gap={'5'}>
          <Controller
            control={control}
            name="designation"
            render={(props) => {
              const {
                fieldState: { error },
                field: { onChange, value },
              } = props;
              return (
                <SingleSelect
                  id={`${idPrefix}-select-designation`}
                  style={{ width: 300 }}
                  options={roleOptions}
                  onSave={(option) => {
                    clearErrors('designation');
                    onChange(option.value);
                  }}
                  value={value}
                  label="Designation"
                  isRequired
                  errorMessage={error?.message}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="branches"
            render={(props) => {
              const {
                field: { onChange, value },
              } = props;
              return (
                <MultiSelect
                  id={`${idPrefix}-select-branches`}
                  type="textInput"
                  style={{ width: 300 }}
                  options={branchOptions}
                  onSave={onChange}
                  label="Branch"
                  value={value}
                  inputProps={{
                    isRequired: false,
                  }}
                />
              );
            }}
          />
        </Box>
        <Box display={'flex'} gap={'5'} marginTop="3">
          <Box style={{ width: 300 }}>
            <FormDateInput
              id={`${idPrefix}-enter-joining-date`}
              control={control}
              name="joiningDate"
              label="Joining Date"
              isRequired={false}
              isControlled
              datePickerProps={{
                placeholderText: 'Select Joining Date',
              }}
              onChangeDate={(date) => {
                setValue('joiningDate', date?.toISOString() || null);
                if (date?.toISOString()) {
                  clearErrors('joiningDate');
                }
              }}
              fullWidth
            />
          </Box>
          <Box style={{ width: 300 }}>
            <FormDateInput
              id={`${idPrefix}-enter-exit-date`}
              control={control}
              name="exitDate"
              label="Exit Date"
              isRequired={false}
              isControlled
              datePickerProps={{
                placeholderText: 'Select Exit Date',
              }}
              onChangeDate={(date) => {
                setValue('exitDate', date?.toISOString() || null);
                if (date?.toISOString()) {
                  clearErrors('exitDate');
                }
              }}
              fullWidth
            />
          </Box>
        </Box>
      </Stack>

      <Stack marginLeft="5" marginBottom="10">
        <Text variation={'t3'} marginY={'3'}>
          Contact
        </Text>
        <Box display={'flex'} gap={'5'}>
          <Controller
            control={control}
            name="mobile"
            render={(props) => {
              const {
                fieldState: { error },
                field: { onChange, onBlur, value },
              } = props;
              return (
                <TextInput
                  id={`${idPrefix}-enter-mobile-number`}
                  label={'Mobile Number'}
                  isRequired
                  placeholder="Enter mobile name"
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                  value={value !== undefined ? String(value) : undefined}
                  leftIcon={() => (
                    <Text variation={'b2'} color={'textMedium'}>
                      +91
                    </Text>
                  )}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="email"
            render={(props) => {
              const {
                fieldState: { error },
                field: { onChange, onBlur, value },
              } = props;
              return (
                <TextInput
                  id={`${idPrefix}-enter-email`}
                  label={'Email ID'}
                  isRequired
                  placeholder="E.g. abc@xyz.com"
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                  value={value !== undefined ? String(value) : undefined}
                />
              );
            }}
          />
        </Box>
        <Inline
          alignItems="center"
          onClick={() => setCanLogIn((prevState) => !prevState)}
          cursor="pointer"
          marginTop="3"
        >
          <Checkbox
            isSelected={canLogIn}
            onChange={(value) => setCanLogIn(value)}
          />
          <Text variation="c2" marginLeft="1.5">
            Can Login In Dashboard?
          </Text>
        </Inline>
      </Stack>

      <Box
        width={'auto'}
        display={'flex'}
        alignItems={'center'}
        justifyContent="end"
        backgroundColor="surfaceDefault"
        paddingY="2"
        paddingX="5"
        borderTopWidth="1"
        borderColor="borderSeparator"
        position="sticky"
        bottom="0"
        zIndex="10"
      >
        {isEditFlow ? (
          <>
            <Box marginRight="2">
              <Button
                id={`${idPrefix}-cancel-edit`}
                title="Cancel"
                type="outlined"
                onClick={() => navigate(-1)}
              />
            </Box>
            <Box>
              <Button
                id={`${idPrefix}-update-details-button`}
                title={'Update Details'}
                type="filled"
                state={loadingUpdateAction ? 'loading' : undefined}
                onClick={handleSubmit(submitDetailsAndUpdate)}
              />
            </Box>
          </>
        ) : (
          <>
            <Box marginRight="2">
              <Button
                id={`${idPrefix}-save-and-close`}
                title="Save & Close"
                type="outlined"
                state={loading ? 'loading' : undefined}
                onClick={handleSubmit(submitDetails)}
              />
            </Box>
            <Box>
              <Button
                id={`${idPrefix}-save-and-add-new`}
                title={'Save & Add New'}
                type="filled"
                state={loadingAddNew ? 'loading' : undefined}
                onClick={handleSubmit(submitDetailsAndAddNew)}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
