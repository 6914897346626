export const PERMISSION_LIST = {
  // Roles
  CREATE_ROLE: 'roles.create',
  UPDATE_ROLE: 'roles.update',
  READ_ROLE: 'roles.read',
  DELETE_ROLE: 'roles.delete',
  ASSIGN_ROLE: 'roles.assign',

  // Branches
  CREATE_BRANCH: 'branches.create',
  UPDATE_BRANCH: 'branches.update',
  READ_BRANCH: 'branches.read',
  DELETE_BRANCH: 'branches.delete',
  IMPORT_BRANCH: 'branches.import',

  // Employees
  READ_ANY_EMPLOYEE: 'users.read_any',
  CREATE_ANY_EMPLOYEE: 'users.create_any',
  UPDATE_ANY_EMPLOYEE: 'users.update_any',
  DELETE_ANY_EMPLOYEE: 'users.delete_any',
  IMPORT_ANY_EMPLOYEE: 'users.import_any',

  READ_BRANCH_EMPLOYEE: 'users.read_branch',
  CREATE_BRANCH_EMPLOYEE: 'users.create_branch',
  UPDATE_BRANCH_EMPLOYEE: 'users.update_branch',
  DELETE_BRANCH_EMPLOYEE: 'users.delete_branch',

  // Vendors
  READ_ANY_VENDOR: 'vendors.read_any',
  CREATE_ANY_VENDOR: 'vendors.create_any',
  UPDATE_ANY_VENDOR: 'vendors.update_any',
  DELETE_ANY_VENDOR: 'vendors.delete_any',
  IMPORT_ANY_VENDOR: 'vendors.import_any',

  READ_BRANCH_VENDOR: 'vendors.read_branch',
  CREATE_BRANCH_VENDOR: 'vendors.create_branch',
  UPDATE_BRANCH_VENDOR: 'vendors.update_branch',
  DELETE_BRANCH_VENDOR: 'vendors.delete_branch',

  // Invoices
  CREATE_ANY_INVOICE: 'invoices.create_any',
  READ_ANY_INVOICE: 'invoices.read_any',
  UPDATE_ANY_INVOICE: 'invoices.update_any',
  DELETE_ANY_INVOICE: 'invoices.delete_any',
  MARK_PAID_ANY_INVOICE: 'invoices.markpaid_any',

  CREATE_BRANCH_INVOICE: 'invoices.create_branch',
  READ_BRANCH_INVOICE: 'invoices.read_branch',
  UPDATE_BRANCH_INVOICE: 'invoices.update_branch',
  DELETE_BRANCH_INVOICE: 'invoices.delete_branch',
  MARK_PAID_BRANCH_INVOICE: 'invoices.markpaid_branch',

  CREATE_OWN_INVOICE: 'invoices.create_own',
  READ_OWN_INVOICE: 'invoices.read_own',
  UPDATE_OWN_INVOICE: 'invoices.create_own',
  DELETE_OWN_INVOICE: 'invoices.update_own',
  MARK_PAID_OWN_INVOICE: 'invoices.delete_own',

  // Dashboard
  VIEW_DASHBOARD: 'dashboard.view_any',

  READ_VENDOR_APPROVAL: 'vendor-approval.read',
  READ_INVOICE_APPROVAL: 'invoice-approval.read',
  READ_REIMBURSEMENT_APPROVAL: 'reimbursement-approval.read',

  // reimbursements
  READ_ANY_REIMBURSEMENT: 'reimbursements.read_any',
  CREATE_ANY_REIMBURSEMENT: 'reimbursements.create_any',
  UPDATE_ANY_REIMBURSEMENT: 'reimbursements.update_any',
  DELETE_ANY_REIMBURSEMENT: 'reimbursements.delete_any',
  MARK_PAID_ANY_REIMBURSEMENT: 'reimbursements.markpaid_any',
  READ_BRANCH_REIMBURSEMENT: 'reimbursements.read_branch',
  CREATE_BRANCH_REIMBURSEMENT: 'reimbursements.create_branch',
  UPDATE_BRANCH_REIMBURSEMENT: 'reimbursements.update_branch',
  DELETE_BRANCH_REIMBURSEMENT: 'reimbursements.delete_branch',
  READ_OWN_REIMBURSEMENT: 'reimbursements.read_own',
  CREATE_OWN_REIMBURSEMENT: 'reimbursements.create_own',
  UPDATE_OWN_REIMBURSEMENT: 'reimbursements.update_own',
  DELETE_OWN_REIMBURSEMENT: 'reimbursements.delete_own',
};

export const WILD_CARD_PERMISSIONS = {
  invoices: 'invoices',
  branches: 'branches',
  roles: 'roles',
  employees: 'users',
  vendors: 'vendors',
  dashboard: 'dashboard',
  rules_policies: 'rules_policies',
  reimbursements: 'reimbursements',
};
