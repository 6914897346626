export const API_END_POINTS = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  INVOICES: 'invoices',
  VENDORS_LIST: 'vendors/list',
  BRANCHES_LIST_FILTER: 'branches/list',
  ROLES_LIST: 'admin/roles',
  ADD_EMPLOYEE: 'admin/users',
  INVOICES_AWAITED_FOR_APPROVAL: 'invoices/waiting-my-approval',
  INVOICE_DETAILS: 'invoices/:invoiceTicketNumber',
  APPROVE_INVOICE: 'invoices/:invoiceTicketNumber/approve',
  REJECT_INVOICE: 'invoices/:invoiceTicketNumber/reject',
  RETURN_INVOICE: 'invoices/:invoiceTicketNumber/return',
  MARK_PAID_INVOICE: 'invoices/:invoiceTicketNumber/mark-paid',
  DELETE_INVOICE: 'invoices/:invoiceTicketNumber',
  UPLOAD_MEDIA: 'upload',
  HSN_CODES_LIST: 'hsn-codes/list',
  SAC_CODES_LIST: 'sac-codes/list',
  EXPENSE_HEAD_LIST: 'expense-types/list',
  TAX_CALCULATOR: 'tax-calculator',
  BRANCH_LIST: 'admin/branches',
  BUSINESS_BY_FILTERS: 'admin/branches/list-business-by',
  RENT_BY_FILTERS: 'admin/branches/list-rent-by',
  GET_PROFILE: 'users/me',
  CREATE_VENDOR: 'vendors',
  UPDATE_VENDOR: 'vendors/:vendorId',
  DEACTIVATE_VENDOR: 'vendors/:vendorId/deactivate',
  REACTIVATE_VENDOR: 'vendors/:vendorId/reactivate',
  GET_VENDOR_DETAILS: 'vendors/:vendorId',
  GET_VENDOR_TYPES: 'vendor-types/list',
  GET_TYPED_INVOICES_STAT: 'invoices/aggr-stats',
  GET_INVOICES_PAYMENT_STAT: 'invoices/payment-wise-aggr-stats',
  GET_WAITING_FOR_APPROVAL_STAT: 'analytics/waiting-my-approval',
  GET_INVOICE_APPROVAL_TIME_STAT: 'analytics/invoice-approval-time',
  GET_SPEND_STAT: 'analytics/spend-trend',
  GET_EXPENSE_BREAKDOWN: 'analytics/expense-breakdown',
  ALL_INVOICES_LIST: 'invoices/all',
  GET_UNAPPROVED_VENDORS_PAGINATED: 'vendors/waiting-my-approval',
  GET_PAGINATED_VENDOR_LISTING: 'vendors',
  LIST_EMPLOYEES: 'admin/users',
  TAX_SLABS: 'tax-slabs/list',
  DOWNLOAD_IMPORT_VENDORS_SAMPLE_FILE: 'vendors/import/sample',
  DOWNLOAD_IMPORT_BRANCHES_SAMPLE_FILE: 'admin/branches/import/sample',
  DOWNLOAD_IMPORT_EMPLOYEES_SAMPLE_FILE: 'admin/users/import/sample',
  IMPORT_VENDORS: 'vendors/import',
  IMPORT_BRANCHES: 'admin/branches/import',
  IMPORT_EMPLOYEES: 'admin/users/import',
  LIST_APPROVAL_RULES: 'admin/approval-rules',
  LIST_ALL_PERMISSIONS: 'admin/permissions',
  REJECT_VENDOR: 'vendors/:vendorId/reject',
  APPROVE_VENDOR: 'vendors/:vendorId/approve',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  LIST_REIMBURSEMENT_EXPENSE_TYPES: 'reimbursement-expense-types/list',
  CREATE_REIMBURSEMENT: 'reimbursements',
  UPDATE_REIMBURSEMENT: 'reimbursements/:reimbursementId',
  APPROVE_REIMBURSEMENT: 'reimbursements/:reimbursementId/approve',
  REJECT_REIMBURSEMENT: 'reimbursements/:reimbursementId/reject',
  RETURN_REIMBURSEMENT: 'reimbursements/:reimbursementId/return',
  MARK_PAID_REIMBURSEMENT: 'reimbursements/:reimbursementId/mark-paid',
  DELETE_REIMBURSEMENT: 'reimbursements/:reimbursementId',
  GET_REIMBURSEMENT_DETAILS: 'reimbursements/:reimbursementId',
  REIMBURSEMENTS: 'reimbursements',
  REIMBURSEMENTS_AGGREGATE: 'reimbursements/aggr-stats',
  WAITING_MY_APPROVAL_REIMBURSEMENTS: 'reimbursements/waiting-my-approval',
  ALL_REIMBURSEMENTS: 'reimbursements/all',
  LIST_ALL_EMPLOYEES: 'users/list',
  INVOICE_OCR_UPLOAD: 'invoices/import/parse',
  GET_IFSC_DETAILS: 'ifsc-search/:ifscCode',
  GET_TENANT_CONFIG: 'tenants/config',
  GET_NOTIFICATIONS: 'notifications',
  MARK_READ_NOTIFICATIONS: 'notifications/read',
};
