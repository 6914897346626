import { Box, Stack, Tabs, Header } from '@nbfc-expense-tool/ui';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Item } from 'react-stately';
import { useMemo, createContext } from 'react';
import { AddNewReimbursementButton } from '../../Reimbursement';
import AddOrEditReimbursement from './AddOrEditReimbursement';
import ReimbursementDetails from './ReimbursementDetails';
import { CreatedByYou } from './CreatedByYou';
import { WaitingForApproval } from './WaitingForApproval';
import { AllReimbursements } from './AllReimbursements';
import {
  EMPLOYEE_TYPES_FOR_FILTER,
  useEmployeesForFilter,
} from '@nbfc-expense-tool/data-store/dashboard';
import { TypedReimbursementsPage as TypedReimbursements } from './TypedReimbursements';

export {
  CreatedByYou as ReimbursementsCreatedByYou,
  WaitingForApproval as ReimbursementsWaitingForApproval,
  AllReimbursements,
  TypedReimbursements,
  AddOrEditReimbursement,
  ReimbursementDetails,
};

export type FilterContextType = {
  employeesStatus: 'success' | 'in_progress' | 'failed';
  employees?: EMPLOYEE_TYPES_FOR_FILTER[];
};

export const ReimbursementFilterContext =
  createContext<FilterContextType | null>(null);

type FilterContextProps = {
  children: React.ReactNode;
};

// Create a provider component that holds the state/props you want to pass
const FilterContextProvider: React.FC<FilterContextProps> = ({ children }) => {
  const { status: employeesStatus, employees } = useEmployeesForFilter();

  return (
    <ReimbursementFilterContext.Provider value={{ employeesStatus, employees }}>
      {children}
    </ReimbursementFilterContext.Provider>
  );
};

const TabOptions = [
  {
    title: 'Created by You',
    path: 'created-by-you',
  },
  {
    title: 'Waiting For Your Approval',
    path: 'waiting-for-your-approval',
  },
  {
    title: 'All Reimbursements',
    path: 'all-reimbursements',
  },
];

export function ReimbursementsLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { type, reimbursementId } = useParams();

  const activePath = useMemo(() => {
    const activePath = TabOptions.find((option) =>
      pathname.startsWith(`/home/reimbursements/${option.path}`)
    );
    return activePath?.path || TabOptions[0].path;
  }, [pathname]);

  const option = useMemo(() => {
    return getLayoutOption(Boolean(type || reimbursementId));
  }, [reimbursementId, type]);

  return option === 'typed_reimbursements' ? (
    <Outlet />
  ) : (
    <FilterContextProvider>
      <Stack flex="1" paddingX="4" paddingY="5" gap="3">
        <Header
          headingText="Reimbursement"
          renderRight={() => (
            <AddNewReimbursementButton id="reimbursement-home" />
          )}
        />
        <Tabs
          selectedKey={activePath}
          onClick={(path: string) => navigate(path)}
        >
          {TabOptions.map((option) => (
            <Item
              key={option.path}
              textValue={option.path}
              title={
                <Box textDecoration="none" style={{ color: 'inherit' }}>
                  {option.title}
                </Box>
              }
            >
              <Outlet />
            </Item>
          ))}
        </Tabs>
      </Stack>
    </FilterContextProvider>
  );
}

function getLayoutOption(
  paramsIncluded: boolean
): 'typed_reimbursements' | 'reimbursements' {
  return paramsIncluded ? 'typed_reimbursements' : 'reimbursements';
}
