import {
  startOfDay,
  endOfDay,
  startOfMonth,
  startOfQuarter,
  getYear,
  isAfter,
  subYears,
  set,
  format as formatDate,
  parseISO as parseISODate,
  isSameYear,
  endOfMonth,
  startOfYear,
  endOfYear,
  endOfQuarter,
  getQuarter,
} from 'date-fns';

export { startOfDay, formatDate, parseISODate };

export function getMonthInterval(filterDate?: Date): [Date, Date] {
  const date = filterDate || new Date();
  return [startOfMonth(date), endOfMonth(date)];
}

export function getYearInterval(filterDate?: Date): [Date, Date] {
  const date = filterDate || new Date();
  return [startOfYear(date), endOfYear(date)];
}

export function getQuarterInterval(filterDate?: Date): [Date, Date] {
  const date = filterDate || new Date();
  return [startOfQuarter(date), endOfQuarter(date)];
}

export function getFinancialQuarter(filterDate?: Date): number {
  const date = filterDate || new Date();
  const quarterNumber = getQuarter(date);
  if (quarterNumber === 1) {
    return 4;
  } else {
    return quarterNumber - 1;
  }
}

export function getMonthToDateInterval(relativeTo?: Date): [Date, Date] {
  const date = relativeTo ? new Date(relativeTo) : new Date();
  return [startOfMonth(date), endOfDay(date)];
}

export function getQuarterToDateInterval(relativeTo?: Date): [Date, Date] {
  const date = relativeTo ? new Date(relativeTo) : new Date();
  return [startOfQuarter(date), endOfDay(date)];
}

export function getFinancialYearToDateInterval(
  relativeTo?: Date
): [Date, Date] {
  const currentDate = relativeTo || new Date();
  const currentYear = getYear(currentDate);

  // Determine the start of the current financial year (April 1st of the current year).
  const startOfFinancialYear = set(new Date(), {
    year: currentYear,
    month: 3, // April is 0-indexed
    date: 1,
  });

  // Check if the current date is before or after the start of the financial year.
  if (isAfter(currentDate, startOfFinancialYear)) {
    // Current date is in or after the current financial year.
    return [startOfDay(startOfFinancialYear), endOfDay(currentDate)];
  } else {
    // Current date is before the start of the current financial year.
    // In this case, we need to go back one year to get the previous financial year.
    const previousFinancialYearStart = set(subYears(startOfFinancialYear, 1), {
      date: 1,
    });
    return [startOfDay(previousFinancialYearStart), endOfDay(currentDate)];
  }
}

export function getStaticDateIntervals(relativeTo?: Date) {
  const monthToDateInterval = getMonthToDateInterval(relativeTo);
  const quarterToDateInterval = getQuarterToDateInterval(relativeTo);
  const financialYearToDateInterval =
    getFinancialYearToDateInterval(relativeTo);

  const isCurrentYear = isSameYear(...financialYearToDateInterval);

  return [
    {
      id: 'monthToDate',
      interval: monthToDateInterval,
      label: `Month to Date (${formatDate(
        monthToDateInterval[0],
        'd MMM'
      )} - ${formatDate(monthToDateInterval[1], 'd MMM')})` as const,
    },
    {
      id: 'quarterToDate',
      interval: quarterToDateInterval,
      label: `Quarter to Date (${formatDate(
        quarterToDateInterval[0],
        'd MMM'
      )} - ${formatDate(quarterToDateInterval[1], 'd MMM')})` as const,
    },
    {
      id: 'financialYearToDate',
      interval: financialYearToDateInterval,
      label: `F.Y. to Date (${formatDate(
        financialYearToDateInterval[0],
        isCurrentYear ? 'd MMM' : 'd MMM yy'
      )} - ${formatDate(
        financialYearToDateInterval[1],
        isCurrentYear ? 'd MMM' : 'd MMM yy'
      )})` as const,
    },
  ];
}
