import { useRef, useState } from 'react';
import type { AriaTextFieldProps } from 'react-aria';
import { useTextField } from 'react-aria';
import { Text } from '../text/text';
import Stack from '../stack/stack';
import { Box, BoxOwnProps } from '../box/box';
import { IconParams } from '../../types';
import { borderColorStyles, inputColorStyles } from '../css/common.css';
import { AlertCircleIcon, InfoCircle } from '../icons/icons';
import { HoverableBox } from '../hoverableBox/hoverableBox';
import { Inline } from '../inline/inline';

export interface TextInputProps extends AriaTextFieldProps {
  leftIcon?: ({ color, size }: IconParams) => React.ReactElement;
  rightIcon?: ({ color, size }: IconParams) => React.ReactElement;
  fullWidth?: boolean;
  marginTop?: BoxOwnProps['marginTop'];
  marginBottom?: BoxOwnProps['marginBottom'];
  marginLeft?: BoxOwnProps['marginLeft'];
  marginRight?: BoxOwnProps['marginRight'];
  marginX?: BoxOwnProps['marginX'];
  marginY?: BoxOwnProps['marginY'];
  width?: BoxOwnProps['width'];
  removeMinWidth?: boolean;
  minHeight?: React.ComponentProps<typeof Box>['minHeight'];
  fieldInfo?: string;
}

export function TextInput({
  leftIcon,
  rightIcon,
  fullWidth = false,
  removeMinWidth = false,
  minHeight,
  fieldInfo,
  ...props
}: TextInputProps) {
  const { label } = props;
  const ref = useRef(null);

  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(
      {
        ...props,
        'aria-label':
          props['aria-label'] ||
          props.label?.toString() ||
          props.name ||
          props.placeholder,
      },
      ref
    );

  const [isFocused, setFocused] = useState(false);

  inputProps.onFocus = () => {
    setFocused(true);
  };

  inputProps.onBlur = () => {
    setFocused(false);
  };

  const { color: _, width, height, size, ...restInputProps } = inputProps;

  return (
    <Stack
      display={'flex'}
      style={{
        minWidth: removeMinWidth ? undefined : 300,
      }}
      width={fullWidth ? 'full' : undefined}
      maxWidth={!fullWidth ? 'min' : undefined}
    >
      <Inline gap={'1'}>
        <Text
          as="label"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          color={'textHigh' as any}
          variation="c2"
          {...labelProps}
        >
          {label}
          {props.isRequired && (
            <Text marginLeft={'0.5'} as="span" color={'textError'}>
              *
            </Text>
          )}
        </Text>
        {fieldInfo?.length && (
          <HoverableBox text={fieldInfo}>
            <InfoCircle size="2" color="iconLow" marginRight="1" />
          </HoverableBox>
        )}
      </Inline>
      <Box borderWidth={isFocused ? '0' : '1'} borderColor="transparent">
        <Box
          display={'flex'}
          paddingLeft={!leftIcon ? '1.5' : undefined}
          borderWidth={isFocused ? '2' : '1'}
          borderRadius="md"
          paddingY={minHeight ? undefined : '1.5'}
          minHeight={minHeight}
          bgColor={props.isDisabled ? 'surfaceNeutralLowest' : undefined}
          className={borderColorStyles({
            color: props.errorMessage
              ? 'borderError'
              : isFocused
              ? 'borderPrimary'
              : 'borderOutline',
          })}
          marginTop="0.5"
        >
          {!!leftIcon && (
            <Box
              marginLeft={'1.5'}
              marginRight={'1'}
              display={'flex'}
              alignSelf={'center'}
            >
              {leftIcon({
                color: props.errorMessage
                  ? 'iconError'
                  : isFocused
                  ? 'iconPrimary'
                  : 'iconLow',
                size: '3',
              })}
            </Box>
          )}
          <Text
            variation={'b2'}
            display={'flex'}
            flex={'1'}
            as="input"
            paddingRight={'2'}
            {...restInputProps}
            ref={ref}
            className={inputColorStyles({
              color: {
                default: 'textHigh',
                placeholder: 'textLowest',
              },
            })}
            borderRadius="md"
          ></Text>
          {!!rightIcon && (
            <Box alignItems="center" display="flex" marginRight="1.5">
              {rightIcon({
                color: 'iconMedium',
                size: '3',
              })}
            </Box>
          )}
          {!!props.errorMessage && (
            <AlertCircleIcon
              size={'3'}
              color={'iconError'}
              marginRight={'1.5'}
            />
          )}
        </Box>
      </Box>
      {!props.errorMessage && props.description && (
        <Text
          marginTop={'0.5'}
          as="p"
          {...descriptionProps}
          variation={'c2'}
          color={'textLow'}
        >
          {props.description}
        </Text>
      )}
      {props.errorMessage && (
        <Text
          marginTop={'0.5'}
          borderRadius="md"
          paddingY={'0.5'}
          paddingX={'1'}
          display={'flex'}
          {...errorMessageProps}
          color={'textError'}
          variation={'c2'}
          bgColor={'surfaceErrorLowest'}
        >
          {props.errorMessage}
        </Text>
      )}
    </Stack>
  );
}
