import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';
import { Box } from '../box/box';
import { ElementType, forwardRef, useEffect, useMemo, useState } from 'react';
import { Text } from '../text/text';
import {
  ArrowDownFilledIcon,
  CalendarIcon,
  CrossIcon,
  SearchIcon,
  SpinnerIcon,
} from '../icons/icons';
import { Inline } from '../inline/inline';
import { MenuContext, MenuList } from '../menu/menu';
import { useOverlayTriggerState } from 'react-stately';
import { Radio, RadioGroup } from '../radio/radio';
import Stack from '../stack/stack';
import Checkbox from '../checkbox/checkbox';
import { Button } from '../button/button';
import { TextInput } from '../textinput/textinput';
import { getStaticDateIntervals } from '@nbfc-expense-tool/util-dates';
import { useFocusWithin } from 'react-aria';

const DefaultElement = 'div';

export type BaseOption = {
  label: string;
  value: string;
};

type SelectBaseProps = {
  label?: string;
  labelIcon?: React.ReactNode;
  options?: Array<BaseOption>;
  cancelBtnTitle?: string;
  actionBtnTitle?: string;
  loadingOptions?: boolean;
};

type SelectOwnProps = SelectBaseProps & {
  value?: string;
  onSave: (option: BaseOption | null) => void;
  onCancel?: () => void;
};

export type SelectProps<C extends React.ElementType = typeof DefaultElement> =
  PolymorphicComponentPropWithRef<C, SelectOwnProps>;

type SelectComponent = <C extends React.ElementType = typeof DefaultElement>(
  props: SelectProps<C>
) => React.ReactNode;

export const Select: SelectComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultElement>(
    {
      as,
      label,
      options,
      value,
      cancelBtnTitle,
      actionBtnTitle,
      onSave,
      onCancel,
      id,
      ...props
    }: SelectProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Element: ElementType = as || DefaultElement;

    const menuState = useOverlayTriggerState({});
    const [selected, setSelected] = useState<BaseOption | null>(null);

    const menuContext = useMemo(() => {
      return {
        isExpanded: menuState.isOpen,
        open: () => {
          menuState.open();
          // This will ensure that we have latest options available on every open
        },
        close: () => {
          // focus the container before closing for better focus management
          menuState.close();
          // reset the query
        },
        toggle: () => (menuState.isOpen ? menuState.close() : menuState.open()),
      };
    }, [menuState]);

    const [isFocusWithin, setFocusWithin] = useState(false);

    const { focusWithinProps } = useFocusWithin({
      onFocusWithinChange: (isFocusWithin) => setFocusWithin(isFocusWithin),
    });

    useEffect(() => {
      if (!isFocusWithin) menuState.close();
    }, [menuState, isFocusWithin]);

    const saveOptions = () => {
      if (selected) {
        onSave(selected);
      }
      menuContext.close();
    };

    useEffect(() => {
      if (!menuContext.isExpanded) {
        const appliedValue = options?.find((option) =>
          value?.includes(option.value)
        );
        setSelected(appliedValue?.value ? appliedValue : null);
      }
    }, [menuContext.isExpanded, options, value]);

    const hasValue: boolean = useMemo(() => {
      return Boolean(value?.length);
    }, [value]);

    const labelText: string = useMemo(() => {
      const appliedValue = options?.find((option) =>
        value?.includes(option.value)
      );
      return appliedValue?.label ? appliedValue.label : label || 'Select';
    }, [label, options, value]);

    const onCancelClick = () => {
      if (cancelBtnTitle === 'Clear' || cancelBtnTitle === 'Reset') {
        setSelected(null);
        onCancel?.();
      }
      menuContext.close();
    };

    return (
      <Box
        ref={ref}
        as={Element}
        width="fitContent"
        position="relative"
        backgroundColor="surfaceDefault"
        id={id}
        {...props}
        {...focusWithinProps}
      >
        <MenuContext.Provider value={menuContext}>
          <Inline
            as="button"
            rounded="md"
            borderWidth="1"
            paddingLeft="1.5"
            paddingRight="0.5"
            paddingY="1"
            cursor="pointer"
            alignItems="center"
            borderColor={
              hasValue || menuContext.isExpanded
                ? 'borderPrimaryLow'
                : 'borderSeparator'
            }
            backgroundColor={
              hasValue || menuContext.isExpanded
                ? 'surfacePrimaryLowest'
                : 'surfaceDefault'
            }
            onClick={menuContext.toggle}
            color={
              hasValue || menuContext.isExpanded ? 'textPrimary' : 'textHigh'
            }
          >
            <Text
              variation={hasValue || menuContext.isExpanded ? 'c1' : 'c2'}
              paddingRight="0.5"
            >
              {labelText}
            </Text>
            <ArrowDownFilledIcon
              size="3"
              style={{ rotate: menuContext.isExpanded ? '180deg' : '0deg' }}
              color={
                hasValue || menuContext.isExpanded
                  ? 'iconPrimary'
                  : 'iconMedium'
              }
            />
          </Inline>
          <MenuList>
            <Box
              overflow="auto"
              style={{
                maxHeight: 300,
                width: 280,
                position: 'absolute',
              }}
              backgroundColor={'surfaceDefault'}
              shadowLevel="s2"
            >
              <Stack
                as="ul"
                gap="1"
                paddingY="1"
                overflow="auto"
                style={{
                  maxHeight: 200,
                  position: 'relative',
                }}
              >
                {options?.map((props) => {
                  const isSelected = selected?.value === props.value;
                  return (
                    <Inline
                      id={`${id}-list-${props.value}`}
                      as="li"
                      key={props.value}
                      onClick={() => setSelected(props)}
                      cursor="pointer"
                      backgroundColor={{
                        default: isSelected
                          ? 'surfacePrimaryLowest'
                          : undefined,
                        hover: !isSelected ? 'surfaceNeutralLowest' : undefined,
                      }}
                    >
                      <RadioGroup
                        value={selected?.value}
                        aria-label={props.label}
                      >
                        <Radio value={props.value} paddingX="2">
                          <Text variation="b2">{props.label}</Text>
                        </Radio>
                      </RadioGroup>
                    </Inline>
                  );
                })}
              </Stack>
              <Inline
                padding="2"
                gap="4"
                justifyContent="end"
                borderTopWidth="1"
                borderColor="borderSeparator"
              >
                <Button
                  id={`${id}-cancel-select`}
                  type="text"
                  title={cancelBtnTitle || 'Cancel'}
                  onClick={onCancelClick}
                />
                <Button
                  id={`${id}-save-select`}
                  title={actionBtnTitle || 'Save'}
                  onClick={saveOptions}
                />
              </Inline>
            </Box>
          </MenuList>
        </MenuContext.Provider>
      </Box>
    );
  }
);

type MultiSelectOwnProps = SelectBaseProps & {
  type?: 'textInput' | 'filter';
  value?: string[];
  searchPlaceholder?: string;
  removeSearch?: boolean;
  onSave: (option: string[]) => void;
  onCancel?: () => void;
  inputProps?: React.ComponentProps<typeof TextInput>;
};

export type MultiSelectProps<
  C extends React.ElementType = typeof DefaultElement
> = PolymorphicComponentPropWithRef<C, MultiSelectOwnProps>;

type MultiSelectComponent = <
  C extends React.ElementType = typeof DefaultElement
>(
  props: MultiSelectProps<C>
) => React.ReactNode;
export const MultiSelect: MultiSelectComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultElement>(
    {
      as,
      label,
      options,
      value,
      labelIcon,
      removeSearch,
      cancelBtnTitle,
      actionBtnTitle,
      searchPlaceholder,
      loadingOptions,
      onSave,
      onCancel,
      inputProps,
      type = 'filter',
      id,
      ...props
    }: MultiSelectProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Element: ElementType = as || DefaultElement;

    const [isFocusWithin, setFocusWithin] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<BaseOption[]>([]);

    const menuState = useOverlayTriggerState({});

    const menuContext = useMemo(() => {
      return {
        isExpanded: menuState.isOpen,
        open: () => {
          menuState.open();
          // This will ensure that we have latest options available on every open
        },
        close: () => {
          // focus the container before closing for better focus management
          menuState.close();
          // reset the query
        },
        toggle: () => (menuState.isOpen ? menuState.close() : menuState.open()),
      };
    }, [menuState]);

    const saveOptions = () => {
      const values = selectedOptions.map((option) => option.value);
      onSave(values);
      menuContext.close();
    };

    const onCancelClick = () => {
      if (cancelBtnTitle === 'Clear' || cancelBtnTitle === 'Reset') {
        setSelectedOptions([]);
        onCancel?.();
      }
      menuContext.close();
    };

    const labelText: string = useMemo(() => {
      const values = options?.filter((option) => value?.includes(option.value));
      if (type === 'textInput') {
        return values?.length
          ? `${label || 'Select'} (${values.length})`
          : label || 'Select';
      } else {
        return values?.length && values.length > 1
          ? `${label || 'Select'} (${values.length})`
          : values?.length === 1
          ? values[0].label
          : label || 'Select';
      }
    }, [label, options, value, type]);

    const hasValue: boolean = useMemo(() => {
      return Boolean(value?.length);
    }, [value]);

    const [q, setSearchQ] = useState<string>('');

    const filteredOptions = useMemo(() => {
      return removeSearch
        ? options
        : options?.filter((option) =>
            option.label.toLowerCase().includes(q.toLowerCase())
          );
    }, [options, q, removeSearch]);

    useEffect(() => {
      if (!menuContext.isExpanded) {
        const values = options?.length
          ? options?.filter((option) => value?.includes(option.value))
          : [];
        setSelectedOptions(values);
      }
    }, [menuContext.isExpanded, options, value]);

    const { focusWithinProps } = useFocusWithin({
      onFocusWithinChange: (isFocusWithin) => setFocusWithin(isFocusWithin),
    });

    useEffect(() => {
      if (!isFocusWithin) menuState.close();
    }, [menuState, isFocusWithin]);

    return (
      <Box
        ref={ref}
        as={Element}
        width="fitContent"
        position="relative"
        id={id}
        {...props}
        {...focusWithinProps}
      >
        <MenuContext.Provider value={menuContext}>
          {type === 'textInput' ? (
            <Box onClick={menuContext.toggle}>
              <TextInput
                label={labelText}
                isRequired
                fullWidth
                placeholder="Search and Add"
                isReadOnly
                rightIcon={() => (
                  <ArrowDownFilledIcon
                    size="3"
                    style={{
                      rotate: menuContext.isExpanded ? '180deg' : '0deg',
                    }}
                    color={'iconMedium'}
                  />
                )}
                value={selectedOptions.map((option) => option.label).join(', ')}
                {...inputProps}
              />
            </Box>
          ) : (
            <Inline
              as="button"
              rounded="md"
              borderWidth="1"
              paddingLeft="1.5"
              paddingRight="0.5"
              paddingY="1"
              height="5"
              cursor="pointer"
              alignItems="center"
              borderColor={
                hasValue || menuContext.isExpanded
                  ? 'borderPrimaryLow'
                  : 'borderSeparator'
              }
              backgroundColor={
                hasValue || menuContext.isExpanded
                  ? 'surfacePrimaryLowest'
                  : 'surfaceDefault'
              }
              onClick={menuContext.toggle}
              color={
                hasValue || menuContext.isExpanded ? 'textPrimary' : 'textHigh'
              }
            >
              {labelIcon ? labelIcon : null}
              <Text
                variation={hasValue || menuContext.isExpanded ? 'c1' : 'c2'}
                paddingRight="0.5"
                paddingLeft={labelIcon ? '1' : '0'}
              >
                {labelText}
              </Text>
              <ArrowDownFilledIcon
                size="3"
                style={{ rotate: menuContext.isExpanded ? '180deg' : '0deg' }}
                color={
                  hasValue || menuContext.isExpanded
                    ? 'iconPrimary'
                    : 'iconMedium'
                }
              />
            </Inline>
          )}
          <MenuList>
            <Box
              rounded="md"
              borderWidth="1"
              borderColor="borderSeparator"
              backgroundColor="surfaceDefault"
              style={{
                maxHeight: 440,
                width: 324,
                position: 'absolute',
              }}
              shadowLevel="s2"
            >
              {loadingOptions ? (
                <Inline
                  gap="3"
                  alignItems="center"
                  justifyContent="center"
                  style={{ height: 284 }}
                >
                  <SpinnerIcon size="2.5" />
                  <Text variation="b2">Fetching...</Text>
                </Inline>
              ) : (
                <>
                  {removeSearch ? null : (
                    <Box
                      padding="2"
                      borderBottomWidth="1"
                      borderColor="borderSeparator"
                    >
                      <TextInput
                        fullWidth
                        removeMinWidth
                        value={q}
                        id="search"
                        aria-label="search"
                        minHeight="5"
                        onChange={(e: string) => setSearchQ(e)}
                        placeholder={searchPlaceholder || ''}
                        leftIcon={(props) => <SearchIcon {...props} />}
                      />
                    </Box>
                  )}
                  <Stack
                    as="ul"
                    gap="1"
                    paddingY="1"
                    overflow="auto"
                    style={{ maxHeight: 200, position: 'relative' }}
                  >
                    {filteredOptions?.length ? (
                      filteredOptions?.map((props) => {
                        const isSelected = Boolean(
                          selectedOptions.find(
                            (option) => option.value === props.value
                          )
                        );

                        const onClickItem = () => {
                          isSelected
                            ? setSelectedOptions((prev) =>
                                prev.filter(
                                  (prevOption) =>
                                    prevOption.value !== props.value
                                )
                              )
                            : setSelectedOptions((prev) => [...prev, props]);
                        };

                        return (
                          <Inline
                            id={`${id}-list-${props.value}`}
                            as="li"
                            key={props.value}
                            cursor="pointer"
                            paddingX="2"
                            paddingY="1"
                            gap="3"
                            backgroundColor={{
                              default: isSelected
                                ? 'surfacePrimaryLowest'
                                : undefined,
                              hover: !isSelected
                                ? 'surfaceNeutralLowest'
                                : undefined,
                            }}
                            alignItems="center"
                            onClick={onClickItem}
                          >
                            <Checkbox
                              id={`checkbox_${props.value}`}
                              name={`checkbox_${props.value}`}
                              value={props.value}
                              isSelected={isSelected}
                              onChange={onClickItem}
                            />
                            <Text
                              variation="b2"
                              color={!isSelected ? 'textMedium' : 'textHigh'}
                            >
                              {props.label}
                            </Text>
                          </Inline>
                        );
                      })
                    ) : (
                      <Stack
                        as="li"
                        width="full"
                        paddingY="5"
                        alignItems="center"
                      >
                        <Text variation="c2">No results found</Text>
                      </Stack>
                    )}
                  </Stack>
                  <Inline
                    padding="2"
                    gap="4"
                    justifyContent="end"
                    borderTopWidth="1"
                    borderColor="borderSeparator"
                  >
                    <Button
                      id={`${id}-cancel-select`}
                      type="text"
                      title={cancelBtnTitle || 'Cancel'}
                      onClick={onCancelClick}
                    />
                    <Button
                      id={`${id}-save-select`}
                      title={actionBtnTitle || 'Save'}
                      onClick={saveOptions}
                    />
                  </Inline>
                </>
              )}
            </Box>
          </MenuList>
        </MenuContext.Provider>
        {type === 'textInput' && (
          <Inline gap="2" flexWrap="wrap" marginTop="1">
            {selectedOptions.map((option) => {
              return (
                <Inline
                  key={option.value}
                  justifyContent="center"
                  alignItems="center"
                  paddingLeft="2"
                  borderRadius="full"
                  borderWidth="1"
                  borderColor="borderOutline"
                >
                  <Text variation="c2" color="textMedium">
                    {option.label}
                  </Text>
                  <CrossIcon
                    paddingRight={'2'}
                    paddingY="1"
                    size="2"
                    color="iconLow"
                    marginLeft="1"
                    onClick={() => {
                      const updatedSelectedOptions = selectedOptions.filter(
                        (prevOption) => prevOption.value !== option.value
                      );
                      setSelectedOptions(updatedSelectedOptions);
                      onSave(
                        updatedSelectedOptions.map((option) => option.value)
                      );
                    }}
                  />
                </Inline>
              );
            })}
          </Inline>
        )}
      </Box>
    );
  }
);

function getDateLabel(label: string) {
  const inputString = label;

  // Find the index of the opening parenthesis "(" and closing parenthesis ")"
  const startIndex = inputString.indexOf('(');
  const endIndex = inputString.indexOf(')');

  // Check if both the opening and closing parentheses exist in the string
  if (startIndex !== -1 && endIndex !== -1) {
    // Extract the substring between the parentheses
    const extractedString = inputString.substring(startIndex + 1, endIndex);
    return extractedString;
  } else {
    return label;
  }
}

type DateOptions =
  | 'all'
  | 'monthToDate'
  | 'quarterToDate'
  | 'financialYearToDate'
  | 'custom';
type DateSelectOwnProps = Omit<SelectBaseProps, 'options'> & {
  value?: DateBaseOption;
  options?: Array<DateOptions>;
  onSave: (option: DateBaseOption) => void;
};

export type DateSelectProps<
  C extends React.ElementType = typeof DefaultElement
> = PolymorphicComponentPropWithRef<C, DateSelectOwnProps>;
type DateSelectComponent = <
  C extends React.ElementType = typeof DefaultElement
>(
  props: DateSelectProps<C>
) => React.ReactNode;

export type DateBaseOption = BaseOption & { dates?: [Date, Date] };

export const DateSelect: DateSelectComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultElement>(
    {
      as,
      label,
      options,
      value,
      labelIcon,
      removeSearch,
      cancelBtnTitle,
      actionBtnTitle,
      searchPlaceholder,
      onSave,
      id,
      ...props
    }: DateSelectProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Element: ElementType = as || DefaultElement;

    const dateOptions: Array<DateBaseOption> = useMemo(() => {
      return [
        {
          label: 'All Time',
          value: 'all',
        },
      ].concat(
        getStaticDateIntervals(new Date()).map((date) => {
          return {
            label: date.label,
            value: date.id,
            dates: date.interval,
          };
        })
      );
    }, []);

    const [selectedOption, setSelectedOption] = useState<
      DateBaseOption | undefined
    >(undefined);
    const [isFocusWithin, setFocusWithin] = useState(false);

    const menuState = useOverlayTriggerState({});

    const menuContext = useMemo(() => {
      return {
        isExpanded: menuState.isOpen,
        open: () => {
          menuState.open();
          // This will ensure that we have latest options available on every open
        },
        close: () => {
          // focus the container before closing for better focus management
          menuState.close();
          // reset the query
        },
        toggle: () => (menuState.isOpen ? menuState.close() : menuState.open()),
      };
    }, [menuState]);

    useEffect(() => {
      if (!isFocusWithin) menuState.close();
    }, [menuState, isFocusWithin]);

    const saveOptions = () => {
      if (selectedOption) {
        onSave(selectedOption);
      }
      menuContext.close();
    };

    const labelText: string = useMemo(() => {
      return value?.dates?.[0] && value.dates[1]
        ? `${getDateLabel(value.label)}`
        : label || 'Duration';
    }, [label, value]);

    const hasValue: boolean = useMemo(() => {
      return Boolean(value?.value && value.value !== 'all');
    }, [value]);

    useEffect(() => {
      if (!menuContext.isExpanded) {
        setSelectedOption(value);
      }
    }, [menuContext.isExpanded, options, value]);

    const { focusWithinProps } = useFocusWithin({
      onFocusWithinChange: (isFocusWithin) => setFocusWithin(isFocusWithin),
    });

    return (
      <Box
        ref={ref}
        as={Element}
        width="fitContent"
        position="relative"
        backgroundColor="surfaceDefault"
        id={id}
        {...props}
        {...focusWithinProps}
      >
        <MenuContext.Provider value={menuContext}>
          <Inline
            as="button"
            rounded="md"
            borderWidth="1"
            paddingLeft="1.5"
            paddingRight="0.5"
            paddingY="1"
            height="5"
            cursor="pointer"
            alignItems="center"
            borderColor={
              hasValue || menuContext.isExpanded
                ? 'borderPrimaryLow'
                : 'borderSeparator'
            }
            backgroundColor={
              hasValue || menuContext.isExpanded
                ? 'surfacePrimaryLowest'
                : 'surfaceDefault'
            }
            onClick={menuContext.toggle}
            color={
              hasValue || menuContext.isExpanded ? 'textPrimary' : 'textHigh'
            }
          >
            {labelIcon ? (
              labelIcon
            ) : (
              <CalendarIcon
                size="2.5"
                color={
                  hasValue || menuContext.isExpanded
                    ? 'iconPrimary'
                    : 'iconMedium'
                }
              />
            )}
            <Text
              variation={hasValue || menuContext.isExpanded ? 'c1' : 'c2'}
              paddingRight="0.5"
              paddingLeft={'1'}
            >
              {labelText}
            </Text>
            <ArrowDownFilledIcon
              size="3"
              style={{ rotate: menuContext.isExpanded ? '180deg' : '0deg' }}
              color={
                hasValue || menuContext.isExpanded
                  ? 'iconPrimary'
                  : 'iconMedium'
              }
            />
          </Inline>
          <MenuList>
            <Box
              overflow="auto"
              style={{
                maxHeight: 300,
                width: 324,
              }}
              shadowLevel="s2"
              rounded="md"
              borderWidth="1"
              borderColor="borderSeparator"
            >
              <Stack as="ul" gap="1" paddingY="1">
                <RadioGroup
                  aria-label="Choose a date"
                  value={selectedOption?.value}
                  onChange={(e) => {
                    if (selectedOption?.value === e) {
                      return;
                    } else {
                      setSelectedOption(
                        dateOptions.find((date) => date.value === e)
                      );
                    }
                  }}
                >
                  {dateOptions?.map((props) => {
                    const isSelected = selectedOption?.value === props.value;
                    return (
                      <Inline
                        as="li"
                        key={props.value}
                        backgroundColor={{
                          default: isSelected
                            ? 'surfacePrimaryLowest'
                            : undefined,
                          hover: !isSelected
                            ? 'surfaceNeutralLowest'
                            : undefined,
                        }}
                      >
                        <Radio value={props.value} paddingX="2">
                          <Text variation="b2" color="textMedium">
                            {props.label}
                          </Text>
                        </Radio>
                      </Inline>
                    );
                  })}
                </RadioGroup>
              </Stack>
              <Inline
                padding="2"
                gap="4"
                justifyContent="end"
                borderTopWidth="1"
                borderColor="borderSeparator"
              >
                <Button
                  id={`${id}-cancel-select`}
                  type="text"
                  title={cancelBtnTitle || 'Cancel'}
                  onClick={menuContext.close}
                />
                <Button
                  id={`${id}-save-select`}
                  title={actionBtnTitle || 'Save'}
                  onClick={saveOptions}
                />
              </Inline>
            </Box>
          </MenuList>
        </MenuContext.Provider>
      </Box>
    );
  }
);
