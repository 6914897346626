import {
  useBranchesForFilter,
  useEmployees,
  useRoleTypes,
} from '@nbfc-expense-tool/data-store/dashboard';
import { debounce } from '@nbfc-expense-tool/data-store/utils';
import {
  Box,
  Button,
  EmptyState,
  FileIcon,
  Header,
  Inline,
  PlusIcon,
  RightCaratIcon,
  SearchIcon,
  SpinnerIcon,
  Stack,
  Text,
  TextInput,
  SkeletonRows,
  Avatar,
  MultiSelect,
  ReturnIcon,
} from '@nbfc-expense-tool/ui';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { BulkImportButton } from '../../Common';

const idPrefix = 'employees-page';

export default function EmployeesPage() {
  const navigate = useNavigate();
  const { status, branches } = useBranchesForFilter();

  const { status: rolesStatus, roles } = useRoleTypes();
  const rolesForFilters = useMemo(() => {
    return roles?.map((r) => {
      return {
        label: r.display_name,
        value: `${r.name}`,
      };
    });
  }, [roles]);

  const branchForFilters = useMemo(() => {
    return branches?.map((b) => {
      return {
        label: `${b.name} - ${b.branch_code}`,
        value: `${b.id}`,
      };
    });
  }, [branches]);

  const {
    employees,
    loading,
    perPage,
    totalEmployees,
    canGoNext,
    canGoBack,
    syncingData,
    currentPage,
    lastPage,
    params,
    hasAppliedFilters,
    resetFilters,
    handlePageChange,
    handleParamChange,
    handleSearchQuery,
    canAddEmployee,
    canUpdateEmployee,
    exportExcel,
    reportLoading,
  } = useEmployees();

  return (
    <Box paddingX={'5'} paddingBottom={'5'}>
      <Header
        paddingTop={'4'}
        headingText="Employees"
        renderRight={() => {
          if (!canAddEmployee) {
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
          }
          return (
            <Inline gap="4">
              <BulkImportButton entityType="employees" />
              <Button
                id={`${idPrefix}-add-new-employee-button`}
                onClick={() => {
                  navigate('/add-new-employee');
                }}
                title="Add New"
                type="filled"
                leftIcon={(props) => <PlusIcon {...props} />}
              />
            </Inline>
          );
        }}
      />

      {!employees?.length && !hasAppliedFilters ? (
        <Stack paddingTop="10" alignItems="center" justifyContent="center">
          {loading === 'in_progress' ? (
            <Inline gap="4">
              <SpinnerIcon size="3" color="iconMedium" />
              <Text variation="b2">Loading...</Text>
            </Inline>
          ) : (
            <EmptyState
              renderIcon={(props) => <FileIcon {...props} />}
              title="No Employees Found!"
              subText={
                canAddEmployee
                  ? `Tap ‘Add New’ to create your first employee`
                  : undefined
              }
              renderButton={() => {
                return (
                  <Inline gap="4">
                    <BulkImportButton entityType="employees" />
                    {canAddEmployee && (
                      <Button
                        id={`${idPrefix}-empty-state-add-new-employee`}
                        onClick={() => {
                          navigate('/add-new-employee');
                        }}
                        title="Add New"
                        type="filled"
                        leftIcon={(props) => <PlusIcon {...props} />}
                      />
                    )}
                  </Inline>
                );
              }}
            />
          )}
        </Stack>
      ) : (
        <Stack paddingTop="3.5" gap="6">
          <Inline justifyContent={'between'}>
            <Inline gap="4">
              <MultiSelect
                id={`${idPrefix}-select-branches`}
                label="Branch"
                actionBtnTitle="Show Results"
                value={params.branches}
                options={branchForFilters}
                loadingOptions={status === 'in_progress'}
                onSave={(values) => {
                  handleParamChange('branch', values);
                }}
              />
              <MultiSelect
                id={`${idPrefix}-select-designation`}
                label="Designation"
                actionBtnTitle="Show Results"
                value={params.roles}
                options={rolesForFilters}
                loadingOptions={rolesStatus === 'in_progress'}
                onSave={(values) => {
                  handleParamChange('role', values);
                }}
              />
            </Inline>
            <Button
              id={`${idPrefix}-export-excel-button`}
              title="Export To Excel"
              onClick={exportExcel}
              type="outlined"
              state={reportLoading ? 'loading' : undefined}
              leftIcon={(props) => <ReturnIcon {...props} />}
            />
          </Inline>
          <Box
            rounded="md"
            borderWidth="1"
            paddingTop="2.5"
            borderColor="borderSeparator"
            backgroundColor="surfaceDefault"
          >
            <Stack gap="6">
              <Inline
                paddingX="2.5"
                alignItems="center"
                justifyContent="between"
              >
                <Inline style={{ width: 324 }}>
                  <TextInput
                    minHeight="5"
                    id={`${idPrefix}-search-input`}
                    aria-label="search"
                    placeholder="Search by name or code"
                    leftIcon={(props) => (
                      <SearchIcon {...props} marginRight="1" />
                    )}
                    onChange={debounce((value) => {
                      handleSearchQuery(value);
                    }, 500)}
                    fullWidth
                  />
                </Inline>
                <Inline alignItems="center" gap="6">
                  {totalEmployees ? (
                    <Text color="textMedium" variation="c1">
                      Showing{' '}
                      {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}-
                      {lastPage === currentPage
                        ? totalEmployees
                        : employees?.length}{' '}
                      of {totalEmployees}
                    </Text>
                  ) : null}
                  <Inline gap="2">
                    <Box
                      id={`${idPrefix}-show-previous-page`}
                      as="button"
                      disabled={!canGoBack}
                      backgroundColor="transparent"
                      onClick={() =>
                        canGoBack ? handlePageChange('previous') : undefined
                      }
                    >
                      <RightCaratIcon
                        cursor="pointer"
                        size="2.5"
                        rotate="180"
                        color={canGoBack ? 'iconMedium' : 'iconLowest'}
                      />
                    </Box>
                    <Box
                      as="hr"
                      width="px"
                      height="2.5"
                      backgroundColor="borderSeparator"
                    />
                    <Box
                      id={`${idPrefix}-show-next-page`}
                      as="button"
                      disabled={!canGoNext}
                      backgroundColor="transparent"
                      onClick={() =>
                        canGoNext ? handlePageChange('next') : undefined
                      }
                    >
                      <RightCaratIcon
                        cursor="pointer"
                        size="2.5"
                        color={canGoNext ? 'iconMedium' : 'iconLowest'}
                      />
                    </Box>
                  </Inline>
                </Inline>
              </Inline>
              <Box as="table" width="full" position="relative">
                <Box as="thead" bgColor="surfaceNeutralLowest">
                  <Box as="tr">
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 248,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">Employeee Name & Code</Text>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 120,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">Designation</Text>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        maxWidth: 120,
                        width: 120,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">Branch</Text>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 120,
                      }}
                      top="0"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Inline justifyContent="start">
                        <Text variation="c1">Mobile Number</Text>
                      </Inline>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingLeft="2"
                      paddingRight="3"
                      style={{
                        width: 120,
                      }}
                      top="0"
                      bgColor="surfaceNeutralLowest"
                      textAlign="left"
                    >
                      <Text variation="c1">Email Id</Text>
                    </Box>
                    {canUpdateEmployee && (
                      <Box
                        as="th"
                        position="sticky"
                        paddingY="1.5"
                        paddingLeft="2"
                        paddingRight="3"
                        style={{
                          width: 120,
                        }}
                        top="0"
                        bgColor="surfaceNeutralLowest"
                        textAlign="left"
                      >
                        <Text variation="c1">Action</Text>
                      </Box>
                    )}
                  </Box>
                </Box>
                {syncingData ? (
                  <SkeletonRows numOfRows={10} numOfCols={5} />
                ) : (
                  <Box as="tbody">
                    {employees?.length ? (
                      employees.map((employee) => {
                        const {
                          id,
                          email,
                          name,
                          phone_number,
                          roles,
                          branches,
                          employee_code,
                          avatar,
                        } = employee;
                        return (
                          <Box
                            key={id}
                            as="tr"
                            borderTopWidth="1"
                            tabIndex={-1}
                            bgColor={{
                              hover: 'surfacePrimaryLowest',
                            }}
                          >
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY={'2'}
                              textAlign="left"
                              borderColor="borderSeparator"
                              borderBottomWidth={'1'}
                            >
                              <Inline gap={'4'}>
                                <Avatar
                                  id={employee_code}
                                  avatarSize="lg"
                                  as="img"
                                  src={avatar.preview}
                                />
                                <Stack gap={'1'}>
                                  <Text variation="b2">{name}</Text>
                                  <Text variation="c2" color={'textLow'}>
                                    {employee_code}
                                  </Text>
                                </Stack>
                              </Inline>
                            </Box>
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY={'2'}
                              borderColor="borderSeparator"
                              borderBottomWidth={'1'}
                            >
                              <Text variation="b2">
                                {roles?.length ? roles[0].display_name : ''}
                              </Text>
                            </Box>
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY={'2'}
                              borderColor="borderSeparator"
                              borderBottomWidth={'1'}
                            >
                              <Text variation="b2">
                                {branches?.length
                                  ? branches
                                      .map((branch) => branch.name)
                                      .join(', ')
                                  : ''}
                              </Text>
                            </Box>
                            <Box
                              as="td"
                              borderColor="borderSeparator"
                              paddingX="2"
                              paddingY={'2'}
                              className="whitespace-pre"
                              textAlign="left"
                              borderBottomWidth={'1'}
                            >
                              <Box>
                                <Text variation="b2">{phone_number}</Text>
                              </Box>
                            </Box>
                            <Box
                              borderColor="borderSeparator"
                              as="td"
                              paddingLeft="2"
                              paddingRight="3"
                              className="whitespace-pre"
                              textAlign="left"
                              paddingY={'2'}
                              borderBottomWidth={'1'}
                            >
                              <Box>
                                <Text variation="b2">{email}</Text>
                              </Box>
                            </Box>
                            {canUpdateEmployee && (
                              <Box
                                as="td"
                                paddingLeft="2"
                                paddingRight="3"
                                paddingY="1.5"
                                className="whitespace-pre"
                                textAlign="left"
                                valign="top"
                                borderBottomWidth={'1'}
                                borderColor="borderSeparator"
                              >
                                <Box
                                  marginBottom="2.5"
                                  cursor="pointer"
                                  onClick={() => {
                                    navigate('/add-new-employee', {
                                      state: {
                                        employeeDetails: employee,
                                      },
                                    });
                                  }}
                                >
                                  <Box width="fitContent">
                                    <Text
                                      variation="lk"
                                      color="textPrimary"
                                      textDecoration="underline"
                                    >
                                      Edit
                                    </Text>
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          <EmptyState
                            renderIcon={(props) => <FileIcon {...props} />}
                            title="No Employees Found!"
                            subText={`Please try changing your applied filters!`}
                            renderButton={() => (
                              <Button
                                id={`${idPrefix}-empty-state-reset-filters`}
                                title="Reset Filters"
                                onClick={resetFilters}
                              />
                            )}
                          />
                        </td>
                      </tr>
                    )}
                  </Box>
                )}
              </Box>
            </Stack>
          </Box>
        </Stack>
      )}
    </Box>
  );
}
