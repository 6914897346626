import {
  VendorDetails,
  useVendorProfile,
  vendorRegistrationTypeOptions,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  Avatar,
  Box,
  Button,
  CrossCircle,
  EmptyState,
  Inline,
  SpinnerIcon,
  Stack,
  Text,
} from '@nbfc-expense-tool/ui';
import { useMemo } from 'react';
import { VendorCompleteDetails } from '../pages/Vendors';

type Props = {
  vendorId: string;
};

const VendorProfileModal: React.FC<Props> = ({ vendorId }) => {
  const { loadingStatus, vendorDetails, reloadDetails } =
    useVendorProfile(vendorId);

  const renderVendorDetails = useMemo(() => {
    switch (loadingStatus) {
      case 'in_progress':
        return (
          <Stack justifyContent="center" alignItems="center" marginTop="10">
            <SpinnerIcon size="3" color="iconMedium" />
            <Text variation="b2" color="textMedium" marginTop="1">
              Loading...
            </Text>
          </Stack>
        );
      case 'failed':
        return (
          <Stack justifyContent="center" alignItems="center" marginTop="10">
            <EmptyState
              renderIcon={(props) => <CrossCircle {...props} />}
              title="No Details Found!"
              subText={`Please check your internet connection and try again`}
              renderButton={() => (
                <Button
                  id="vendor-profile-reload"
                  title="Reload"
                  onClick={reloadDetails}
                />
              )}
            />
          </Stack>
        );
      case 'success':
        return (
          <Box backgroundColor="surfaceDefault">
            <Inline
              paddingY="2"
              paddingX="3"
              borderRadius="md"
              borderWidth="1"
              borderColor="borderOutline"
              marginBottom="3"
            >
              <Avatar
                marginRight="2"
                avatarText={vendorDetails?.name?.[0]?.toUpperCase()}
                avatarSize="md"
                id={vendorDetails?.id?.toString()}
              />
              <Stack>
                <Inline>
                  <Text color="textHigh" variation={'t1'}>
                    {vendorDetails?.name}
                  </Text>
                </Inline>
                <Inline alignItems="center">
                  <Text color={'textLow'} variation={'b2'}>
                    {vendorDetails?.vendor_type?.name}
                  </Text>
                  <Box
                    width="0.5"
                    height="0.5"
                    borderRadius="full"
                    backgroundColor="textLow"
                    marginX="1"
                  />
                  <Text color={'textLow'} variation={'b2'}>
                    {
                      vendorRegistrationTypeOptions.find(
                        (item) => item.value === vendorDetails?.company_reg_type
                      )?.label
                    }
                  </Text>
                </Inline>
              </Stack>
            </Inline>
            <VendorCompleteDetails
              vendorDetails={vendorDetails as VendorDetails}
            />
          </Box>
        );
    }
  }, [loadingStatus, vendorDetails, reloadDetails]);

  return <Box padding="5">{renderVendorDetails}</Box>;
};

export default VendorProfileModal;
