import { useRef, useState } from 'react';
import type { AriaTextFieldProps } from 'react-aria';
import { useTextField } from 'react-aria';
import { Text } from '../text/text';
import Stack from '../stack/stack';
import { Box, BoxOwnProps } from '../box/box';
import { borderColorStyles } from '../css/common.css';
import { AlertCircleIcon } from '../icons/icons';

interface TextAreaProps extends AriaTextFieldProps {
  fullWidth?: boolean;
  marginTop?: BoxOwnProps['marginTop'];
  marginBottom?: BoxOwnProps['marginBottom'];
  marginLeft?: BoxOwnProps['marginLeft'];
  marginRight?: BoxOwnProps['marginRight'];
  marginX?: BoxOwnProps['marginX'];
  marginY?: BoxOwnProps['marginY'];
  width?: BoxOwnProps['width'];
  labelVariation?: React.ComponentProps<typeof Text>['variation'];
}

export function TextArea(props: TextAreaProps) {
  const { label, marginLeft, fullWidth } = props;
  const ref = useRef(null);

  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(
      {
        ...props,
        inputElementType: 'textarea',
      },
      ref
    );

  const [isFocused, setFocused] = useState(false);

  inputProps.onFocus = () => {
    setFocused(true);
  };

  inputProps.onBlur = () => {
    setFocused(false);
  };
  const { color: _, ...restInputProps } = inputProps;

  return (
    <Stack
      display={'flex'}
      maxWidth={!fullWidth ? 'min' : undefined}
      style={{
        minWidth: '300px',
        height: 120,
      }}
      width={fullWidth ? 'full' : undefined}
      marginLeft={marginLeft}
    >
      <Text
        variation={props.labelVariation || 'c2'}
        as="label"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        color={'textHigh' as any}
        {...labelProps}
      >
        {label}
        {props.isRequired && (
          <Text marginLeft={'0.5'} as="span" color="textError">
            *
          </Text>
        )}
      </Text>
      <Box
        display={'flex'}
        paddingY={'1.5'}
        marginTop={'0.5'}
        borderWidth={!isFocused ? '1' : '2'}
        borderRadius="md"
        height="full"
        className={borderColorStyles({
          color: props.errorMessage
            ? 'borderError'
            : isFocused
            ? 'borderPrimary'
            : 'borderOutline',
        })}
      >
        <Text
          variation={'b2'}
          paddingLeft={'1.5'}
          as="textarea"
          display={'flex'}
          flex={'1'}
          width="full"
          height="full"
          {...restInputProps}
          ref={ref}
        ></Text>
        {!!props.errorMessage && (
          <AlertCircleIcon size={'3'} color={'iconError'} marginX={'1.5'} />
        )}
      </Box>
      {props.description && (
        <Text
          marginTop={'0.5'}
          as="p"
          {...descriptionProps}
          variation={'c2'}
          color={'textLow'}
        >
          {props.description}
        </Text>
      )}
      {props.errorMessage && (
        <Text
          marginTop={'0.5'}
          borderRadius="md"
          paddingY={'0.5'}
          paddingX={'1'}
          display={'flex'}
          {...errorMessageProps}
          color={'textError'}
          variation={'c2'}
          bgColor={'surfaceErrorLowest'}
        >
          {props.errorMessage}
        </Text>
      )}
    </Stack>
  );
}
