/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DashboardInvoiceStatType,
  DateFilter,
  OwnInvoicesOverviewFilters,
  WaitingStatType,
  useBranchesForFilter,
  useDashboardInvoicesOverview,
  useDashboardWaitingStat,
  useInvoicesApprovalTimeStat,
} from '@nbfc-expense-tool/data-store/dashboard';
import { useDownloadHTMLAsImage } from '@nbfc-expense-tool/data-store/utils';
import {
  Amount,
  Box,
  Button,
  COLORS,
  ClipboardIcon,
  DownloadIcon,
  EmptyState,
  Inline,
  MultiSelect,
  RefreshIcon,
  RightCaratIcon,
  SkeletonRows,
  SpinnerIcon,
  Stack,
  Text,
  UserCheckIcon,
} from '@nbfc-expense-tool/ui';
import {
  getMonthInterval,
  getYearInterval,
} from '@nbfc-expense-tool/util-dates';
import { add, format, sub } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

const initialOverview = [
  {
    id: 'under_review',
    label: 'Under Review',
    value: 0,
    to: 'under_review',
  },
  {
    id: 'approved',
    label: 'Approved',
    value: 0,
    to: 'approved',
  },
  {
    id: 'paid',
    label: 'Paid',
    value: 0,
    to: 'approved',
  },
  {
    id: 'unpaid',
    label: 'Unpaid',
    value: 0,
    to: 'approved',
  },
];

const options = {
  parsing: {
    xAxisKey: 'month',
    yAxisKey: 'approval_time',
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      border: { dash: [4, 4], color: COLORS.borderSeparator }, // for the grid lines
      grid: {
        color: COLORS.borderPrimaryLow,
        drawTicks: false,
      },
      ticks: {
        padding: 12,
        callback: function (value: any) {
          return Math.ceil(value) + 'D';
        },
      },
    },
  },
  hoverRadius: 8,
  responsive: true,
  plugins: {
    tooltip: {
      enabled: false,
      displayColors: false,
      padding: 12,
      titleColor: COLORS.textLow,
      bodyColor: COLORS.textHigh,
      footerColor: COLORS.textSuccess,
      callbacks: {
        title: function (context: any) {
          const { raw } = context[0];
          return `${raw['monthName']} Approval Time`;
        },
        label: function (context: any) {
          const { formattedValue } = context;
          return `${formattedValue} Days`;
        },
        footer: function (context: any) {
          const {
            raw,
            dataIndex,
            dataset: { data },
          } = context[0];
          const percentage = raw['percentageStat'];
          if (dataIndex === 0) {
            return ``;
          } else {
            if (percentage === 0) {
              return `Equals ${data[dataIndex - 1].month}`;
            } else if (percentage === Infinity) {
              return `N/A`;
            } else if (percentage > 0) {
              return `${percentage.toFixed(2)}% greater than ${
                data[dataIndex - 1].month
              }`;
            } else {
              return `${percentage.toFixed(2)}% lesser than ${
                data[dataIndex - 1].month
              }`;
            }
          }
        },
      },
      external: function (context: any) {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        const tooltipModel = context.tooltip;

        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem: any) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);
          const footerLines = tooltipModel.footer || [];

          let innerHtml = '<thead>';

          titleLines.forEach(function (title: any) {
            let titleStyle = 'color:' + COLORS.textLow;
            titleStyle +=
              "; font-family: 'Inter', sans-serif; font-size: 12px; font-weight: 400; line-height: 16px; letter-spacing: 0.4px; font-style: normal";
            innerHtml +=
              '<tr><th style="' + titleStyle + '">' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';

          bodyLines.forEach(function (body: any) {
            let style = 'color:' + COLORS.textHigh;
            style +=
              "; font-family: 'Inter', sans-serif; font-size: 16px; font-weight: 500; line-height: 28px; letter-spacing: 0.25px; font-style: normal";
            const span = '<span style="' + style + '">' + body + '</span>';
            innerHtml += '<tr><td>' + span + '</td></tr>';
          });

          footerLines.forEach(function (body: any) {
            const textColor = footerLines[0].includes('Equals')
              ? COLORS.textPrimary
              : footerLines[0].includes('lesser')
              ? COLORS.textError
              : footerLines[0].includes('greater')
              ? COLORS.textSuccess
              : COLORS.textLow;
            const backgroundColor = footerLines[0].includes('Equals')
              ? COLORS.surfacePrimaryLowest
              : footerLines[0].includes('lesser')
              ? COLORS.surfaceErrorLowest
              : footerLines[0].includes('greater')
              ? COLORS.surfaceSuccessLowest
              : COLORS.surfaceDefault;
            let style = 'color:' + textColor;
            style += '; background: ' + backgroundColor;
            style +=
              "; font-family: 'Inter'; font-size: 10px; font-weight: 500; line-height: 12px; letter-spacing: 0.4px; font-style: normal; padding: 2px 4px; border-radius: 4px;";
            const span = '<span style="' + style + '">' + body + '</span>';
            innerHtml += '<tr><td>' + span + '</td></tr>';
          });

          innerHtml += '</tbody>';

          const tableRoot = tooltipEl.querySelector(
            'table'
          ) as HTMLTableElement;
          tableRoot.innerHTML = innerHtml;
        }

        const position = context.chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.transform = 'translate(-50%, -120%)';
        tooltipEl.style.border = '1px solid ' + COLORS.borderSeparator;
        tooltipEl.style.borderRadius = '8px';
        tooltipEl.style.background = COLORS.surfaceDefault;
        tooltipEl.style.left =
          position.left + window.scrollX + tooltipModel.caretX + 'px';
        tooltipEl.style.top =
          position.top + window.scrollY + tooltipModel.caretY + 'px';
        tooltipEl.style.padding = '8px 16px 12px 16px';
        tooltipEl.style.boxShadow = '0px 4px 10px 0px rgba(0, 0, 0, 0.06)';
        tooltipEl.style.pointerEvents = 'none';
      },
    },
  },
};

export default function InvoiceAnalysis() {
  const { download } = useDownloadHTMLAsImage();
  const idPrefix = 'dashboard-invoice-analysis-approval-time';
  const {
    status: approvalTimeStatus,
    approvalStats,
    handleParamChange: handleInvoiceApprovalParamChange,
    params: invoiceApprovalParams,
    handleDateChange: handleInvoiceApprovalDateChange,
  } = useInvoicesApprovalTimeStat();
  const data = {
    labels: [],
    datasets: [
      {
        label: 'Invoice Approval Analysis',
        data: approvalStats,
        fill: true,
        borderColor: COLORS.borderPrimary,
        pointBackgroundColor: COLORS.borderPrimary,
        backgroundColor: (context: any) => {
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, 0, 0, bottom);
          gradientBg.addColorStop(0, '#D0DFFF');
          gradientBg.addColorStop(1, '#F4F4F4');
          return gradientBg;
        },
      },
    ],
  };
  const {
    status: overviewStatus,
    overview,
    handleParamChange,
    params: invoicesOverviewParams,
    handleDateChange,
    syncingData: syncingOverviewData,
  } = useDashboardInvoicesOverview();
  const { status: waitingStatStatus, waitingStats } = useDashboardWaitingStat();

  const { status, branches } = useBranchesForFilter();
  const branchForFilters = useMemo(() => {
    return branches?.map((b) => {
      return {
        label: `${b.name} - ${b.branch_code}`,
        value: `${b.id}`,
      };
    });
  }, [branches]);
  const [approvalDate, setApprovalDate] = useState<Date>(new Date());

  const onChangeYear = useCallback(
    (type: 'next' | 'prev') => {
      let updatedDate;
      if (type === 'next') {
        updatedDate = add(approvalDate, { years: 1 });
      } else {
        updatedDate = sub(approvalDate, { years: 1 });
      }
      const values = getYearInterval(updatedDate);
      handleInvoiceApprovalDateChange({ dates: values });
      setApprovalDate(updatedDate);
    },
    [approvalDate, handleInvoiceApprovalDateChange]
  );

  const isLoading = useMemo(() => {
    return (
      (overviewStatus || waitingStatStatus || approvalTimeStatus) ===
      'in_progress'
    );
  }, [overviewStatus, waitingStatStatus, approvalTimeStatus]);

  const errorOccured = useMemo(() => {
    return (
      (overviewStatus || waitingStatStatus || approvalTimeStatus) === 'failed'
    );
  }, [overviewStatus, waitingStatStatus, approvalTimeStatus]);

  return isLoading || errorOccured ? (
    <Stack paddingTop="10" alignItems="center" justifyContent="center">
      {isLoading ? (
        <Inline gap="4">
          <SpinnerIcon size="3" color="iconMedium" />
          <Text variation="b2">Loading...</Text>
        </Inline>
      ) : (
        <EmptyState
          title="Temporarily Data Unavailable"
          subText="Try to refresh the web page"
          renderButton={() => (
            <Button
              id={`${idPrefix}-refresh-button`}
              title="Refresh"
              leftIcon={() => <RefreshIcon fill="iconOnSurface" />}
              onClick={() => window.location.reload()}
            />
          )}
        />
      )}
    </Stack>
  ) : (
    <Box flex={'1'} paddingTop={'4'}>
      <Inline gap={'6'}>
        <Stack style={{ width: '75%' }}>
          <InvoiceOverView
            handleParamChange={handleParamChange}
            invoicesOverviewParams={invoicesOverviewParams}
            overview={overview}
            overviewStatus={overviewStatus}
            branchForFilters={branchForFilters}
            handleDateChange={handleDateChange}
            syncingData={syncingOverviewData}
          />
          <Stack
            paddingX={'4'}
            paddingY={'3'}
            bgColor={'surfaceDefault'}
            borderRadius="lg"
            borderWidth="1"
            borderColor="borderSeparator"
          >
            <Inline
              marginBottom={'4'}
              justifyContent={'between'}
              alignItems={'center'}
            >
              <Text variation={'t3'}>Average Invoice Approval Time</Text>
              <Inline alignItems={'center'}>
                <Inline
                  gap="2"
                  alignItems={'center'}
                  borderRadius="md"
                  borderWidth="1"
                  borderColor="borderSeparator"
                  paddingX={'1.5'}
                  paddingY={'1'}
                  marginRight={'2'}
                >
                  <Box
                    id={`${idPrefix}-change-to-previous-date`}
                    as="button"
                    backgroundColor="transparent"
                    onClick={() => onChangeYear('prev')}
                  >
                    <RightCaratIcon
                      cursor="pointer"
                      size="2.5"
                      rotate="180"
                      color={'iconLow'}
                    />
                  </Box>
                  <Text color={'textHigh'} variation={'c2'}>
                    {'FY: ' + format(approvalDate, 'yyyy')}
                  </Text>
                  <Box
                    id={`${idPrefix}-change-to-next-date`}
                    as="button"
                    backgroundColor="transparent"
                    onClick={() => onChangeYear('next')}
                  >
                    <RightCaratIcon
                      cursor="pointer"
                      size="2.5"
                      color={'iconLow'}
                    />
                  </Box>
                </Inline>

                <MultiSelect
                  id={`${idPrefix}-select-branches-filter`}
                  label="Branches: All"
                  actionBtnTitle="Show Results"
                  value={invoiceApprovalParams.branches}
                  options={branchForFilters}
                  loadingOptions={status === 'in_progress'}
                  onSave={(values) => {
                    handleInvoiceApprovalParamChange('branches', values);
                  }}
                  onCancel={() => {
                    handleInvoiceApprovalParamChange('branches', []);
                  }}
                  cancelBtnTitle="Reset"
                />
                <Box
                  id={`${idPrefix}-download`}
                  marginLeft={'3'}
                  onClick={() =>
                    download(
                      document.getElementById('invoiceAnalysis') as HTMLElement,
                      `Invoice_analysis_${format(new Date(), 'dd-mm-yy')}`
                    )
                  }
                >
                  <DownloadIcon size={'3'} color={'iconLow'} />
                </Box>
              </Inline>
            </Inline>
            <Box id="invoiceAnalysis" bgColor={'surfaceDefault'}>
              <Line data={data} options={options} />
            </Box>
          </Stack>
        </Stack>
        <WaitingForApprovalView
          waitingStats={waitingStats}
          status={waitingStatStatus}
        />
      </Inline>
    </Box>
  );
}

type WaitingForApprovalStatsType = {
  waitingStats: WaitingStatType | undefined;
  status: 'in_progress' | 'success' | 'failed';
};

function WaitingForApprovalView({ waitingStats }: WaitingForApprovalStatsType) {
  const idPrefix = 'waiting-for-approval-analysis';
  return (
    <Stack
      paddingTop={'2.5'}
      paddingBottom={'1'}
      borderRadius="lg"
      borderWidth="1"
      borderColor="borderSeparator"
      bgColor={'surfaceDefault'}
      paddingX={'3'}
      height={'fitContent'}
      style={{ width: '25%' }}
    >
      <Text variation={'t3'}>Waiting Your Approval</Text>
      <Box
        bgColor={'surfaceReturned'}
        style={{ borderRadius: 100, width: 48, height: 4 }}
        marginTop={'1'}
        marginBottom={'1.5'}
      />
      <Inline
        paddingTop={'2'}
        paddingBottom={'2.5'}
        gap={'4'}
        alignItems={'center'}
        paddingRight={'4'}
        borderBottomWidth="1"
        borderColor="borderSeparator"
      >
        <ClipboardIcon color={'iconWarning'} size="3" />
        <Stack>
          <Inline
            id={`${idPrefix}-pending-invoices`}
            marginBottom={'0.5'}
            as={Link}
            to={`/home/invoices/waiting-for-your-approval`}
            textDecoration="none"
          >
            <Text variation={'c0'} color={'textLow'} marginRight={'0.5'}>
              Pending Invoices
            </Text>
            <RightCaratIcon cursor="pointer" size="2.5" color={'iconLowest'} />
          </Inline>
          <Text variation={'h3'}>{waitingStats?.invoices_count || 0}</Text>
        </Stack>
      </Inline>
      <Inline
        paddingTop={'2'}
        paddingBottom={'2.5'}
        gap={'4'}
        alignItems={'center'}
      >
        <UserCheckIcon color={'iconWarning'} size="3" />
        <Stack>
          <Inline
            id={`${idPrefix}-pending-vendors`}
            marginBottom={'0.5'}
            as={Link}
            to={`/home/vendors/vendors-list/waiting-for-approval`}
            textDecoration="none"
          >
            <Text variation={'c0'} color={'textLow'} marginRight={'0.5'}>
              Pending Vendors
            </Text>
            <RightCaratIcon cursor="pointer" size="2.5" color={'iconLowest'} />
          </Inline>
          <Text variation={'h3'}>{waitingStats?.vendors_count || 0}</Text>
        </Stack>
      </Inline>
    </Stack>
  );
}

type InvoiceOverviewParamsType = {
  branchForFilters: Array<{ label: string; value: string }> | undefined;
  overviewStatus: 'in_progress' | 'success' | 'failed';
  overview: DashboardInvoiceStatType | undefined;
  handleParamChange: (
    key: keyof OwnInvoicesOverviewFilters,
    value: string[]
  ) => void;
  handleDateChange: (values: DateFilter) => void;
  invoicesOverviewParams: OwnInvoicesOverviewFilters;
  syncingData?: boolean;
};

const rootPathForOverview = '/home/invoices/all-invoices/';

function InvoiceOverView({
  branchForFilters,
  overviewStatus,
  overview,
  handleParamChange,
  invoicesOverviewParams,
  handleDateChange,
  syncingData,
}: InvoiceOverviewParamsType) {
  const [dateType, setDateType] = useState<'year' | 'month'>('month');
  const [dateFilter, setDateFilter] = useState<Date>(new Date());
  const isTypeMonth = useMemo(() => dateType === 'month', [dateType]);
  const idPrefix = 'dashboard-invoice-overview';

  const changeDateMonthly = useCallback(
    (changeType: 'add' | 'sub' | 'none') => {
      let updatedDate;
      if (changeType === 'add') {
        updatedDate = add(dateFilter, { months: 1 });
      } else if (changeType === 'sub') {
        updatedDate = sub(dateFilter, { months: 1 });
      } else {
        updatedDate = new Date();
      }
      const values = getMonthInterval(updatedDate);
      handleDateChange({ dates: values });
      setDateFilter(updatedDate);
    },
    [dateFilter, handleDateChange]
  );

  const changeDateYearly = useCallback(
    (changeType: 'add' | 'sub' | 'none') => {
      let updatedDate;
      if (changeType === 'add') {
        updatedDate = add(dateFilter, { years: 1 });
      } else if (changeType === 'sub') {
        updatedDate = sub(dateFilter, { years: 1 });
      } else {
        updatedDate = new Date();
      }
      const values = getYearInterval(updatedDate);
      handleDateChange({ dates: values });
      setDateFilter(updatedDate);
    },
    [dateFilter, handleDateChange]
  );

  const changeDate = (changeType: 'add' | 'sub') => {
    if (isTypeMonth) {
      changeDateMonthly(changeType);
    } else {
      changeDateYearly(changeType);
    }
  };

  const changeDateType = (type: 'month' | 'year') => {
    if (type === 'month') {
      if (isTypeMonth) return;
      else {
        setDateType('month');
        changeDateMonthly('none');
      }
    } else {
      if (!isTypeMonth) return;
      else {
        setDateType('year');
        changeDateYearly('none');
      }
    }
  };

  return (
    <Stack
      bgColor={'surfaceDefault'}
      paddingTop={'3'}
      borderRadius="lg"
      paddingX={'3'}
      marginBottom={'2.5'}
      borderWidth="1"
      borderColor="borderSeparator"
    >
      <Inline
        marginBottom={'3'}
        justifyContent={'between'}
        alignItems={'center'}
      >
        <Inline>
          <Inline marginRight={'4'}>
            <Box
              id={`${idPrefix}-change-date-type-to-month`}
              bgColor={isTypeMonth ? 'surfacePrimaryLowest' : 'surfaceDefault'}
              borderColor={'borderSeparator'}
              borderWidth="1"
              paddingY={'0.5'}
              paddingX={'1.5'}
              style={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
              onClick={() => changeDateType('month')}
            >
              <Text
                color={isTypeMonth ? 'textPrimary' : 'textMedium'}
                variation={'c2'}
              >
                Month
              </Text>
            </Box>
            <Box
              id={`${idPrefix}-change-date-type-to-year`}
              bgColor={!isTypeMonth ? 'surfacePrimaryLowest' : 'surfaceDefault'}
              borderColor={'borderSeparator'}
              borderWidth="1"
              paddingY={'0.5'}
              paddingX={'1.5'}
              style={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }}
              onClick={() => changeDateType('year')}
            >
              <Text
                color={!isTypeMonth ? 'textPrimary' : 'textMedium'}
                variation={'c2'}
              >
                Year
              </Text>
            </Box>
          </Inline>
          <Inline gap="2" alignItems={'center'}>
            <Box
              id={`${idPrefix}-change-to-previous-date`}
              as="button"
              backgroundColor="transparent"
              onClick={() => changeDate('sub')}
            >
              <RightCaratIcon
                cursor="pointer"
                size="2.5"
                rotate="180"
                color={'iconLow'}
              />
            </Box>
            <Text color={'textPrimary'} variation={'t4'}>
              {dateType === 'month'
                ? format(dateFilter, 'MMM, yyyy')
                : 'FY: ' + format(dateFilter, 'yyyy')}
            </Text>
            <Box
              id={`${idPrefix}-change-to-next-date`}
              as="button"
              backgroundColor="transparent"
              onClick={() => changeDate('add')}
            >
              <RightCaratIcon cursor="pointer" size="2.5" color={'iconLow'} />
            </Box>
          </Inline>
        </Inline>
        <MultiSelect
          id={`${idPrefix}-select-branches-filter`}
          label="Branches: All"
          actionBtnTitle="Show Results"
          value={invoicesOverviewParams.branches}
          options={branchForFilters}
          loadingOptions={overviewStatus === 'in_progress'}
          onSave={(values) => {
            handleParamChange('branches', values);
          }}
          onCancel={() => {
            handleParamChange('branches', []);
          }}
          cancelBtnTitle="Reset"
        />
      </Inline>
      <Box width="fitContent">
        <Inline as="ul" padding="1" backgroundColor="surfaceDefault">
          {initialOverview.map(({ id, label, value, to }, i) => {
            const data = overview?.find(
              (item) => (item?.invoice_status || item?.payment_status) === id
            );
            return (
              <Inline
                as="li"
                key={id}
                alignItems="center"
                style={{
                  listStyleType: 'none',
                }}
              >
                <Inline
                  as={Link}
                  to={`${rootPathForOverview}${to}?from=all-invoices&to=${
                    data?.invoice_status === 'approved'
                      ? 'all'
                      : data?.invoice_status === 'under_review'
                      ? 'unpaid'
                      : data?.payment_status
                  }`}
                  key={id}
                  paddingY="2"
                  paddingX="4"
                  gap="3"
                  style={{ color: 'inherit' }}
                  textDecoration="none"
                >
                  <Box
                    size="1.5"
                    rounded="full"
                    backgroundColor={
                      id === 'approved' || id === 'paid'
                        ? 'surfaceSuccess'
                        : id === 'under_review'
                        ? 'surfacePending'
                        : id === 'unpaid'
                        ? 'surfaceRejected'
                        : 'transparent'
                    }
                    style={{ marginTop: 6 }}
                  />
                  <Stack gap="1">
                    <Inline gap="1" alignItems="center">
                      <Text
                        variation="b2"
                        color="textMedium"
                        textTransform="capitalize"
                      >
                        {label}
                      </Text>
                      <RightCaratIcon size="2" color="iconMedium" />
                    </Inline>
                    {syncingData ? (
                      <SkeletonRows numOfRows={1} numOfCols={1} width={'4'} />
                    ) : (
                      <Stack>
                        <Text variation="h3" as="h3">
                          {data?.total_count || value}
                        </Text>
                        <Amount
                          variation="b2"
                          color="textLow"
                          amount={Number(data?.total_amount || '0')}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Inline>

                {i !== initialOverview.length - 1 ? (
                  <Box
                    width="px"
                    style={{ height: 40 }}
                    backgroundColor="borderSeparator"
                  />
                ) : null}
              </Inline>
            );
          })}
        </Inline>
      </Box>
    </Stack>
  );
}
