import React from 'react';
import { Text, TextProps } from '../text/text';
import { formatDate, parseISODate } from '@nbfc-expense-tool/util-dates';

export const Time = React.forwardRef<
  HTMLTimeElement,
  Omit<TextProps<'time'>, 'as' | 'children'> & {
    /**
     * This is the server time stamp value returned by firebase
     */
    /**
     * Date parsed value or a ISO datetime string
     */
    date?: Date | string;
    /**
     * Custom format to render the date in
     * @default dd MMM, yyyy
     */
    format?: string;
    children?: (props: { date: Date; format: string }) => React.ReactNode;
  }
>(function Time({ date, format = 'dd/MM/yyyy', children, ...props }, ref) {
  let value: Date | null = null;
  value = typeof date === 'string' ? parseISODate(date) : date || null;
  if (!value) return null;
  return (
    <Text as="time" dateTime={value.toISOString()} ref={ref} {...props}>
      {children ? children({ date: value, format }) : formatDate(value, format)}
    </Text>
  );
});

export function Date(props: React.ComponentProps<typeof Time>) {
  return (
    <Time {...props}>
      {({ date, format }) => {
        return formatDate(date, format);
      }}
    </Time>
  );
}
