import {
  Box,
  Text,
  GlobalToastRegion,
  OverlayProvider,
  Button,
  toastQueue,
  CrossIcon,
} from '@nbfc-expense-tool/ui';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { registerSW } from 'virtual:pwa-register';
import {
  DashboardLayout as DashboardTabLayout,
  InvoiceAnalysis,
  SpendAnalysis,
} from './pages/Dashboard';
import { DashboardLayout, PanelLayout } from './DashboardLayout';
import {
  GuestRoutes,
  ProtectedRoutes,
  RedirectForDashboard,
  RedirectForInvoices,
  RedirectForReimbursements,
  RedirectForVendors,
  RedirectToDashboard,
  RedirectToHome,
  RedirectToLogin,
} from './Redirects';
import {
  InvoicesLayout,
  InvoicesCreatedByYou,
  InvoicesWaitingForYourApproval,
  AllInvoices,
  TypedInvoicesPage,
  InvoiceDetailsPage,
  AddNewInvoice,
} from './pages/Invoices';
import {
  VendorsLayout,
  ActiveVendors,
  WaitingForApproval,
  InactiveVendors,
  VendorDetails,
  AddOrEditVendor,
  VendorProfile,
  BulkImportVendors,
} from './pages/Vendors';
import {
  AuthLayout,
  ForgotPassword,
  LoginPage,
  Profile,
  ResetPassword,
} from './pages/Auth';
import BranchesPage from './pages/Branches';
import EmployeesPage from './pages/Employees';
import AddNewEmployee from './pages/Employees/AddNewEmployee';
import BranchDetailsPage from './pages/Branches/BranchDetails';
import BulkImportBranches from './pages/Branches/BulkImportBranches';
import BulkImportEmployees from './pages/Employees/BulkImportEmployees';
import RulesAndPolicies from './pages/RulesAndPolicies';
import { init } from '@nbfc-expense-tool/util-logging';
import config from '../config';
import * as Sentry from '@sentry/react';
import {
  ReimbursementsLayout,
  ReimbursementsCreatedByYou,
  ReimbursementsWaitingForApproval,
  AllReimbursements,
  AddOrEditReimbursement,
  ReimbursementDetails,
  TypedReimbursements,
} from './pages/Reimbursements';
import { useEffect } from 'react';
import { getTenantConfig } from '@nbfc-expense-tool/data-store/utils';
import { useSetAtom } from 'jotai';
import { tenantConfig } from '@nbfc-expense-tool/data-store/jotai-store';

const updateSW = registerSW({
  onRegistered() {
    console.log('SW Registered');
  },
  onNeedRefresh() {
    toastQueue.add({
      type: 'custom',
      renderToastUI: ({ onPress }) => {
        return (
          <Box bgColor={'surfaceNeutralHigh'} borderRadius={'md'}>
            <Box>
              <Box display={'flex'} flexDirection={'row'}>
                <Text
                  paddingTop={'2'}
                  paddingLeft={'2'}
                  color={'textOnSurface'}
                >
                  New Update Available!
                </Text>
                <CrossIcon
                  color={'iconOnSurface'}
                  size={'3'}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onClick={onPress as any}
                  padding={'1.5'}
                />
              </Box>
              <Box display={'flex'} padding={'2'} paddingTop={'0'}>
                <Button id="update-button" title="Update" onClick={updateSW} />
              </Box>
            </Box>
          </Box>
        );
      },
    });
  },
});

const router = createBrowserRouter([
  {
    path: '',
    element: (
      <ProtectedRoutes>
        <DashboardLayout />
      </ProtectedRoutes>
    ),
    children: [
      {
        path: '',
        element: <RedirectToHome />,
      },
      {
        path: '/home',
        element: <PanelLayout />,
        children: [
          { path: '', element: <RedirectToDashboard /> },
          {
            path: 'dashboard',
            element: <DashboardTabLayout />,
            children: [
              {
                path: '',
                element: <RedirectForDashboard />,
              },
              {
                path: 'invoice-analysis',
                element: <InvoiceAnalysis />,
              },
              {
                path: 'spend-analysis',
                element: <SpendAnalysis />,
              },
            ],
          },
          {
            path: 'invoices',
            element: <InvoicesLayout />,
            children: [
              {
                path: '',
                element: <RedirectForInvoices />,
              },
              {
                path: ':invoiceTicketNumber',
                element: <InvoiceDetailsPage />,
              },
              {
                path: 'all-invoices',
                children: [
                  {
                    path: '',
                    element: <AllInvoices />,
                  },
                  {
                    path: ':type',
                    element: <TypedInvoicesPage />,
                  },
                ],
              },
              {
                path: 'created-by-you',
                element: <InvoicesCreatedByYou />,
              },
              {
                path: 'waiting-for-your-approval',
                element: <InvoicesWaitingForYourApproval />,
              },
              {
                path: '*',
                element: <RedirectForInvoices />,
              },
            ],
          },
          {
            path: 'vendors',
            children: [
              {
                path: '',
                element: <RedirectForVendors />,
              },
              {
                path: 'vendors-list',
                element: <VendorsLayout />,
                children: [
                  {
                    path: '',
                    element: <RedirectForVendors />,
                  },
                  {
                    path: 'active-vendors',
                    element: <ActiveVendors />,
                  },
                  {
                    path: 'waiting-for-approval',
                    element: <WaitingForApproval />,
                  },
                  {
                    path: 'inactive-vendors',
                    element: <InactiveVendors />,
                  },
                ],
              },
              {
                path: ':vendorId',
                children: [
                  {
                    path: '',
                    element: <VendorDetails />,
                  },
                  {
                    path: 'vendor-profile',
                    element: <VendorProfile />,
                  },
                  {
                    path: 'invoice-details/:invoiceTicketNumber',
                    element: <InvoiceDetailsPage />,
                  },
                ],
              },
              {
                path: 'bulk-import-vendors',
                element: <BulkImportVendors />,
              },
              {
                path: '*',
                element: <RedirectForVendors />,
              },
            ],
          },
          {
            path: 'reimbursements',
            element: <ReimbursementsLayout />,
            children: [
              {
                path: '',
                element: <RedirectForReimbursements />,
              },
              {
                path: ':reimbursementId',
                element: <ReimbursementDetails />,
              },
              {
                path: 'all-reimbursements',
                children: [
                  {
                    path: '',
                    element: <AllReimbursements />,
                  },
                  {
                    path: ':type',
                    element: <TypedReimbursements />,
                  },
                ],
              },
              {
                path: 'created-by-you',
                element: <ReimbursementsCreatedByYou />,
              },
              {
                path: 'waiting-for-your-approval',
                element: <ReimbursementsWaitingForApproval />,
              },
              {
                path: '*',
                element: <RedirectForReimbursements />,
              },
            ],
          },
          {
            path: 'branches',
            children: [
              {
                path: '',
                element: <BranchesPage />,
              },
              {
                path: ':branchId',
                element: <BranchDetailsPage />,
              },
              {
                path: 'bulk-import-branches',
                element: <BulkImportBranches />,
              },
            ],
          },
          {
            path: 'employees',
            children: [
              {
                path: '',
                element: <EmployeesPage />,
              },
              {
                path: 'bulk-import-employees',
                element: <BulkImportEmployees />,
              },
            ],
          },
          {
            path: 'rules-policies',
            element: <RulesAndPolicies />,
          },
        ],
      },
    ],
  },
  {
    path: 'profile-details',
    element: <Profile />,
  },
  {
    path: '/auth',
    element: (
      <GuestRoutes>
        <AuthLayout />
      </GuestRoutes>
    ),
    children: [
      {
        path: '',
        element: <RedirectToLogin />,
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'password-reset/:token',
        element: <ResetPassword />,
      },
      {
        path: '*',
        element: <RedirectToLogin />,
      },
    ],
  },
  {
    path: '/add-new-invoice',
    element: (
      <ProtectedRoutes>
        <AddNewInvoice />
      </ProtectedRoutes>
    ),
  },
  {
    path: 'edit-invoice/:invoiceTicketNumber',
    element: (
      <ProtectedRoutes>
        <AddNewInvoice />
      </ProtectedRoutes>
    ),
  },
  {
    path: '/add-new-vendor',
    element: (
      <ProtectedRoutes>
        <AddOrEditVendor />
      </ProtectedRoutes>
    ),
  },
  {
    path: '/edit-vendor/',
    element: (
      <ProtectedRoutes>
        <AddOrEditVendor flowType="edit" />
      </ProtectedRoutes>
    ),
  },
  {
    path: '/add-new-employee',
    element: (
      <ProtectedRoutes>
        <AddNewEmployee />
      </ProtectedRoutes>
    ),
  },
  {
    path: '/add-new-reimbursement',
    element: (
      <ProtectedRoutes>
        <AddOrEditReimbursement />
      </ProtectedRoutes>
    ),
  },
  {
    path: '/edit-reimbursement',
    element: (
      <ProtectedRoutes>
        <AddOrEditReimbursement flowType="edit" />
      </ProtectedRoutes>
    ),
  },
  {
    path: '*',
    element: (
      <ProtectedRoutes>
        <RedirectToHome />
      </ProtectedRoutes>
    ),
  },
]);

init({
  appName: config.appName,
  appVersion: config.appVersion,
  dsn: config.sentryDsn,
  env: config.appEnv,
});

function FallbackComponent() {
  return (
    <Box
      display={'flex'}
      height={'screen'}
      flexDirection={'col'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Text marginY={'1'} variation={'b3'}>
        An Error has Occured
      </Text>
      <Button
        id="reload-app-button"
        title="Reload App"
        onClick={() => window.location.reload()}
      />
    </Box>
  );
}

const myFallback = <FallbackComponent />;

export function App() {
  const setTenantConfig = useSetAtom(tenantConfig);

  useEffect(() => {
    getTenantConfig()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        setTenantConfig(res.data);
      })
      .catch(() => {
        setTenantConfig(null);
      });
  }, [setTenantConfig]);

  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <OverlayProvider>
        <Box>
          <GlobalToastRegion />
          <RouterProvider router={router} />
        </Box>
      </OverlayProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
