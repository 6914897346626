import { Box, Stack, Text } from '@nbfc-expense-tool/ui';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import LoginSlide1 from '../../../../src/assets/LoginSlide1.webp';
import LoginSlide2 from '../../../../src/assets/LoginSlide2.webp';
import LoginSlide3 from '../../../../src/assets/LoginSlide3.webp';

const slidesData = [
  {
    image: LoginSlide1,
    title: 'Get All Actionable Insights on Spend',
    bulletPointOne: 'Spend Trends & breakdown',
    bulletPointTwo: 'Invoice approval efficiency',
  },
  {
    image: LoginSlide2,
    title: 'Manage Invoices from Single Dashboard',
    bulletPointOne: 'Add vendor invoices',
    bulletPointTwo: 'Track invoice status',
  },
  {
    image: LoginSlide3,
    title: 'Manage Vendor Ledgers & Track Dues',
    bulletPointOne: 'View all vendor ledgers',
    bulletPointTwo: 'Keep track of due amounts',
  },
];

const OnboardingSlides = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const idPrefix = 'onboarding-slide';

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slidesData.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Stack
      style={{ width: 472 }}
      height="full"
      backgroundColor="surfacePrimary"
      alignItems="center"
      overflow="hidden"
      display={{ sm: 'none', md: 'none', xs: 'none', lg: 'flex', xl: 'flex' }}
      justifyContent="center"
    >
      <Box
        display="flex"
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
          transition: 'ease-out 0.5s',
        }}
        alignItems="center"
        width="full"
      >
        {slidesData.map((slide) => {
          return (
            <Stack
              display="flex"
              justifyContent="center"
              flex="1"
              paddingX="4"
              key={slide.title}
            >
              <Box
                as="img"
                src={slide.image}
                style={{ height: 300 }}
                alignSelf="center"
              />
              <Text
                color="textOnSurface"
                variation="h3"
                marginTop="4"
                textAlign="left"
              >
                {slide.title}
              </Text>
              <Box display="flex" alignItems="center" marginTop="3">
                <Box backgroundColor="iconOnSurface" height="1" width="1" />
                <Text color="textOnSurface" variation="t3" marginLeft="2">
                  {slide.bulletPointOne}
                </Text>
              </Box>
              <Box display="flex" alignItems="center" marginTop="2">
                <Box backgroundColor="iconOnSurface" height="1" width="1" />
                <Text color="textOnSurface" variation="t3" marginLeft="2">
                  {slide.bulletPointTwo}
                </Text>
              </Box>
            </Stack>
          );
        })}
      </Box>
      <Box
        display="flex"
        marginTop="6"
        alignSelf="start"
        marginLeft="4"
        alignItems="center"
      >
        <Box
          id={`first-${idPrefix}`}
          backgroundColor={currentSlide === 0 ? 'iconOnSurface' : 'iconPrimary'}
          height="1.5"
          width={currentSlide === 0 ? '5' : '1.5'}
          rounded="full"
          onClick={() => setCurrentSlide(0)}
          cursor={currentSlide !== 0 ? 'pointer' : 'default'}
          borderWidth={currentSlide !== 0 ? '1' : '0'}
          borderColor="borderPrimaryLow"
        />
        <Box
          id={`second-${idPrefix}`}
          backgroundColor={currentSlide === 1 ? 'iconOnSurface' : 'iconPrimary'}
          height="1.5"
          width={currentSlide === 1 ? '5' : '1.5'}
          rounded="full"
          marginLeft="1"
          onClick={() => setCurrentSlide(1)}
          cursor={currentSlide !== 1 ? 'pointer' : 'default'}
          borderWidth={currentSlide !== 1 ? '1' : '0'}
          borderColor="borderPrimaryLow"
        />
        <Box
          id={`third-${idPrefix}`}
          backgroundColor={currentSlide === 2 ? 'iconOnSurface' : 'iconPrimary'}
          height="1.5"
          width={currentSlide === 2 ? '5' : '1.5'}
          rounded="full"
          marginLeft="1"
          onClick={() => setCurrentSlide(2)}
          cursor={currentSlide !== 2 ? 'pointer' : 'default'}
          borderWidth={currentSlide !== 2 ? '1' : '0'}
          borderColor="borderPrimaryLow"
        />
      </Box>
    </Stack>
  );
};

const AuthLayout: React.FC = () => {
  return (
    <Box display="flex" backgroundColor="surfaceDefault" height="screen">
      <OnboardingSlides />
      <Outlet />
    </Box>
  );
};

export default AuthLayout;
