/* eslint-disable-next-line */
import { userDetails } from '@nbfc-expense-tool/data-store/jotai-store';
import {
  getSyncStoredItem,
  setSyncStoredItem,
} from '@nbfc-expense-tool/data-store/storage';
import { logout } from '@nbfc-expense-tool/data-store/utils';
import { setLoggerIdentity } from '@nbfc-expense-tool/util-logging';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';

type UserRole = {
  id: number;
  name: string;
  display_name: string;
  level: number;
  permissions: {
    id: number;
    name: string;
  }[];
};

export type UserDetails = {
  id: number;
  name: string;
  email: string;
  email_verified_at: Date | null;
  phone_number: null | string;
  dob: Date | null;
  gender: string | null;
  disabled_at: Date | null;
  branch_id: string | null;
  branches: {
    id: number;
    name: string;
    branch_code: string;
  }[];
  created_at: Date | null;
  updated_at: Date | null;
  avatar: {
    original: string | null;
    preview: string | null;
  };
  roles: UserRole[];
  employee_code?: number;
};

export function useUser() {
  const userDetailsAtom = useAtomValue(userDetails);
  const userPersistingDetails = userDetailsAtom || getSyncStoredItem('user');
  if (userPersistingDetails) {
    setLoggerIdentity({
      uid: userPersistingDetails.id.toString(),
      displayName: userPersistingDetails.name,
    });
  }
  return {
    user: userPersistingDetails as UserDetails,
  };
}

export function useLogout() {
  return useCallback(async () => {
    try {
      await logout();
      setLoggerIdentity(null);
      setSyncStoredItem('user', undefined);
      setSyncStoredItem('authToken', undefined);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setSyncStoredItem('user', undefined);
      setSyncStoredItem('authToken', undefined);
      return e as Error;
    }
  }, []);
}
