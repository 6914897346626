/* eslint-disable @typescript-eslint/no-explicit-any */
import { setSyncStoredItem } from '@nbfc-expense-tool/data-store/storage';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  logError,
  CONFIG as SENTRY_CONFIG,
} from '@nbfc-expense-tool/util-logging';

type HttpsHeaders = {
  Accept: string;
  'Content-Type': string;
  Authorization: string;
  'X-REQUEST-SIGNATURE'?: string;
  Tenant?: string;
};

export type Options = {
  timeout?: number;
  headers?: any;
  payload?: any;
  abortController?: AbortController;
  apiVersion?: 'v1';
  inQueryParams?: string[];
  responseType?:
    | 'arraybuffer'
    | 'blob'
    | 'document'
    | 'json'
    | 'text'
    | 'stream';
  ignoredStatusCodesForReporting?: number[];
  isAuthenticatedRequest?: boolean;
};

export enum FRONTEND_API_ERROR_MESSAGES {
  TimeoutError = 'There was a problem with your request. Please try again later.',
  NoInternet = 'Please check your internet connection and try again.',
}

export const config = {
  baseUrl: '' as string,
};

export function configure(values: typeof config) {
  config.baseUrl = values.baseUrl;
}

const defaultOptions: Options = {
  timeout: 20000,
  payload: {},
  headers: {},
  apiVersion: 'v1',
  ignoredStatusCodesForReporting: [], // default status codes to ignore for all apis
  isAuthenticatedRequest: true,
};

export const callApiService: <T>(
  endPoint: string,
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT',
  optionalParams?: Partial<Options>
) => Promise<T> = async (
  endPoint,
  method = 'GET',
  optionalParams = { ignoredStatusCodesForReporting: [] }
) => {
  const options = {
    ...defaultOptions,
    ...optionalParams,
  };

  if (
    Array.isArray(optionalParams.ignoredStatusCodesForReporting) &&
    Array.isArray(defaultOptions.ignoredStatusCodesForReporting)
  ) {
    options.ignoredStatusCodesForReporting = [
      ...optionalParams.ignoredStatusCodesForReporting,
      ...defaultOptions.ignoredStatusCodesForReporting,
    ];
  }

  const BASE_URL = `${config.baseUrl}/${options.apiVersion}`;

  let url: string = BASE_URL + `/${endPoint}`;

  const headers: HttpsHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: options.isAuthenticatedRequest
      ? `Bearer ${axios.defaults.headers.common['Authorization']}`
      : '',
    Tenant: /localhost|deploy-preview|.netlify.app/.test(window.location.host)
      ? 'spendtest.cashbook.in'
      : window.location.host,
    ...options.headers,
  };

  if (
    method === 'GET' &&
    options.payload &&
    Object.keys(options.payload).length > 0
  ) {
    url += `?${getQueryParams(options.payload, options.inQueryParams)}`;
  }

  return new Promise((resolve, reject) => {
    //Handling payload
    const payload: AxiosRequestConfig = {
      method,
      url,
      headers,
      data:
        method === 'POST' || method === 'PATCH' || method === 'PUT'
          ? options?.payload
          : undefined,
      timeout: options?.timeout || 20000, // Default timeout
    };
    if (options.responseType) {
      payload['responseType'] = options.responseType;
    }

    const promise = axios(payload);
    promise
      .then((resolvedResponse: AxiosResponse) => {
        resolve(resolvedResponse.data);
      })
      .catch((error) => {
        resolveErrorResponse(error, options.ignoredStatusCodesForReporting)
          .then((errorData: ErrorResponse) => {
            reject(errorData);
          })
          .catch((innerError) => {
            reject(innerError);
          });
      });
  });
};

export interface ErrorResponse {
  message: string;
  errors: Record<string, string[]>;
  status: number;
}

function resolveErrorResponse(
  error: any,
  ignoredStatusCodesForReporting: number[] = []
): Promise<ErrorResponse> {
  return new Promise((resolve, reject) => {
    if (axios.isCancel(error)) {
      return reject(FRONTEND_API_ERROR_MESSAGES.TimeoutError);
    }
    if (!navigator.onLine) {
      return reject(FRONTEND_API_ERROR_MESSAGES.NoInternet);
    }
    if (error.response && error.response.status === 401) {
      setSyncStoredItem('user', undefined);
      setSyncStoredItem('authToken', undefined);
    }
    if (
      SENTRY_CONFIG.ENV === 'development' ||
      (error.response &&
        error.response.status >= 400 &&
        error.response.status < 500 &&
        !ignoredStatusCodesForReporting.includes(error.response.status))
    ) {
      logError(error);
    }
    if (error.response && error.response.data) {
      const errorData: ErrorResponse = error.response.data;
      resolve({ ...errorData, status: error.response.status });
    } else {
      reject(error);
    }
  });
}

export const getQueryParams = (
  params: {
    [key: string]: string | number | boolean | Array<string | number>;
  },
  inQueryParams?: string[]
) => {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key];
      if (
        (inQueryParams?.includes(key) || key === 'page') &&
        (typeof value === 'number' || typeof value === 'string')
      ) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
      if (typeof value === 'object') {
        let values = '';
        value.forEach((item, i) => {
          values = values + `${item}${value.length !== i + 1 ? ',' : ''}`;
        });
        return `filter[${key}]=${values}`;
      }
      return `filter[${key}]=${value}`;
    })
    .join('&');

  return queryString;
};
