import { useUser, UserDetails } from '@nbfc-expense-tool/data-store/auth';
import {
  ReimbursementActivityEvent,
  ReimbursementDetails as ReimbursementDetailsType,
  useReimbursementDetails,
  useReimbursementExpenseListAndDocuments,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  Amount,
  Avatar,
  Box,
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  CheckIcon,
  ClockIcon,
  ConfirmationDialog,
  CopyIcon,
  CrossCircle,
  CrossIcon,
  Date,
  EditIconTwo,
  EmptyState,
  FilePlusIcon,
  IconParams,
  Inline,
  PencilIcon,
  ReturnIcon,
  SecondaryHeader,
  SpinnerIcon,
  Stack,
  Text,
  UploadDocument,
  UserIcon,
} from '@nbfc-expense-tool/ui';
import React, { useCallback, useMemo } from 'react';
import ActionOnReimbursementInModal from '../../Reimbursement/ActionOnReimbursementInModal';

const idPrefix = 'reimbursement-details';

const ReimbursementDetails: React.FC = () => {
  const {
    goBack,
    reimbursementDetails,
    loadingStatus,
    fromScreenName,
    isDeleteReimbursementConfirmationDialogVisible,
    isDeleteReimbursementApiLoading,
    reloadDetails,
    copyUTRNumberToClipboard,
    navigateToReimbursementsListing,
    onClickEditReimbursement,
    onClickDeleteReimbursement,
    closeDeleteReimbursementConfirmationDialog,
    onConfirmDeleteReimbursement,
  } = useReimbursementDetails();
  const { user } = useUser();

  const renderReimbursementStatus = useMemo(() => {
    switch (reimbursementDetails?.status) {
      case 'under_review':
        return (
          <Box
            paddingY="0.5"
            paddingX="1"
            marginLeft="1"
            borderRadius="md"
            backgroundColor="surfaceWarningLowest"
          >
            <Text variation="c1" color="textWarning">
              Under Review
            </Text>
          </Box>
        );
      case 'approved':
        return (
          <Box
            paddingY="0.5"
            paddingX="1"
            marginLeft="1"
            borderRadius="md"
            backgroundColor="surfaceSuccessLowest"
          >
            <Text variation="c1" color="textSuccess">
              Approved
            </Text>
          </Box>
        );
      case 'rejected':
        return (
          <Box
            paddingY="0.5"
            paddingX="1"
            marginLeft="1"
            borderRadius="md"
            backgroundColor="surfaceErrorLowest"
          >
            <Text variation="c1" color="textError">
              Rejected
            </Text>
          </Box>
        );
      case 'returned':
        return (
          <Box
            paddingY="0.5"
            paddingX="1"
            marginLeft="1"
            borderRadius="md"
            backgroundColor="surfaceNeutralLowest"
          >
            <Text variation="c1" color="textMedium">
              Returned
            </Text>
          </Box>
        );
      default:
        return null;
    }
  }, [reimbursementDetails?.status]);

  const renderReimbursementStateBanner = useMemo(() => {
    if (reimbursementDetails?.status === 'under_review') return null;
    const borderTopColor =
      reimbursementDetails?.status === 'approved'
        ? reimbursementDetails.payment_status === 'paid'
          ? 'borderSuccess'
          : 'borderWarning'
        : reimbursementDetails?.status === 'returned'
        ? 'borderWarning'
        : 'borderError';
    return (
      <Box
        borderRadius="md"
        borderWidth="1"
        borderTopWidth="4"
        borderColor="borderSeparator"
        borderTopColor={borderTopColor}
        backgroundColor="surfaceDefault"
        marginBottom="3"
        paddingX="3"
        paddingY="2"
      >
        {reimbursementDetails?.status === 'approved' ? (
          <Inline justifyContent="between">
            <Stack alignItems="start" gap="1">
              <Text variation="t4" color="textMedium">
                Payment Status:
              </Text>
              {reimbursementDetails?.payment_status === 'paid' ? (
                <Box
                  borderRadius="md"
                  backgroundColor="surfaceSuccessLowest"
                  paddingX="1"
                  paddingY="0.5"
                >
                  <Text variation="c1" color="textSuccess">
                    Paid
                  </Text>
                </Box>
              ) : (
                <Box
                  borderRadius="md"
                  backgroundColor="surfaceWarningLowest"
                  paddingX="1"
                  paddingY="0.5"
                >
                  <Text variation="c1" color="textWarning">
                    Unpaid
                  </Text>
                </Box>
              )}
            </Stack>
            {reimbursementDetails?.payment_status === 'paid' && (
              <Stack gap="1">
                <Inline alignItems="center" justifyContent="end">
                  <Text variation="t4" color="textMedium">
                    UTR:
                  </Text>
                  <Text variation="b2" marginLeft="1">
                    {reimbursementDetails?.utr_number || '-'}
                  </Text>
                  <CopyIcon
                    id={`${idPrefix}-copy-utr-number`}
                    color={'iconMedium'}
                    size={'2.5'}
                    onClick={copyUTRNumberToClipboard}
                    marginLeft="2"
                  />
                </Inline>
                <Text variation="c2" color="textLow" textAlign="right">
                  Paid On:{' '}
                  <Date
                    date={reimbursementDetails.paid_at as string}
                    variation="c2"
                    color="textLow"
                  />
                </Text>
              </Stack>
            )}
          </Inline>
        ) : (
          <Stack>
            <Text variation="t4">
              {reimbursementDetails?.status === 'returned'
                ? 'Return Reason:'
                : 'Reject Reason:'}
            </Text>
            <Text variation="c0" color="textMedium" marginTop="1">
              {reimbursementDetails?.status === 'returned'
                ? reimbursementDetails?.last_returned_remarks
                : reimbursementDetails?.last_rejected_remarks}
            </Text>
          </Stack>
        )}
      </Box>
    );
  }, [copyUTRNumberToClipboard, reimbursementDetails]);

  const renderReimbursementDetails = useMemo(() => {
    return (
      <Stack marginBottom="8" flex="1">
        {renderReimbursementStateBanner}
        <Box
          backgroundColor="surfaceDefault"
          borderRadius="md"
          borderWidth="1"
          borderColor="borderSeparator"
        >
          <Inline
            justifyContent="between"
            paddingX="3"
            paddingY="2"
            borderBottomWidth="1"
            borderColor="borderSeparator"
          >
            <Inline alignItems="center">
              <Text variation="t4" color="textMedium">
                Reimbursement Status:
              </Text>
              {renderReimbursementStatus}
            </Inline>
            <Inline alignItems="center">
              <Text variation="t3" color="textMedium">
                Amount:
              </Text>
              <Amount
                marginLeft="1"
                amount={parseInt(
                  reimbursementDetails?.grand_total_amount || '0',
                  10
                )}
              />
            </Inline>
          </Inline>
          <Box paddingX="3" paddingBottom="5">
            <Inline
              alignItems="center"
              paddingY="2"
              borderBottomWidth="1"
              borderColor="borderSeparator"
              marginBottom="4"
            >
              <Avatar
                avatarSize="lg"
                as="img"
                src={reimbursementDetails?.creator.avatar.preview}
              />
              <Box marginLeft="2">
                <Text variation="t3">{reimbursementDetails?.creator.name}</Text>
                <Text variation="b2" color="textLow">
                  {reimbursementDetails?.creator.employee_code || '-'}
                </Text>
              </Box>
            </Inline>
            <Inline alignItems="center">
              <Box flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Reimbursement ID
                </Text>
                <Text variation="b2">
                  {reimbursementDetails?.ticket_number}
                </Text>
              </Box>
              <Box flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Title
                </Text>
                <Text variation="b2">{reimbursementDetails?.title}</Text>
              </Box>
              <Box flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Requested On
                </Text>
                <Date date={reimbursementDetails?.created_at} />
              </Box>
            </Inline>
          </Box>
          <ReimbursementExpenseListAndDocuments
            grandTotalAmount={reimbursementDetails?.grand_total_amount || '0'}
            expensesList={reimbursementDetails?.reimbursement_items || []}
            documents={reimbursementDetails?.documents || []}
          />
        </Box>
      </Stack>
    );
  }, [
    renderReimbursementStatus,
    reimbursementDetails,
    renderReimbursementStateBanner,
  ]);

  const renderActivityLogs = useMemo(() => {
    if (reimbursementDetails?.activities.length) {
      return (
        <Stack
          gap="6"
          borderWidth="1"
          rounded="md"
          width="full"
          backgroundColor="surfaceDefault"
          borderColor="borderSeparator"
          maxWidth="xs"
          marginBottom="5"
          paddingBottom="4"
        >
          <Box
            borderBottomWidth="1"
            borderColor="borderSeparator"
            paddingX="3"
            paddingY="1.5"
          >
            <Text variation="t3">Activity Log</Text>
          </Box>
          <Stack
            as="ul"
            gap="10"
            style={{ listStyleType: 'none' }}
            paddingX="3"
          >
            {reimbursementDetails?.current_actor?.id && (
              <Inline
                as="li"
                backgroundColor="surfaceDefault"
                key={reimbursementDetails.current_actor.id}
              >
                <Inline gap="5" zIndex="10" position="relative">
                  <IconForActivityLog event="pending" />
                  {reimbursementDetails.activities.length ? (
                    <Box
                      as="hr"
                      position="absolute"
                      height="full"
                      marginX="1.5"
                      backgroundColor="borderSeparator"
                      style={{ width: 2, top: 30, zIndex: 1 }}
                    />
                  ) : null}
                  <Stack gap="3">
                    <TitleForActivityLog event="pending" />
                    <Inline gap="3" alignItems="center">
                      <Avatar
                        avatarSize="md"
                        backgroundColor="AvatarDisabled"
                        avatarIcon={(props) => <UserIcon {...props} />}
                      />
                      <Text variation="b2">
                        {reimbursementDetails.current_actor?.display_name}
                      </Text>
                    </Inline>
                  </Stack>
                </Inline>
              </Inline>
            )}
            {reimbursementDetails?.activities.map((activity, i) => (
              <ActivityLogItem
                user={user}
                key={activity.id}
                activity={activity}
                isLastItem={i + 1 === reimbursementDetails.activities.length}
              />
            ))}
          </Stack>
        </Stack>
      );
    }
    return null;
  }, [
    user,
    reimbursementDetails?.activities,
    reimbursementDetails?.current_actor,
  ]);

  const renderReimbursementStates = useMemo(() => {
    switch (loadingStatus) {
      case 'in_progress':
        return (
          <Stack justifyContent="center" alignItems="center" marginTop="10">
            <SpinnerIcon size="3" color="iconMedium" />
            <Text variation="b2" color="textMedium" marginTop="1">
              Loading...
            </Text>
          </Stack>
        );
      case 'failed':
        return (
          <Stack justifyContent="center" alignItems="center" marginTop="10">
            <EmptyState
              renderIcon={(props) => <CrossCircle {...props} />}
              title="Reimbursement Details Not Found!"
              subText={`Please check your internet connection and try again`}
              renderButton={() => (
                <Button
                  id={`${idPrefix}-reload-button`}
                  title="Reload"
                  onClick={reloadDetails}
                />
              )}
            />
          </Stack>
        );
      case 'success':
        return (
          <Inline gap="4">
            {renderReimbursementDetails}
            {renderActivityLogs}
          </Inline>
        );
    }
  }, [
    loadingStatus,
    reloadDetails,
    renderReimbursementDetails,
    renderActivityLogs,
  ]);

  const renderReimbursementActions = useCallback(() => {
    if (reimbursementDetails?.status === 'under_review') {
      return (
        <Inline gap="2" alignItems="center">
          {reimbursementDetails.i_can_act_on && (
            <ActionOnReimbursementInModal
              reimbursement={reimbursementDetails}
              onSuccess={reloadDetails}
            >
              {({ onOpen }) => (
                <>
                  <Button
                    id={`${idPrefix}-reject-action`}
                    buttonRole="destructive"
                    leftIcon={(props) => <CrossIcon {...props} />}
                    type="outlined"
                    title="Reject"
                    onClick={() => onOpen('reject')}
                  />
                  <Button
                    id={`${idPrefix}-return-action`}
                    leftIcon={(props) => <ReturnIcon {...props} />}
                    type="outlined"
                    title="Return"
                    onClick={() => onOpen('return')}
                  />
                  <Button
                    id={`${idPrefix}-approve-action`}
                    leftIcon={(props) => <CheckIcon {...props} />}
                    title="Approve"
                    buttonRole="success"
                    onClick={() => onOpen('approve')}
                  />
                </>
              )}
            </ActionOnReimbursementInModal>
          )}
          {reimbursementDetails.i_can_edit &&
            reimbursementDetails.i_can_act_on && (
              <Box
                height="4"
                marginX="1.5"
                backgroundColor="borderSeparator"
                style={{ width: 2 }}
              />
            )}
          {reimbursementDetails.i_can_delete && (
            <>
              <Button
                id={`${idPrefix}-delete-action`}
                type="outlined"
                buttonRole="destructive"
                leftIcon={(props) => <CrossIcon {...props} />}
                title="Delete"
                onClick={onClickDeleteReimbursement}
              />
              <ConfirmationDialog
                id={`${idPrefix}-delete-reimbursement`}
                title="Delete Reimbursement"
                description={`You want to delete '${reimbursementDetails?.ticket_number}' Reimbursement.`}
                isVisible={isDeleteReimbursementConfirmationDialogVisible}
                confirmLabel="Yes, Delete"
                confirmButtonRole="destructive"
                confirmButtonState={
                  isDeleteReimbursementApiLoading ? 'loading' : undefined
                }
                onCancel={closeDeleteReimbursementConfirmationDialog}
                onConfirm={onConfirmDeleteReimbursement}
              />
            </>
          )}
          {reimbursementDetails.i_can_edit && (
            <Button
              id={`${idPrefix}-edit-action`}
              leftIcon={(props) => <EditIconTwo {...props} />}
              title="Edit"
              onClick={onClickEditReimbursement}
            />
          )}
        </Inline>
      );
    } else if (
      reimbursementDetails?.status === 'returned' &&
      reimbursementDetails.i_can_edit
    ) {
      return (
        <Button
          id={`${idPrefix}-returned-edit-action`}
          leftIcon={(props) => <EditIconTwo {...props} />}
          title="Edit"
          onClick={onClickEditReimbursement}
        />
      );
    } else if (
      reimbursementDetails?.status === 'approved' &&
      reimbursementDetails?.i_can_mark_paid &&
      reimbursementDetails.payment_status === 'unpaid'
    ) {
      return (
        <ActionOnReimbursementInModal
          reimbursement={reimbursementDetails}
          onSuccess={reloadDetails}
        >
          {({ onOpen }) => (
            <Inline gap="4">
              <Button
                id={`${idPrefix}-approved-unpaid-reject-action`}
                buttonRole="destructive"
                leftIcon={(props) => <CrossIcon {...props} />}
                type="outlined"
                title="Reject"
                onClick={() => onOpen('reject')}
              />
              <Button
                id={`${idPrefix}-approved-unpaid-mark-paid-action`}
                leftIcon={(props) => <CheckIcon {...props} />}
                title="Mark Paid"
                buttonRole="default"
                onClick={() => onOpen('markPaid')}
              />
            </Inline>
          )}
        </ActionOnReimbursementInModal>
      );
    }
    return null;
  }, [
    isDeleteReimbursementConfirmationDialogVisible,
    reimbursementDetails,
    isDeleteReimbursementApiLoading,
    onClickEditReimbursement,
    onClickDeleteReimbursement,
    reloadDetails,
    closeDeleteReimbursementConfirmationDialog,
    onConfirmDeleteReimbursement,
  ]);

  return (
    <Box paddingX="5" paddingTop="4">
      <Stack marginBottom="3">
        <Breadcrumbs marginBottom="0.5">
          {fromScreenName && (
            <BreadcrumbItem isFirst onClick={navigateToReimbursementsListing}>
              {fromScreenName}
            </BreadcrumbItem>
          )}
          <BreadcrumbItem isCurrent>Reimbursement Details</BreadcrumbItem>
        </Breadcrumbs>
        <SecondaryHeader
          onClickBack={goBack}
          headingText={`Reimbursement${
            reimbursementDetails?.ticket_number
              ? `: ${reimbursementDetails?.ticket_number}`
              : ''
          }`}
          renderRight={renderReimbursementActions}
        />
      </Stack>
      {renderReimbursementStates}
    </Box>
  );
};

export default ReimbursementDetails;

const ReimbursementExpenseListAndDocuments: React.FC<{
  grandTotalAmount: string;
  expensesList: ReimbursementDetailsType['reimbursement_items'];
  documents: ReimbursementDetailsType['documents'];
}> = ({ grandTotalAmount, expensesList, documents }) => {
  const { selectedOption, options, expenseTypeList, onChangeSelectedOption } =
    useReimbursementExpenseListAndDocuments();

  const renderExpensesList = useMemo(() => {
    return (
      <Box>
        <Box as="table" width="full" position="relative">
          <Box as="thead" bgColor="surfaceNeutralLowest">
            <Box as="tr">
              <Box
                as="th"
                paddingX="3"
                paddingY="1.5"
                style={{
                  width: '40%',
                }}
                textAlign="left"
                bgColor="surfaceNeutralLowest"
              >
                <Text variation="c1">Expense Type & Description</Text>
              </Box>
              <Box
                as="th"
                paddingX="3"
                paddingY="1.5"
                style={{
                  width: '20%',
                }}
                textAlign="left"
                bgColor="surfaceNeutralLowest"
              >
                <Text variation="c1">Date</Text>
              </Box>
              <Box
                as="th"
                paddingX="3"
                paddingY="1.5"
                style={{
                  width: '20%',
                }}
                textAlign="right"
                bgColor="surfaceNeutralLowest"
              >
                <Text variation="c1">Total Amount</Text>
              </Box>
            </Box>
          </Box>
          <Box as="tbody">
            {expensesList.map(
              (
                { expense_date, description, total_amount, expense_head_id },
                index
              ) => {
                return (
                  <React.Fragment key={index.toString()}>
                    <Box as="tr" borderTopWidth="1" tabIndex={-1}>
                      <Box as="td" paddingX="3" paddingY="2.5" textAlign="left">
                        <Stack>
                          <Text variation="c1">
                            {expenseTypeList[expense_head_id.toString()]
                              ?.name || '-'}
                          </Text>
                          <Text
                            variation="c2"
                            color="textLowest"
                            marginTop="0.5"
                          >
                            {description || '-'}
                          </Text>
                        </Stack>
                      </Box>
                      <Box as="td" paddingX="3" paddingY="2.5">
                        <Date variation="c2" date={expense_date} />
                      </Box>
                      <Box
                        as="td"
                        paddingX="3"
                        paddingY="2.5"
                        className="whitespace-pre"
                        textAlign="right"
                      >
                        <Amount variation="c1" amount={Number(total_amount)} />
                      </Box>
                    </Box>
                  </React.Fragment>
                );
              }
            )}
          </Box>
        </Box>
        <Inline
          paddingY="2"
          justifyContent="between"
          alignItems="center"
          borderTopWidth="1"
          borderColor="borderSeparator"
          marginX="3"
        >
          <Text variation="c1">Total</Text>
          <Amount variation="c1" amount={parseInt(grandTotalAmount, 10)} />
        </Inline>
      </Box>
    );
  }, [expensesList, expenseTypeList, grandTotalAmount]);

  const renderDocumentsList = useMemo(() => {
    return (
      <Stack paddingX="4" paddingBottom="8">
        {documents.map((document) => {
          return (
            <Box marginBottom="5" key={document.id}>
              <UploadDocument
                id={`${idPrefix}`}
                documentInfo={{
                  imgSrc: document.url,
                  name: document.file_name,
                  type: document.mime_type,
                  size: document.size,
                }}
                showDownloadButton
              />
            </Box>
          );
        })}
      </Stack>
    );
  }, [documents]);

  return (
    <Box>
      <Inline
        borderBottomWidth="1"
        borderColor="borderSeparator"
        marginBottom="4"
        marginX="2"
        gap="1"
      >
        {options.map((option) => {
          const isSelected = option.value === selectedOption;
          return (
            <Box
              key={option.value}
              padding="1.5"
              onClick={() => onChangeSelectedOption(option.value)}
              borderBottomWidth="2"
              borderColor={isSelected ? 'borderPrimary' : 'transparent'}
            >
              <Text
                variation="t4"
                color={isSelected ? 'textPrimary' : 'textMedium'}
              >
                {option.label}
              </Text>
            </Box>
          );
        })}
      </Inline>
      {selectedOption === 'items' ? renderExpensesList : renderDocumentsList}
    </Box>
  );
};

const ActivityLogItem: React.FC<{
  user: UserDetails;
  activity: ReimbursementDetailsType['activities'][0];
  isLastItem?: boolean;
}> = ({ user, activity, isLastItem }) => {
  const { created_at, event, causer } = activity;

  return (
    <Inline as="li" backgroundColor="surfaceDefault">
      <Inline gap="5" zIndex="10" position="relative">
        <IconForActivityLog event={event} />
        {isLastItem ? null : (
          <Box
            as="hr"
            position="absolute"
            height="full"
            marginX="1.5"
            backgroundColor="borderSeparator"
            style={{ width: 2, top: 30, zIndex: 1 }}
          />
        )}
        <Stack gap="3">
          <TitleForActivityLog event={event} date={created_at} />
          <Inline gap="3">
            <Avatar
              as="img"
              src={causer?.avatar?.preview || ''}
              avatarSize="md"
            />
            <Stack gap="1">
              <Text variation="b2">
                {causer?.name} {user.id === causer?.id ? `(You)` : ''}
              </Text>
              {causer?.roles?.map((role) => (
                <Text variation="c2" color="textMedium" key={role.id}>
                  {role.display_name}
                </Text>
              ))}
            </Stack>
          </Inline>
        </Stack>
      </Inline>
    </Inline>
  );
};

type IconType = (props: IconParams) => React.ReactElement;
const IconForActivityLog: React.FC<{
  event: ReimbursementActivityEvent;
}> = ({ event }) => {
  const iconBody: IconType | undefined = useMemo(() => {
    switch (event) {
      case 'updated':
        return (props) => <PencilIcon {...props} />;
      case 'approved':
      case 'mark_paid':
        return (props) => <CheckIcon {...props} />;
      case 'pending':
        return (props) => <ClockIcon {...props} />;
      case 'rejected':
        return (props) => <CrossIcon {...props} />;
      case 'returned':
        return (props) => <ReturnIcon {...props} />;
      default:
        return (props) => <FilePlusIcon {...props} />;
    }
  }, [event]);

  return iconBody ? (
    <Avatar
      avatarSize="sm"
      backgroundColor={
        event === 'approved' || event === 'mark_paid'
          ? 'AvatarSuccess'
          : event === 'pending'
          ? 'AvatarWarning'
          : event === 'rejected'
          ? 'AvatarError'
          : 'Avatar9'
      }
      avatarIcon={(props) => iconBody(props)}
    />
  ) : null;
};

const titleForActivityLog = {
  created: 'Created on ',
  updated: 'Updated on ',
  approved: 'Approved on ',
  pending: 'Pending',
  rejected: 'Rejected on ',
  returned: 'Returned on ',
  mark_paid: 'Paid on ',
};

const TitleForActivityLog: React.FC<{
  event: ReimbursementActivityEvent;
  date?: Date | string;
}> = ({ event, date }) => {
  return (
    <Text variation="c4" color="textMedium">
      {titleForActivityLog[event] || ''}
      {date ? (
        <Date
          date={date}
          color="textMedium"
          variation="c4"
          format="dd MMM yyyy, hh:mm a"
        />
      ) : (
        ''
      )}
    </Text>
  );
};
