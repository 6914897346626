import { useUser } from '@nbfc-expense-tool/data-store/auth';
import { checkForSinglePermission } from '@nbfc-expense-tool/data-store/permissions';
import { Button, PlusIcon, ScannerIcon } from '@nbfc-expense-tool/ui';
import { useNavigate } from 'react-router-dom';
import { CONFIG } from '@nbfc-expense-tool/util-logging';
import { useAtomValue } from 'jotai';
import { tenantConfig } from '@nbfc-expense-tool/data-store/jotai-store';
import { TENANT_CONFIG_KEYS } from '@nbfc-expense-tool/data-store/utils';

type AddNewButtonProps = {
  label?: string;
};

export const AddNewInvoiceButton: React.FC<AddNewButtonProps> = ({
  label = 'Add New',
}) => {
  const navigate = useNavigate();
  const { user } = useUser();

  if (
    !checkForSinglePermission(
      'createInvoice',
      user?.roles[0]?.permissions || null
    )
  ) {
    return null;
  }

  return (
    <Button
      id="add-new-invoice-button"
      title={label}
      leftIcon={(props) => <PlusIcon {...props} />}
      onClick={() => {
        navigate('/add-new-invoice');
      }}
    />
  );
};

type UploadButtonProps = {
  label?: string;
  onClick: () => void;
};

export const UploadInvoiceButton: React.FC<UploadButtonProps> = ({
  label = 'Upload Invoice',
  onClick,
}) => {
  const { user } = useUser();
  const tenantFeatureConfig = useAtomValue(tenantConfig);
  const isFeatureAvailableForTenant =
    (tenantFeatureConfig &&
      tenantFeatureConfig[TENANT_CONFIG_KEYS.INVOICE_OCR_FEATURE_ENABLED]
        ?.value) ||
    CONFIG.ENV === 'development';

  if (
    !checkForSinglePermission(
      'createInvoice',
      user?.roles[0]?.permissions || null
    ) ||
    !isFeatureAvailableForTenant
  ) {
    return null;
  }

  return (
    <Button
      id="upload-invoice-button"
      title={label}
      leftIcon={(props) => <ScannerIcon {...props} />}
      onClick={onClick}
      type="outlined"
    />
  );
};
