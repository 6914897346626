import {
  listApprovalRules,
  listPermissions,
} from '@nbfc-expense-tool/data-store/utils';
import { useCallback, useEffect, useReducer } from 'react';
import { PermissionItem } from './types';

type InitialRulesList<T> = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  rules?: T;
};

const intitalRulesList: InitialRulesList<undefined> = {
  status: 'in_progress',
  error: null,
  rules: undefined,
};

type RulesListAction<T, P = undefined> = {
  type: T;
  payload?: P;
};

type RulesListResponseType<T> = {
  data: T;
};

type RULES_LIST_ACTION_TYPES<P> =
  | RulesListAction<'FETCHING_RULES'>
  | RulesListAction<'FETCHED_RULES', RulesListResponseType<P>>;

const reducer = <T>(
  state: InitialRulesList<T>,
  action: RULES_LIST_ACTION_TYPES<T>
): InitialRulesList<T> => {
  switch (action.type) {
    case 'FETCHING_RULES':
      return {
        ...state,
        ...intitalRulesList,
      };
    case 'FETCHED_RULES':
      return {
        ...state,
        status: 'success',
        error: null,
        rules: action.payload?.data,
      };
    default:
      return state;
  }
};

export function useRules<T>(type: 'invoice' | 'vendor' | 'reimbursement') {
  const [state, dispatch] = useReducer(reducer, intitalRulesList);

  const getBranchesForFilters = useCallback(async () => {
    const result = await listApprovalRules<RulesListResponseType<T>>({
      type: type,
    });
    const mappedResults = {
      ...(result.data as RulesListResponseType<T> & { Default: unknown }),
    };
    if (type === 'reimbursement' && result.data) {
      mappedResults['Default'] = (mappedResults as never)[''];
      if ('' in mappedResults) {
        delete mappedResults[''];
      }
    }

    dispatch({
      type: 'FETCHED_RULES',
      payload: { data: mappedResults },
    });
  }, [type]);

  useEffect(() => {
    getBranchesForFilters();
  }, [getBranchesForFilters]);

  return {
    status: state.status,
    rules: state.rules as T | undefined,
  };
}

type PermissionType = PermissionItem[];

type InitialPermissionsList = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  permissions?: PermissionType;
};

const initialPermissionsList: InitialPermissionsList = {
  status: 'in_progress',
  error: null,
  permissions: undefined,
};

type PermissionsListResponseType = {
  data: PermissionType;
};

type PERMISSIONS_LIST_ACTION_TYPES =
  | { type: 'FETCHING_PERMISSIONS' }
  | { type: 'FETCHED_PERMISSIONS'; payload: PermissionsListResponseType }
  | { type: 'FETCHING_PERMISSIONS_FAILED'; payload: Error };

const permissionsReducer = (
  state: InitialPermissionsList,
  action: PERMISSIONS_LIST_ACTION_TYPES
): InitialPermissionsList => {
  switch (action.type) {
    case 'FETCHING_PERMISSIONS':
      return {
        ...state,
        ...initialPermissionsList,
      };
    case 'FETCHED_PERMISSIONS':
      return {
        ...state,
        status: 'success',
        error: null,
        permissions: action.payload.data,
      };
    default:
      return state;
  }
};

export function useAllRolesPermissions() {
  const [state, dispatch] = useReducer(
    permissionsReducer,
    initialPermissionsList
  );

  const getPermissions = useCallback(async () => {
    const result = await listPermissions<PermissionsListResponseType>();
    dispatch({ type: 'FETCHED_PERMISSIONS', payload: result });
  }, []);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  return {
    status: state.status,
    permissions: state.permissions,
  };
}
