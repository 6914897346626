/* eslint-disable @typescript-eslint/no-explicit-any */
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AddOrEditReimbursementPayload,
  ErrorResponse,
  addReimbursement,
  approveReimbursement,
  deleteReimbursement,
  editReimbursement,
  getReimbursementDetails,
  getReimbursementExpenseTypes,
  getReimbursementListReport,
  markPaidReimbursement,
  rejectReimbursement,
  returnReimbursement,
  uploadMediaToTemp,
} from '@nbfc-expense-tool/data-store/utils';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { toastQueue } from '@nbfc-expense-tool/ui';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { z } from 'zod';
import { ReimbursementDetails } from './types';
import { ReimbursementListItem } from './types';
import {
  ReimbursementsRequestPayload,
  getAllEmployeesListing,
  getAllReimbursements,
  getOwnReimbursements,
  getTypedReimbursementStats,
  getWaitingMyApprovalReimbursements,
  usePagination,
  TypeReimbursementsStatPayload,
} from '@nbfc-expense-tool/data-store/utils';

export type TypesOfReimbursements =
  | 'created-by-you'
  | 'waiting-for-your-approval'
  | 'all-reimbursements';

const requiredStringValidation = z
  .string({
    required_error: 'This field is required',
  })
  .max(255)
  .nonempty({
    message: 'This field is required',
  });

const singleExpenseItemSchema = z
  .object({
    expense_date: requiredStringValidation,
    description: z.string().optional(),
    total_amount: requiredStringValidation,
    expense_head_id: z.number({
      required_error: 'This field is required',
    }),
  })
  .superRefine((data, context) => {
    if (
      data.total_amount &&
      !Number.isNaN(Number(data.total_amount)) &&
      Number(data.total_amount) > 0
    ) {
      return true;
    } else {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter valid amount',
        path: ['total_amount'],
      });
    }
  });

const addOrEditReimbursementFormSchema = z.object({
  title: requiredStringValidation,
  items: z.array(singleExpenseItemSchema).nonempty({
    message: 'Please add atleast one item to raise reimbursement request',
  }),
  documents: z
    .array(
      z.object({
        name: z.string(),
        size: z.number(),
        type: z.string(),
        imgSrc: z.string(),
        fileBlobValue: z.any(),
        newDocument: z.boolean(),
        id: z.number().optional(),
      })
    )
    .optional(),
});

export type SingleExpenseItemFormValues = z.infer<
  typeof singleExpenseItemSchema
>;
type AddOrEditReimbursementFormValues = z.infer<
  typeof addOrEditReimbursementFormSchema
>;

export const useAddOrEditReimbursement = (flowType: 'add' | 'edit') => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [
    addOrEditReimbursementApiLoading,
    setAddOrEditReimbursementApiLoading,
  ] = React.useState(false);
  const [addSingleExpenseItemModalState, setAddSingleExpenseItemModalState] =
    React.useState<{
      isOpen: boolean;
      itemIndex?: number;
    }>({
      isOpen: false,
    });
  const [expenseTypeList, setExpenseTypeList] = React.useState<{
    [id: string]: {
      label: string;
      value: string;
      subText?: string;
    };
  }>({});

  const reimbursementDetails = state?.reimbursementDetails as
    | ReimbursementDetails
    | undefined;

  const deletedDocumentIdsRef = React.useRef<number[]>([]);

  const {
    control: addOrEditReimbursementFormControl,
    handleSubmit: handleAddOrEditReimbursementFormSubmit,
    setValue: setAddOrEditReimbursementFormValues,
    getValues: getAddOrEditReimbursementValues,
  } = useForm<AddOrEditReimbursementFormValues>({
    resolver: zodResolver(addOrEditReimbursementFormSchema),
    defaultValues: {
      title: reimbursementDetails?.title || '',
      items:
        reimbursementDetails?.reimbursement_items?.map((item) => ({
          expense_date: item.expense_date,
          description: item.description || undefined,
          total_amount: item.total_amount.toString(),
          expense_head_id: item.expense_head_id,
        })) || [],
      documents:
        reimbursementDetails?.documents.map((item) => ({
          name: item.name,
          size: item.size,
          type: item.mime_type,
          imgSrc: item.url,
          newDocument: false,
          id: item.id,
        })) || [],
    },
  });
  const {
    control: singleExpenseItemFormControl,
    handleSubmit: handleSingleExpenseItemFormSubmit,
    reset: resetSingleExpenseItemForm,
  } = useForm<SingleExpenseItemFormValues>({
    resolver: zodResolver(singleExpenseItemSchema),
  });

  const expenseItemsList = useWatch({
    control: addOrEditReimbursementFormControl,
    name: 'items',
  });

  useEffect(() => {
    if (flowType === 'edit' && !reimbursementDetails) {
      navigate('/home/reimbursements');
    }
    getReimbursementExpenseTypes().then((response: any) => {
      const data = (response?.data || []) as {
        id: string;
        name: string;
        description: string;
      }[];
      setExpenseTypeList(
        data.reduce(
          (acc, item) => {
            acc[item.id.toString()] = {
              label: item.name,
              value: item.id.toString(),
              subText: item.description,
            };
            return acc;
          },
          {} as {
            [id: string]: {
              label: string;
              value: string;
              subText?: string;
            };
          }
        )
      );
    });
  }, [flowType, navigate, reimbursementDetails]);

  const goBack = () => {
    navigate(-1);
  };

  const onClickAddOrExpenseItem = (itemIndex?: number) => {
    if (itemIndex !== undefined) {
      const item = getAddOrEditReimbursementValues('items')[itemIndex];
      resetSingleExpenseItemForm({
        expense_date: item.expense_date,
        description: item.description,
        total_amount: item.total_amount,
        expense_head_id: item.expense_head_id,
      });
    }
    setAddSingleExpenseItemModalState({
      isOpen: true,
      itemIndex,
    });
  };

  const onCloseAddExpenseItemModal = () => {
    setAddSingleExpenseItemModalState({
      isOpen: false,
    });
    resetSingleExpenseItemForm();
  };

  const onAddOrEditReimbursement = (data: AddOrEditReimbursementFormValues) => {
    setAddOrEditReimbursementApiLoading(true);
    const documentsToUpload =
      data.documents?.filter((item) => item.newDocument) || [];
    const uploadDocRequests: Promise<any>[] = [];
    documentsToUpload.forEach((doc) => {
      const formData = new FormData();
      formData.append('file', doc.fileBlobValue, doc.name);
      uploadDocRequests.push(uploadMediaToTemp(formData));
    });
    Promise.all(uploadDocRequests)
      .then(async (response) => {
        const document_upload_ids: number[] = [];
        response.forEach((responseItem: any, index: number) => {
          document_upload_ids.push(responseItem.data.id);
        });
        try {
          const payload: AddOrEditReimbursementPayload = {
            title: data.title,
            grand_total_amount: data.items.reduce(
              (acc, item) => acc + parseInt(item.total_amount, 10),
              0
            ),
            document_upload_ids,
            document_delete_ids: deletedDocumentIdsRef.current,
            items: data.items.map((item) => ({
              ...item,
              expense_date: new Date(item.expense_date).toISOString(),
              expense_head_id: item.expense_head_id,
              total_amount: parseInt(item.total_amount, 10),
            })),
          };
          if (flowType === 'edit' && reimbursementDetails?.ticket_number) {
            await editReimbursement(
              reimbursementDetails.ticket_number,
              payload
            );
            navigate(-1);
            toastQueue.add(
              {
                title: 'Reimbursement request updated successfully',
                type: 'success',
              },
              {
                timeout: 2000,
              }
            );
          } else {
            await addReimbursement(payload);
            navigate('/home/reimbursements');
            toastQueue.add(
              {
                title: 'Reimbursement request raised successfully',
                type: 'success',
              },
              {
                timeout: 2000,
              }
            );
          }
          setAddOrEditReimbursementApiLoading(false);
        } catch (error: any) {
          setAddOrEditReimbursementApiLoading(false);
          toastQueue.add(
            {
              title: error?.message || 'Something Went Wrong! Please try again',
              type: 'error',
            },
            {
              timeout: 2000,
            }
          );
        }
      })
      .catch((error) => {
        setAddOrEditReimbursementApiLoading(false);
        toastQueue.add(
          {
            title: 'Error in uploading docs, Please try again',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
      });
  };

  const onSaveReimbursement = () => {
    handleAddOrEditReimbursementFormSubmit(onAddOrEditReimbursement, (err) => {
      console.log(err);
    })();
  };

  const onAddOrEditSingleExpenseItem = (
    item: SingleExpenseItemFormValues,
    itemIndex?: number
  ) => {
    const updatedItems = getAddOrEditReimbursementValues('items') || [];
    if (itemIndex !== undefined) {
      updatedItems[itemIndex] = item;
      setAddOrEditReimbursementFormValues(`items`, updatedItems);
    } else {
      updatedItems.push(item);
      setAddOrEditReimbursementFormValues(`items`, updatedItems);
    }
    onCloseAddExpenseItemModal();
    resetSingleExpenseItemForm();
  };

  const onDeleteSingleExpenseItem = (itemIndex: number) => {
    const updatedItems = getAddOrEditReimbursementValues('items');
    updatedItems.splice(itemIndex, 1);
    setAddOrEditReimbursementFormValues(`items`, updatedItems);
  };

  const onSaveSingleExpenseItem = (itemIndex?: number) => {
    handleSingleExpenseItemFormSubmit((item) => {
      onAddOrEditSingleExpenseItem(item, itemIndex);
    })();
  };

  return {
    goBack,
    expenseTypeList,
    addOrEditReimbursementFormControl,
    onSaveReimbursement,
    addOrEditReimbursementApiLoading,
    expenseItemsList,
    singleExpenseItemFormControl,
    onSaveSingleExpenseItem,
    onDeleteSingleExpenseItem,
    addSingleExpenseItemModalState,
    onClickAddOrExpenseItem,
    onCloseAddExpenseItemModal,
    deletedDocumentIdsRef,
  };
};

type ReimbursementDetailsState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  reimbursementDetails?: ReimbursementDetails;
};

type REIMBURSEMENT_DETAILS_ACTION_TYPES =
  | { type: 'FETCHING_REIMBURSEMENT_DETAILS' }
  | { type: 'FETCHED_REIMBURSEMENT_DETAILS'; payload: ReimbursementDetails }
  | { type: 'FETCHING_REIMBURSEMENT_DETAILS_FAILED'; payload: Error };

const reimbursementDetailsReducer = (
  state: ReimbursementDetailsState,
  action: REIMBURSEMENT_DETAILS_ACTION_TYPES
): ReimbursementDetailsState => {
  switch (action.type) {
    case 'FETCHING_REIMBURSEMENT_DETAILS':
      return {
        ...state,
        status: 'in_progress',
        error: null,
        reimbursementDetails: undefined,
      };
    case 'FETCHED_REIMBURSEMENT_DETAILS':
      return {
        ...state,
        status: 'success',
        error: null,
        reimbursementDetails: action.payload,
      };
    case 'FETCHING_REIMBURSEMENT_DETAILS_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const useReimbursementDetails = () => {
  const navigate = useNavigate();
  const { reimbursementId } = useParams();
  const [searchParams] = useSearchParams();

  const [state, dispatch] = useReducer(reimbursementDetailsReducer, {
    status: 'in_progress',
    error: null,
    reimbursementDetails: undefined,
  });

  const [reimbursementDetails, setReimbursementDetails] =
    React.useState<ReimbursementDetails | null>();
  const [
    isDeleteReimbursementConfirmationDialogVisible,
    setIsDeleteReimbursementConfirmationDialogVisible,
  ] = React.useState<boolean>(false);
  const [isDeleteReimbursementApiLoading, setIsDeleteReimbursementApiLoading] =
    React.useState<boolean>(false);

  const getReimbursementDetailsApiCall = useCallback(() => {
    if (reimbursementId) {
      dispatch({ type: 'FETCHING_REIMBURSEMENT_DETAILS' });
      getReimbursementDetails(reimbursementId)
        .then((response: any) => {
          setReimbursementDetails(response?.data as ReimbursementDetails);
          dispatch({
            type: 'FETCHED_REIMBURSEMENT_DETAILS',
            payload: response.data,
          });
        })
        .catch((error: any) => {
          dispatch({
            type: 'FETCHING_REIMBURSEMENT_DETAILS_FAILED',
            payload: error,
          });
        });
    } else {
      navigate('/home/reimbursements');
    }
  }, [reimbursementId, navigate]);

  useEffect(() => {
    getReimbursementDetailsApiCall();
  }, [getReimbursementDetailsApiCall]);

  const fromScreenName = useMemo(() => {
    const from = searchParams.get('from');
    switch (from) {
      case 'created-by-you':
        return 'Created by You';
      case 'waiting-for-your-approval':
        return 'Waiting for Your Approval';
      case 'all-reimbursements':
        return 'All Reimbursements';
      default:
        return undefined;
    }
  }, [searchParams]);

  const reloadDetails = () => {
    getReimbursementDetailsApiCall();
  };

  const navigateToReimbursementsListing = () => {
    const from = searchParams.get('from');
    navigate(`/home/reimbursements/${from}`);
  };

  const goBack = () => {
    navigate(-1);
  };

  const copyUTRNumberToClipboard = () => {
    navigator.clipboard.writeText(reimbursementDetails?.utr_number || '');
    toastQueue.add(
      {
        title: 'UTR number copied successfully',
        type: 'success',
      },
      {
        timeout: 2000,
      }
    );
  };

  const onClickEditReimbursement = () => {
    navigate('/edit-reimbursement', {
      state: {
        reimbursementDetails: state.reimbursementDetails,
      },
    });
  };

  const onClickDeleteReimbursement = () => {
    setIsDeleteReimbursementConfirmationDialogVisible(true);
  };

  const closeDeleteReimbursementConfirmationDialog = () => {
    setIsDeleteReimbursementConfirmationDialogVisible(false);
  };

  const onConfirmDeleteReimbursement = async () => {
    try {
      setIsDeleteReimbursementApiLoading(true);
      await deleteReimbursement(
        state.reimbursementDetails?.ticket_number || ''
      );
      goBack();
      toastQueue.add(
        {
          title: 'Reimbursement request deleted successfully',
          type: 'success',
        },
        {
          timeout: 2000,
        }
      );
    } catch (e) {
      toastQueue.add(
        {
          title: 'Something went wrong, Please try again',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
    } finally {
      setIsDeleteReimbursementApiLoading(false);
    }
  };

  return {
    goBack,
    reloadDetails,
    navigateToReimbursementsListing,
    loadingStatus: state.status,
    fromScreenName,
    reimbursementDetails,
    isDeleteReimbursementConfirmationDialogVisible,
    isDeleteReimbursementApiLoading,
    copyUTRNumberToClipboard,
    onClickEditReimbursement,
    onClickDeleteReimbursement,
    closeDeleteReimbursementConfirmationDialog,
    onConfirmDeleteReimbursement,
  };
};

export const useReimbursementExpenseListAndDocuments = () => {
  const [selectedOption, setSelectedOption] = React.useState<
    'items' | 'documents'
  >('items');
  const [expenseTypeList, setExpenseTypeList] = React.useState<{
    [id: string]: {
      name: string;
      id: string;
      description?: string;
    };
  }>({});

  const options: {
    label: string;
    value: 'items' | 'documents';
  }[] = [
    {
      label: 'Items',
      value: 'items',
    },
    {
      label: 'Documents',
      value: 'documents',
    },
  ];

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getReimbursementExpenseTypes().then((response: any) => {
      const data = (response?.data || []) as {
        id: string;
        name: string;
        description: string;
      }[];
      setExpenseTypeList(
        data.reduce(
          (acc, item) => {
            acc[item.id.toString()] = {
              name: item.name,
              id: item.id.toString(),
              description: item.description,
            };
            return acc;
          },
          {} as {
            [id: string]: {
              name: string;
              id: string;
              description?: string;
            };
          }
        )
      );
    });
  }, []);

  const onChangeSelectedOption = (value: 'items' | 'documents') => {
    setSelectedOption(value);
  };

  return {
    selectedOption,
    options,
    expenseTypeList,
    onChangeSelectedOption,
  };
};

export const useActionOnReimbursement = (
  ticketNumber: string,
  type: 'approve' | 'reject' | 'return' | 'markPaid'
) => {
  const approveAnReimbursement = useCallback(
    async (remark?: string) => {
      try {
        await approveReimbursement(ticketNumber, { remarks: remark });
      } catch (e) {
        return e as Error;
      }
    },
    [ticketNumber]
  );

  const rejectAnReimbursement = useCallback(
    async (remark?: string) => {
      try {
        await rejectReimbursement(ticketNumber, { remarks: remark });
      } catch (e) {
        return e as Error;
      }
    },
    [ticketNumber]
  );

  const returnAnReimbursement = useCallback(
    async (remark?: string) => {
      try {
        await returnReimbursement(ticketNumber, { remarks: remark });
      } catch (e) {
        return e as Error;
      }
    },
    [ticketNumber]
  );

  const markPaidAnReimbursement = useCallback(
    async (utr_number?: string) => {
      await markPaidReimbursement(ticketNumber, utr_number || '');
    },
    [ticketNumber]
  );

  return useCallback(
    (remark?: string, utr_number?: string) => {
      switch (type) {
        case 'approve':
          return approveAnReimbursement(remark);
        case 'reject':
          return rejectAnReimbursement(remark);
        case 'return':
          return returnAnReimbursement(remark);
        case 'markPaid':
          return markPaidAnReimbursement(utr_number);
        default:
          return;
      }
    },
    [
      approveAnReimbursement,
      rejectAnReimbursement,
      type,
      returnAnReimbursement,
      markPaidAnReimbursement,
    ]
  );
};

type ReimbursementsListingResponseType = {
  data: ReimbursementListItem[];
  current_page: number;
  total: number;
  per_page?: number;
};

type FiltersType = {
  status?: string[];
  employees?: string[];
  q?: string;
  dateFilter?: DateFilter;
  payment_status?: string[];
};

type DateFilter = {
  label: string;
  value: string;
  dates?: [Date, Date];
};

type ReimbursementsListState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  reimbursements?: ReimbursementListItem[];
  totalReimbursements?: number;
  perPage?: number;
  currentPage?: number;
  syncingWithFilers?: boolean;
};

const initialReimbursementsList: ReimbursementsListState = {
  status: 'in_progress',
  error: null,
  reimbursements: undefined,
  syncingWithFilers: false,
};

type REIMBURSEMENTs_LIST_TYPES =
  | { type: 'FETCHING_REIMBURSEMENTS' }
  | {
      type: 'FETCHED_REIMBURSEMENTS';
      payload: ReimbursementsListingResponseType;
    }
  | { type: 'FETCHING_REIMBURSEMENTS_FAILED'; payload: Error }
  | { type: 'FETCHING_REIMBURSEMENTS_WITH_FILTERS' };

const ownReimbursementListingReducer = (
  state: ReimbursementsListState,
  action: REIMBURSEMENTs_LIST_TYPES
): ReimbursementsListState => {
  switch (action.type) {
    case 'FETCHING_REIMBURSEMENTS':
      return {
        ...state,
        ...initialReimbursementsList,
      };

    case 'FETCHING_REIMBURSEMENTS_WITH_FILTERS':
      return {
        ...state,
        error: null,
        syncingWithFilers: true,
      };

    case 'FETCHED_REIMBURSEMENTS':
      return {
        ...state,
        status: 'success',
        error: null,
        syncingWithFilers: false,
        currentPage: action.payload.current_page,
        totalReimbursements: action.payload.total,
        reimbursements: action.payload.data,
        perPage: action.payload.per_page,
      };

    case 'FETCHING_REIMBURSEMENTS_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        syncingWithFilers: false,
      };

    default:
      return state;
  }
};

const reimbursementInitialFilters: FiltersType = {
  dateFilter: {
    label: 'All Time',
    value: 'all',
  },
};

export function useReimbursements(
  fetchFor: TypesOfReimbursements = 'all-reimbursements',
  initialFilters: FiltersType = reimbursementInitialFilters
) {
  const [state, dispatch] = useReducer(
    ownReimbursementListingReducer,
    initialReimbursementsList
  );
  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true);
  const [reportLoading, setReportLoading] = useState<boolean>(false);

  const {
    current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    nextPage,
    previousPage,
    resetPagination,
  } = usePagination({
    perPage: 10,
    totalItems: state.totalReimbursements || 0,
  });

  const { getValues, setValue } = useForm<FiltersType>({
    defaultValues: initialFilters,
  });

  const params = getValues();

  const exportExcelReport = useCallback(async () => {
    try {
      setReportLoading(true);
      const { status, dateFilter, q, employees, payment_status } = params;
      const constructedPayload: ReimbursementsRequestPayload = {};
      if (dateFilter?.dates) {
        constructedPayload['created_at_after'] =
          dateFilter.dates[0].toISOString();
        constructedPayload['created_at_before'] =
          dateFilter.dates[1].toISOString();
      }
      if (status?.length) {
        constructedPayload['status'] = status;
      }
      if (q?.length) {
        constructedPayload['q'] = q;
      }
      if (employees?.length) {
        constructedPayload['created_by'] = employees;
      }
      if (payment_status?.length) {
        constructedPayload['payment_status'] = payment_status;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any =
        await getReimbursementListReport<ReimbursementsListingResponseType>(
          fetchFor,
          constructedPayload,
          {
            responseType: 'blob',
            headers: {
              'Content-Type':
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              Accept: '*/*',
            },
          }
        );
      const linkElement = document.createElement('a');
      const url = URL.createObjectURL(data);
      linkElement.href = url;
      linkElement.download = `ReimbursementList.xlsx`;
      document.body.appendChild(linkElement);
      linkElement.click();
      setTimeout(function () {
        document.body.removeChild(linkElement);
        window.URL.revokeObjectURL(url);
      }, 0);
      setReportLoading(false);
    } catch (err) {
      const error = err as ErrorResponse;
      toastQueue.add(
        {
          title: error.message || 'Something Went Wrong! Please try again',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
      setReportLoading(false);
    }
  }, [fetchFor, params]);

  const getOwnReimbursementsList = useCallback(async () => {
    try {
      const { status, dateFilter, q, employees, payment_status } = params;
      const constructedPayload: ReimbursementsRequestPayload = {};
      if (current) {
        constructedPayload['page'] = current;
      }
      if (dateFilter?.dates) {
        constructedPayload['created_at_after'] =
          dateFilter.dates[0].toISOString();
        constructedPayload['created_at_before'] =
          dateFilter.dates[1].toISOString();
      }
      if (status?.length) {
        constructedPayload['status'] = status;
      }
      if (q?.length) {
        constructedPayload['q'] = q;
      }
      if (employees?.length) {
        constructedPayload['created_by'] = employees;
      }
      if (payment_status?.length) {
        constructedPayload['payment_status'] = payment_status;
      }
      if (fetchFor === 'created-by-you') {
        const response: any = await getOwnReimbursements(constructedPayload);
        dispatch({ type: 'FETCHED_REIMBURSEMENTS', payload: response });
      } else if (fetchFor === 'waiting-for-your-approval') {
        const response: any = await getWaitingMyApprovalReimbursements(
          constructedPayload
        );
        dispatch({ type: 'FETCHED_REIMBURSEMENTS', payload: response });
      } else {
        const response: any = await getAllReimbursements(constructedPayload);
        dispatch({ type: 'FETCHED_REIMBURSEMENTS', payload: response });
      }
    } catch (err) {
      const error = err as Error;
      dispatch({ type: 'FETCHING_REIMBURSEMENTS_FAILED', payload: error });
    }
  }, [current, fetchFor, params]);

  useEffect(() => {
    if (shouldApiCall) {
      getOwnReimbursementsList();
      setShouldApiCall(false);
    }
  }, [getOwnReimbursementsList, shouldApiCall]);

  const handlePageChange = (type: 'next' | 'previous' | 'reset') => {
    switch (type) {
      case 'next':
        nextPage();
        setShouldApiCall(true);
        dispatch({ type: 'FETCHING_REIMBURSEMENTS_WITH_FILTERS' });
        break;
      case 'previous':
        previousPage();
        setShouldApiCall(true);
        dispatch({ type: 'FETCHING_REIMBURSEMENTS_WITH_FILTERS' });
    }
  };

  function handleParamChange(key: keyof FiltersType, value: string[]) {
    switch (key) {
      case 'status':
        resetPagination();
        setShouldApiCall(true);
        setValue('status', value);
        dispatch({ type: 'FETCHING_REIMBURSEMENTS_WITH_FILTERS' });
        break;
      case 'q':
        resetPagination();
        setShouldApiCall(true);
        setValue('q', value[0]);
        dispatch({ type: 'FETCHING_REIMBURSEMENTS_WITH_FILTERS' });
        break;
      case 'payment_status':
        resetPagination();
        setShouldApiCall(true);
        setValue('payment_status', value);
        dispatch({ type: 'FETCHING_REIMBURSEMENTS_WITH_FILTERS' });
        break;
      case 'employees':
        resetPagination();
        setShouldApiCall(true);
        setValue('employees', value);
        dispatch({ type: 'FETCHING_REIMBURSEMENTS_WITH_FILTERS' });
        break;
    }
  }

  function resetFilters() {
    resetPagination();
    setShouldApiCall(true);
    dispatch({ type: 'FETCHING_REIMBURSEMENTS' });
    setValue('q', undefined);
    setValue('dateFilter', undefined);
    if (!initialFilters.employees?.length) {
      setValue('employees', undefined);
    }
    if (!initialFilters.status?.length) {
      setValue('status', undefined);
    }
  }

  function handleDateChange(values: DateFilter) {
    resetPagination();
    setShouldApiCall(true);
    setValue('dateFilter', values);
    dispatch({ type: 'FETCHING_REIMBURSEMENTS_WITH_FILTERS' });
  }

  const hasAppliedFilters = useMemo(() => {
    let isFilterApplied = false;
    if (params.dateFilter?.dates) {
      isFilterApplied = true;
      return isFilterApplied;
    }
    if (params.status?.length && !initialFilters.status?.length) {
      isFilterApplied = true;
      return isFilterApplied;
    }
    if (params.employees?.length && !initialFilters.employees?.length) {
      isFilterApplied = true;
      return isFilterApplied;
    }
    if (params.q?.length) {
      isFilterApplied = true;
      return isFilterApplied;
    }

    return isFilterApplied;
  }, [params, initialFilters]);

  return {
    error: state.error,
    currentPage: current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    params,
    hasAppliedFilters,
    loading: state.status,
    reimbursements: state.reimbursements,
    totalReimbursements: state.totalReimbursements,
    syncingData: state.syncingWithFilers,
    reportLoading,

    nextPage,
    previousPage,
    resetFilters,
    handlePageChange,
    handleParamChange,
    handleDateChange,
    exportExcelReport,
  };
}

type TypeOfReimbursementsCountResponse = {
  data: Array<{
    total_count: number;
    status: string;
    total_amount: string;
  }>;
};

type REIMBURSEMENTS_OVERVIEW_TYPES =
  | { type: 'FETCHING_REIMBURSEMENT_OVERVIEW' }
  | {
      type: 'FETCHED_REIMBURSEMENT_OVERVIEW';
      payload: TypeOfReimbursementsCountResponse;
    }
  | { type: 'FETCHING_REIMBURSEMENT_OVERVIEW_FAILED'; payload: Error };

type ReimbursementOverviewState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  overviewStats?: Array<{
    total_count: number;
    status: string;
    total_amount: string;
  }>;
};

const initialReimbursementOverview: ReimbursementOverviewState = {
  status: 'in_progress',
  error: null,
  overviewStats: [],
};

const overviewReducer = (
  state: ReimbursementOverviewState,
  action: REIMBURSEMENTS_OVERVIEW_TYPES
): ReimbursementOverviewState => {
  switch (action.type) {
    case 'FETCHING_REIMBURSEMENT_OVERVIEW':
      return {
        ...initialReimbursementOverview,
      };

    case 'FETCHED_REIMBURSEMENT_OVERVIEW':
      return {
        ...state,
        status: 'success',
        error: null,
        overviewStats: action.payload.data,
      };

    case 'FETCHING_REIMBURSEMENT_OVERVIEW_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        overviewStats: [],
      };

    default:
      return state;
  }
};

export type TypesOReimbursements =
  | 'created-by-you'
  | 'waiting-for-your-approval'
  | 'all-reimbursements';

export function useReimbursementsOverview(
  fetchFor: TypesOReimbursements = 'all-reimbursements'
) {
  const [shouldCallApi, setShouldCallApi] = useState<boolean>(true);
  const [state, dispatch] = useReducer(
    overviewReducer,
    initialReimbursementOverview
  );

  const { getValues, setValue } = useForm<FiltersType>({
    defaultValues: reimbursementInitialFilters,
  });

  const params = getValues();

  function handleParamChange(key: keyof FiltersType, value: string[]) {
    switch (key) {
      case 'q':
        setShouldCallApi(true);
        setValue('q', value[0]);
        break;
      case 'employees':
        setShouldCallApi(true);
        setValue('employees', value);
        break;
    }
  }

  function resetFilters() {
    setShouldCallApi(true);
    dispatch({ type: 'FETCHING_REIMBURSEMENT_OVERVIEW' });
    setValue('q', undefined);
    setValue('dateFilter', undefined);
    setValue('employees', undefined);
  }

  function handleDateFilterChange(values: DateFilter) {
    setShouldCallApi(true);
    setValue('dateFilter', values);
  }

  const fetchTypedReimbursementsCount = useCallback(async () => {
    const { dateFilter, q, employees } = params;
    const constructedPayload: TypeReimbursementsStatPayload = {
      type: fetchFor === 'created-by-you' ? 'my' : 'all',
    };
    if (dateFilter?.dates) {
      constructedPayload['created_at_after'] =
        dateFilter.dates[0].toISOString();
      constructedPayload['created_at_before'] =
        dateFilter.dates[1].toISOString();
    }
    if (q?.length) {
      constructedPayload['q'] = q;
    }
    if (employees?.length) {
      constructedPayload['created_by'] = employees;
    }
    try {
      const response =
        await getTypedReimbursementStats<TypeOfReimbursementsCountResponse>(
          constructedPayload
        );
      dispatch({ type: 'FETCHED_REIMBURSEMENT_OVERVIEW', payload: response });
    } catch (e) {
      dispatch({
        type: 'FETCHING_REIMBURSEMENT_OVERVIEW_FAILED',
        payload: e as Error,
      });
    }
  }, [fetchFor, params]);

  useEffect(() => {
    if (shouldCallApi) {
      fetchTypedReimbursementsCount();
      setShouldCallApi(false);
    }
  }, [shouldCallApi, fetchTypedReimbursementsCount]);

  return {
    error: state.error,
    status: state.status,
    overview: state.overviewStats,
    handleParamChange,
    handleDateFilterChange,
    resetFilters,
  };
}

export type EMPLOYEE_TYPES_FOR_FILTER = {
  id: number;
  name: string;
};

type EmployeesForFilterResponse = {
  data: EMPLOYEE_TYPES_FOR_FILTER[];
};

type EmployeesForFilterState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  employees?: EMPLOYEE_TYPES_FOR_FILTER[];
};
type EMPLOYEES_LIST_FOR_FILTER_TYPES =
  | { type: 'FETCHING_EMPLOYEES' }
  | { type: 'FETCHED_EMPLOYEES'; payload: EmployeesForFilterResponse }
  | { type: 'FETCHING_EMPLOYEES_FAILED'; payload: Error }
  | { type: 'FETCHING_EMPLOYEES_WITH_FILTERS' };

const initialBranchForFilterList: EmployeesForFilterState = {
  status: 'in_progress',
  error: null,
  employees: undefined,
};

const reducer = (
  state: EmployeesForFilterState,
  action: EMPLOYEES_LIST_FOR_FILTER_TYPES
): EmployeesForFilterState => {
  switch (action.type) {
    case 'FETCHING_EMPLOYEES':
      return {
        ...state,
        ...initialBranchForFilterList,
      };
    case 'FETCHED_EMPLOYEES':
      return {
        ...state,
        status: 'success',
        error: null,
        employees: action.payload.data,
      };
    default:
      return state;
  }
};

export function useEmployeesForFilter() {
  const [state, dispatch] = useReducer(reducer, initialBranchForFilterList);

  const getEmployeesForFilters = useCallback(async () => {
    const response = await getAllEmployeesListing<EmployeesForFilterResponse>();
    dispatch({ type: 'FETCHED_EMPLOYEES', payload: response });
  }, []);

  useEffect(() => {
    getEmployeesForFilters();
  }, [getEmployeesForFilters]);

  return {
    status: state.status,
    employees: state.employees,
  };
}
