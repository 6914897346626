import {
  Inline,
  Stack,
  Text,
  Box,
  ArrowLeftIcon,
  Tabs,
  DateSelect,
  MultiSelect,
  TextInput,
  SearchIcon,
  RightCaratIcon,
  SkeletonRows,
  Date as DateAndTime,
  Avatar,
  Amount,
  EmptyState,
  FileIcon,
  Button,
  SpinnerIcon,
  ExtendedBreadcrumbs,
  Time,
  ReturnIcon,
} from '@nbfc-expense-tool/ui';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useReimbursementsLocal from './useReimbursements';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Reimbursement_Status,
  Payment_status,
  TypesOReimbursements,
  useEmployeesForFilter,
  useReimbursements,
} from '@nbfc-expense-tool/data-store/dashboard';
import { Item } from 'react-stately';
import { InvoiceStatusTag } from '../../Invoices';
import { debounce } from '@nbfc-expense-tool/data-store/utils';

const idPrefix = 'typed-reimbursements';

export const TypedReimbursementsPage = () => {
  const { type } = useParams();
  if (!type) return null;
  return <TypedReimbursements key={type} type={type as Reimbursement_Status} />;
};

const TabOptions = [
  {
    title: 'Unpaid',
    path: 'unpaid',
  },
  {
    title: 'Paid',
    path: 'paid',
  },
  {
    title: 'All Approved Reimbursements',
    path: 'all',
  },
];

const initialOwnFilters = {
  dateFilter: {
    label: 'All Time',
    value: 'all',
  },
};

function TypedReimbursements({ type }: { type: Reimbursement_Status }) {
  const [searchParams] = useSearchParams();
  const fromSearchParams = searchParams.get('from') as TypesOReimbursements;
  const toSearchParams = searchParams.get('to');
  const [activePath, setActivePath] = useState(
    toSearchParams || TabOptions[0].path
  );
  const isCreatedByYouPage = fromSearchParams === 'created-by-you';
  const { status: employeesStatus, employees } = useEmployeesForFilter();

  const initialFilters = {
    ...initialOwnFilters,
    status: [type],
    payment_status: activePath === 'all' ? [] : [activePath as Payment_status],
  };

  const {
    reimbursements,
    loading,
    perPage,
    totalReimbursements,
    canGoNext,
    canGoBack,
    syncingData,
    currentPage,
    lastPage,
    params,
    hasAppliedFilters,
    resetFilters,
    handlePageChange,
    handleDateChange,
    handleParamChange,
    exportExcelReport,
    reportLoading,
  } = useReimbursements(fromSearchParams, initialFilters);

  const navigate = useNavigate();

  const employeesForFilters = useMemo(() => {
    return employees?.map((b) => {
      return {
        label: b.name,
        value: `${b.id}`,
      };
    });
  }, [employees]);

  const {
    getTitleFromLink,
    getTitleForTypeOfReimbursement,
    getEmptyStateText,
    getFifthColumnBasedOnType,
  } = useReimbursementsLocal();

  const title = useMemo(() => {
    return getTitleForTypeOfReimbursement(type as Reimbursement_Status);
  }, [type, getTitleForTypeOfReimbursement]);

  const { title: emptyTitle, subtitle: emptySubtitle } = useMemo(() => {
    return getEmptyStateText(type, activePath);
  }, [type, activePath, getEmptyStateText]);

  const { title: columnTitle } = useMemo(() => {
    return getFifthColumnBasedOnType(type, activePath);
  }, [type, activePath, getFifthColumnBasedOnType]);

  const onReimbursementClickHandler = (ticketNumber: string) => {
    navigate(`/home/reimbursements/${ticketNumber}?from=${fromSearchParams}`);
  };

  const renderComponent = useCallback(
    (
      payment_status: Payment_status,
      last_rejected_remarks: string,
      last_returned_remarks: string,
      i_can_mark_paid: boolean
    ) => {
      if (type === 'approved') {
        if (activePath === 'unpaid') {
          return i_can_mark_paid ? (
            <Text variation="lk2" color="textPrimary" marginTop="0.5">
              Mark Paid
            </Text>
          ) : null;
        } else if (activePath === 'paid') {
          return (
            <Text variation="lk2" color="textPrimary" marginTop="0.5">
              View
            </Text>
          );
        } else {
          return <InvoiceStatusTag status={payment_status} />;
        }
      } else if (type === 'under_review') {
        return (
          <Text variation="lk2" color="textPrimary" marginTop="0.5">
            Review
          </Text>
        );
      } else if (type === 'returned') {
        return <Text variation={'b2'}>{last_returned_remarks}</Text>;
      } else if (type === 'rejected') {
        return <Text variation={'b2'}>{last_rejected_remarks}</Text>;
      }
    },
    [activePath, type]
  );

  return (
    <Box paddingY="4" paddingX="5">
      <Stack gap="2">
        <ExtendedBreadcrumbs
          links={[
            {
              title: getTitleFromLink(fromSearchParams),
              to: fromSearchParams || '',
            },
            { title: `${title} Reimbursements`, to: '', isCurrent: true },
          ]}
        />
        <Inline gap="6">
          <ArrowLeftIcon
            size="4"
            onClick={() => navigate(-1)}
            cursor="pointer"
          />
          <Text as="h3" variation="h3">
            {title} Reimbursements
          </Text>
        </Inline>
        {type === 'approved' && (
          <Tabs
            selectedKey={activePath}
            onClick={(path: string) => {
              setActivePath(path);
              if (path === 'all') {
                handleParamChange('payment_status', []);
              } else {
                handleParamChange('payment_status', [path as Payment_status]);
              }
            }}
          >
            {TabOptions.map((option) => (
              <Item
                key={option.path}
                textValue={option.path}
                title={
                  <Box textDecoration="none" style={{ color: 'inherit' }}>
                    {option.title}
                  </Box>
                }
                children={undefined}
              ></Item>
            ))}
          </Tabs>
        )}
      </Stack>
      {!reimbursements?.length && !hasAppliedFilters ? (
        <Stack paddingTop="10" alignItems="center" justifyContent="center">
          {loading === 'in_progress' ? (
            <Inline gap="4">
              <SpinnerIcon size="3" color="iconMedium" />
              <Text variation="b2">Loading...</Text>
            </Inline>
          ) : (
            <EmptyState
              renderIcon={(props) => <FileIcon {...props} />}
              title={emptyTitle}
              subText={emptySubtitle}
            />
          )}
        </Stack>
      ) : (
        <Stack paddingTop="3.5" gap="6">
          <Inline justifyContent="between">
            <Inline gap="4">
              <DateSelect
                value={params.dateFilter}
                onSave={(option) => {
                  handleDateChange(option);
                }}
                id={`${idPrefix}-select-date`}
              />
              {!isCreatedByYouPage ? (
                <MultiSelect
                  label="Employee"
                  actionBtnTitle="Show Results"
                  value={params.employees}
                  options={employeesForFilters}
                  loadingOptions={employeesStatus === 'in_progress'}
                  onSave={(values) => {
                    handleParamChange('employees', values);
                  }}
                  id={`${idPrefix}-select-employee`}
                />
              ) : null}
            </Inline>
            <Button
              id={`${idPrefix}-export-excel-button`}
              title="Export To Excel"
              onClick={exportExcelReport}
              type="outlined"
              state={reportLoading ? 'loading' : undefined}
              leftIcon={(props) => <ReturnIcon {...props} />}
            />
          </Inline>
          <Box
            rounded="md"
            borderWidth="1"
            paddingTop="2.5"
            borderColor="borderSeparator"
            backgroundColor="surfaceDefault"
          >
            <Stack gap="6">
              <Inline
                paddingX="2.5"
                alignItems="center"
                justifyContent="between"
              >
                <Inline style={{ width: 352 }}>
                  <TextInput
                    minHeight="5"
                    id="search"
                    aria-label="search"
                    placeholder="Search by Reimbursement ID or amount"
                    leftIcon={(props) => (
                      <SearchIcon {...props} marginRight="1" />
                    )}
                    fullWidth
                    onChange={debounce((value) => {
                      handleParamChange('q', [value]);
                    })}
                  />
                </Inline>
                <Inline alignItems="center" gap="6">
                  {totalReimbursements ? (
                    <Text color="textMedium" variation="c1">
                      Showing{' '}
                      {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}-
                      {lastPage === currentPage
                        ? totalReimbursements
                        : reimbursements?.length}{' '}
                      of {totalReimbursements}
                    </Text>
                  ) : null}
                  <Inline gap="2">
                    <Box
                      as="button"
                      disabled={!canGoBack}
                      backgroundColor="transparent"
                      onClick={() =>
                        canGoBack ? handlePageChange('previous') : undefined
                      }
                      id={`${idPrefix}-show-previous-page`}
                    >
                      <RightCaratIcon
                        cursor="pointer"
                        size="2.5"
                        rotate="180"
                        color={canGoBack ? 'iconMedium' : 'iconLowest'}
                      />
                    </Box>
                    <Box
                      as="hr"
                      width="px"
                      height="2.5"
                      backgroundColor="borderSeparator"
                    />
                    <Box
                      as="button"
                      disabled={!canGoNext}
                      backgroundColor="transparent"
                      onClick={() =>
                        canGoNext ? handlePageChange('next') : undefined
                      }
                      id={`${idPrefix}-show-next-page`}
                    >
                      <RightCaratIcon
                        cursor="pointer"
                        size="2.5"
                        color={canGoNext ? 'iconMedium' : 'iconLowest'}
                      />
                    </Box>
                  </Inline>
                </Inline>
              </Inline>
              <Box as="table" width="full" position="relative">
                <Box as="thead" bgColor="surfaceNeutralLowest">
                  <Box as="tr">
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 82,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">Requested On</Text>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 120,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">ID</Text>
                    </Box>
                    {!isCreatedByYouPage ? (
                      <Box
                        as="th"
                        position="sticky"
                        paddingY="1.5"
                        paddingX="2"
                        style={{
                          width: 160,
                        }}
                        top="0"
                        textAlign="left"
                        bgColor="surfaceNeutralLowest"
                      >
                        <Text variation="c1">Employee</Text>
                      </Box>
                    ) : null}
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 120,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">Title</Text>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 120,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Inline justifyContent="end">
                        <Text variation="c1">Amount</Text>
                      </Inline>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingLeft="2"
                      paddingRight="3"
                      style={{
                        width: 120,
                      }}
                      top="0"
                      bgColor="surfaceNeutralLowest"
                      textAlign="left"
                    >
                      <Text variation="c1">{columnTitle}</Text>
                    </Box>
                  </Box>
                </Box>
                {syncingData ? (
                  <SkeletonRows numOfRows={10} numOfCols={5} />
                ) : (
                  <Box as="tbody">
                    {reimbursements?.length ? (
                      reimbursements.map(
                        ({
                          grand_total_amount,
                          payment_status,
                          last_rejected_remarks,
                          last_returned_remarks,
                          i_can_mark_paid,
                          ticket_number,
                          title,
                          creator,
                          created_at,
                        }) => {
                          return (
                            <React.Fragment key={ticket_number}>
                              <Box
                                id={`${idPrefix}-list-${ticket_number}`}
                                as="tr"
                                borderTopWidth="1"
                                cursor="pointer"
                                tabIndex={-1}
                                backgroundColor={{
                                  hover: 'surfacePrimaryLowest',
                                }}
                                onClick={() =>
                                  onReimbursementClickHandler(ticket_number)
                                }
                              >
                                <Box
                                  as="td"
                                  paddingX="2"
                                  paddingY="2"
                                  textAlign="left"
                                  valign="top"
                                  borderBottomWidth="1"
                                  borderColor="borderSeparator"
                                >
                                  <Stack gap="1">
                                    <DateAndTime
                                      variation="b2"
                                      date={created_at}
                                    />
                                    <Time
                                      variation="c2"
                                      color="textLow"
                                      date={created_at}
                                      format="hh:mm a"
                                    />
                                  </Stack>
                                </Box>
                                <Box
                                  as="td"
                                  paddingX="2"
                                  paddingY="2"
                                  valign="top"
                                  borderBottomWidth="1"
                                  borderColor="borderSeparator"
                                >
                                  <Text variation="b2">{ticket_number}</Text>
                                </Box>
                                {!isCreatedByYouPage ? (
                                  <Box
                                    as="td"
                                    paddingX="2"
                                    paddingY="2"
                                    valign="top"
                                    borderBottomWidth="1"
                                    borderColor="borderSeparator"
                                  >
                                    <Inline gap="2" marginBottom="0.5">
                                      <Avatar
                                        avatarSize="sm"
                                        id={
                                          creator?.id.toString() ||
                                          creator?.employee_code?.toString()
                                        }
                                        avatarText={
                                          creator?.name?.length
                                            ? creator?.name[0]
                                            : 'U'
                                        }
                                      />
                                      <Stack gap="1">
                                        <Text variation="b2">
                                          {creator?.name}
                                        </Text>
                                        <Text variation="c2" color="textMedium">
                                          {creator?.employee_code}
                                        </Text>
                                      </Stack>
                                    </Inline>
                                  </Box>
                                ) : null}
                                <Box
                                  as="td"
                                  paddingX="2"
                                  paddingY="2"
                                  valign="top"
                                  borderBottomWidth="1"
                                  borderColor="borderSeparator"
                                >
                                  <Text variation="b2">{title}</Text>
                                </Box>
                                <Box
                                  as="td"
                                  paddingX="2"
                                  paddingY="2"
                                  className="whitespace-pre"
                                  textAlign="right"
                                  valign="top"
                                  borderBottomWidth="1"
                                  borderColor="borderSeparator"
                                >
                                  <Box>
                                    <Amount
                                      amount={Number(grand_total_amount || 0)}
                                      variation="t4"
                                    />
                                  </Box>
                                </Box>
                                <Box
                                  as="td"
                                  paddingLeft="2"
                                  paddingRight="3"
                                  paddingY="2"
                                  className="whitespace-pre"
                                  textAlign="left"
                                  valign="top"
                                  borderBottomWidth="1"
                                  borderColor="borderSeparator"
                                >
                                  <Box width="fitContent">
                                    {renderComponent(
                                      payment_status,
                                      last_rejected_remarks || '',
                                      last_returned_remarks || '',
                                      i_can_mark_paid || false
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            </React.Fragment>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          <EmptyState
                            renderIcon={(props) => <FileIcon {...props} />}
                            title="No Reimbursements Found!"
                            subText={`Please try changing your applied filters!`}
                            renderButton={() => (
                              <Button
                                title="Reset Filters"
                                onClick={resetFilters}
                                id={`${idPrefix}-empty-state-reset-filter-button`}
                              />
                            )}
                          />
                        </td>
                      </tr>
                    )}
                  </Box>
                )}
              </Box>
            </Stack>
          </Box>
        </Stack>
      )}
    </Box>
  );
}
