import type { ElementType } from 'react';
import { forwardRef } from 'react';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';
import { Box, BoxOwnProps } from '../box/box';
import { Text } from '../text/text';
import { TBackgroundColorStyles } from '../avatar/avatar.css';
import { CrossIcon } from '../icons/icons';

const DefaultHeaderElement = 'div';

type HeaderOwnProps = {
  headingText: string;
  renderRight?: () => React.ReactElement | null;
  onClickCross?: () => void;
} & TBackgroundColorStyles;

export type FullScreenHeaderProps<
  C extends React.ElementType = typeof DefaultHeaderElement
> = PolymorphicComponentPropWithRef<C, BoxOwnProps & HeaderOwnProps>;

type HeaderComponent = <
  C extends React.ElementType = typeof DefaultHeaderElement
>(
  props: FullScreenHeaderProps<C>
) => React.ReactNode;

export const FullScreenHeader: HeaderComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultHeaderElement>(
    {
      as,
      headingText,
      renderRight,
      onClickCross,
      ...restProps
    }: FullScreenHeaderProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Element: ElementType = as || DefaultHeaderElement;

    return (
      <Box
        {...restProps}
        as={Element}
        ref={ref}
        width={'auto'}
        display={'flex'}
        alignItems={'center'}
        justifyContent="between"
        backgroundColor="surfaceDefault"
        paddingY="3"
        paddingX="5"
      >
        <Box display="flex" flex="1" marginRight="3">
          <CrossIcon
            size="4"
            color="iconHigh"
            cursor="pointer"
            onClick={onClickCross}
          />
          <Text variation="h3" color="textHigh" marginLeft="3" flex="1">
            {headingText}
          </Text>
        </Box>
        {renderRight ? renderRight() : null}
      </Box>
    );
  }
);

export default FullScreenHeader;
