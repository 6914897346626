/* eslint-disable @typescript-eslint/no-explicit-any */

const NOTIFICATION_TYPE_PREFIX = 'App\\Notifications\\';

export const NOTIFICATION_TYPES = {
  // invoice
  INVOICE_APPROVED: `${NOTIFICATION_TYPE_PREFIX}InvoiceApprovedNotification`,
  INVOICE_REJECTED: `${NOTIFICATION_TYPE_PREFIX}InvoiceRejectedNotification`,
  INVOICE_PAID: `${NOTIFICATION_TYPE_PREFIX}InvoicePaidNotification`,
  INVOICE_RETURNED: `${NOTIFICATION_TYPE_PREFIX}InvoiceReturnedNotification`,
  INVOICE_WAITING_APPROVAL: `${NOTIFICATION_TYPE_PREFIX}InvoiceWaitingApprovalNotification`,
  INVOICE_WAITING_PAYMENT: `${NOTIFICATION_TYPE_PREFIX}InvoiceWaitingPaymentNotification`,

  // Reimbursements
  REIMBURSEMENT_APPROVED: `${NOTIFICATION_TYPE_PREFIX}ReimbursementApprovedNotification`,
  REIMBURSEMENT_PAID: `${NOTIFICATION_TYPE_PREFIX}ReimbursementPaidNotification`,
  REIMBURSEMENT_REJECTED: `${NOTIFICATION_TYPE_PREFIX}ReimbursementRejectedNotification`,
  REIMBURSEMENT_RETURNED: `${NOTIFICATION_TYPE_PREFIX}ReimbursementReturnedNotification`,
  REIMBURSEMENT_WAITING_APPROVAL: `${NOTIFICATION_TYPE_PREFIX}ReimbursementWaitingApprovalNotification`,
  REIMBURSEMENT_WAITING_PAYMENT: `${NOTIFICATION_TYPE_PREFIX}ReimbursementWaitingPaymentNotification`,

  // Vendors
  VENDOR_APPROVED: `${NOTIFICATION_TYPE_PREFIX}VendorApprovedNotification`,
  VENDOR_REJECTED: `${NOTIFICATION_TYPE_PREFIX}VendorRejectedNotification`,
  VENDOR_WAITING_APPROVAL: `${NOTIFICATION_TYPE_PREFIX}VendorWaitingApprovalNotification`,
};

export type CauserPayload = {
  id?: number;
  name?: string;
  employee_code?: null | string;
};

export type NotificationDataPayload = {
  id?: number;
  ticket_number?: string; // invoice or reimbursement
  invoice_number?: string;
  invoice_date?: Date | string | null;
  grand_total_amount?: string;
  last_rejected_remarks?: string;
  vendor_id?: number;
  branch_id?: number;
  causer?: CauserPayload;
  is_final_approval?: boolean;
  name?: string; // vendor name
  legal_name?: string; // vendor legal name
};

export type NotificationPayload = {
  id: string;
  type: keyof typeof NOTIFICATION_TYPES;
  notifiable_type: string;
  notifiable_id: number;
  data: NotificationDataPayload;
  read_at: Date | string | null;
  created_at: Date | string | null;
  updated_at: Date | string | null;
};

export const getNotificationText = (
  notificationType: keyof typeof NOTIFICATION_TYPES,
  notificationData: NotificationDataPayload
) => {
  if (notificationType.includes('Invoice')) {
    const { ticket_number, causer } = notificationData;
    switch (notificationType) {
      case NOTIFICATION_TYPES.INVOICE_APPROVED:
        return notificationData.is_final_approval
          ? `Invoice ${ticket_number} has been approved by ${causer?.name} and is now awaiting payment.`
          : `Invoice ${ticket_number} has been approved by ${causer?.name} and moved forward in approval chain.`;

      case NOTIFICATION_TYPES.INVOICE_PAID:
        return `Invoice ${ticket_number} has been marked paid by ${causer?.name}.`;

      case NOTIFICATION_TYPES.INVOICE_REJECTED:
        return `Invoice ${ticket_number} has been rejected by ${causer?.name}.`;

      case NOTIFICATION_TYPES.INVOICE_RETURNED:
        return `Invoice ${ticket_number} has been returned by ${causer?.name}.`;

      case NOTIFICATION_TYPES.INVOICE_WAITING_APPROVAL:
        return `Invoice ${ticket_number} is pending for your approval`;

      case NOTIFICATION_TYPES.INVOICE_WAITING_PAYMENT:
        return `Invoice ${ticket_number} is pending for payment`;

      default:
        return 'New Invoice Notification';
    }
  }

  if (notificationType.includes('Vendor')) {
    const { causer, legal_name } = notificationData;
    switch (notificationType) {
      case NOTIFICATION_TYPES.VENDOR_APPROVED:
        return notificationData.is_final_approval
          ? `Vendor ${legal_name} has been approved by ${causer?.name}`
          : `Vendor ${legal_name} has been approved by ${causer?.name} and moved forward in approval chain.`;

      case NOTIFICATION_TYPES.VENDOR_REJECTED:
        return `Vendor ${legal_name} has been rejected by ${causer?.name}.`;

      case NOTIFICATION_TYPES.VENDOR_WAITING_APPROVAL:
        return `Vendor ${legal_name} is pending for your approval`;

      default:
        return 'New Vendor Notification';
    }
  }

  const { causer, ticket_number } = notificationData;
  switch (notificationType) {
    case NOTIFICATION_TYPES.REIMBURSEMENT_APPROVED:
      return notificationData.is_final_approval
        ? `Reimbursement ${ticket_number} has been approved by ${causer?.name} and is now awaiting payment.`
        : `Reimbursement ${ticket_number} has been approved by ${causer?.name} and moved forward in approval chain.`;

    case NOTIFICATION_TYPES.REIMBURSEMENT_PAID:
      return `Reimbursement ${ticket_number} has been marked paid by ${causer?.name}.`;

    case NOTIFICATION_TYPES.REIMBURSEMENT_REJECTED:
      return `Reimbursement ${ticket_number} has been rejected by ${causer?.name}.`;

    case NOTIFICATION_TYPES.REIMBURSEMENT_RETURNED:
      return `Reimbursement ${ticket_number} has been returned by ${causer?.name}.`;

    case NOTIFICATION_TYPES.REIMBURSEMENT_WAITING_APPROVAL:
      return `Reimbursement ${ticket_number} is pending for your approval`;

    case NOTIFICATION_TYPES.REIMBURSEMENT_WAITING_PAYMENT:
      return `Reimbursement ${ticket_number} is pending for payment`;
    default:
      return 'New Notification';
  }
};
