import { forwardRef, useMemo } from 'react';
import { Text, TextProps } from './text';
import { Box } from '../box/box';

type CURRENCY_TYPES = 'inr';

export const currencies_supported = {
  inr: '₹',
};

export const Amount = forwardRef<
  HTMLSpanElement,
  TextProps<'span'> & {
    currency?: CURRENCY_TYPES;
    amount: number;
  }
>(function Amount({ currency = 'inr', amount, ...props }, ref) {
  return (
    <Text
      as="span"
      ref={ref}
      itemScope
      itemType="http://schema.org/UnitPriceSpecification"
      {...props}
    >
      {currency ? (
        <Box as="span" itemProp="priceCurrency" color={props.color}>
          {currencies_supported[currency]}
        </Box>
      ) : null}
      <Box itemProp="price" display="inline">
        <LocalizedNumber number={amount} color={props.color} />
      </Box>
    </Text>
  );
});

export function useNumberFormatter(options?: Intl.NumberFormatOptions) {
  const formatter = useMemo(
    (newOptions?: Intl.NumberFormatOptions) => {
      newOptions = { ...options, ...newOptions };
      const maximumFractionDigits =
        newOptions?.maximumFractionDigits === undefined
          ? 2
          : newOptions?.maximumFractionDigits;
      try {
        if (typeof Intl !== 'undefined') {
          return new Intl.NumberFormat(`en-IN`, {
            ...newOptions,
            maximumFractionDigits,
          }).format;
        }
        throw new Error('Intl not supported');
      } catch (e) {
        return (n: number) =>
          fallbackNumberToLocalString(n, maximumFractionDigits);
      }
    },
    [options]
  );
  return formatter;
}

export const LocalizedNumber = forwardRef<
  HTMLSpanElement,
  TextProps<'span'> & {
    number: number;
  }
>(function LocalizedNumber({ number, ...props }, ref) {
  const format = useNumberFormatter();
  return (
    <Text as="span" {...props} ref={ref}>
      {format(number)}
    </Text>
  );
});

function fallbackNumberToLocalString(
  n: number | null | undefined,
  digitsAfterDecimal: number | undefined = 2
) {
  if (n === null || n === undefined) return '';
  const str = normalizeNumber(n, digitsAfterDecimal).toString();
  // we dont want to add commas in the after the decimal point
  const parts = str.split('.');
  parts[0] = parts[0].replace(/\B(?=(((\d{2})+\d{3})|\d{3})(?!\d))/g, ',');
  return parts.join('.');
}

export function normalizeNumber(
  n: number,
  digitsAfterDecimal: number | undefined = 2
): number {
  let str = n.toString();
  if (digitsAfterDecimal !== undefined) {
    str = Number(str).toFixed(digitsAfterDecimal).toString();
  }
  if (parseInt(str) === parseFloat(str)) {
    str = parseInt(str).toString();
  }
  return Number(str);
}
