import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';
import { Box } from '../box/box';
import { ElementType, forwardRef, useEffect, useMemo, useState } from 'react';
import { Text } from '../text/text';
import {
  ArrowDownFilledIcon,
  SearchIcon,
  FileIcon,
  InfoCircle,
} from '../icons/icons';
import { Inline } from '../inline/inline';
import { MenuContext, MenuList } from '../menu/menu';
import { Radio, RadioGroup } from '../radio/radio';
import Stack from '../stack/stack';
import { TextInput } from '../textinput/textinput';
import { useFocusWithin } from 'react-aria';
import { EmptyState } from '../emptystate/emptystate';
import { HoverableBox } from '../hoverableBox/hoverableBox';
import { Button } from '../button/button';

const DefaultElement = 'div';

type BaseOption = {
  label: string;
  value: string;
  subText?: string;
};

type SelectBaseProps = {
  label?: string;
  labelIcon?: React.ReactNode;
  options?: Array<BaseOption>;
  cancelBtnTitle?: string;
  actionBtnTitle?: string;
};

type SingleSelectOwnProps = SelectBaseProps & {
  value?: BaseOption | string;
  searchPlaceholder?: string;
  removeSearch?: boolean;
  onSave: (option: BaseOption) => void;
  label: string;
  isRequired?: boolean;
  placeholder?: string;
  description?: string;
  errorMessage?: string;
  onChangeSearchTerm?: (q: string) => void;
  emptyTitle?: string;
  fieldInfo?: string;
  id: string;
  onPressClear?: () => void;
};

export type SingleSelectProps<
  C extends React.ElementType = typeof DefaultElement
> = PolymorphicComponentPropWithRef<C, SingleSelectOwnProps>;

type SingleSelectComponent = <
  C extends React.ElementType = typeof DefaultElement
>(
  props: SingleSelectProps<C>
) => React.ReactNode;
export const SingleSelect: SingleSelectComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultElement>(
    {
      as,
      label,
      options,
      value,
      labelIcon,
      removeSearch,
      cancelBtnTitle,
      actionBtnTitle,
      searchPlaceholder,
      isRequired = false,
      placeholder = 'Select',
      errorMessage,
      onSave,
      onChangeSearchTerm,
      emptyTitle = 'No results',
      fieldInfo,
      id,
      onPressClear,
      ...props
    }: SingleSelectProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Element: ElementType = as || DefaultElement;
    const [isFocusWithin, setFocusWithin] = useState(false);
    const [selectedOption, setSelectedOption] = useState<BaseOption | null>(
      null
    );
    const [isOpen, setIsOpen] = useState(false);

    const menuContext = useMemo(() => {
      return {
        isExpanded: isOpen,
        open: () => {
          setIsOpen(true);
          // This will ensure that we have latest options available on every open
        },
        close: () => {
          setIsOpen(false);
          // focus the container before closing for better focus management
          // reset the query
        },
        toggle: () => (isOpen ? setIsOpen(false) : setIsOpen(true)),
      };
    }, [isOpen]);

    useEffect(() => {
      if (value) {
        if (typeof value !== 'string') {
          return setSelectedOption(value);
        }
        if (typeof value === 'string') {
          const defaultSelectedOption = options?.find(
            (option) => option.value === value
          );
          if (defaultSelectedOption) {
            return setSelectedOption(defaultSelectedOption);
          }
        }
      } else {
        setSelectedOption(null);
      }
    }, [options, value]);

    const placeholderText: string = useMemo(() => {
      const values = options?.filter(
        (option) => selectedOption?.value === option.value
      );
      return values?.length && values.length > 1
        ? `${label || 'Select'} (${values.length})`
        : values?.length === 1
        ? values[0].label
        : placeholder;
    }, [label, options, placeholder, selectedOption?.value]);

    const hasValue: boolean = useMemo(() => {
      return Boolean(selectedOption);
    }, [selectedOption]);

    const [q, setSearchQ] = useState<string>('');

    const filteredOptions = useMemo(() => {
      return removeSearch || onChangeSearchTerm
        ? options
        : options?.filter((option) =>
            option.label.toLowerCase().includes(q.toLowerCase())
          );
    }, [onChangeSearchTerm, options, q, removeSearch]);

    const { focusWithinProps } = useFocusWithin({
      onFocusWithinChange: (isFocusWithin) => setFocusWithin(isFocusWithin),
    });

    useEffect(() => {
      if (!isFocusWithin) setIsOpen(false);
    }, [isFocusWithin]);

    return (
      <Box
        ref={ref}
        as={Element}
        width="full"
        position="relative"
        backgroundColor="surfaceDefault"
        id={id}
        {...props}
        {...focusWithinProps}
      >
        <MenuContext.Provider value={menuContext}>
          <Stack display="flex">
            <Inline gap={'1'}>
              <Text variation="c2" display="flex">
                {label}
                {isRequired ? (
                  <Text
                    variation="c2"
                    color="textError"
                    as="span"
                    marginLeft="0.5"
                  >
                    {'*'}
                  </Text>
                ) : null}
              </Text>
              {fieldInfo?.length && (
                <HoverableBox text={fieldInfo}>
                  <InfoCircle size="2" color="iconLow" marginRight="1" />
                </HoverableBox>
              )}
            </Inline>
            <Box
              display="flex"
              rounded="md"
              borderWidth="1"
              paddingLeft="1.5"
              paddingRight="0.5"
              paddingY="1.5"
              cursor="pointer"
              alignItems="center"
              borderColor={errorMessage ? 'borderError' : 'borderOutline'}
              onClick={menuContext.open}
              color={
                hasValue || menuContext.isExpanded ? 'textPrimary' : 'textHigh'
              }
              backgroundColor="surfaceDefault"
              marginTop="0.5"
              justifyContent="between"
            >
              <Text variation="b2" color={hasValue ? 'textHigh' : 'textLowest'}>
                {placeholderText}
              </Text>
              <ArrowDownFilledIcon
                size="3"
                style={{ rotate: menuContext.isExpanded ? '180deg' : '0deg' }}
                color="iconMedium"
              />
            </Box>
            {!errorMessage && props.description && (
              <Text marginTop={'0.5'} as="p" variation={'c2'} color={'textLow'}>
                {props.description}
              </Text>
            )}
            {errorMessage && (
              <Text
                marginTop={'0.5'}
                borderRadius="md"
                paddingY={'0.5'}
                paddingX={'1'}
                display={'flex'}
                color={'textError'}
                variation={'c2'}
                bgColor={'surfaceErrorLowest'}
              >
                {errorMessage}
              </Text>
            )}
          </Stack>
          <MenuList>
            <Box
              backgroundColor="surfaceDefault"
              style={{
                maxHeight: 440,
                width: 324,
              }}
              borderWidth="1"
              borderColor="borderSeparator"
              shadowLevel="s2"
              rounded="md"
            >
              {removeSearch ? null : (
                <Box
                  borderBottomWidth="1"
                  borderColor="borderSeparator"
                  padding="2"
                >
                  <TextInput
                    fullWidth
                    removeMinWidth
                    value={q}
                    minHeight="5"
                    onChange={(e: string) => {
                      setSearchQ(e);
                      if (onChangeSearchTerm) {
                        onChangeSearchTerm(e);
                      }
                    }}
                    placeholder={searchPlaceholder || 'Search here'}
                    leftIcon={(props) => <SearchIcon {...props} />}
                    aria-label="Search"
                  />
                </Box>
              )}

              <Stack
                as="ul"
                gap="1"
                paddingY="1"
                overflow="auto"
                style={{ maxHeight: 200 }}
              >
                {filteredOptions?.length ? (
                  filteredOptions?.map((option) => {
                    const isSelected = Boolean(
                      selectedOption?.value === option?.value
                    );
                    return (
                      <Inline
                        id={`${id}-list-${option.value}`}
                        as="li"
                        key={option.value}
                        cursor="pointer"
                        paddingX="2"
                        backgroundColor={{
                          default: isSelected
                            ? 'surfacePrimaryLowest'
                            : undefined,
                          hover: !isSelected
                            ? 'surfaceNeutralLowest'
                            : undefined,
                        }}
                        onClick={() => {
                          if (isSelected) {
                            menuContext.close();
                            return;
                          }
                          onSave(option);
                          setSelectedOption(option);
                          menuContext.close();
                        }}
                      >
                        <RadioGroup
                          value={selectedOption?.value}
                          aria-label={'Single Select Radio'}
                        >
                          <Radio
                            value={option.value}
                            alignItems={option.subText ? 'start' : 'center'}
                          >
                            <Stack flex="1">
                              <Text
                                variation="b2"
                                color={isSelected ? 'textHigh' : 'textMedium'}
                              >
                                {option.label}
                              </Text>
                              {option.subText ? (
                                <Text
                                  variation="c2"
                                  color="textMedium"
                                  marginTop="1.5"
                                >
                                  {option.subText}
                                </Text>
                              ) : null}
                            </Stack>
                          </Radio>
                        </RadioGroup>
                      </Inline>
                    );
                  })
                ) : (
                  <Stack as="li" width="full" paddingY="2" alignItems="center">
                    <EmptyState
                      renderIcon={(props) => <FileIcon {...props} />}
                      title={q ? 'No Result Found!' : emptyTitle}
                      subText={
                        q
                          ? 'Please type a different keyword'
                          : 'Type to choose from suggestions'
                      }
                      componentSize="sm"
                    />
                  </Stack>
                )}
              </Stack>
              {onPressClear ? (
                <Inline
                  padding="2"
                  gap="4"
                  justifyContent="end"
                  borderTopWidth="1"
                  borderColor="borderSeparator"
                >
                  <Button
                    id={`${id}-cancel-select`}
                    title={cancelBtnTitle || 'Clear'}
                    onClick={() => {
                      menuContext.close();
                      onPressClear();
                    }}
                  />
                </Inline>
              ) : null}
            </Box>
          </MenuList>
        </MenuContext.Provider>
      </Box>
    );
  }
);
