import {
  useAllRolesPermissions,
  useRoleTypes,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  Box,
  CheckCircle,
  CrossCircle,
  Text,
  Inline,
  SpinnerIcon,
} from '@nbfc-expense-tool/ui';

type FinalResults = {
  [prefix: string]: {
    [permissionName: string]: {
      [roleName: string]: boolean;
    };
  };
};

function formatModuleName(variableName: string) {
  // capitalize each word and replace hyphens and underscores with spaces
  return variableName
    .replace(/[-_]/g, ' ')
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const useRolesAndPermissions = () => {
  const { permissions: allPermissions, status } = useAllRolesPermissions();
  const { roles: allRoles, status: rolesFetchStatus } = useRoleTypes();
  const roles = allRoles?.filter((role) => {
    return role.name !== 'superadmin';
  });

  const finalResults: FinalResults = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const moduleNameToDisplayNameMapping: any = {
    dashboard: 'Dashboard Module',
    invoices: 'Invoices Module',
    vendors: 'Vendors Module',
    branches: 'Branches Module',
    users: 'Employees Module',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const permissionNamesMappedWithDisplayName: any = {};

  Object.keys(moduleNameToDisplayNameMapping).forEach((key) => {
    const modulePermissions = allPermissions?.filter((permission) => {
      if (permission.name.includes(key)) {
        return true;
      }
      return false;
    });
    modulePermissions?.forEach((permission) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, permissionName] = permission.name.split('.');
      permissionNamesMappedWithDisplayName[permission.name] =
        permission.display_name;
      const moduleName = moduleNameToDisplayNameMapping[key];
      if (permissionName) {
        if (!finalResults[moduleName]) {
          finalResults[moduleName] = {};
        }
        finalResults[moduleName][permission.display_name] = {};
        roles?.forEach((role) => {
          finalResults[moduleName][permission.display_name][role.display_name] =
            role.permissions.some(
              (rolePermission) => rolePermission.name === permission.name
            )
              ? true
              : false;
        });
      }
    });
  });

  return {
    rolesAndPermissions: finalResults,
    roles: roles,
    status:
      status === 'in_progress' || rolesFetchStatus === 'in_progress'
        ? 'in_progress'
        : 'success',
  };
};

export const RolesAndPermissions = () => {
  const { rolesAndPermissions, status, roles } = useRolesAndPermissions();

  const permissionNames = Object.keys(rolesAndPermissions || {});

  if (status === 'in_progress') {
    return (
      <Inline gap="4" justifyContent={'center'} marginTop={'2'}>
        <SpinnerIcon size="3" color="iconMedium" />
        <Text variation="b2">Loading...</Text>
      </Inline>
    );
  }

  return (
    <>
      {permissionNames.map((moduleName) => {
        const modulePermission = rolesAndPermissions[moduleName];
        const modulePermissionNames = Object.keys(modulePermission || {});
        return (
          <>
            <Text variation={'t3'} color={'textMedium'} marginBottom={'2'}>
              {moduleName}
            </Text>
            <Box
              as="table"
              width="full"
              position="relative"
              marginBottom={'3'}
              rounded="md"
              borderWidth="1"
              borderColor="borderSeparator"
            >
              <Box as="thead" bgColor="surfaceNeutralLowest">
                <Box as="tr">
                  <Box
                    as="th"
                    position="sticky"
                    paddingY="1.5"
                    paddingX="2"
                    style={{
                      width: 340,
                    }}
                    top="0"
                    textAlign="left"
                    bgColor="surfaceNeutralLowest"
                  >
                    <Text variation="c1">Permission Name</Text>
                  </Box>
                  {roles?.map((role) => {
                    return (
                      <Box
                        as="th"
                        position="sticky"
                        paddingY="1.5"
                        paddingX="2"
                        style={{
                          width: 120,
                        }}
                        top="0"
                        textAlign="left"
                        bgColor="surfaceNeutralLowest"
                      >
                        <Text variation="c1">{role.display_name}</Text>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box as="tbody" bgColor={'surfaceDefault'}>
                {modulePermissionNames?.length
                  ? modulePermissionNames.map((key) => {
                      const permission = rolesAndPermissions[moduleName][key];
                      return (
                        <Box
                          key={key}
                          as="tr"
                          borderTopWidth="1"
                          cursor="pointer"
                          tabIndex={-1}
                          bgColor={{
                            hover: 'surfacePrimaryLowest',
                          }}
                        >
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY={'2'}
                            borderColor="borderSeparator"
                            borderBottomWidth={'1'}
                          >
                            <Text variation="b2">{formatModuleName(key)}</Text>
                          </Box>
                          {roles?.map((role) => {
                            return (
                              <Box
                                as="td"
                                paddingX="2"
                                paddingY={'2'}
                                borderColor="borderSeparator"
                                borderBottomWidth={'1'}
                              >
                                {permission[role.display_name] ? (
                                  <CheckCircle
                                    size={'3'}
                                    color={'iconSuccess'}
                                  />
                                ) : (
                                  <CrossCircle size={'3'} color={'iconError'} />
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })
                  : null}
              </Box>
            </Box>
          </>
        );
      })}
    </>
  );
};
