import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import * as htmlToImage from 'html-to-image';

type Pagination = {
  perPage: number;
  totalItems?: number;
  lastPage?: number;
};

type PaginationState = {
  current: number;
  perPage: number;
  lastPage?: number;
  canGoNext?: boolean;
  canGoBack?: boolean;
};

type TYPE_AND_PAYLOAD =
  | {
      type: 'UPDATE_LAST_PAGE';
      payload: { lastPage: number };
    }
  | { type: 'RESET_PAGINATION'; payload: PaginationState }
  | { type: 'NEXT_PAGE' }
  | { type: 'PREVIOUS_PAGE' };

const reducer = (
  state: PaginationState,
  action: TYPE_AND_PAYLOAD
): PaginationState => {
  switch (action.type) {
    case 'UPDATE_LAST_PAGE':
      // eslint-disable-next-line no-case-declarations
      const lastPage = action.payload.lastPage;
      return {
        ...state,
        lastPage: lastPage,
        canGoBack: state.current > 1,
        canGoNext: state.current !== lastPage,
      };
    case 'NEXT_PAGE':
      // eslint-disable-next-line no-case-declarations
      const nextPage = state.current + 1;
      return {
        ...state,
        canGoBack: Boolean(nextPage > 1),
        canGoNext: nextPage !== state.lastPage,
        current: nextPage,
      };
    case 'PREVIOUS_PAGE':
      // eslint-disable-next-line no-case-declarations
      const prevPage = state.current - 1;
      return {
        ...state,
        canGoBack: prevPage > 1,
        current: prevPage,
        canGoNext: prevPage !== state.lastPage,
      };
    case 'RESET_PAGINATION':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export function usePagination({ perPage, lastPage, totalItems }: Pagination) {
  const initialPagination = useMemo(() => {
    return {
      current: 1,
      perPage,
      canGoNext: false,
      canGoBack: false,
    };
  }, [perPage]);

  const [state, dispatch] = useReducer(reducer, initialPagination);

  const lastPageCalculated: number = useMemo(() => {
    if (totalItems) {
      return Math.ceil(totalItems / perPage);
    }
    if (lastPage) {
      return lastPage;
    }
    return 0;
  }, [totalItems, perPage, lastPage]);

  useEffect(() => {
    if (!state.lastPage || state.lastPage !== lastPageCalculated) {
      dispatch({
        type: 'UPDATE_LAST_PAGE',
        payload: {
          lastPage: lastPageCalculated,
        },
      });
    }
  }, [lastPageCalculated, state.lastPage]);

  function nextPage() {
    if (state.current !== lastPageCalculated) {
      dispatch({ type: 'NEXT_PAGE' });
    }
  }

  function previousPage() {
    if (state.current > 1) {
      dispatch({ type: 'PREVIOUS_PAGE' });
    }
  }

  function resetPagination() {
    dispatch({ type: 'RESET_PAGINATION', payload: initialPagination });
  }

  return {
    ...state,
    nextPage,
    previousPage,
    resetPagination,
  };
}

type DebouncedFunction<T extends unknown[]> = (...args: T) => void;

export function debounce<T extends unknown[]>(
  func: (...args: T) => void,
  delay = 600
): DebouncedFunction<T> {
  let timeoutId: NodeJS.Timeout;
  return (...args: T) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export function useDownloadHTMLAsImage() {
  const [downloadStatus, setDownloadStatus] = useState<
    'init' | 'in_progress' | 'success' | 'failed'
  >('init');

  // reset the status after success
  useEffect(() => {
    if (downloadStatus === 'success') {
      const handler = setTimeout(() => {
        setDownloadStatus('init');
      }, 5000);
      return () => {
        clearTimeout(handler);
      };
    }
    return () => undefined;
  }, [downloadStatus]);

  function downloadBlob(blob: Blob, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    setTimeout(() => {
      a.remove();
    }, 5000);
  }

  const download = useCallback(async (html: HTMLElement, fileName: string) => {
    setDownloadStatus('in_progress');
    setTimeout(async () => {
      try {
        const url = await htmlToImage.toBlob(html);
        if (url) {
          setDownloadStatus('success');
          return downloadBlob(url, fileName);
        }
        setDownloadStatus('failed');
        throw new Error('Error downloading file. Please try again later.');
      } catch (e) {
        setDownloadStatus('failed');
        throw e;
      }
    }, 0);
  }, []);

  return {
    download,
    status: downloadStatus,
  };
}

export const isPanNumberCorrect = (regType: string, panNumber: string) => {
  if (regType === 'co_operative' && panNumber[3] !== 'A') {
    return false;
  }
  if (regType === 'huf' && panNumber[3] !== 'H') {
    return false;
  }
  if (
    (regType === 'individual' ||
      regType === 'one_person_company' ||
      regType === 'proprietorship') &&
    panNumber[3] !== 'P'
  ) {
    return false;
  }
  if (
    (regType === 'llp' || regType === 'partnership') &&
    panNumber[3] !== 'F'
  ) {
    return false;
  }
  if (
    (regType === 'private_limited' ||
      regType === 'limited_co' ||
      regType === 'public_sector_unit' ||
      regType === 'unlimited_company') &&
    panNumber[3] !== 'C'
  ) {
    return false;
  }
  if (regType === 'government_body' && panNumber[3] !== 'G') {
    return false;
  }
  return true;
};
