export const IndianStatesAndUTs = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli and Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
];

export const monthLabels = [
  { id: '4', month: 'Apr', monthName: 'April' },
  { id: '5', month: 'May', monthName: 'May' },
  { id: '6', month: 'Jun', monthName: 'June' },
  { id: '7', month: 'Jul', monthName: 'July' },
  { id: '8', month: 'Aug', monthName: 'August' },
  { id: '9', month: 'Sep', monthName: 'September' },
  { id: '10', month: 'Oct', monthName: 'October' },
  { id: '11', month: 'Nov', monthName: 'November' },
  { id: '12', month: 'Dec', monthName: 'December' },
  { id: '1', month: 'Jan', monthName: 'January' },
  { id: '2', month: 'Feb', monthName: 'February' },
  { id: '3', month: 'Mar', monthName: 'March' },
];

export const TENANT_CONFIG_KEYS = {
  REIMBURSEMENT_FEATURE_ENABLED: 'reimbursement_feature_enabled',
  INVOICE_OCR_FEATURE_ENABLED: 'ai_invoice_import_feature_enabled',
};
