import { useBulkImportEntities } from '@nbfc-expense-tool/data-store/dashboard';
import {
  Box,
  BoxOwnProps,
  Button,
  CheckCircle,
  CrossCircle,
  DownloadIcon,
  FileIcon,
  FilePlusIcon,
  InfoCircle,
  Inline,
  SpinnerIcon,
  Stack,
  Text,
  UploadIcon,
} from '@nbfc-expense-tool/ui';
import React, { useMemo } from 'react';

type Props = {
  entityType: 'vendors' | 'branches' | 'employees';
};

const BulkImportEntities: React.FC<Props & BoxOwnProps> = ({
  entityType,
  ...props
}) => {
  const {
    labels,
    entitiesToImportFile,
    importEntitiesApiState,
    isDownloadSampleFileApiLoading,
    fileInputRef,
    clickFileInputField,
    onPickFile,
    importEntities,
    downloadSampleFile,
    navigateToEntitiesListing,
  } = useBulkImportEntities(entityType);

  const renderSelectFileView = useMemo(() => {
    if (!entitiesToImportFile) {
      return (
        <Inline
          paddingX="3"
          paddingY="4"
          marginBottom="2"
          width="full"
          justifyContent="between"
          alignItems="center"
          borderWidth="1"
          borderRadius="md"
          borderColor="borderSeparator"
          borderStyle="dashed"
          backgroundColor="surfaceNeutralLowest"
        >
          <Inline flex="1" alignItems="center">
            <FilePlusIcon size="6" color="iconPrimary" marginRight="3" />
            <Box>
              <Text variation="t4">Select xlsx/csv File</Text>
              <Text variation="c2" color="textMedium">
                Import can be done in excel file format only
              </Text>
            </Box>
          </Inline>
          <Button
            id={`select-file-for-${entityType}`}
            type="outlined"
            title={'Select File'}
            onClick={clickFileInputField}
            leftIcon={(props) => <UploadIcon {...props} />}
          />
        </Inline>
      );
    }
    return (
      <Inline
        paddingX="3"
        paddingY="4"
        marginBottom="2"
        width="full"
        justifyContent="between"
        alignItems="center"
        borderWidth="1"
        borderRadius="md"
        borderColor="borderSeparator"
      >
        <Inline flex="1" alignItems="center" marginRight="4">
          <FileIcon size="6" color="iconPrimary" marginRight="3" />
          <Box>
            <Text variation="t4">{entitiesToImportFile.name || '-'}</Text>
            <Inline alignItems="center" marginTop="1">
              <Text variation="c2" color="textMedium">
                {entitiesToImportFile.type || '-'}
              </Text>
              <Box
                width="0.5"
                height="0.5"
                marginX="0.5"
                borderRadius="full"
                backgroundColor="surfaceNeutralMedium"
              />
              <Text variation="c2" color="textMedium">
                {((entitiesToImportFile.size || 0) / 1024).toFixed(2)} KB
              </Text>
            </Inline>
          </Box>
        </Inline>
        {(importEntitiesApiState.status === 'idle' ||
          importEntitiesApiState.status === 'error') && (
          <Button
            id={`change-for-${entityType}`}
            type="outlined"
            title={'Change'}
            onClick={clickFileInputField}
          />
        )}
      </Inline>
    );
  }, [
    entitiesToImportFile,
    importEntitiesApiState,
    clickFileInputField,
    entityType,
  ]);

  const renderViewBasedOnImportEntitiesState = useMemo(() => {
    switch (importEntitiesApiState.status) {
      case 'idle':
        if (entitiesToImportFile) {
          return (
            <Inline
              paddingX="3"
              paddingY="2"
              borderWidth="1"
              borderRadius="md"
              borderColor="borderSeparator"
              width="full"
              justifyContent="end"
            >
              <Button
                id={`import-file-for-${entityType}`}
                type="filled"
                title={`Import ${labels[entityType].singularLabel} Details`}
                onClick={importEntities}
              />
            </Inline>
          );
        }
        return (
          <Inline
            paddingX="3"
            paddingY="2"
            borderWidth="1"
            borderRadius="md"
            borderColor="borderPrimaryLow"
            backgroundColor="surfacePrimaryLowest"
            width="full"
          >
            <InfoCircle size="3" color="iconPrimary" marginRight="2" />
            <Text
              variation="b1"
              color="textPrimary"
            >{`Cannot import more than 1,000 ${labels[
              entityType
            ].singularLabel.toLowerCase()} details at a time`}</Text>
          </Inline>
        );
      case 'loading':
        return (
          <Inline
            paddingX="3"
            paddingY="2"
            borderWidth="1"
            borderRadius="md"
            borderColor="borderSeparator"
            width="full"
          >
            <SpinnerIcon size="3" color="iconLowest" marginRight="2" />
            <Text variation="b1" color="textLow">{`Importing ${labels[
              entityType
            ].singularLabel.toLowerCase()} details, please wait...`}</Text>
          </Inline>
        );
      case 'success':
        return (
          <Inline
            paddingX="3"
            paddingY="2"
            borderWidth="1"
            borderRadius="md"
            borderColor="borderSuccessLow"
            backgroundColor="surfaceSuccessLowest"
            width="full"
            justifyContent="between"
          >
            <Inline flex="1" marginRight="3">
              <CheckCircle size="3" color="iconSuccess" marginRight="2" />
              <Text variation="b1" color="textSuccess">
                {importEntitiesApiState.message}
              </Text>
            </Inline>
            <Text
              id={`open-${entityType}-file`}
              variation="lk"
              color="textSuccess"
              cursor="pointer"
              onClick={navigateToEntitiesListing}
            >{`Open ${labels[entityType].singularLabel} List`}</Text>
          </Inline>
        );
      case 'error':
        return (
          <Inline
            paddingX="3"
            paddingY="2"
            borderWidth="1"
            borderRadius="md"
            borderColor="borderErrorLow"
            backgroundColor="surfaceErrorLowest"
            width="full"
          >
            <CrossCircle size="3" color="iconError" marginRight="2" />
            <Text variation="b1" color="textError">
              {importEntitiesApiState.message}
            </Text>
          </Inline>
        );
      default:
        return null;
    }
  }, [
    labels,
    importEntitiesApiState,
    entityType,
    entitiesToImportFile,
    navigateToEntitiesListing,
    importEntities,
  ]);

  return (
    <Box
      paddingX="3"
      paddingY="4"
      borderRadius="md"
      borderWidth="1"
      borderColor="borderSeparator"
      backgroundColor="surfaceDefault"
      {...props}
    >
      <Stack alignItems="center" marginBottom="8" paddingX="20">
        {renderSelectFileView}
        {renderViewBasedOnImportEntitiesState}
      </Stack>
      <Stack textAlign="center" alignItems="center">
        <Text variation="t4">Download Sample File</Text>
        <Text variation="b3" color="textMedium" marginTop="1">
          Your CSV file should have same columns as this sample file
        </Text>
        <Button
          id={`download-${entityType}-file`}
          title="Download XLSX Sample"
          type="outlined"
          leftIcon={(props) => <DownloadIcon {...props} />}
          marginTop="2"
          onClick={downloadSampleFile}
          state={isDownloadSampleFileApiLoading ? 'loading' : undefined}
        />
      </Stack>
      <Box
        as="input"
        type="file"
        ref={fileInputRef}
        onChange={onPickFile}
        display="none"
        accept=".xlsx, .xls, .csv"
      />
    </Box>
  );
};

export default BulkImportEntities;
