import { RuleInfo, useRules } from '@nbfc-expense-tool/data-store/dashboard';
import {
  Box,
  Text,
  Inline,
  SkeletonRows,
  Amount,
  RoundedLevelIcon,
} from '@nbfc-expense-tool/ui';

type ApprovedRule = {
  [key: string]: RuleInfo[];
};

const empty = {};
export const InvoiceApproval = () => {
  const { rules, status } = useRules<ApprovedRule>('invoice');
  const rulesKeys = Object.keys(rules || empty);
  const rulesExist = rulesKeys.length > 0;

  return (
    <Box
      as="table"
      width="full"
      position="relative"
      marginBottom={'3'}
      rounded="md"
      borderWidth="1"
      borderColor="borderSeparator"
    >
      <Box as="thead" bgColor="surfaceNeutralLowest">
        <Box as="tr">
          <Box
            as="th"
            position="sticky"
            paddingY="1.5"
            paddingX="2"
            style={{
              width: 120,
            }}
            top="0"
            textAlign="left"
            bgColor="surfaceNeutralLowest"
          >
            <Text variation="c1">Minimum</Text>
          </Box>
          <Box
            as="th"
            position="sticky"
            paddingY="1.5"
            paddingX="2"
            style={{
              maxWidth: 120,
              width: 120,
            }}
            top="0"
            textAlign="left"
            bgColor="surfaceNeutralLowest"
          >
            <Text variation="c1">Maximum Amount</Text>
          </Box>
          <Box
            as="th"
            position="sticky"
            paddingY="1.5"
            paddingX="2"
            style={{
              width: 100,
            }}
            top="0"
            bgColor="surfaceNeutralLowest"
          >
            <Inline justifyContent="start">
              <Text variation="c1">Approval Flow</Text>
            </Inline>
          </Box>
        </Box>
      </Box>

      {status === 'in_progress' ? (
        <SkeletonRows numOfRows={10} numOfCols={5} />
      ) : (
        <Box as="tbody" bgColor={'surfaceDefault'}>
          {rulesExist
            ? rulesKeys.map((key) => {
                if (!rules) {
                  return null;
                }
                const ruleInfo: RuleInfo[] = rules[key];

                const { min_trigger_amount, max_trigger_amount, id } =
                  ruleInfo[0];

                return (
                  <Box
                    key={id}
                    as="tr"
                    borderTopWidth="1"
                    cursor="pointer"
                    tabIndex={-1}
                    bgColor={{
                      hover: 'surfacePrimaryLowest',
                    }}
                  >
                    <Box
                      as="td"
                      paddingX="2"
                      paddingY={'2'}
                      borderColor="borderSeparator"
                      borderBottomWidth={'1'}
                      valign="top"
                    >
                      <Amount
                        variation="b2"
                        amount={Number(min_trigger_amount)}
                      />
                    </Box>
                    <Box
                      as="td"
                      paddingX="2"
                      paddingY={'2'}
                      borderColor="borderSeparator"
                      borderBottomWidth={'1'}
                      valign="top"
                    >
                      {!!max_trigger_amount && (
                        <Amount
                          variation="b2"
                          amount={Number(max_trigger_amount)}
                        />
                      )}
                    </Box>
                    <Box
                      as="td"
                      paddingX="2"
                      paddingY={'2'}
                      borderColor="borderSeparator"
                      borderBottomWidth={'1'}
                    >
                      {ruleInfo.map((rule, index) => {
                        const isLastItem = ruleInfo.length - 1 === index;
                        return (
                          <Box key={rule.id}>
                            <Inline alignItems={'center'}>
                              <RoundedLevelIcon
                                color={'surfaceDisabledAction'}
                                size={'1'}
                                marginRight={'1'}
                              />
                              <Text variation="b2">
                                {rule.role.display_name}
                              </Text>
                            </Inline>
                            {!isLastItem && (
                              <Box
                                style={{
                                  height: 10,
                                  width: 2,
                                  marginLeft: 3,
                                }}
                                bgColor={'surfaceDisabledAction'}
                              />
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })
            : null}
        </Box>
      )}
    </Box>
  );
};
