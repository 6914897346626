import { Controller, Control } from 'react-hook-form';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  TextInput,
  TextInputProps,
  DatePickerInput,
  DatePickerProps,
} from '@nbfc-expense-tool/ui';

type FormTextInputProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  isControlled?: boolean;
} & TextInputProps;

export const FormTextInput: React.FC<FormTextInputProps> = ({
  control,
  name,
  isControlled = false,
  ...textInputProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={(props) => {
        const {
          field: { onChange, onBlur, value },
          fieldState: { error },
        } = props;
        return (
          <TextInput
            onChange={onChange}
            onBlur={onBlur}
            errorMessage={error?.message}
            value={
              isControlled && value !== undefined ? String(value) : undefined
            }
            {...textInputProps}
          />
        );
      }}
    />
  );
};

type FormDateInputProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
} & DatePickerProps;

export const FormDateInput: React.FC<FormDateInputProps> = ({
  control,
  name,
  ...restprops
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={(props) => {
        const {
          fieldState: { error },
          field: { value },
        } = props;
        return (
          <DatePickerInput
            errorMessage={error?.message}
            selectedDate={value ? new Date(value) : null}
            {...restprops}
          />
        );
      }}
    />
  );
};
