import {
  SingleExpenseItemFormValues,
  useAddOrEditReimbursement,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  BaseOption,
  Box,
  Button,
  Date as DateUI,
  DatePickerInput,
  DeleteIcon,
  EditIcon,
  EmptyState,
  FileIcon,
  FullScreenHeader,
  Inline,
  Modal,
  PlusIcon,
  SingleSelect,
  Stack,
  Text,
  TextArea,
  TextInput,
  Amount,
  UploadDocument,
  DocumentFileType,
} from '@nbfc-expense-tool/ui';
import React, { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';

const DOCUMENT_ATTACHMENTS_LIMIT = 4;

const idPrefix = 'add-or-edit-reimbursement';

const AddOrEditReimbursement: React.FC<{
  flowType?: 'add' | 'edit';
}> = ({ flowType = 'add' }) => {
  const {
    goBack,
    expenseTypeList,
    addOrEditReimbursementFormControl,
    onSaveReimbursement,
    addOrEditReimbursementApiLoading,
    expenseItemsList,
    singleExpenseItemFormControl,
    onSaveSingleExpenseItem,
    onDeleteSingleExpenseItem,
    addSingleExpenseItemModalState,
    onClickAddOrExpenseItem,
    onCloseAddExpenseItemModal,
    deletedDocumentIdsRef,
  } = useAddOrEditReimbursement(flowType);

  const renderBasicDetails = useMemo(() => {
    return (
      <Stack marginBottom="6">
        <Text variation="t3" marginBottom="3">
          Basic Details
        </Text>
        <Controller
          control={addOrEditReimbursementFormControl}
          name="title"
          render={(props) => {
            const {
              field: { onChange, onBlur, value },
              fieldState: { error },
            } = props;
            return (
              <TextInput
                id={`${idPrefix}-enter-title`}
                label={'Title'}
                isRequired
                placeholder="Reimbursement title"
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={error?.message}
                defaultValue={value !== undefined ? String(value) : undefined}
              />
            );
          }}
        />
      </Stack>
    );
  }, [addOrEditReimbursementFormControl]);

  const renderExpenseDetails = useMemo(() => {
    return (
      <Stack marginBottom="6">
        <Text variation="t3" marginBottom="3">
          Expense Details
        </Text>
        <Box as="table" width="full" position="relative">
          <Box as="thead" bgColor="surfaceNeutralLowest">
            <Box as="tr">
              <Box
                as="th"
                paddingY="1.5"
                paddingX="2"
                style={{
                  width: '40%',
                }}
                textAlign="left"
                bgColor="surfaceNeutralLowest"
              >
                <Text variation="c1">Expense Type & Description</Text>
              </Box>
              <Box
                as="th"
                paddingY="1.5"
                paddingX="2"
                style={{
                  width: '20%',
                }}
                textAlign="left"
                bgColor="surfaceNeutralLowest"
              >
                <Text variation="c1">Date</Text>
              </Box>
              <Box
                as="th"
                paddingY="1.5"
                paddingX="2"
                style={{
                  width: '20%',
                }}
                textAlign="right"
                bgColor="surfaceNeutralLowest"
              >
                <Text variation="c1">Total Amount</Text>
              </Box>
              <Box
                as="th"
                paddingY="1.5"
                paddingX="2"
                style={{
                  width: '20%',
                }}
                textAlign="right"
                bgColor="surfaceNeutralLowest"
              >
                <Text variation="c1">Actions</Text>
              </Box>
            </Box>
          </Box>
          <Box as="tbody">
            {expenseItemsList?.length ? (
              expenseItemsList.map(
                (
                  { expense_date, description, total_amount, expense_head_id },
                  index
                ) => {
                  return (
                    <React.Fragment key={index.toString()}>
                      <Box as="tr" borderTopWidth="1" tabIndex={-1}>
                        <Box
                          as="td"
                          paddingX="2"
                          paddingY="1.5"
                          textAlign="left"
                          borderBottomWidth="1"
                          borderColor="borderSeparator"
                        >
                          <Stack>
                            <Text variation="b2">
                              {
                                expenseTypeList[expense_head_id.toString()]
                                  ?.label
                              }
                            </Text>
                            <Text
                              variation="c2"
                              color="textLowest"
                              marginTop="0.5"
                            >
                              {description}
                            </Text>
                          </Stack>
                        </Box>
                        <Box
                          as="td"
                          paddingX="2"
                          paddingY="1.5"
                          borderBottomWidth="1"
                          borderColor="borderSeparator"
                        >
                          <DateUI variation="b2" date={expense_date} />
                        </Box>
                        <Box
                          as="td"
                          paddingX="2"
                          paddingY="1.5"
                          className="whitespace-pre"
                          textAlign="right"
                          borderBottomWidth="1"
                          borderColor="borderSeparator"
                        >
                          <Amount
                            variation="t4"
                            amount={Number(total_amount)}
                          />
                        </Box>
                        <Box
                          as="td"
                          paddingLeft="2"
                          paddingRight="3"
                          paddingY="1.5"
                          className="whitespace-pre"
                          textAlign="left"
                          borderBottomWidth="1"
                          borderColor="borderSeparator"
                        >
                          <Inline justifyContent="end">
                            <EditIcon
                              id={`${idPrefix}-edit-expense-button`}
                              color="iconPrimary"
                              size="3"
                              marginRight="3"
                              cursor="pointer"
                              onClick={() => onClickAddOrExpenseItem(index)}
                            />
                            <DeleteIcon
                              id={`${idPrefix}-delete-expense-button`}
                              color="iconError"
                              size="3"
                              cursor="pointer"
                              onClick={() => onDeleteSingleExpenseItem(index)}
                            />
                          </Inline>
                        </Box>
                      </Box>
                    </React.Fragment>
                  );
                }
              )
            ) : (
              <Box as="tr">
                <Box as="td" colSpan={8}>
                  <EmptyState
                    renderIcon={(props) => <FileIcon {...props} />}
                    title="No Expenses!"
                    subText={'Add expense to create reimbursement request'}
                    renderButton={() => (
                      <Button
                        id={`${idPrefix}-empty-state-add-expense-button`}
                        title="Add Expense"
                        type="outlined"
                        leftIcon={(props) => <PlusIcon {...props} />}
                        onClick={() => onClickAddOrExpenseItem()}
                      />
                    )}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {expenseItemsList?.length > 0 && (
          <Box display="flex" marginTop="3">
            <Button
              id={`${idPrefix}-add-new-expense-button`}
              title="Add New Expense"
              type="outlined"
              leftIcon={(props) => <PlusIcon {...props} />}
              onClick={() => onClickAddOrExpenseItem()}
            />
          </Box>
        )}
      </Stack>
    );
  }, [
    expenseItemsList,
    expenseTypeList,
    onClickAddOrExpenseItem,
    onDeleteSingleExpenseItem,
  ]);

  const renderDocumentDetails = useMemo(() => {
    return (
      <Stack marginBottom="6">
        <Text variation="t3" marginBottom="3">
          Documents
        </Text>
        <Controller
          control={addOrEditReimbursementFormControl}
          name="documents"
          render={(props) => {
            const {
              field: { onChange, value },
            } = props;
            const documents: (DocumentFileType & {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              fileBlobValue?: any;
              newDocument: boolean;
            })[] = value || [];
            return (
              <Stack>
                {documents.length > 0 ? (
                  <Inline
                    alignItems="center"
                    justifyContent="start"
                    gap="12"
                    overflowX="scroll"
                    marginBottom="4"
                  >
                    {documents.map((document, index) => {
                      return (
                        <>
                          <UploadDocument
                            id={`${idPrefix}-document${index}`}
                            key={`document${index}`}
                            type="secondary"
                            documentInfo={document}
                            showDownloadButton={false}
                            rightIcon={(props) => (
                              <DeleteIcon
                                id={`${idPrefix}-delete-document-button`}
                                {...props}
                                onClick={() => {
                                  if (document.id && flowType === 'edit') {
                                    deletedDocumentIdsRef.current.push(
                                      document.id
                                    );
                                  }
                                  const updatedDocuments = documents;
                                  updatedDocuments.splice(index, 1);
                                  onChange(updatedDocuments);
                                }}
                                color="iconError"
                                cursor="pointer"
                              />
                            )}
                          />
                          {index !== documents.length - 1 && (
                            <Box
                              height="7"
                              style={{
                                width: '2px',
                              }}
                              backgroundColor="borderSeparator"
                            />
                          )}
                        </>
                      );
                    })}
                  </Inline>
                ) : null}
                {documents.length < DOCUMENT_ATTACHMENTS_LIMIT && (
                  <Box display="flex" width="fitContent">
                    <UploadDocument
                      id={`${idPrefix}`}
                      type={documents.length > 0 ? 'addOnlyButton' : 'primary'}
                      key={'add-document'}
                      showDownloadButton={false}
                      onClickSelectDocument={(file, documentInfo) => {
                        const updatedDocuments = documents;
                        updatedDocuments.push({
                          fileBlobValue: file,
                          ...documentInfo,
                          newDocument: true,
                        });
                        onChange(updatedDocuments);
                      }}
                    />
                  </Box>
                )}
              </Stack>
            );
          }}
        />
      </Stack>
    );
  }, [addOrEditReimbursementFormControl, flowType, deletedDocumentIdsRef]);

  return (
    <Box height="fitContent">
      <FullScreenHeader
        headingText={
          flowType === 'edit' ? 'Edit Reimbursement' : 'Add Reimbursement'
        }
        onClickCross={goBack}
        borderBottomWidth="1"
        borderColor="borderSeparator"
        position="sticky"
        top="0"
        zIndex="50"
      />
      <Stack paddingX="5" paddingY="4" height="full">
        {renderBasicDetails}
        {renderExpenseDetails}
        {renderDocumentDetails}
      </Stack>
      <Box
        display="flex"
        justifyContent="end"
        backgroundColor="surfaceDefault"
        paddingY="2"
        paddingX="5"
        borderTopWidth="1"
        borderColor="borderSeparator"
        position="sticky"
        bottom="0"
        zIndex="50"
      >
        <Button
          id={`${idPrefix}-cancel-button`}
          title={'Cancel'}
          type="outlined"
          onClick={goBack}
          marginRight="3"
        />
        <Button
          id={`${idPrefix}-save-reimbursement-button`}
          title={'Save Reimbursement'}
          type="filled"
          state={
            addOrEditReimbursementApiLoading
              ? 'loading'
              : !expenseItemsList?.length
              ? 'disabled'
              : undefined
          }
          onClick={onSaveReimbursement}
        />
      </Box>
      <Modal
        isOpen={addSingleExpenseItemModalState.isOpen}
        title={'Add Expense'}
        placement="right"
        status={'success'}
        onClose={onCloseAddExpenseItemModal}
        size="lg"
      >
        <AddSingleExpenseItemModal
          singleExpenseItemFormControl={singleExpenseItemFormControl}
          expenseTypeList={Object.values(expenseTypeList)}
          onSaveSingleExpenseItem={() =>
            onSaveSingleExpenseItem(addSingleExpenseItemModalState.itemIndex)
          }
        />
      </Modal>
    </Box>
  );
};

const idPrefixForExpense = 'add-single-expense-for-reimbursement';

const AddSingleExpenseItemModal: React.FC<{
  singleExpenseItemFormControl: Control<SingleExpenseItemFormValues>;
  expenseTypeList: BaseOption[];
  onSaveSingleExpenseItem: () => void;
}> = ({
  singleExpenseItemFormControl,
  expenseTypeList,
  onSaveSingleExpenseItem,
}) => {
  return (
    <Box>
      <Stack paddingX="5" paddingY="4" height="screen">
        <Inline>
          <Stack flex="1">
            <Box marginBottom="6">
              <Controller
                control={singleExpenseItemFormControl}
                name="expense_head_id"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <SingleSelect
                      id={`${idPrefixForExpense}-select-expense-type`}
                      options={expenseTypeList}
                      onSave={(option) => {
                        onChange(parseInt(option.value, 10));
                      }}
                      label="Expense Type"
                      placeholder="Select Expense Type"
                      isRequired
                      errorMessage={error?.message}
                      value={
                        value
                          ? { label: value.toString(), value: value.toString() }
                          : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
            <Box marginBottom="6">
              <Controller
                control={singleExpenseItemFormControl}
                name="expense_date"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <DatePickerInput
                      id={`${idPrefixForExpense}-select-issue-date`}
                      errorMessage={error?.message}
                      selectedDate={value ? new Date(value) : null}
                      label="Expense Date"
                      isRequired
                      isControlled
                      fullWidth
                      datePickerProps={{
                        placeholderText: 'Select Issue Date',
                        maxDate: new Date(),
                      }}
                      onChangeDate={(date) => {
                        onChange(date?.toISOString());
                      }}
                    />
                  );
                }}
              />
            </Box>
            <Box marginBottom="6">
              <Controller
                control={singleExpenseItemFormControl}
                name="total_amount"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefixForExpense}-add-amount`}
                      label={'Amount'}
                      isRequired
                      fullWidth
                      placeholder="Add Amount"
                      onChange={onChange}
                      errorMessage={error?.message}
                      value={value !== undefined ? String(value) : undefined}
                      removeMinWidth
                    />
                  );
                }}
              />
            </Box>
            <Box marginBottom="6">
              <Controller
                control={singleExpenseItemFormControl}
                name="description"
                render={(props) => {
                  const {
                    field: { onChange, value },
                  } = props;
                  return (
                    <TextArea
                      id={`${idPrefixForExpense}-enter-description`}
                      label="Description"
                      placeholder="Add additional item details"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  );
                }}
              />
            </Box>
          </Stack>
          <Box
            style={{
              flex: 2,
            }}
          />
        </Inline>
      </Stack>
      <Box
        display="flex"
        justifyContent="end"
        backgroundColor="surfaceDefault"
        paddingY="2"
        paddingX="5"
        borderTopWidth="1"
        borderColor="borderSeparator"
        position="sticky"
        bottom="0"
        zIndex="50"
      >
        <Button
          id={`${idPrefixForExpense}-save-expense-button`}
          title={'Save Expense'}
          type="filled"
          onClick={onSaveSingleExpenseItem}
        />
      </Box>
    </Box>
  );
};

export default AddOrEditReimbursement;
