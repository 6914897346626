import { RuleInfo, useRules } from '@nbfc-expense-tool/data-store/dashboard';
import {
  Box,
  Text,
  Inline,
  Amount,
  RoundedLevelIcon,
  SpinnerIcon,
} from '@nbfc-expense-tool/ui';
import { useEffect, useState } from 'react';

type ReimbursementKeyTypes =
  | 'Branch Analyst'
  | 'Branch Manager'
  | 'State Head'
  | 'Department Head'
  | 'Business Head';

type ReimbursementRuleType = {
  [key: string]: {
    [key: string]: RuleInfo[];
  };
};

const idPrefix = 'reimbursement-rules';

export const ReimbursementRules = () => {
  const { rules, status } = useRules<ReimbursementRuleType>('reimbursement');
  const [selectedRule, setSelectedRule] = useState<undefined | string>(
    undefined
  );

  useEffect(() => {
    if (rules) {
      const firstTab = Object.keys(rules)[0];
      setSelectedRule(firstTab);
    }
  }, [rules]);

  if (status === 'in_progress') {
    return (
      <Inline gap="4" justifyContent={'center'} marginTop={'2'}>
        <SpinnerIcon size="3" color="iconMedium" />
        <Text variation="b2">Loading...</Text>
      </Inline>
    );
  } else if (status === 'failed') {
    return <Text>Failed to load rules</Text>;
  }
  if (rules) {
    const ruleInfo = rules[selectedRule || 'State Head'];

    return (
      <Box>
        <Inline gap={'4'} marginBottom={'4'}>
          {Object.keys(rules).map((key) => {
            const roleName = key;
            if (roleName) {
              return (
                <Chip
                  title={roleName}
                  isSelected={key === selectedRule}
                  onClick={() => setSelectedRule(key as ReimbursementKeyTypes)}
                />
              );
            }
            return null;
          })}
        </Inline>
        {!!ruleInfo &&
          (() => {
            return (
              <Box
                as="table"
                width="full"
                position="relative"
                marginBottom={'3'}
                rounded="md"
                borderWidth="1"
                borderColor="borderSeparator"
              >
                <Box as="thead" bgColor="surfaceNeutralLowest">
                  <Box as="tr">
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 120,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">Minimum</Text>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        maxWidth: 120,
                        width: 120,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">Maximum Amount</Text>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 100,
                      }}
                      top="0"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Inline justifyContent="start">
                        <Text variation="c1">Approval Flow</Text>
                      </Inline>
                    </Box>
                  </Box>
                </Box>

                <Box as="tbody" bgColor={'surfaceDefault'}>
                  {(() => {
                    return Object.keys(ruleInfo).map((ruleKey) => {
                      const rule = ruleInfo[ruleKey];
                      const { max_trigger_amount, min_trigger_amount } =
                        rule[0];
                      return (
                        <Box
                          as="tr"
                          borderTopWidth="1"
                          cursor="pointer"
                          tabIndex={-1}
                          bgColor={{
                            hover: 'surfacePrimaryLowest',
                          }}
                        >
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY={'2'}
                            borderColor="borderSeparator"
                            borderBottomWidth={'1'}
                            valign="top"
                          >
                            <Amount
                              variation="b2"
                              amount={Number(min_trigger_amount)}
                            />
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY={'2'}
                            borderColor="borderSeparator"
                            borderBottomWidth={'1'}
                            valign="top"
                          >
                            {!!max_trigger_amount && (
                              <Amount
                                variation="b2"
                                amount={Number(max_trigger_amount)}
                              />
                            )}
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY={'2'}
                            borderColor="borderSeparator"
                            borderBottomWidth={'1'}
                          >
                            {rule.map((ruleStage, index) => {
                              const isLastItem = rule.length - 1 === index;

                              return (
                                <Box key={ruleStage.id}>
                                  <Inline alignItems={'center'}>
                                    <RoundedLevelIcon
                                      color={'surfaceDisabledAction'}
                                      size={'1'}
                                      marginRight={'1'}
                                    />
                                    <Text variation="b2">
                                      {ruleStage.role.display_name}
                                    </Text>
                                  </Inline>
                                  {!isLastItem && (
                                    <Box
                                      style={{
                                        height: 10,
                                        width: 2,
                                        marginLeft: 3,
                                      }}
                                      bgColor={'surfaceDisabledAction'}
                                    />
                                  )}
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      );
                    });
                  })()}
                </Box>
              </Box>
            );
          })()}
      </Box>
    );
  }
};

const Chip = ({
  title,
  isSelected,
  onClick,
}: {
  title: string;
  isSelected: boolean;
  onClick?: () => void;
}) => {
  return (
    <Box
      id={`${idPrefix}-${title}-chip`}
      onClick={onClick}
      cursor="pointer"
      borderWidth={'1'}
      borderColor={isSelected ? 'borderPrimaryLow' : 'borderSeparator'}
      rounded="full"
      bgColor={isSelected ? 'surfacePrimaryLowest' : 'surfaceDefault'}
    >
      <Text
        variation={'c2'}
        paddingX={'2'}
        paddingY={'1'}
        color={isSelected ? 'textPrimary' : 'textHigh'}
      >
        {title}
      </Text>
    </Box>
  );
};
