import {
  ArrowDownFilledIcon,
  Avatar,
  Box,
  ClipboardIcon,
  IconProps,
  Inline,
  LogoutIcon,
  Menu,
  MenuAction,
  MenuIcon,
  MenuItem,
  MenuList,
  PanelNavItem,
  Stack,
  Text,
  MapPinIcon,
  EmployeesIcon,
  UserCheckIcon,
  HomeIcon,
  LockIcon,
  PocketIcon,
  AppLogo,
  BellIcon,
  Button,
} from '@nbfc-expense-tool/ui';
import { useLogout, useUser } from '@nbfc-expense-tool/data-store/auth';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import config from '../config';
import { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import {
  isSidebarExpanded,
  tenantConfig,
  userDetails,
} from '@nbfc-expense-tool/data-store/jotai-store';
import { useSetAtom, useAtomValue } from 'jotai';
import { checkForModuleAccess } from '@nbfc-expense-tool/data-store/permissions';
import {
  TENANT_CONFIG_KEYS,
  getNotifications,
  getProfile,
  markNotificationsAsRead,
} from '@nbfc-expense-tool/data-store/utils';
import { setSyncStoredItem } from '@nbfc-expense-tool/data-store/storage';
import {
  NOTIFICATION_TYPES,
  NotificationPayload,
  getNotificationText,
} from '@nbfc-expense-tool/data-store/notifications';

export function DashboardLayout() {
  const setUserDetails = useSetAtom(userDetails);

  useEffect(() => {
    getProfile()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        setUserDetails(res.data);
        setSyncStoredItem('user', res.data);
      })
      .catch((err) => null);
  }, [setUserDetails]);

  return (
    <Stack minHeight="screen" minWidth="screenMd">
      <Header />
      <Inline flex="1">
        <Outlet />
      </Inline>
    </Stack>
  );
}

export const getNotificationIcon = (
  notificationType: keyof typeof NOTIFICATION_TYPES
) => {
  if (notificationType.includes('Invoice')) {
    return ClipboardIcon;
  }
  if (notificationType.includes('Vendor')) {
    return UserCheckIcon;
  }
  return PocketIcon;
};

type NotificationType = {
  icon: (props: IconProps) => React.ReactElement;
  notificationTitle: string;
} & NotificationPayload;

function Header() {
  const { user } = useUser();
  const logout = useLogout();
  const navigate = useNavigate();
  const setIsSidebarExpanded = useSetAtom(isSidebarExpanded);
  const { pathname } = useLocation();
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [callNotificationsApi, setCallNotifictionsApi] = useState(false);
  const currentPageRef = useRef<number>(1);
  const observer = useRef<IntersectionObserver>();

  const getNewNotifications = useCallback(() => {
    getNotifications({ page: currentPageRef.current })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        const notifications: NotificationType[] = res.data;
        notifications.forEach((notification: NotificationType) => {
          notification['notificationTitle'] = getNotificationText(
            notification.type,
            notification.data
          );
          notification['icon'] = getNotificationIcon(notification.type);
        });
        if (currentPageRef.current === 1) {
          setNotifications(notifications);
        } else {
          setNotifications((prevState) => {
            return [...prevState, ...notifications];
          });
        }
        setTotalNotifications(res.total);
      })
      .catch((err) => null);
  }, []);

  useEffect(() => {
    if (callNotificationsApi) {
      getNewNotifications();
      setCallNotifictionsApi(false);
    }
  }, [callNotificationsApi, getNewNotifications]);

  useEffect(() => {
    if (pathname) {
      currentPageRef.current = 1;
      getNewNotifications();
    }
  }, [getNewNotifications, pathname]);

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          notifications.length < totalNotifications
        ) {
          currentPageRef.current = currentPageRef.current + 1;
          setCallNotifictionsApi(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [notifications.length, totalNotifications]
  );

  async function logoutHandler() {
    await logout();
    navigate('/auth/login');
  }

  return (
    <Inline
      alignItems="center"
      justifyContent="between"
      borderBottomWidth="1"
      borderColor="borderOutline"
      display={'flex'}
    >
      <Inline gap="3">
        <MenuIcon
          paddingLeft={'2'}
          size="3"
          cursor="pointer"
          color="iconMedium"
          id="toggle-app-side-drawer"
          alignSelf={'center'}
          onClick={() => setIsSidebarExpanded((prev) => !prev)}
        />
        <AppLogo width="16.5" height="3.5" />
      </Inline>
      <Inline>
        <Inline marginRight="2.5" alignItems="center">
          <Menu>
            <MenuAction height={'fitContent'}>
              <Inline position="relative">
                {totalNotifications ? (
                  <Inline
                    height="2.5"
                    width="2.5"
                    backgroundColor="surfaceError"
                    rounded="full"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    left="4"
                    bottom="4"
                  >
                    <Text variation="c4" color="textOnSurface">
                      {totalNotifications}
                    </Text>
                  </Inline>
                ) : null}
                <BellIcon color="iconMedium" height="4" width="4" />
              </Inline>
            </MenuAction>
            <MenuList
              alignRight
              style={{
                top: '3rem',
                right: '1rem',
              }}
            >
              <MenuItem action="profile">
                <Inline
                  gap="3"
                  style={{
                    width: 300,
                  }}
                >
                  <Stack display="flex" width="full">
                    <Text variation="t1" color={'textHigh'} marginBottom="1">
                      Notifications
                    </Text>
                    <Box
                      style={{ width: 120 }}
                      rounded="sm"
                      backgroundColor="surfacePrimary"
                      height="0.5"
                    />
                    <Box
                      style={{ maxHeight: 300, minHeight: 100 }}
                      overflowY="scroll"
                      width="full"
                    >
                      {notifications.length > 0 ? (
                        notifications.map((notification, i) => {
                          return (
                            <Inline
                              ref={
                                notifications.length === i + 1
                                  ? lastElementRef
                                  : null
                              }
                              alignItems="start"
                              borderBottomWidth="1"
                              paddingY="2"
                              borderColor="borderSeparator"
                              cursor="pointer"
                              onClick={() => {
                                markNotificationsAsRead({
                                  notifications: [notification.id],
                                })
                                  .then(() => null)
                                  .catch(() => null)
                                  .finally(() => {
                                    if (notification.type.includes('Invoice')) {
                                      navigate(
                                        `/home/invoices/${notification.data?.ticket_number}`
                                      );
                                    } else if (
                                      notification.type.includes('Vendor')
                                    ) {
                                      navigate(
                                        `/home/vendors/${
                                          notification.data?.id
                                        }${
                                          notification.type ===
                                          NOTIFICATION_TYPES.VENDOR_WAITING_APPROVAL
                                            ? '/vendor-profile'
                                            : ''
                                        }`
                                      );
                                    } else {
                                      navigate(
                                        `/home/reimbursements/${notification.data?.ticket_number}`
                                      );
                                    }
                                  });
                              }}
                            >
                              <Inline
                                rounded="full"
                                cursor="pointer"
                                backgroundColor="surfacePrimaryLowest"
                                padding="1"
                              >
                                <notification.icon
                                  height="3"
                                  width="3"
                                  color="iconMedium"
                                />
                              </Inline>
                              <Text marginLeft="2" variation="c1">
                                {notification.notificationTitle}
                              </Text>
                            </Inline>
                          );
                        })
                      ) : (
                        <Inline
                          justifyContent="center"
                          alignItems="center"
                          width="full"
                          height="full"
                        >
                          <Text
                            textAlign="center"
                            variation="c1"
                            color="textMedium"
                          >
                            No new notifications
                          </Text>
                        </Inline>
                      )}
                    </Box>
                    {notifications.length > 0 ? (
                      <Button
                        title="Mark all as read"
                        type="text"
                        marginTop="1"
                        onClick={() => {
                          setNotifications([]);
                          setTotalNotifications(0);
                          markNotificationsAsRead();
                        }}
                      />
                    ) : null}
                  </Stack>
                </Inline>
              </MenuItem>
            </MenuList>
          </Menu>
        </Inline>
        <Inline
          gap="5"
          display={'flex'}
          bgColor={{
            default: undefined,
            hover: 'surfacePrimaryLowest',
          }}
          paddingY="1.5"
          borderBottomWidth="2"
          borderColor={{
            default: 'transparent',
            hover: 'borderPrimary',
          }}
        >
          <Menu>
            <MenuAction height={'fitContent'}>
              <Inline gap="2" paddingLeft={'3'} paddingRight={'2'}>
                {user?.avatar?.preview?.length ? (
                  <Avatar
                    avatarSize="md"
                    as="img"
                    src={user?.avatar.preview || ''}
                  />
                ) : (
                  <Avatar
                    avatarSize="md"
                    avatarText={user?.name[0]}
                    id={user?.id.toString()}
                    title={user?.name}
                  />
                )}
                <Text variation="t4" color="textMedium">
                  {user?.name}
                </Text>
                <ArrowDownFilledIcon size="3" color="iconMedium" />
              </Inline>
            </MenuAction>
            <MenuList
              alignRight
              style={{
                top: '3rem',
                right: '1rem',
              }}
            >
              <MenuItem
                action="profile"
                onClick={() => {
                  navigate('/profile-details');
                }}
              >
                <Inline
                  gap="3"
                  style={{
                    width: 300,
                  }}
                >
                  {user?.avatar?.preview?.length ? (
                    <Avatar
                      avatarSize="lg"
                      as="img"
                      src={user?.avatar.preview || ''}
                    />
                  ) : (
                    <Avatar
                      avatarSize="lg"
                      avatarText={user?.name[0]}
                      id={user?.id.toString()}
                      title={user?.name}
                    />
                  )}
                  <Box>
                    <Text variation="t3" color={'textHigh'}>
                      {user?.name}
                    </Text>
                    <Text
                      variation="c1"
                      color={'textHigh'}
                      marginTop={'1'}
                      marginBottom={'0.5'}
                    >
                      {user?.roles.map((role) => role.display_name).join(', ')}
                    </Text>
                    <Text
                      variation="c2"
                      color={'textMedium'}
                      marginTop={'1'}
                      marginBottom={'0.5'}
                    >
                      {user?.email}
                    </Text>
                  </Box>
                </Inline>
              </MenuItem>
              <Box borderWidth="1" borderColor="borderSeparator" />
              <MenuItem id="logout" action="logout" onClick={logoutHandler}>
                <Inline gap="2">
                  <LogoutIcon size="3" />
                  <Text variation="b2">Logout</Text>
                </Inline>
              </MenuItem>
            </MenuList>
          </Menu>
        </Inline>
      </Inline>
    </Inline>
  );
}

export function PanelLayout({ children }: { children?: React.ReactNode }) {
  const maxHeight = 'calc(100vh - 52px)';
  return (
    <Stack
      flex="1"
      marginX="auto"
      minWidth="screenMd"
      style={{ minHeight: `calc(100vh - 60px)` }}
    >
      <Inline flex="1" width="full" height="full" maxWidth="full">
        <Box as="aside" position="relative" zIndex="10">
          <PanelSidebar maxHeight={maxHeight} />
        </Box>
        <Stack
          as="main"
          flex="1"
          maxWidth="full"
          overflow="auto"
          height="full"
          backgroundColor="backgroundGray"
        >
          <Box
            flex="1"
            overflow="auto"
            style={{
              maxHeight,
            }}
          >
            {children || <Outlet />}
          </Box>
        </Stack>
      </Inline>
    </Stack>
  );
}

function PanelSidebar({ maxHeight }: { maxHeight?: string }) {
  const collapsed = useAtomValue(isSidebarExpanded);
  const tenantFeatureConfig = useAtomValue(tenantConfig);
  const { pathname } = useLocation();
  const { user } = useUser();

  const navBarOptions = useMemo(() => {
    const {
      dashboard,
      invoices,
      vendors,
      branches,
      employees,
      rules_policies,
      reimbursement,
    } = checkForModuleAccess(user?.roles[0]?.permissions);
    const options: NavigationOptionType[] = [];
    // We will need to add this code later thats why adding it
    if (dashboard) {
      options.push({
        icon: (props) => <HomeIcon {...props} />,
        title: 'Dashboard',
        path: 'dashboard',
      });
    }
    if (invoices) {
      options.push({
        icon: (props) => <ClipboardIcon {...props} />,
        title: 'Invoices',
        path: 'invoices',
      });
    }
    if (vendors) {
      options.push({
        icon: (props) => <UserCheckIcon {...props} />,
        title: 'Vendors',
        path: 'vendors',
      });
    }
    if (
      reimbursement &&
      tenantFeatureConfig &&
      tenantFeatureConfig[TENANT_CONFIG_KEYS.REIMBURSEMENT_FEATURE_ENABLED]
        ?.value
    ) {
      options.push({
        icon: (props) => <PocketIcon {...props} />,
        title: 'Reimbursement',
        path: 'reimbursements',
      });
    }
    if (branches) {
      options.push({
        icon: (props) => <MapPinIcon {...props} />,
        title: 'Branches',
        path: 'branches',
      });
    }
    if (employees) {
      options.push({
        icon: (props) => <EmployeesIcon {...props} />,
        title: 'Employees',
        path: 'employees',
      });
    }
    if (rules_policies) {
      options.push({
        icon: (props) => <LockIcon {...props} />,
        title: 'Rules & Policies',
        path: 'rules-policies',
      });
    }

    return options;
  }, [tenantFeatureConfig, user?.roles]);

  const active = useMemo(() => {
    return navBarOptions.find((option) => pathname.includes(`/${option.path}`));
  }, [navBarOptions, pathname]);

  return (
    <Box
      position="relative"
      overflow="auto"
      paddingTop="3"
      paddingX="1.5"
      backgroundColor="surfaceDefault"
      flex="1"
      style={{
        width: !collapsed ? 64 : 244,
        maxHeight: maxHeight,
        transition: 'all 0.3s',
      }}
    >
      <Stack gap="3">
        {navBarOptions.map((option) => (
          <PanelNavItem
            key={option.path}
            path={option.path}
            itemName={option.title}
            leftIcon={option.icon}
            expended={collapsed}
            isSelected={active?.path === option.path}
            topSaperator={option.path === 'rules-policies'}
          />
        ))}
      </Stack>
      {collapsed && (
        <Box position={'fixed'} bottom={'0'} paddingY={'2'}>
          <Text textAlign={'left'} variation="c2" color="textMedium">
            Version Name: {config.appVersion}
          </Text>
        </Box>
      )}
    </Box>
  );
}

type NavigationOptionType = {
  icon: (props: IconProps) => React.ReactElement;
  title: string;
  path: string;
};
