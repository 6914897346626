import { parsePhoneNumber } from 'libphonenumber-js';
import { z } from 'zod';

export const phoneValidationSchema = z.string().refine(
  (value) => {
    const phoneNumber = parsePhoneNumber(value, 'IN');
    return phoneNumber ? phoneNumber.isValid() : false;
  },
  {
    message: 'Invalid phone number',
  }
);
