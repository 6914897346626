import { callApiService } from './api';
import { API_END_POINTS } from './endpoints';

type LoginPayload = {
  email: string;
  password: string;
};

type ApiConfigOptions = {
  timeout?: number;
  headers?: Record<string, string | number | boolean>;
  apiVersion?: 'v1';
  inQueryParams?: string[];
  responseType?:
    | 'arraybuffer'
    | 'blob'
    | 'document'
    | 'json'
    | 'text'
    | 'stream';
  ignoredStatusCodesForReporting?: number[];
  isAuthenticatedRequest?: boolean;
};

export function login<T>(
  payload: LoginPayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(API_END_POINTS.LOGIN, 'POST', {
    payload,
    ...options,
  });
}

export type EmployeesPayload = {
  page?: number;
  branch_id?: string[];
  role_name?: string[];
  q?: string;
};

export type InvoicesPayload = {
  page?: number;
  invoice_date_before?: string;
  invoice_date_after?: string;
  branch_id?: string[];
  vendor_id?: string[];
  invoice_status?: string;
  payment_status?: string;
  q?: string;
};

export const getBranchListing = (options: ApiConfigOptions = {}) => {
  return callApiService(API_END_POINTS.BRANCHES_LIST_FILTER, 'GET', {
    ...options,
  });
};

export const getRolesListing = <T>(
  options: ApiConfigOptions = {}
): Promise<T> => {
  return callApiService(API_END_POINTS.ROLES_LIST, 'GET', {
    ...options,
  });
};

export function getEmployeesListing<T>(
  payload: EmployeesPayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(API_END_POINTS.LIST_EMPLOYEES, 'GET', {
    payload,
    ...options,
  });
}

export function getEmployeesReport<T>(
  payload: EmployeesPayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  const updatedPayload = {
    ...payload,
    export: 1,
  };

  return callApiService(API_END_POINTS.LIST_EMPLOYEES, 'GET', {
    payload: updatedPayload,
    inQueryParams: ['export'],
    ...options,
  });
}

export type VendorsListingRequestPayload = {
  branch_id?: string[];
};

export const getVendorsListing = (
  payload: VendorsListingRequestPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.VENDORS_LIST, 'GET', {
    payload,
    ...options,
  });
};

type InvoiceItemPayload = {
  type: 'product' | 'service';
  name: string;
  description?: string;
  hsn_code?: string;
  sac_code?: string;
  unit_type: string;
  unit_number: number;
  unit_price: number;
  total_price: number;
  sgst_percent: number;
  cgst_percent: number;
  igst_percent: number;
  gst_amount: number;
  total_amount: number;
  expense_head_id: number;
  expense_particular_id: number;
  tds_id?: number;
  gst_id?: number;
};

export type CreateInvoicePaylod = {
  invoice_number: string;
  invoice_date: string;
  due_date: string;
  irn_number?: string | null;
  remarks?: string | null;
  terms?: string | null;
  vendor_id: number;
  branch_id: number;
  gst_amount: number;
  sub_total_amount: number;
  grand_total_amount: number;
  document_upload_ids?: string[];
  items: InvoiceItemPayload[];
  tds_amount: number;
};

export const createNewInvoice = (
  payload: CreateInvoicePaylod,
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.INVOICES, 'POST', {
    payload,
    ...options,
  });
};

export const uploadMediaToTemp = (
  payload: FormData,
  options: ApiConfigOptions = {
    headers: { 'Content-Type': 'multipart/form-data' },
  }
) => {
  return callApiService(API_END_POINTS.UPLOAD_MEDIA, 'POST', {
    payload,
    ...options,
  });
};

export type GetHsnOrSacCodePayload = {
  q?: string;
};

export const getHsnCodesList = (
  payload: GetHsnOrSacCodePayload = {},
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.HSN_CODES_LIST, 'GET', {
    payload,
    ...options,
  });
};

export const getSacCodesList = (
  payload: GetHsnOrSacCodePayload = {},
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.SAC_CODES_LIST, 'GET', {
    payload,
    ...options,
  });
};

export const getExpenseHeadList = (options: ApiConfigOptions = {}) => {
  return callApiService(API_END_POINTS.EXPENSE_HEAD_LIST, 'GET', {
    ...options,
  });
};

type GetGstPayload = {
  vendor_id: number;
  branch_id: number;
  expense_head_id?: number;
  expense_particular_id?: number;
  gst_id?: number;
  tds_id?: number;
};

export const getGst = (
  payload: GetGstPayload,
  options: ApiConfigOptions = {
    inQueryParams: [
      'vendor_id',
      'branch_id',
      'expense_head_id',
      'expense_particular_id',
      'gst_id',
      'tds_id',
    ],
  }
) => {
  return callApiService(API_END_POINTS.TAX_CALCULATOR, 'GET', {
    payload,
    ...options,
  });
};

export function ownInvoiceList<T>(
  payload: InvoicesPayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(API_END_POINTS.INVOICES, 'GET', {
    payload,
    ...options,
  });
}

export function getInvoiceListReport<T>(
  fetchFor: 'created-by-you' | 'waiting-for-your-approval' | 'all-invoices',
  payload: InvoicesPayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  const updatedPayload = {
    ...payload,
    export: 1,
  };

  const endPoint =
    fetchFor === 'created-by-you'
      ? API_END_POINTS.INVOICES
      : fetchFor === 'all-invoices'
      ? API_END_POINTS.ALL_INVOICES_LIST
      : API_END_POINTS.INVOICES_AWAITED_FOR_APPROVAL;

  return callApiService(endPoint, 'GET', {
    payload: updatedPayload,
    inQueryParams: ['export'],
    ...options,
  });
}

export function branchListForFilters<T>(
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(API_END_POINTS.BRANCHES_LIST_FILTER, 'GET', {
    ...options,
  });
}

export function vendorListForFilters<T>(
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(API_END_POINTS.VENDORS_LIST, 'GET', {
    ...options,
  });
}

export function waitingForApprovalInvoiceList<T>(
  payload: InvoicesPayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(API_END_POINTS.INVOICES_AWAITED_FOR_APPROVAL, 'GET', {
    payload,
    ...options,
  });
}

export function invoiceDetails<T>(
  ticketNumber: string,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(
    API_END_POINTS.INVOICE_DETAILS.replace(
      ':invoiceTicketNumber',
      ticketNumber
    ),
    'GET',
    {
      ...options,
    }
  );
}

export function vendorDetails<T>(
  vendorId: string,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(
    API_END_POINTS.GET_PAGINATED_VENDOR_LISTING + `/${vendorId}`,
    'GET',
    {
      ...options,
    }
  );
}

type ActionOnInvoicePayload = {
  remarks?: string;
};

export function approveInvoice<T>(
  ticketNumber: string,
  payload: ActionOnInvoicePayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(
    API_END_POINTS.APPROVE_INVOICE.replace(
      ':invoiceTicketNumber',
      ticketNumber
    ),
    'POST',
    {
      payload,
      ...options,
    }
  );
}

export function rejectInvoice<T>(
  ticketNumber: string,
  payload: ActionOnInvoicePayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(
    API_END_POINTS.REJECT_INVOICE.replace(':invoiceTicketNumber', ticketNumber),
    'POST',
    {
      payload,
      ...options,
    }
  );
}

export function returnInvoice<T>(
  ticketNumber: string,
  payload: ActionOnInvoicePayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(
    API_END_POINTS.RETURN_INVOICE.replace(':invoiceTicketNumber', ticketNumber),
    'POST',
    {
      payload,
      ...options,
    }
  );
}

type MarkPaidOnInvoicePayload = {
  utr_number?: string;
};

export function markPaidInvoice<T>(
  ticketNumber: string,
  payload: MarkPaidOnInvoicePayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(
    API_END_POINTS.MARK_PAID_INVOICE.replace(
      ':invoiceTicketNumber',
      ticketNumber
    ),
    'POST',
    {
      payload,
      ...options,
    }
  );
}

export function deleteInvoice<T>(
  ticketNumber: string,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(
    API_END_POINTS.DELETE_INVOICE.replace(':invoiceTicketNumber', ticketNumber),
    'DELETE',
    {
      ...options,
    }
  );
}

export type BranchListPayload = {
  page?: number;
  address_state?: string[];
  business_by?: string[];
  rent_by?: string[];
  parent_branch_id?: string[];
  branch_level?: number;
  q?: string;
};

export function branchList<T>(
  payload: BranchListPayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(API_END_POINTS.BRANCH_LIST, 'GET', {
    payload,
    ...options,
  });
}

export function branchListReport<T>(
  payload: BranchListPayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  const updatedPayload = {
    ...payload,
    export: 1,
  };

  return callApiService(API_END_POINTS.BRANCH_LIST, 'GET', {
    payload: updatedPayload,
    inQueryParams: ['export'],
    ...options,
  });
}

export function businessByForFilters<T>(
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(API_END_POINTS.BUSINESS_BY_FILTERS, 'GET', {
    ...options,
  });
}

export function rentByForFilters<T>(
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(API_END_POINTS.BUSINESS_BY_FILTERS, 'GET', {
    ...options,
  });
}

export type AddNewBranchPayload = {
  name: string;
  branch_code: string;
  phone_number: string;
  branch_email: string | null;
  gst_number: string | null;
  branch_level: number | null;
  business_by: string;
  rent_by: string;
  address_line_1: string;
  address_line_2: string;
  address_city: string;
  address_pincode: string;
  address_state: string;
  parent_branch_id: number | null;
};

export function addNewBranch<T>(
  payload: AddNewBranchPayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(API_END_POINTS.BRANCH_LIST, 'POST', {
    payload,
    ...options,
  });
}

export function allInvoicesList<T>(
  payload: InvoicesPayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(API_END_POINTS.ALL_INVOICES_LIST, 'GET', {
    payload,
    ...options,
  });
}

export function logout<T>(options: ApiConfigOptions = {}): Promise<T> {
  return callApiService(API_END_POINTS.LOGOUT, 'POST', {
    ...options,
  });
}

export type AddEmployeePayload = {
  employee_code: string;
  name: string;
  email: string;
  phone_number: string;
  can_login: boolean;
  role: string;
  branches: number[];
  org_joined_at?: string;
  org_left_at?: string;
};

export const addEmployee = (
  payload: AddEmployeePayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.ADD_EMPLOYEE, 'POST', {
    payload,
    ...options,
  });
};
export const getProfile = (options: ApiConfigOptions = {}) => {
  return callApiService(API_END_POINTS.GET_PROFILE, 'GET', {
    ...options,
  });
};

export const updateEmployee = (
  employeeId: number,
  payload: AddEmployeePayload,
  options: ApiConfigOptions = {}
) => {
  const endPoint = `${API_END_POINTS.ADD_EMPLOYEE}/${employeeId}`;

  return callApiService(endPoint, 'PATCH', {
    payload,
    ...options,
  });
};

export type CreateOrUpdateVendorPayload = {
  name: string;
  legal_name: string;
  vendor_type_id: number;
  company_reg_type: string;
  is_einvoicing_enabled: boolean;
  pan_number: string;
  tan_number: string;
  cin_number: string;
  is_dpiit_certified: boolean;
  dpiit_cert_number: string;
  is_gst_registered: boolean;
  gst_number: string;
  is_msme_registered: boolean;
  msme_reg_number: string;
  office_status: 'rented' | 'owned';
  contact_person_details: {
    name: string;
    designation: string;
    phone_number: string;
    mobile_number: string;
    email: string;
  };
  applicant_details: {
    name: string;
    designation: string;
    phone_number: string;
    mobile_number: string;
    email: string;
  };
  registered_address: {
    address_line_1: string;
    address_line_2: string;
    city: string;
    pincode: string;
    state: string;
    country: string;
    phone_number?: string;
    email?: string;
  };
  business_address: {
    address_line_1: string;
    address_line_2: string;
    city: string;
    pincode: string;
    state: string;
    country: string;
    phone_number?: string;
    email?: string;
  };
  payment_details: {
    account_holder_name: string;
    account_number: string;
    account_type: 'saving' | 'current';
    ifsc_code: string;
    bank_name: string;
    branch_name: string;
    micr: string;
    swift_code: string;
  };
  social_links: {
    website: string | null;
    facebook: string | null;
    instagram: string | null;
    linkedin: string | null;
    twitter: string | null;
    others: string | null;
  };
  branch_ids: number[];
  address_proof_upload_id?: number;
  cancelled_cheque_upload_id: number;
  company_reg_cert_upload_id: number;
  gst_certificate_upload_id?: number;
  msme_certificate_upload_id?: number;
  dpiit_certificate_upload_id?: number;
};

export const createNewVendor = (
  payload: CreateOrUpdateVendorPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.CREATE_VENDOR, 'POST', {
    payload,
    ...options,
  });
};

export const updateVendor = (
  vendorId: number,
  payload: Partial<CreateOrUpdateVendorPayload>,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.UPDATE_VENDOR.replace(':vendorId', vendorId.toString()),
    'PATCH',
    {
      payload,
      ...options,
    }
  );
};

export const deactivateVendorApi = (
  vendorId: number,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.DEACTIVATE_VENDOR.replace(':vendorId', vendorId.toString()),
    'POST',
    {
      ...options,
    }
  );
};

export const reactivateVendorApi = (
  vendorId: number,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.REACTIVATE_VENDOR.replace(':vendorId', vendorId.toString()),
    'POST',
    {
      ...options,
    }
  );
};

export const getVendorDetails = (
  vendorId: string,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.GET_VENDOR_DETAILS.replace(':vendorId', vendorId),
    'GET',
    {
      ...options,
    }
  );
};

export type TypeInvoicesStatPayload = {
  type?: 'my' | 'all';
  branch_id?: string[];
  invoice_date_before?: string;
  invoice_date_after?: string;
  vendor_id?: string[];
  q?: string;
};

export const getTypedInvoicesStat = <T>(
  payload: TypeInvoicesStatPayload,
  options: ApiConfigOptions = { inQueryParams: ['type'] }
): Promise<T> => {
  return callApiService(API_END_POINTS.GET_TYPED_INVOICES_STAT, 'GET', {
    payload,
    ...options,
  });
};

export const getInvoicesPaymentStat = <T>(
  payload: TypeInvoicesStatPayload,
  options: ApiConfigOptions = { inQueryParams: ['type'] }
): Promise<T> => {
  return callApiService(API_END_POINTS.GET_INVOICES_PAYMENT_STAT, 'GET', {
    payload,
    ...options,
  });
};

export const getInvoiceApprovalTimeStat = <T>(
  payload: TypeInvoicesStatPayload,
  options: ApiConfigOptions = {}
): Promise<T> => {
  return callApiService(API_END_POINTS.GET_INVOICE_APPROVAL_TIME_STAT, 'GET', {
    payload,
    ...options,
  });
};

export const getWaitingForApprovalStat = <T>(
  options: ApiConfigOptions = {}
): Promise<T> => {
  return callApiService(API_END_POINTS.GET_WAITING_FOR_APPROVAL_STAT, 'GET', {
    ...options,
  });
};

export type SpendStatRequestPayload = {
  branch_id?: string[];
  vendor_id?: string[];
  expense_head_id?: string[];
  expense_particular_id?: string[];
  paid_at_after?: string;
  paid_at_before?: string;
};

export const getSpendStat = <T>(
  payload: SpendStatRequestPayload,
  options: ApiConfigOptions = {}
): Promise<T> => {
  return callApiService(API_END_POINTS.GET_SPEND_STAT, 'GET', {
    payload,
    ...options,
  });
};

export type ExpenseBreakdownByTypes =
  | 'branch'
  | 'vendor'
  | 'head'
  | 'particular';

export type ExpenseBreakdownRequestPayload = {
  paid_at_after?: string;
  paid_at_before?: string;
  by?: ExpenseBreakdownByTypes;
};

export const getExpenseBreakdownStat = <T>(
  payload: ExpenseBreakdownRequestPayload,
  options: ApiConfigOptions = { inQueryParams: ['by'] }
): Promise<T> => {
  return callApiService(API_END_POINTS.GET_EXPENSE_BREAKDOWN, 'GET', {
    payload,
    ...options,
  });
};

export type VendorsRequestPayload = {
  page?: number;
  created_before?: string;
  created_after?: string;
  branch_id?: string[];
  company_reg_type?: string[];
  type?: string[];
  vendor_type_id?: string[];
  q?: string;
};

export const getUnApprovedVendorsList = (
  payload: VendorsRequestPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.GET_UNAPPROVED_VENDORS_PAGINATED,
    'GET',
    {
      payload,
      ...options,
    }
  );
};

export const getUnApprovedVendorsReport = (
  payload: VendorsRequestPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.GET_UNAPPROVED_VENDORS_PAGINATED,
    'GET',
    {
      payload: {
        ...payload,
        export: 1,
      },
      inQueryParams: ['export'],
      ...options,
    }
  );
};

export const getInactiveVendors = (
  payload: VendorsRequestPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.GET_PAGINATED_VENDOR_LISTING, 'GET', {
    payload: {
      vendor_status: 'inactive',
      ...payload,
    },
    ...options,
  });
};

export const getInActiveVendorsReport = (
  payload: VendorsRequestPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.GET_PAGINATED_VENDOR_LISTING, 'GET', {
    payload: {
      ...payload,
      vendor_status: 'inactive',
      export: 1,
    },
    inQueryParams: ['export'],
    ...options,
  });
};

export const getActiveVendors = (
  payload: VendorsRequestPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.GET_PAGINATED_VENDOR_LISTING, 'GET', {
    payload: {
      ...payload,
      vendor_status: 'active',
    },
    ...options,
  });
};

export const getActiveVendorsReport = (
  payload: VendorsRequestPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.GET_PAGINATED_VENDOR_LISTING, 'GET', {
    payload: {
      ...payload,
      vendor_status: 'active',
      export: 1,
    },
    inQueryParams: ['export'],
    ...options,
  });
};

export const getVendorTypes = (options: ApiConfigOptions = {}) => {
  return callApiService(API_END_POINTS.GET_VENDOR_TYPES, 'GET', {
    ...options,
  });
};

export type EditInvoicePayload = {
  document_delete_ids?: number[];
  id?: number;
} & Partial<CreateInvoicePaylod>;

export const editInvoice = (
  ticketNumber: string,
  payload: EditInvoicePayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(`${API_END_POINTS.INVOICES}/${ticketNumber}`, 'PATCH', {
    payload,
    ...options,
  });
};
export const importEntitiesApi = (
  entityType: 'vendors' | 'branches' | 'employees',
  payload: FormData,
  options: ApiConfigOptions = {
    headers: { 'Content-Type': 'multipart/form-data' },
  }
) => {
  let apiEndpoint;
  switch (entityType) {
    case 'vendors':
      apiEndpoint = API_END_POINTS.IMPORT_VENDORS;
      break;
    case 'branches':
      apiEndpoint = API_END_POINTS.IMPORT_BRANCHES;
      break;
    case 'employees':
      apiEndpoint = API_END_POINTS.IMPORT_EMPLOYEES;
      break;
  }
  return callApiService(apiEndpoint, 'POST', {
    payload,
    ...options,
  });
};

export const getTaxSlabList = (options: ApiConfigOptions = {}) => {
  return callApiService(API_END_POINTS.TAX_SLABS, 'GET', {
    ...options,
  });
};

export const downloadImportEntitySampleFile = (
  entityType: 'vendors' | 'branches' | 'employees',
  options: ApiConfigOptions = {}
) => {
  let apiEndpoint;
  switch (entityType) {
    case 'vendors':
      apiEndpoint = API_END_POINTS.DOWNLOAD_IMPORT_VENDORS_SAMPLE_FILE;
      break;
    case 'branches':
      apiEndpoint = API_END_POINTS.DOWNLOAD_IMPORT_BRANCHES_SAMPLE_FILE;
      break;
    case 'employees':
      apiEndpoint = API_END_POINTS.DOWNLOAD_IMPORT_EMPLOYEES_SAMPLE_FILE;
      break;
  }
  return callApiService(apiEndpoint, 'GET', {
    ...options,
  });
};

export type listApprovalRulesPayload = {
  type?: 'invoice' | 'vendor' | 'reimbursement';
};

export const listApprovalRules = <T>(
  payload: listApprovalRulesPayload,
  options: ApiConfigOptions = { inQueryParams: ['type'] }
): Promise<T> => {
  return callApiService(API_END_POINTS.LIST_APPROVAL_RULES, 'GET', {
    payload,
    ...options,
  });
};

export const listPermissions = <T>(
  options: ApiConfigOptions = {}
): Promise<T> => {
  return callApiService(API_END_POINTS.LIST_ALL_PERMISSIONS, 'GET', {
    ...options,
  });
};

export const approveVendor = (
  vendorId: string,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.APPROVE_VENDOR.replace(':vendorId', vendorId),
    'POST',
    {
      ...options,
    }
  );
};

export const rejectVendor = (
  vendorId: string,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.REJECT_VENDOR.replace(':vendorId', vendorId),
    'POST',
    {
      ...options,
    }
  );
};

export const forgotPassword = (
  payload: { email: string },
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.FORGOT_PASSWORD, 'POST', {
    payload,
    ...options,
  });
};

export const resetPassword = (
  payload: {
    email: string;
    password: string;
    password_confirmation: string;
    token: string;
  },
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.RESET_PASSWORD, 'POST', {
    payload,
    ...options,
  });
};

export type AddOrEditReimbursementPayload = {
  title: string;
  grand_total_amount: number;
  document_upload_ids: number[];
  document_delete_ids?: number[];
  items: {
    expense_date: string;
    description?: string;
    total_amount: number;
    expense_head_id: number;
  }[];
};

export const addReimbursement = (
  payload: AddOrEditReimbursementPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.CREATE_REIMBURSEMENT, 'POST', {
    payload,
    ...options,
  });
};

export const editReimbursement = (
  reimbursementId: string,
  payload: AddOrEditReimbursementPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.UPDATE_REIMBURSEMENT.replace(
      ':reimbursementId',
      reimbursementId.toString()
    ),
    'PATCH',
    {
      payload,
      ...options,
    }
  );
};

export const getReimbursementDetails = (
  reimbursementId: string,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.GET_REIMBURSEMENT_DETAILS.replace(
      ':reimbursementId',
      reimbursementId.toString()
    ),
    'GET',
    {
      ...options,
    }
  );
};

export const getReimbursementExpenseTypes = (
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.LIST_REIMBURSEMENT_EXPENSE_TYPES,
    'GET',
    {
      ...options,
    }
  );
};

type ActionOnReimbursementPayload = {
  remarks?: string;
};

export const approveReimbursement = (
  reimbursementId: string,
  payload: ActionOnReimbursementPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.APPROVE_REIMBURSEMENT.replace(
      ':reimbursementId',
      reimbursementId.toString()
    ),
    'POST',
    {
      payload,
      ...options,
    }
  );
};

export const rejectReimbursement = (
  reimbursementId: string,
  payload: ActionOnReimbursementPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.REJECT_REIMBURSEMENT.replace(
      ':reimbursementId',
      reimbursementId.toString()
    ),
    'POST',
    {
      payload,
      ...options,
    }
  );
};

export const returnReimbursement = (
  reimbursementId: string,
  payload: ActionOnReimbursementPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.RETURN_REIMBURSEMENT.replace(
      ':reimbursementId',
      reimbursementId.toString()
    ),
    'POST',
    {
      payload,
      ...options,
    }
  );
};

export const markPaidReimbursement = (
  reimbursementId: string,
  utr_number: string,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.MARK_PAID_REIMBURSEMENT.replace(
      ':reimbursementId',
      reimbursementId.toString()
    ) + `?utr_number=${utr_number}`,
    'POST',
    {
      ...options,
    }
  );
};

export const deleteReimbursement = (
  reimbursementId: string,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.DELETE_REIMBURSEMENT.replace(
      ':reimbursementId',
      reimbursementId.toString()
    ),
    'DELETE',
    {
      ...options,
    }
  );
};

export const getAllReimbursements = (
  payload: ReimbursementsRequestPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.ALL_REIMBURSEMENTS, 'GET', {
    payload,
    ...options,
  });
};

export function getAllEmployeesListing<T>(
  options: ApiConfigOptions = {}
): Promise<T> {
  return callApiService(API_END_POINTS.LIST_ALL_EMPLOYEES, 'GET', {
    ...options,
  });
}

export type TypeReimbursementsStatPayload = {
  created_at_before?: string;
  created_at_after?: string;
  q?: string;
  created_by?: string[];
  type: 'my' | 'all';
};

export const getTypedReimbursementStats = <T>(
  payload: TypeReimbursementsStatPayload,
  options: ApiConfigOptions = { inQueryParams: ['type'] }
): Promise<T> => {
  return callApiService(API_END_POINTS.REIMBURSEMENTS_AGGREGATE, 'GET', {
    payload,
    ...options,
  });
};

export const getWaitingMyApprovalReimbursements = (
  payload: ReimbursementsRequestPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.WAITING_MY_APPROVAL_REIMBURSEMENTS,
    'GET',
    {
      payload,
      ...options,
    }
  );
};

export type ReimbursementsRequestPayload = {
  page?: number;
  created_at_before?: string;
  created_at_after?: string;
  status?: string[];
  q?: string;
  created_by?: string[];
  payment_status?: string[];
};

export const getOwnReimbursements = (
  payload: ReimbursementsRequestPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.REIMBURSEMENTS, 'GET', {
    payload,
    ...options,
  });
};

export function getReimbursementListReport<T>(
  fetchFor:
    | 'created-by-you'
    | 'waiting-for-your-approval'
    | 'all-reimbursements',
  payload: ReimbursementsRequestPayload,
  options: ApiConfigOptions = {}
): Promise<T> {
  const updatedPayload = {
    ...payload,
    export: 1,
  };

  const endPoint =
    fetchFor === 'created-by-you'
      ? API_END_POINTS.REIMBURSEMENTS
      : fetchFor === 'all-reimbursements'
      ? API_END_POINTS.LIST_ALL_EMPLOYEES
      : API_END_POINTS.WAITING_MY_APPROVAL_REIMBURSEMENTS;

  return callApiService(endPoint, 'GET', {
    payload: updatedPayload,
    inQueryParams: ['export'],
    ...options,
  });
}

export const invoiceOcrDetection = (
  payload: FormData,
  options: ApiConfigOptions = {
    headers: { 'Content-Type': 'multipart/form-data' },
  }
) => {
  return callApiService(API_END_POINTS.INVOICE_OCR_UPLOAD, 'POST', {
    payload,
    ...options,
  });
};

export const getIfscDetails = (
  ifscCode: string,
  options: ApiConfigOptions = {}
) => {
  return callApiService(
    API_END_POINTS.GET_IFSC_DETAILS.replace(':ifscCode', ifscCode),
    'GET',
    {
      ...options,
    }
  );
};

export function getTenantConfig<T>(
  options: ApiConfigOptions = { isAuthenticatedRequest: false }
): Promise<T> {
  return callApiService(API_END_POINTS.GET_TENANT_CONFIG, 'GET', {
    ...options,
  });
}

type GetNotificationPayload = {
  page: number;
};

export const getNotifications = (
  payload: GetNotificationPayload,
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.GET_NOTIFICATIONS, 'GET', {
    ...options,
    payload,
  });
};

export const markNotificationsAsRead = (
  payload: { notifications?: string[] } = {},
  options: ApiConfigOptions = {}
) => {
  return callApiService(API_END_POINTS.MARK_READ_NOTIFICATIONS, 'PUT', {
    ...options,
    payload,
  });
};
