import type { ElementType } from 'react';
import { forwardRef } from 'react';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';
import { Box, BoxOwnProps } from '../box/box';
import { TTextStyles, textStyles } from '../css/text.css';
import classNames from 'classnames';

const DefaultTextElement = 'p';

type TextOwnProps = TTextStyles;

export type TextProps<C extends React.ElementType = typeof DefaultTextElement> =
  PolymorphicComponentPropWithRef<C, BoxOwnProps & TextOwnProps>;

type TextComponent = <C extends React.ElementType = typeof DefaultTextElement>(
  props: TextProps<C>
) => React.ReactNode;

export const Text: TextComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultTextElement>(
    { as, textTransform, variation, whiteSpace, ...restProps }: TextProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Element: ElementType = as || DefaultTextElement;
    return (
      <Box
        {...restProps}
        color={restProps.color}
        className={classNames(
          textStyles({
            textTransform,
            whiteSpace,
            variation,
          })
        )}
        as={Element}
        ref={ref}
      />
    );
  }
);

const DefaultH1Element = 'h1';

export type H1Props<C extends React.ElementType = typeof DefaultH1Element> =
  PolymorphicComponentPropWithRef<C, BoxOwnProps & TextOwnProps>;

type H1Component = <C extends React.ElementType = typeof DefaultH1Element>(
  props: TextProps<C>
) => React.ReactNode;

export const H1: H1Component = forwardRef(
  <C extends React.ElementType = typeof DefaultH1Element>(
    props: H1Props<C>,
    ref?: PolymorphicRef<C>
  ) => {
    return <Text {...props} variation="h1" as={DefaultH1Element} ref={ref} />;
  }
);

const DefaultH2Element = 'h2';

export type H2Props<C extends React.ElementType = typeof DefaultH2Element> =
  PolymorphicComponentPropWithRef<C, BoxOwnProps & TextOwnProps>;

type H2Component = <C extends React.ElementType = typeof DefaultH2Element>(
  props: TextProps<C>
) => React.ReactNode;

export const H2: H2Component = forwardRef(
  <C extends React.ElementType = typeof DefaultH2Element>(
    props: H2Props<C>,
    ref?: PolymorphicRef<C>
  ) => {
    return <Text {...props} variation="h2" as={DefaultH1Element} ref={ref} />;
  }
);

const DefaultH3Element = 'h3';

export type H3Props<C extends React.ElementType = typeof DefaultH3Element> =
  PolymorphicComponentPropWithRef<C, BoxOwnProps & TextOwnProps>;

type H3Component = <C extends React.ElementType = typeof DefaultH3Element>(
  props: TextProps<C>
) => React.ReactNode;

export const H3: H3Component = forwardRef(
  <C extends React.ElementType = typeof DefaultH3Element>(
    props: H3Props<C>,
    ref?: PolymorphicRef<C>
  ) => {
    return <Text {...props} variation="h3" as={DefaultH1Element} ref={ref} />;
  }
);
