import { useUser } from '@nbfc-expense-tool/data-store/auth';
import { checkForSinglePermission } from '@nbfc-expense-tool/data-store/permissions';
import { Button, PlusIcon } from '@nbfc-expense-tool/ui';
import { useNavigate } from 'react-router-dom';

type AddNewButtonProps = {
  label?: string;
};

const AddNewVendorButton: React.FC<AddNewButtonProps> = ({
  label = 'Add New',
}) => {
  const navigate = useNavigate();
  const { user } = useUser();

  if (
    !checkForSinglePermission(
      'createVendor',
      user?.roles[0]?.permissions || null
    )
  ) {
    return null;
  }

  return (
    <Button
      id="add-new-vendor-button"
      title={label}
      leftIcon={(props) => <PlusIcon {...props} />}
      onClick={() => {
        navigate('/add-new-vendor');
      }}
    />
  );
};

export default AddNewVendorButton;
