import { forwardRef, useState } from 'react';
import { Box, BoxOwnProps } from '../box/box';
import { Text, TextProps } from '../text/text';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';

type HoverableBoxProps = {
  text: string;
  children: React.ReactElement;
};

const DefaultElement = 'div';

export type AllProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    BoxOwnProps & TextProps & HoverableBoxProps
  >;

type HoverableBoxComponent = <
  C extends React.ElementType = typeof DefaultElement
>(
  props: AllProps<C>
) => React.ReactNode;

export const HoverableBox: HoverableBoxComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultElement>(
    { as, text, children, ...rest }: AllProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Element: React.ElementType = as || DefaultElement;
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter: React.MouseEventHandler<HTMLDivElement> = (e) => {
      setShowTooltip(true);
      setTooltipPosition({
        x: 20,
        y: e.nativeEvent.offsetY + 10,
      });
    };

    const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => {
      setShowTooltip(false);
    };

    return (
      <Box
        position="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        as={Element}
        {...rest}
      >
        {children}
        {showTooltip && (
          <Box
            position="absolute"
            width="screen"
            maxWidth="xs"
            style={{
              top: tooltipPosition.y,
              left: tooltipPosition.x,
              zIndex: 100,
            }}
            display="flex"
            justifyContent="start"
          >
            <Text
              variation="c5"
              color="textOnSurface"
              borderRadius="md"
              paddingX="1.5"
              paddingY="1"
              borderWidth="1"
              borderColor="borderSeparator"
              backgroundColor="surfaceNeutralHigh"
            >
              {text}
            </Text>
          </Box>
        )}
      </Box>
    );
  }
);
