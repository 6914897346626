import { forwardRef, useEffect, useRef, useState } from 'react';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';
import { Box, BoxOwnProps } from '../box/box';
import { Text } from '../text/text';
import {
  AriaTabListProps,
  AriaTabProps,
  mergeProps,
  useFocusRing,
  useTab,
  useTabList,
  useTabPanel,
} from 'react-aria';
import { Node, TabListState, useTabListState } from 'react-stately';
import './tabs.css';

const DefaultTabsElement = 'div';

type TabsOwnProps = Omit<TabOptionals, 'isVertical'> &
  AriaTabListProps<unknown> & {
    defaultSelectedKey?: string;
    onClick?: (textValue: string) => void;
  };

export type TabsProps<C extends React.ElementType = typeof DefaultTabsElement> =
  PolymorphicComponentPropWithRef<
    C,
    Omit<BoxOwnProps, 'backgroundColor' | 'bgColor'> & TabsOwnProps
  >;

type TabsComponent = <C extends React.ElementType = typeof DefaultTabsElement>(
  props: TabsProps<C>
) => React.ReactNode;

export const Tabs: TabsComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultTabsElement>(
    {
      gap,
      orientation,
      activeTabColor,
      activeTabBgColor,
      defaultSelectedKey,
      onClick,
      ...props
    }: TabsProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const state = useTabListState({
      ...props,
      defaultSelectedKey,
    });
    const refForTab = useRef<HTMLDivElement | null>(null);
    const { tabListProps } = useTabList(props, state, refForTab);

    const [activeTabStyle, setActiveTabStyle] = useState({
      width: 0,
      transform: 'translateX(0)',
    });

    useEffect(() => {
      const activeTab = refForTab.current?.querySelector(
        '[role="tab"][aria-selected="true"]'
      );
      setActiveTabStyle({
        width: activeTab?.clientWidth || 0,
        transform: `translateX(${activeTab?.clientLeft}px)`,
      });
    }, [state.selectedKey]);

    const { focusProps, isFocusVisible } = useFocusRing({
      within: true,
    });

    const isVertical = orientation === 'vertical';

    return (
      <Box
        className="tabs"
        display="flex"
        flexDirection={isVertical ? 'row' : 'col'}
        gap={gap || '2'}
      >
        <Box ref={ref} className="tablist-container">
          <div
            className={`tab-selection ${isFocusVisible ? 'focused' : ''}`}
            style={{ zIndex: -1, ...activeTabStyle }}
          />
          <Box
            {...mergeProps(tabListProps, focusProps)}
            ref={refForTab}
            display={'flex'}
            width="full"
            maxWidth={isVertical ? 'max' : 'full'}
            paddingX={isVertical ? '1.5' : '0'}
            flexDirection={isVertical ? 'col' : 'row'}
            alignItems={isVertical ? 'start' : 'center'}
            borderBottomWidth={isVertical ? '0' : '1'}
            borderRightWidth={isVertical ? '1' : '0'}
            borderColor="borderOutline"
          >
            {[...state.collection].map((item) => (
              <Tab
                key={item.key}
                item={item}
                state={state}
                isVertical={isVertical}
                activeTabColor={activeTabColor}
                activeTabBgColor={activeTabBgColor}
                onClick={onClick}
              />
            ))}
          </Box>
        </Box>
        <TabPanel key={state.selectedItem?.key} state={state} />
      </Box>
    );
  }
);

type TabOptionals = {
  isVertical?: boolean;
  activeTabColor?: React.ComponentProps<typeof Text>['color'];
  activeTabBgColor?: React.ComponentProps<typeof Box>['backgroundColor'];
};

type TabProps<T> = {
  item: Node<T>;
  state: TabListState<unknown>;
  onClick?: (textValue: string) => void;
} & TabOptionals;

function Tab({
  item,
  state,
  isVertical = false,
  activeTabColor,
  activeTabBgColor,
  onClick,
}: TabProps<unknown>) {
  const ref = useRef(null);
  const { tabProps } = useTab(item as AriaTabProps, state, ref);

  const isActive = Boolean(state.selectedItem?.index === item.index);

  const activeBorder =
    (activeTabColor as React.ComponentProps<typeof Box>['borderColor']) ||
    'borderPrimary';

  return (
    <Box
      ref={ref}
      as="button"
      {...tabProps}
      cursor="pointer"
      paddingY="1.5"
      position="relative"
      paddingRight={'1.5'}
      title={item.textValue}
      marginRight={isVertical ? '0' : '1.5'}
      paddingLeft={isVertical ? '0' : '1.5'}
      minWidth={isVertical ? 'full' : 'max'}
      roundedTop={isVertical ? 'none' : 'md'}
      backgroundColor={
        isActive && activeTabBgColor ? activeTabBgColor : 'transparent'
      }
      onClick={() => onClick?.(item.textValue)}
    >
      <Text
        as="span"
        variation="t4"
        color={isActive ? activeTabColor || 'textPrimary' : 'textMedium'}
      >
        {item.rendered}
      </Text>
      {isActive ? (
        <Box
          as="hr"
          position="absolute"
          style={{
            height: isVertical ? '100%' : 2,
            width: isVertical ? 2 : '100%',
          }}
          bottom="0"
          left={isVertical ? undefined : '0'}
          right={isVertical ? '0' : undefined}
          backgroundColor={activeBorder}
        />
      ) : null}
    </Box>
  );
}

function TabPanel({ state, ...props }: { state: TabListState<unknown> }) {
  const ref = useRef(null);
  const { tabPanelProps } = useTabPanel(props, state, ref);

  return (
    <Box {...tabPanelProps} ref={ref}>
      {state.selectedItem?.props.children}
    </Box>
  );
}
