import { Reimbursement_Status } from '@nbfc-expense-tool/data-store/dashboard';

export default function useReimbursements() {
  function getTitleForTypeOfReimbursement(type: Reimbursement_Status) {
    switch (type) {
      case 'approved':
        return 'Approved';
      case 'rejected':
        return 'Rejected';
      case 'returned':
        return 'Returned';
      case 'under_review':
        return 'Under Review';
    }
  }

  function getEmptyStateText(type: Reimbursement_Status, status: string) {
    if (type === 'approved') {
      if (status === 'unpaid') {
        return {
          title: 'No Unpaid Approved Reimbursements Found!',
          subtitle:
            'All the approved reimbursements with pending payments will appear here',
        };
      } else if (status === 'paid') {
        return {
          title: 'No Approved & Paid Reimbursements Found!',
          subtitle:
            'All the reimbursements which are approved & paid will appear here',
        };
      } else {
        return {
          title: 'No Approved Reimbursements Found!',
          subtitle: 'All the approved reimbursements will appear here',
        };
      }
    } else if (type === 'under_review') {
      return {
        title: 'No Under Review Reimbursements Found!',
        subtitle: 'All the under review reimbursements will appear here',
      };
    } else if (type === 'returned') {
      return {
        title: 'No Returned Reimbursements Found!',
        subtitle: 'All the returned reimbursements will appear here',
      };
    } else {
      return {
        title: 'No Rejected Reimbursements Found!',
        subtitle: 'All the rejected reimbursements will appear here',
      };
    }
  }

  function getFifthColumnBasedOnType(
    type: Reimbursement_Status,
    status: string
  ) {
    if (type === 'approved') {
      if (status === 'unpaid') {
        return {
          title: 'Action',
        };
      } else if (status === 'paid') {
        return {
          title: 'Action',
        };
      } else {
        return {
          title: 'Paid Status',
        };
      }
    } else if (type === 'under_review') {
      return {
        title: 'Action',
      };
    } else if (type === 'returned') {
      return {
        title: 'Changes Suggested',
      };
    } else {
      return {
        title: 'Reason',
      };
    }
  }

  function getTitleFromLink(from?: string | null): string {
    switch (from) {
      case 'created-by-you':
        return 'Created By You';
      case 'waiting-for-your-approval':
        return 'Waiting For Your Approval';
      case 'all-reimbursements':
        return 'All Reimbursements';
      default:
        return 'Home';
    }
  }

  return {
    getTitleFromLink,
    getTitleForTypeOfReimbursement,
    getEmptyStateText,
    getFifthColumnBasedOnType,
  };
}
