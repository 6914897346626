import React from 'react';
import { IconParams } from '../../types';
import { Box } from '../box/box';
import { Button } from '../button/button';
import { CrossIcon } from '../icons/icons';
import { Inline } from '../inline/inline';
import { Modal } from '../modal/modal';
import Stack from '../stack/stack';
import { Text } from '../text/text';

type ConfirmationDialogProps = {
  isVisible: boolean;
  title: string;
  description?: string;
  steps?: string[];
  onConfirm: () => void;
  onCancel: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmButtonRole?: 'destructive' | 'success';
  confirmButtonState?: 'loading' | 'disabled';
  toggleButtons?: boolean;
  id: string;
};

type ConfirmationDialogComponent = React.FC<ConfirmationDialogProps>;

export const ConfirmationDialog: ConfirmationDialogComponent = ({
  isVisible = false,
  title,
  description,
  steps,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  confirmButtonRole,
  confirmButtonState,
  onConfirm,
  onCancel,
  toggleButtons = false,
  id,
}) => {
  return (
    <Modal
      isOpen={isVisible}
      title={title}
      onClose={onCancel}
      size="sm"
      showDefaultHeader={false}
    >
      <>
        <Inline
          justifyContent="between"
          paddingX="4"
          paddingY="3"
          borderBottomWidth="1"
          borderColor="borderSeparator"
        >
          <Text variation="h3">{title}</Text>
          <CrossIcon onClick={onCancel} size="4" cursor="pointer" />
        </Inline>
        <Box padding="3">
          <Text variation="b2">Are you sure?</Text>
          {description && (
            <Text variation="b2" marginTop="2">
              {description}
            </Text>
          )}
          {steps && (
            <Box marginTop="3">
              {steps.map((step, index) => (
                <Inline alignItems="center" marginTop="2">
                  <Box
                    borderRadius="full"
                    width="1"
                    height="1"
                    backgroundColor="iconLowest"
                    marginRight="2"
                  />
                  <Text key={index} variation="b2">
                    {step}
                  </Text>
                </Inline>
              ))}
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="end"
          paddingY="2"
          paddingX="4"
          borderTopWidth="1"
          borderColor="borderSeparator"
        >
          <Inline flexDirection={toggleButtons ? 'rowReverse' : 'row'} gap="3">
            <Button
              id={`${id}-confirmaton-dialog-confirm-button`}
              title={confirmLabel}
              type={toggleButtons ? 'filled' : 'outlined'}
              buttonRole={confirmButtonRole}
              state={confirmButtonState}
              onClick={onConfirm}
            />
            <Button
              id={`${id}-confirmaton-dialog-cancel-button`}
              title={cancelLabel}
              type={toggleButtons ? 'outlined' : 'filled'}
              onClick={onCancel}
            />
          </Inline>
        </Box>
      </>
    </Modal>
  );
};

type InfoDialogProps = {
  isVisible: boolean;
  title: string;
  text: string;
  icon?: ({ color, size }: IconParams) => React.ReactElement;
  description?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmButtonRole?: 'destructive' | 'success';
  toggleButtons?: boolean;
  id: string;
  alignment?: 'center' | 'left';
  onClose?: () => void;
};

type InfoDialogComponent = React.FC<InfoDialogProps>;

export const InfoDialog: InfoDialogComponent = ({
  isVisible = false,
  title,
  icon,
  text,
  description,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  confirmButtonRole,
  onConfirm,
  onCancel,
  toggleButtons = false,
  id,
  alignment = 'center',
  onClose,
}) => {
  return (
    <Modal
      isOpen={isVisible}
      title={title}
      onClose={onClose}
      size="sm"
      showDefaultHeader={false}
    >
      <>
        <Inline
          justifyContent="between"
          paddingX="4"
          paddingY="3"
          borderBottomWidth="1"
          borderColor="borderSeparator"
        >
          <Text variation="h3">{title}</Text>
          <CrossIcon onClick={onClose} size="4" cursor="pointer" />
        </Inline>
        <Stack
          padding="3"
          paddingX={alignment === 'center' ? '8' : '3'}
          alignItems={alignment === 'center' ? 'center' : 'start'}
          textAlign={alignment}
        >
          {!!icon && (
            <Box marginBottom="2">
              {icon({
                color: 'iconPrimary',
                size: '7',
              })}
            </Box>
          )}
          {text ? (
            <Text variation="t1" marginBottom="2">
              {text}
            </Text>
          ) : null}
          {description && <Text variation="b2">{description}</Text>}
        </Stack>
        <Box
          display="flex"
          justifyContent="end"
          paddingY="2"
          paddingX="4"
          borderTopWidth="1"
          borderColor="borderSeparator"
        >
          <Inline flexDirection={toggleButtons ? 'rowReverse' : 'row'} gap="3">
            <Button
              id={`${id}-info-dialog-confirm-button`}
              title={confirmLabel}
              type={toggleButtons ? 'filled' : 'outlined'}
              buttonRole={confirmButtonRole}
              onClick={onConfirm}
            />
            {onCancel && (
              <Button
                id={`${id}-info-dialog-cancel-button`}
                title={cancelLabel}
                type={toggleButtons ? 'outlined' : 'filled'}
                onClick={onCancel}
              />
            )}
          </Inline>
        </Box>
      </>
    </Modal>
  );
};
