import type { ElementType } from 'react';
import { forwardRef } from 'react';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';
import { Box, BoxOwnProps } from '../box/box';
import { Text } from '../text/text';
import { TBackgroundColorStyles } from '../avatar/avatar.css';

const DefaultHeaderElement = 'div';

type HeaderOwnProps = {
  headingText: string;
  renderRight?: () => React.ReactElement;
} & TBackgroundColorStyles;

export type HeaderProps<
  C extends React.ElementType = typeof DefaultHeaderElement
> = PolymorphicComponentPropWithRef<
  C,
  Omit<BoxOwnProps, 'backgroundColor' | 'bgColor'> & HeaderOwnProps
>;

type HeaderComponent = <
  C extends React.ElementType = typeof DefaultHeaderElement
>(
  props: HeaderProps<C>
) => React.ReactNode;

export const Header: HeaderComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultHeaderElement>(
    {
      as,
      textTransform,
      whiteSpace,
      logoImageUrl,
      bgColor,
      backgroundColor,
      headingText,
      renderRight,
      ...restProps
    }: HeaderProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Element: ElementType = as || DefaultHeaderElement;

    return (
      <Box
        {...restProps}
        as={Element}
        ref={ref}
        width={'auto'}
        display={'flex'}
        alignItems={'center'}
        justifyContent="between"
      >
        <Text variation="h2" color="textHigh" marginRight="3" flex="1">
          {headingText}
        </Text>
        {renderRight ? renderRight() : null}
      </Box>
    );
  }
);

export default Header;
