import {
  Checkbox as HeadlessCheckbox,
  CheckboxProps,
} from 'react-aria-components';
import {
  CheckedIcon,
  DisabledCheckBoxIcon,
  IndeterminateCheckBoxIcon,
  UnCheckedIncon,
} from '../icons/icons';

export function Checkbox(props: CheckboxProps) {
  return (
    <HeadlessCheckbox {...props}>
      {({ isSelected, isDisabled, isIndeterminate }) => {
        if (isIndeterminate) {
          return <IndeterminateCheckBoxIcon color="iconPrimary" size={'3'} />;
        }
        if (isDisabled) {
          if (isSelected) {
            return <DisabledCheckBoxIcon color={'iconLow'} size={'3'} />;
          }
          return <UnCheckedIncon color="iconLowest" size={'3'} />;
        } else if (isSelected) {
          return <CheckedIcon color={'iconPrimary'} size={'3'} />;
        } else {
          return <UnCheckedIncon color="iconLow" size={'3'} />;
        }
      }}
    </HeadlessCheckbox>
  );
}

export default Checkbox;
