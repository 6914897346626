import type { ElementType } from 'react';
import { forwardRef } from 'react';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';
import { BoxOwnProps } from '../box/box';
import { Text } from '../text/text';
import { IconParams } from '../../types';
import { Stack } from '../stack/stack';
import { ButtonProps } from '../button/button';

const DefaultEmptySpaceElement = 'div';

type EmptyStateOwnProps = {
  componentSize?: 'sm' | 'lg';
  title: string;
  subText?: string;
  renderIcon?: ({ color, size }: IconParams) => React.ReactElement;
  renderButton?: (type: ButtonProps['type']) => React.ReactElement;
};

export type EmptyStateProps<
  C extends React.ElementType = typeof DefaultEmptySpaceElement
> = PolymorphicComponentPropWithRef<C, BoxOwnProps & EmptyStateOwnProps>;

type EmptyStateComponent = <
  C extends React.ElementType = typeof DefaultEmptySpaceElement
>(
  props: EmptyStateProps<C>
) => React.ReactNode;

export const EmptyState: EmptyStateComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultEmptySpaceElement>(
    {
      as,
      componentSize = 'lg',
      title,
      subText,
      renderIcon,
      renderButton,
      ...restProps
    }: EmptyStateProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Element: ElementType = as || DefaultEmptySpaceElement;
    const isSmallVariant = componentSize === 'sm';

    return (
      <Stack
        {...restProps}
        as={Element}
        ref={ref}
        width="auto"
        display="flex"
        alignItems="center"
        paddingX="3"
        paddingY="4"
      >
        {renderIcon
          ? renderIcon({
              color: 'iconLowest',
              size: isSmallVariant ? '4' : '8',
            })
          : null}
        <Text
          variation={isSmallVariant ? 'b1' : 't2'}
          color="textMedium"
          marginTop={isSmallVariant ? '1' : '2.5'}
        >
          {title}
        </Text>
        <Text
          variation="b2"
          color="textMedium"
          marginTop="1"
          marginBottom={isSmallVariant ? '2.5' : '3'}
        >
          {subText}
        </Text>
        {renderButton
          ? renderButton(isSmallVariant ? 'outlined' : 'filled')
          : null}
      </Stack>
    );
  }
);
