import {
  Box,
  Stack,
  Text,
  EmailIcon,
  Button,
  InfoDialog,
  toastQueue,
} from '@nbfc-expense-tool/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { FormTextInput } from '@nbfc-expense-tool/data-store/forms';
import { forgotPassword } from '@nbfc-expense-tool/data-store/utils';

const schema = z.object({
  email: z
    .string({
      required_error: 'Please enter your company Email ID to continue',
    })
    .nonempty('Please enter your company Email ID to continue')
    .email('Please enter a valid email'),
});

type FormInputs = {
  email: string;
};

const idPrefix = 'forgot-password';

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const form = useForm<FormInputs>({
    resolver: zodResolver(schema),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [isEmailSentModalVisible, setIsEmailSentModalVisible] =
    useState<boolean>(false);

  const { control, handleSubmit } = form;

  const sendResetLink = async (email: string) => {
    setLoading(true);
    try {
      await forgotPassword({
        email,
      });
      setIsEmailSentModalVisible(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.status === 422) {
        toastQueue.add(
          {
            title: error.message || 'Something went wrong, Please try again',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
      } else {
        toastQueue.add(
          {
            title: 'Something went wrong, Please try again',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const submitDetails = async (data: FormInputs) => {
    sendResetLink(data.email);
  };

  return (
    <Box
      display="flex"
      flex="1"
      height="screen"
      justifyContent="center"
      alignItems="center"
    >
      <Stack display="flex" style={{ width: 432 }} overflow="hidden">
        <Text variation="h2" color="textHigh">
          CashBook EMS
        </Text>
        <Box
          backgroundColor="surfacePrimary"
          width="4.5"
          rounded="full"
          height="0.5"
          marginTop="3.5"
        />
        <Text variation="h3" color="textHigh" marginTop="3.5">
          Forgot Password
        </Text>
        <Box as="form" onSubmit={handleSubmit(submitDetails)}>
          <Box marginTop="4.5" width="full">
            <FormTextInput
              control={control}
              name="email"
              label="Email ID"
              placeholder="Enter email Id"
              leftIcon={(props) => <EmailIcon {...props} />}
              type="email"
              fullWidth
              isRequired
            />
            <Text variation="c2" marginTop="3">
              We’ll send a reset password link to this email if it matches an
              existing registered account.
            </Text>
          </Box>
          <Button
            id={`${idPrefix}-send-reset-link`}
            state={loading ? 'loading' : undefined}
            onClick={handleSubmit(submitDetails)}
            size="lg"
            title={loading ? 'Sending Reset Link' : 'Send Reset Link'}
            marginTop="5"
            width="full"
          />
        </Box>
        <Button
          id={`${idPrefix}-go-back`}
          type="outlined"
          state={loading ? 'disabled' : undefined}
          onClick={() => navigate(-1)}
          size="lg"
          title={'Go Back'}
          marginTop="3"
          width="full"
        />
      </Stack>
      <InfoDialog
        id={`${idPrefix}-reset-password`}
        isVisible={isEmailSentModalVisible}
        title="Reset Password"
        text="Check your email"
        icon={(props) => <EmailIcon {...props} />}
        description={`Please check the email address "${
          form.getValues().email
        }" for instructions to reset your password.`}
        onConfirm={() => {
          setIsEmailSentModalVisible(false);
          sendResetLink(form.getValues().email);
        }}
        onCancel={() => setIsEmailSentModalVisible(false)}
        confirmLabel="Resend Email"
        cancelLabel="Ok, Got it"
        onClose={() => setIsEmailSentModalVisible(false)}
      />
    </Box>
  );
};

export default ForgotPassword;
