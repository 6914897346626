import { UserDetails, useUser } from '@nbfc-expense-tool/data-store/auth';
import {
  VendorDetails,
  useVendorProfile,
  useVendorProfileDetails,
  vendorRegistrationTypeOptions,
  VendorActivity,
  VendorActivityEvent,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  AlertTriangleIcon,
  Avatar,
  Box,
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Checkbox,
  CrossCircle,
  DeleteIcon,
  EmptyState,
  Inline,
  PencilIcon,
  SecondaryHeader,
  SpinnerIcon,
  Stack,
  Text,
  UploadDocument,
  HoverableBox,
  ConfirmationDialog,
  IconParams,
  FilePlusIcon,
  ClockIcon,
  CrossIcon,
  Date as DateUi,
  CheckIcon,
  CancelIcon,
  UserIcon,
} from '@nbfc-expense-tool/ui';
import { useMemo } from 'react';
import { ApproveOrRejectFlowInModal } from '../../Vendors';

const idPrefix = 'vendor-profile';

const VendorProfile: React.FC = () => {
  const {
    loadingStatus,
    vendorDetails,
    fromScreenName,
    hasEditProfilePermission,
    hasDeactivateVendorPermission,
    isDeactivateConfirmationDialogVisible,
    isDeactivateVendorApiLoading,
    reloadDetails,
    goBack,
    navigateToVendorsListing,
    navigateToEditVendor,
    openDeactivateVendorConfirmationDialog,
    closeDeactivateVendorConfirmationDialog,
    deactivateVendor,
  } = useVendorProfile();
  const { user } = useUser();

  const renderVendorDetails = useMemo(() => {
    switch (loadingStatus) {
      case 'in_progress':
        return (
          <Stack justifyContent="center" alignItems="center" marginTop="10">
            <SpinnerIcon size="3" color="iconMedium" />
            <Text variation="b2" color="textMedium" marginTop="1">
              Loading...
            </Text>
          </Stack>
        );
      case 'failed':
        return (
          <Stack justifyContent="center" alignItems="center" marginTop="10">
            <EmptyState
              renderIcon={(props) => <CrossCircle {...props} />}
              title="No Details Found!"
              subText={`Please check your internet connection and try again`}
              renderButton={() => (
                <Button
                  id={`${idPrefix}-reload-details`}
                  title="Reload"
                  onClick={reloadDetails}
                />
              )}
            />
          </Stack>
        );
      case 'success':
        return (
          <Inline gap="6">
            <Box
              borderRadius="md"
              backgroundColor="surfaceDefault"
              borderWidth="1"
              borderColor="borderOutline"
              marginBottom="9"
              flex="1"
            >
              {!(
                vendorDetails?.approval_status === 'approved' &&
                vendorDetails?.vendor_status === 'active'
              ) && (
                <Inline
                  borderBottomWidth="1"
                  borderColor="borderOutline"
                  paddingX="3"
                  paddingY="2"
                  alignItems={'center'}
                >
                  <Text variation="t4" color="textMedium">
                    Status:
                  </Text>
                  <Box
                    backgroundColor={
                      vendorDetails?.vendor_status === 'inactive'
                        ? 'surfaceNeutralLowest'
                        : 'surfaceWarningLowest'
                    }
                    paddingX="1"
                    paddingY="px"
                    borderRadius="md"
                    marginLeft="1"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text
                      variation="c2"
                      color={
                        vendorDetails?.vendor_status === 'inactive'
                          ? 'textMedium'
                          : 'textWarning'
                      }
                    >
                      {vendorDetails?.vendor_status === 'inactive'
                        ? 'Inactive'
                        : 'Under Review'}
                    </Text>
                  </Box>
                </Inline>
              )}
              <Inline
                justifyContent="between"
                alignItems="start"
                paddingY="4"
                paddingX="3"
              >
                <Inline flex="1" marginRight="4">
                  <Avatar
                    marginRight="2"
                    avatarText={vendorDetails?.name?.[0]?.toUpperCase()}
                    avatarSize="md"
                    id={vendorDetails?.id?.toString()}
                  />
                  <Stack flex="1">
                    <Inline>
                      <Text color="textHigh" variation={'t1'}>
                        {vendorDetails?.name}
                      </Text>
                      {!vendorDetails?.is_data_complete &&
                        vendorDetails?.vendor_status === 'active' && (
                          <HoverableBox text="Mandatory Details Missing">
                            <AlertTriangleIcon
                              color="iconWarning"
                              size="3"
                              marginLeft="2"
                            />
                          </HoverableBox>
                        )}
                    </Inline>
                    <Inline alignItems="center">
                      <Text color={'textLow'} variation={'b2'}>
                        {vendorDetails?.vendor_type?.name || '-'}
                      </Text>
                      <Box
                        width="0.5"
                        height="0.5"
                        borderRadius="full"
                        backgroundColor="textLow"
                        marginX="1"
                      />
                      <Text color={'textLow'} variation={'b2'}>
                        {vendorRegistrationTypeOptions.find(
                          (item) =>
                            item.value === vendorDetails?.company_reg_type
                        )?.label || '-'}
                      </Text>
                    </Inline>
                  </Stack>
                </Inline>
                {vendorDetails?.vendor_status === 'active' && (
                  <Inline alignItems="center">
                    {hasEditProfilePermission &&
                      (!vendorDetails?.is_data_complete ||
                        vendorDetails.approval_status !== 'approved') && (
                        <Button
                          id={`${idPrefix}-edit-or-complete-profile`}
                          title={
                            vendorDetails?.is_data_complete
                              ? 'Edit Profile'
                              : 'Complete Profile'
                          }
                          type="text"
                          leftIcon={() => <PencilIcon size="2.5" />}
                          onClick={navigateToEditVendor}
                        />
                      )}
                    {hasDeactivateVendorPermission &&
                      vendorDetails?.vendor_status === 'active' &&
                      (vendorDetails.approval_status === 'approved'
                        ? !vendorDetails.is_data_complete
                        : false) &&
                      hasEditProfilePermission && (
                        <Box
                          height="3"
                          borderWidth="1"
                          borderColor="borderSeparator"
                          marginX="2"
                        />
                      )}
                    {hasDeactivateVendorPermission &&
                      vendorDetails?.vendor_status === 'active' &&
                      vendorDetails.approval_status === 'approved' && (
                        <Button
                          id={`${idPrefix}-deactivate-profile`}
                          title="Deactivate"
                          type="text"
                          buttonRole="destructive"
                          leftIcon={() => <DeleteIcon size="2.5" />}
                          onClick={openDeactivateVendorConfirmationDialog}
                          state={
                            isDeactivateVendorApiLoading ? 'loading' : undefined
                          }
                        />
                      )}
                  </Inline>
                )}
              </Inline>
              <Box marginX="3">
                <VendorCompleteDetails
                  vendorDetails={vendorDetails as VendorDetails}
                />
              </Box>
              <ConfirmationDialog
                id={`${idPrefix}-temporary-deactivate-vendor`}
                title="Temporarily Deactivate Profile"
                isVisible={isDeactivateConfirmationDialogVisible}
                steps={[
                  `You want to deactivate '${vendorDetails?.name}' vendor profile.`,
                  'This vendor details won’t be visible to in your Active Vendor’s list.',
                ]}
                confirmLabel="Yes, Deactivate"
                confirmButtonRole="destructive"
                onCancel={closeDeactivateVendorConfirmationDialog}
                onConfirm={deactivateVendor}
              />
            </Box>
            {vendorDetails?.activities.length ? (
              <Stack
                gap="6"
                borderWidth="1"
                rounded="md"
                width="full"
                backgroundColor="surfaceDefault"
                borderColor="borderSeparator"
                maxWidth="xs"
              >
                <Box
                  borderBottomWidth="1"
                  borderColor="borderSeparator"
                  paddingX="3"
                  paddingY="1.5"
                >
                  <Text variation="t3">Activity Log</Text>
                </Box>
                <Stack
                  as="ul"
                  gap="10"
                  style={{ listStyleType: 'none' }}
                  paddingX="3"
                >
                  {vendorDetails?.current_actor?.id && (
                    <Inline
                      as="li"
                      backgroundColor="surfaceDefault"
                      key={vendorDetails.current_actor.id}
                    >
                      <Inline gap="5" zIndex="10" position="relative">
                        <IconForActivityLog event="pending" />
                        {vendorDetails.activities.length ? (
                          <Box
                            as="hr"
                            position="absolute"
                            height="full"
                            marginX="1.5"
                            backgroundColor="borderSeparator"
                            style={{ width: 2, top: 30, zIndex: 1 }}
                          />
                        ) : null}
                        <Stack gap="3">
                          <TitleForActivityLog event="pending" />
                          <Inline gap="3" alignItems="center">
                            <Avatar
                              avatarSize="md"
                              backgroundColor="AvatarDisabled"
                              avatarIcon={(props) => <UserIcon {...props} />}
                            />
                            <Text variation="b2">
                              {vendorDetails.current_actor?.display_name}
                            </Text>
                          </Inline>
                        </Stack>
                      </Inline>
                    </Inline>
                  )}
                  {vendorDetails?.activities.map((activity, i) => (
                    <ActivityLogItem
                      user={user}
                      key={activity.id}
                      activity={activity}
                      isLastItem={i + 1 === vendorDetails.activities.length}
                    />
                  ))}
                </Stack>
              </Stack>
            ) : null}
          </Inline>
        );
    }
  }, [
    user,
    loadingStatus,
    vendorDetails,
    hasEditProfilePermission,
    hasDeactivateVendorPermission,
    isDeactivateConfirmationDialogVisible,
    isDeactivateVendorApiLoading,
    reloadDetails,
    navigateToEditVendor,
    openDeactivateVendorConfirmationDialog,
    closeDeactivateVendorConfirmationDialog,
    deactivateVendor,
  ]);

  return (
    <Box paddingX="5" paddingTop="4">
      <Stack marginBottom="3">
        <Breadcrumbs marginBottom="0.5">
          {fromScreenName && (
            <BreadcrumbItem isFirst onClick={navigateToVendorsListing}>
              {fromScreenName}
            </BreadcrumbItem>
          )}
          {fromScreenName !== 'Waiting for Approval' && (
            <BreadcrumbItem onClick={goBack}>Vendor Details</BreadcrumbItem>
          )}
          <BreadcrumbItem isCurrent>Vendor Profile</BreadcrumbItem>
        </Breadcrumbs>
        <SecondaryHeader
          onClickBack={goBack}
          headingText="Vendor Profile"
          renderRight={() => {
            if (vendorDetails?.i_can_act_on) {
              return (
                <ApproveOrRejectFlowInModal
                  vendor_details={vendorDetails}
                  onSuccess={reloadDetails}
                >
                  {({ onOpen }) => (
                    <Inline gap="4">
                      <Button
                        id={`${idPrefix}-reject-profile`}
                        title="Reject"
                        buttonRole="destructive"
                        type="outlined"
                        leftIcon={(props) => <CancelIcon {...props} />}
                        onClick={() => onOpen('reject')}
                      />
                      <Button
                        id={`${idPrefix}-approve-details`}
                        title="Approve"
                        buttonRole="success"
                        onClick={() => onOpen('approve')}
                        leftIcon={(props) => <CheckIcon {...props} />}
                      />
                    </Inline>
                  )}
                </ApproveOrRejectFlowInModal>
              );
            }
            return null;
          }}
        />
      </Stack>
      {renderVendorDetails}
    </Box>
  );
};

export default VendorProfile;

export const VendorCompleteDetails: React.FC<{
  vendorDetails: VendorDetails;
}> = ({ vendorDetails }) => {
  const { vendorDetailSections, selectedSection, onClickSection } =
    useVendorProfileDetails();

  const renderVendorDetailSectionsNavitgation = useMemo(() => {
    return (
      <Inline borderBottomWidth="1" borderColor="borderSeparator">
        {vendorDetailSections.map((section) => {
          const isSelected = section.stateId === selectedSection;
          return (
            <Box
              id={`${idPrefix}-complete-${section.stateId}`}
              borderBottomWidth="2"
              borderColor={isSelected ? 'borderPrimary' : 'transparent'}
              padding="1.5"
              onClick={() => onClickSection(section.stateId)}
              cursor="pointer"
            >
              <Text
                variation="t4"
                color={isSelected ? 'textPrimary' : 'textMedium'}
              >
                {section.title}
              </Text>
            </Box>
          );
        })}
      </Inline>
    );
  }, [selectedSection, vendorDetailSections, onClickSection]);

  const renderVendorPrimaryDetails = useMemo(() => {
    return (
      <Box key="primaryDetails" marginY="4" display="flex">
        <Box style={{ flex: 5 }}>
          <Box>
            <Inline justifyContent="between" marginBottom="5">
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Legal Entity Name
                </Text>
                <Text variation="b2">{vendorDetails.legal_name || '-'}</Text>
              </Box>
            </Inline>
            <Inline justifyContent="between" marginBottom="5">
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Branch
                </Text>
                <Inline>
                  {vendorDetails.branches.map((branch, index) => {
                    const isLastIndex =
                      index === vendorDetails.branches.length - 1;
                    return (
                      <Text
                        key={branch.id}
                        variation="b2"
                        marginRight="0.5"
                      >{`${branch.name}${isLastIndex ? '' : ','}`}</Text>
                    );
                  })}
                </Inline>
              </Box>
            </Inline>
            <Inline justifyContent="between" marginBottom="5">
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  PAN
                </Text>
                <Text variation="b2">{vendorDetails.pan_number || '-'}</Text>
              </Box>
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  TAN
                </Text>
                <Text variation="b2">{vendorDetails.tan_number || '-'}</Text>
              </Box>
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  CIN
                </Text>
                <Text variation="b2">{vendorDetails.cin_number || '-'}</Text>
              </Box>
            </Inline>
            {vendorDetails.attachments.pancard_certificate && (
              <Box width="fitContent" marginBottom="5">
                <Text variation="c2" color="textLow" marginBottom="1">
                  PAN Card
                </Text>
                <UploadDocument
                  id={`${idPrefix}-pan-card`}
                  type="secondary"
                  showDownloadButton={true}
                  documentInfo={{
                    imgSrc: vendorDetails.attachments.pancard_certificate?.url,
                    name: vendorDetails.attachments.pancard_certificate?.name,
                    size: parseInt(
                      vendorDetails.attachments.pancard_certificate?.size || ''
                    ),
                    type: vendorDetails.attachments.pancard_certificate
                      ?.mime_type,
                  }}
                />
              </Box>
            )}
            {vendorDetails.attachments.company_reg_cert && (
              <Box width="fitContent">
                <Text variation="c2" color="textLow" marginBottom="1">
                  Company Registration Certificate
                </Text>
                <UploadDocument
                  id={`${idPrefix}-company-registration-certificate`}
                  type="secondary"
                  showDownloadButton={true}
                  documentInfo={{
                    imgSrc: vendorDetails.attachments.company_reg_cert?.url,
                    name: vendorDetails.attachments.company_reg_cert?.name,
                    size: parseInt(
                      vendorDetails.attachments.company_reg_cert?.size || ''
                    ),
                    type: vendorDetails.attachments.company_reg_cert?.mime_type,
                  }}
                />
              </Box>
            )}
          </Box>
          <Box
            width="full"
            borderBottomWidth="1"
            borderColor="borderSeparator"
            marginY="5"
          />
          <Inline justifyContent="between" marginBottom="5" flexWrap="wrap">
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                DPIIT Certified?
              </Text>
              <Text variation="b2">
                {vendorDetails.is_dpiit_certified ? 'Yes' : 'No'}
              </Text>
            </Box>
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                DPIIT Certificate Number
              </Text>
              <Text variation="b2">
                {vendorDetails.dpiit_cert_number || '-'}
              </Text>
            </Box>
            {vendorDetails.attachments.dpiit_certificate ? (
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="1">
                  DPIIT Certificate
                </Text>
                <UploadDocument
                  id={`${idPrefix}-dpiit-certificate`}
                  type="secondary"
                  showDownloadButton={true}
                  documentInfo={{
                    imgSrc: vendorDetails.attachments.dpiit_certificate.url,
                    name: vendorDetails.attachments.dpiit_certificate.name,
                    size: parseInt(
                      vendorDetails.attachments.dpiit_certificate.size || ''
                    ),
                    type: vendorDetails.attachments.dpiit_certificate.mime_type,
                  }}
                />
              </Box>
            ) : (
              <Box marginRight="5" flex="1" />
            )}
          </Inline>
          <Box
            width="full"
            borderBottomWidth="1"
            borderColor="borderSeparator"
            marginY="5"
          />
          <Inline justifyContent="between" marginBottom="5" flexWrap="wrap">
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                GST Registered?
              </Text>
              <Text variation="b2">
                {vendorDetails.is_gst_registered ? 'Yes' : 'No'}
              </Text>
            </Box>
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                GST Number
              </Text>
              <Text variation="b2">{vendorDetails.gst_number || '-'}</Text>
            </Box>
            {vendorDetails.attachments.gst_certificate ? (
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="1">
                  GST Certificate
                </Text>
                <UploadDocument
                  id={`${idPrefix}-gst-certificate`}
                  type="secondary"
                  showDownloadButton={true}
                  documentInfo={{
                    imgSrc: vendorDetails.attachments.gst_certificate.url,
                    name: vendorDetails.attachments.gst_certificate.name,
                    size: parseInt(
                      vendorDetails.attachments.gst_certificate.size || ''
                    ),
                    type: vendorDetails.attachments.gst_certificate.mime_type,
                  }}
                />
              </Box>
            ) : (
              <Box marginRight="5" flex="1" />
            )}
          </Inline>
          <Box
            width="full"
            borderBottomWidth="1"
            borderColor="borderSeparator"
            marginY="5"
          />
          <Inline justifyContent="between" marginBottom="5" flexWrap="wrap">
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                MSME Registered?
              </Text>
              <Text variation="b2">
                {vendorDetails.is_msme_registered ? 'Yes' : 'No'}
              </Text>
            </Box>
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                MSME Reg Number
              </Text>
              <Text variation="b2">{vendorDetails.msme_reg_number || '-'}</Text>
            </Box>
            {vendorDetails.attachments.msme_certificate ? (
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="1">
                  MSME Certificate
                </Text>
                <UploadDocument
                  id={`${idPrefix}-msme-certificate`}
                  type="secondary"
                  showDownloadButton={true}
                  documentInfo={{
                    imgSrc: vendorDetails.attachments.msme_certificate.url,
                    name: vendorDetails.attachments.msme_certificate.name,
                    size: parseInt(
                      vendorDetails.attachments.msme_certificate.size || ''
                    ),
                    type: vendorDetails.attachments.msme_certificate.mime_type,
                  }}
                />
              </Box>
            ) : (
              <Box marginRight="5" flex="1" />
            )}
          </Inline>
          <Box
            width="full"
            borderBottomWidth="1"
            borderColor="borderSeparator"
            marginY="5"
          />
          <Inline justifyContent="between">
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                E-Invoicing
              </Text>
              <Text variation="b2">
                {vendorDetails.is_einvoicing_enabled ? 'Yes' : 'No'}
              </Text>
            </Box>
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                Office Status
              </Text>
              <Text variation="b2">
                {vendorDetails.office_status === 'owned' ? 'Owned' : 'Rented'}
              </Text>
            </Box>
            <Box marginRight="5" flex="1" />
          </Inline>
        </Box>
        <Box style={{ flex: 1 }} />
      </Box>
    );
  }, [vendorDetails]);

  const renderVendorAddressDetails = useMemo(() => {
    const isApplicantDetailsSameAsContactPersonDetails =
      JSON.stringify(vendorDetails.applicant_details) ===
      JSON.stringify(vendorDetails.contact_person_details);

    const isBusinessAddressSameAsRegisteredAddress =
      JSON.stringify(vendorDetails.business_address) ===
      JSON.stringify(vendorDetails.registered_address);

    return (
      <Box key="addressDetails" marginY="4" display="flex">
        <Box style={{ flex: 5 }}>
          <Box marginBottom="5">
            <Text variation="t3" marginBottom="2">
              Contact Person Details
            </Text>
            <Inline display="flex" justifyContent="between" marginBottom="3">
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Vendor Name
                </Text>
                <Text variation="b2">
                  {vendorDetails.contact_person_details.name || '-'}
                </Text>
              </Box>
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Designation
                </Text>
                <Text variation="b2">
                  {vendorDetails.contact_person_details.designation || '-'}
                </Text>
              </Box>
              <Box marginRight="5" flex="1" />
            </Inline>
            <Inline justifyContent="between" alignItems="center">
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Mobile Number
                </Text>
                <Text variation="b2">
                  {vendorDetails.contact_person_details.mobile_number || '-'}
                </Text>
              </Box>
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Phone Number
                </Text>
                <Text variation="b2">
                  {vendorDetails.contact_person_details.phone_number || '-'}
                </Text>
              </Box>
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Email ID
                </Text>
                <Text variation="b2">
                  {vendorDetails.contact_person_details.email || '-'}
                </Text>
              </Box>
            </Inline>
          </Box>
          <Box marginBottom="5">
            <Text variation="t3" marginBottom="2">
              Applicant Details
            </Text>
            {isApplicantDetailsSameAsContactPersonDetails ? (
              <Inline alignItems="center">
                <Checkbox isDisabled isSelected />
                <Text variation="b2" marginLeft="1">
                  Applicant Details is same as Contact Person Details
                </Text>
              </Inline>
            ) : (
              <>
                <Inline
                  display="flex"
                  justifyContent="between"
                  marginBottom="3"
                >
                  <Box marginRight="5" flex="1">
                    <Text variation="c2" color="textLow" marginBottom="0.5">
                      Vendor Name
                    </Text>
                    <Text variation="b2">
                      {vendorDetails.applicant_details.name || '-'}
                    </Text>
                  </Box>
                  <Box marginRight="5" flex="1">
                    <Text variation="c2" color="textLow" marginBottom="0.5">
                      Designation
                    </Text>
                    <Text variation="b2">
                      {vendorDetails.applicant_details.designation || '-'}
                    </Text>
                  </Box>
                  <Box marginRight="5" flex="1" />
                </Inline>
                <Inline justifyContent="between" alignItems="center">
                  <Box marginRight="5" flex="1">
                    <Text variation="c2" color="textLow" marginBottom="0.5">
                      Mobile Number
                    </Text>
                    <Text variation="b2">
                      {vendorDetails.applicant_details.mobile_number || '-'}
                    </Text>
                  </Box>
                  <Box marginRight="5" flex="1">
                    <Text variation="c2" color="textLow" marginBottom="0.5">
                      Phone Number
                    </Text>
                    <Text variation="b2">
                      {vendorDetails.applicant_details.phone_number || '-'}
                    </Text>
                  </Box>
                  <Box marginRight="5" flex="1">
                    <Text variation="c2" color="textLow" marginBottom="0.5">
                      Email ID
                    </Text>
                    <Text variation="b2">
                      {vendorDetails.applicant_details.email || '-'}
                    </Text>
                  </Box>
                </Inline>
              </>
            )}
          </Box>
          <Box marginBottom="5">
            <Text variation="t3" marginBottom="2">
              Registered Address
            </Text>
            <Inline display="flex" justifyContent="between" marginBottom="3">
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Address Line 1
                </Text>
                <Text variation="b2">
                  {vendorDetails.registered_address.address_line_1 || '-'}
                </Text>
              </Box>
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Address Line 2
                </Text>
                <Text variation="b2">
                  {vendorDetails.registered_address.address_line_2 || '-'}
                </Text>
              </Box>
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Pincode
                </Text>
                <Text variation="b2">
                  {vendorDetails.registered_address.pincode || '-'}
                </Text>
              </Box>
            </Inline>
            <Inline
              justifyContent="between"
              alignItems="center"
              marginBottom="3"
            >
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  City
                </Text>
                <Text variation="b2">
                  {vendorDetails.registered_address.city || '-'}
                </Text>
              </Box>
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  State
                </Text>
                <Text variation="b2">
                  {vendorDetails.registered_address.state || '-'}
                </Text>
              </Box>
              <Box marginRight="5" flex="1">
                <Text variation="c2" color="textLow" marginBottom="0.5">
                  Phone Number
                </Text>
                <Text variation="b2">
                  {vendorDetails.registered_address.phone_number || '-'}
                </Text>
              </Box>
            </Inline>
            <Box>
              <Text variation="c2" color="textLow" marginBottom="0.5">
                Email ID
              </Text>
              <Text variation="b2">
                {vendorDetails.registered_address.email || '-'}
              </Text>
            </Box>
          </Box>
          <Box>
            <Text variation="t3" marginBottom="2">
              Business Address
            </Text>
            {isBusinessAddressSameAsRegisteredAddress ? (
              <Inline alignItems="center">
                <Checkbox isDisabled isSelected />
                <Text variation="b2" marginLeft="1">
                  Business Address is same as Registered Address
                </Text>
              </Inline>
            ) : (
              <>
                <Inline
                  display="flex"
                  justifyContent="between"
                  marginBottom="3"
                >
                  <Box marginRight="5" flex="1">
                    <Text variation="c2" color="textLow" marginBottom="0.5">
                      Address Line 1
                    </Text>
                    <Text variation="b2">
                      {vendorDetails.business_address.address_line_1 || '-'}
                    </Text>
                  </Box>
                  <Box marginRight="5" flex="1">
                    <Text variation="c2" color="textLow" marginBottom="0.5">
                      Address Line 2
                    </Text>
                    <Text variation="b2">
                      {vendorDetails.business_address.address_line_2 || '-'}
                    </Text>
                  </Box>
                  <Box marginRight="5" flex="1">
                    <Text variation="c2" color="textLow" marginBottom="0.5">
                      Pincode
                    </Text>
                    <Text variation="b2">
                      {vendorDetails.business_address.pincode || '-'}
                    </Text>
                  </Box>
                </Inline>
                <Inline
                  justifyContent="between"
                  alignItems="center"
                  marginBottom="3"
                >
                  <Box marginRight="5" flex="1">
                    <Text variation="c2" color="textLow" marginBottom="0.5">
                      City
                    </Text>
                    <Text variation="b2">
                      {vendorDetails.business_address.city || '-'}
                    </Text>
                  </Box>
                  <Box marginRight="5" flex="1">
                    <Text variation="c2" color="textLow" marginBottom="0.5">
                      State
                    </Text>
                    <Text variation="b2">
                      {vendorDetails.business_address.state || '-'}
                    </Text>
                  </Box>
                  <Box marginRight="5" flex="1">
                    <Text variation="c2" color="textLow" marginBottom="0.5">
                      Phone Number
                    </Text>
                    <Text variation="b2">
                      {vendorDetails.business_address.phone_number || '-'}
                    </Text>
                  </Box>
                </Inline>
                <Box>
                  <Text variation="c2" color="textLow" marginBottom="0.5">
                    Email ID
                  </Text>
                  <Text variation="b2">
                    {vendorDetails.business_address.email || '-'}
                  </Text>
                </Box>
              </>
            )}
          </Box>
          {!!vendorDetails.attachments.address_proof && (
            <Box width="fitContent" marginTop="5">
              <Text variation="c2" color="textLow" marginBottom="1">
                Address Proof Doc
              </Text>
              <UploadDocument
                id={`${idPrefix}-address-proof-doc`}
                type="secondary"
                showDownloadButton={true}
                documentInfo={{
                  imgSrc: vendorDetails.attachments.address_proof?.url,
                  name: vendorDetails.attachments.address_proof?.name,
                  size: parseInt(
                    vendorDetails.attachments.address_proof?.size || ''
                  ),
                  type: vendorDetails.attachments.address_proof?.mime_type,
                }}
              />
            </Box>
          )}
        </Box>
        <Box style={{ flex: 1 }} />
      </Box>
    );
  }, [
    vendorDetails.business_address,
    vendorDetails.registered_address,
    vendorDetails.applicant_details,
    vendorDetails.contact_person_details,
    vendorDetails.attachments,
  ]);

  const renderVendorPaymentDetails = useMemo(() => {
    return (
      <Box key="paymentDetails" marginY="4" display="flex">
        <Box style={{ flex: 5 }}>
          <Inline justifyContent="between">
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                Bank Name
              </Text>
              <Text variation="b2">
                {vendorDetails.payment_details.bank_name || '-'}
              </Text>
            </Box>
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                Account Type
              </Text>
              <Text variation="b2">
                {vendorDetails.payment_details.account_type === 'current'
                  ? 'Current'
                  : 'Savings' || '-'}
              </Text>
            </Box>
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                Swift Code
              </Text>
              <Text variation="b2">
                {vendorDetails.payment_details.swift_code || '-'}
              </Text>
            </Box>
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                MICR
              </Text>
              <Text variation="b2">
                {vendorDetails.payment_details.micr || '-'}
              </Text>
            </Box>
          </Inline>
          <Box
            width="full"
            borderBottomWidth="1"
            borderColor="borderSeparator"
            marginY="5"
          />
          <Inline justifyContent="between">
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                Account Holder Name
              </Text>
              <Text variation="b2" flex="1">
                {vendorDetails.payment_details.account_holder_name || '-'}
              </Text>
            </Box>
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                Account Number
              </Text>
              <Text variation="b2">
                {vendorDetails.payment_details.account_number || '-'}
              </Text>
            </Box>
            <Box marginRight="5" flex="1">
              <Text variation="c2" color="textLow" marginBottom="0.5">
                IFSC Code
              </Text>
              <Text variation="b2">
                {vendorDetails.payment_details.ifsc_code || '-'}
              </Text>
            </Box>
          </Inline>
          {vendorDetails.attachments.cancelled_cheque ? (
            <>
              <Box
                width="full"
                borderBottomWidth="1"
                borderColor="borderSeparator"
                marginY="5"
              />
              <Box width="fitContent">
                <Text variation="c2" color="textLow" marginBottom="1">
                  Copy of Cancelled Cheque
                </Text>
                <UploadDocument
                  id={`${idPrefix}-cancelled-cheque`}
                  type="secondary"
                  showDownloadButton={true}
                  documentInfo={{
                    imgSrc: vendorDetails.attachments.cancelled_cheque?.url,
                    name: vendorDetails.attachments.cancelled_cheque?.name,
                    size: parseInt(
                      vendorDetails.attachments.cancelled_cheque?.size || ''
                    ),
                    type: vendorDetails.attachments.cancelled_cheque?.mime_type,
                  }}
                />
              </Box>
            </>
          ) : (
            <Box marginRight="5" flex="1" />
          )}
        </Box>
        <Box style={{ flex: 1 }} />
      </Box>
    );
  }, [vendorDetails.payment_details, vendorDetails.attachments]);

  const renderVendorLinks = useMemo(() => {
    return (
      <Box key="links" marginY="4">
        <Box marginBottom="5">
          <Text variation="c2" color="textLow" marginBottom="0.5">
            Website Link
          </Text>
          <Text variation="b2">
            {vendorDetails.social_links?.website || '-'}
          </Text>
        </Box>
        <Box marginBottom="5">
          <Text variation="c2" color="textLow" marginBottom="0.5">
            Facebook Page Link
          </Text>
          <Text variation="b2">
            {vendorDetails.social_links?.facebook || '-'}
          </Text>
        </Box>
        <Box marginBottom="5">
          <Text variation="c2" color="textLow" marginBottom="0.5">
            Instagram Page Link
          </Text>
          <Text variation="b2">
            {vendorDetails.social_links?.instagram || '-'}
          </Text>
        </Box>
        <Box marginBottom="5">
          <Text variation="c2" color="textLow" marginBottom="0.5">
            LinkedIn Profile Link
          </Text>
          <Text variation="b2">
            {vendorDetails.social_links?.linkedin || '-'}
          </Text>
        </Box>
        <Box marginBottom="5">
          <Text variation="c2" color="textLow" marginBottom="0.5">
            Twitter Page Link
          </Text>
          <Text variation="b2">
            {vendorDetails.social_links?.twitter || '-'}
          </Text>
        </Box>
        <Box marginBottom="5">
          <Text variation="c2" color="textLow" marginBottom="0.5">
            Other Link
          </Text>
          <Text variation="b2">
            {vendorDetails.social_links?.others || '-'}
          </Text>
        </Box>
      </Box>
    );
  }, [vendorDetails.social_links]);

  const renderSectionDetails = useMemo(() => {
    switch (selectedSection) {
      case 'primaryDetails':
        return renderVendorPrimaryDetails;
      case 'addressDetails':
        return renderVendorAddressDetails;
      case 'paymentDetails':
        return renderVendorPaymentDetails;
      case 'links':
        return renderVendorLinks;
      default:
        return null;
    }
  }, [
    selectedSection,
    renderVendorPrimaryDetails,
    renderVendorAddressDetails,
    renderVendorPaymentDetails,
    renderVendorLinks,
  ]);

  return (
    <>
      {renderVendorDetailSectionsNavitgation}
      {renderSectionDetails}
    </>
  );
};

function ActivityLogItem({
  user,
  activity,
  isLastItem,
}: {
  user: UserDetails;
  activity: VendorActivity;
  isLastItem?: boolean;
}) {
  const { created_at, event, causer } = activity;
  return (
    <Inline as="li" backgroundColor="surfaceDefault">
      <Inline gap="5" zIndex="10" position="relative">
        <IconForActivityLog event={event} />
        {isLastItem ? null : (
          <Box
            as="hr"
            position="absolute"
            height="full"
            marginX="1.5"
            backgroundColor="borderSeparator"
            style={{ width: 2, top: 30, zIndex: 1 }}
          />
        )}

        <Stack gap="3">
          <TitleForActivityLog event={event} date={created_at} />
          <Inline gap="3">
            <Avatar
              as="img"
              src={causer?.avatar?.preview || ''}
              avatarSize="md"
            />
            <Stack gap="1">
              <Text variation="b2">
                {causer?.name} {user.id === causer?.id ? `(You)` : ''}
              </Text>
              {causer?.roles?.map((role) => (
                <Text variation="c2" color="textMedium">
                  {role.display_name}
                </Text>
              ))}
            </Stack>
          </Inline>
        </Stack>
      </Inline>
    </Inline>
  );
}

type IconType = (props: IconParams) => React.ReactElement;
function IconForActivityLog({
  event,
}: {
  event: VendorActivityEvent | 'pending';
}) {
  const iconBody: IconType | undefined = useMemo(() => {
    switch (event) {
      case 'updated':
        return (props) => <PencilIcon {...props} />;
      case 'approved':
        return (props) => <CheckIcon {...props} />;
      case 'pending':
        return (props) => <ClockIcon {...props} />;
      case 'rejected':
        return (props) => <CrossIcon {...props} />;
      case 'deactivated':
        return (props) => <DeleteIcon {...props} />;
      case 'reactivated':
        return (props) => <CheckIcon {...props} />;
      default:
        return (props) => <FilePlusIcon {...props} />;
    }
  }, [event]);

  return iconBody ? (
    <Avatar
      avatarSize="sm"
      backgroundColor={
        event === 'approved' || event === 'reactivated'
          ? 'AvatarSuccess'
          : event === 'pending'
          ? 'AvatarWarning'
          : event === 'rejected' || event === 'deactivated'
          ? 'AvatarError'
          : 'Avatar9'
      }
      avatarIcon={(props) => iconBody(props)}
    />
  ) : null;
}

function TitleForActivityLog({
  event,
  date,
}: {
  event: VendorActivityEvent | 'pending';
  date?: Date | string;
}) {
  return (
    <Text variation="c4" color="textMedium">
      {event === 'created'
        ? `Created on `
        : event === 'updated'
        ? 'Updated on '
        : event === 'approved'
        ? 'Approved on '
        : event === 'pending'
        ? 'Pending'
        : event === 'rejected'
        ? 'Rejected on '
        : event === 'deactivated'
        ? 'Deactivated on '
        : event === 'reactivated'
        ? 'Reactivated on '
        : ''}
      {date ? (
        <DateUi
          date={date}
          color="textMedium"
          variation="c4"
          format="dd MMM yyyy, hh:mm a"
        />
      ) : (
        ''
      )}
    </Text>
  );
}
