import {
  Invoice_Status,
  Payment_status,
  VendorDetails as VendorDetailsType,
  useBranchesForFilter,
  useInvoices,
  useVendorDetails,
  vendorRegistrationTypeOptions,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  Box,
  Stack,
  Breadcrumbs,
  BreadcrumbItem,
  SecondaryHeader,
  Button,
  Text,
  Inline,
  Avatar,
  Tabs,
  EmptyState,
  FileIcon,
  Amount,
  Date as DateAndTime,
  Time,
  SpinnerIcon,
  DateSelect,
  MultiSelect,
  TextInput,
  SearchIcon,
  RightCaratIcon,
  SkeletonRows,
  EyeIcon,
  CheckIcon,
  ConfirmationDialog,
  ReturnIcon,
} from '@nbfc-expense-tool/ui';
import React from 'react';
import { useMemo, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Item } from 'react-stately';
import { AddNewInvoiceButton } from '../../Invoices/AddNewInvoiceButton';
import { debounce } from '@nbfc-expense-tool/data-store/utils';

function figureOutPreviousLinks(searchParams: URLSearchParams): string {
  const from = searchParams.get('from');
  switch (from) {
    case 'active-vendors':
      return 'Active Vendors';
    case 'inactive-vendors':
      return 'Inactive Vendors';
    default:
      return 'Waiting for Approval';
  }
}

const idPrefix = 'vendor-details';

export const VendorDetails = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const previousLink = useMemo(() => {
    return figureOutPreviousLinks(searchParams);
  }, [searchParams]);

  const { vendorId } = useParams();

  const {
    vendor,
    status,
    hasReactivateVendorPermission,
    reactivateVendor,
    openReactivateVendorConfirmationDialog,
    closeReactivateVendorConfirmationDialog,
    isReactivateConfirmationDialogVisible,
    isReactivateVendorApiLoading,
  } = useVendorDetails(vendorId || '');

  return (
    <Box paddingTop={'4'} paddingX={'5'}>
      <Inline justifyContent="between">
        <Stack display={'flex'} flex="1" width="full">
          <Breadcrumbs>
            <BreadcrumbItem
              isCurrent={false}
              isFirst
              onClick={() => navigate(-1)}
            >
              {previousLink}
            </BreadcrumbItem>
            <BreadcrumbItem isCurrent>Vendor Details</BreadcrumbItem>
          </Breadcrumbs>
          <SecondaryHeader
            onClickBack={() => navigate(-1)}
            headingText="Vendor Details"
            renderRight={
              vendor?.approval_status === 'approved' &&
              vendor.vendor_status === 'active'
                ? () => {
                    return <AddNewInvoiceButton label="Add New Invoice" />;
                  }
                : undefined
            }
          />
        </Stack>
        {hasReactivateVendorPermission &&
          vendor?.vendor_status === 'inactive' && (
            <Button
              id={`${idPrefix}-reactivate-inactivated-vendor`}
              title="Re-Activate"
              leftIcon={(props) => <CheckIcon {...props} />}
              onClick={openReactivateVendorConfirmationDialog}
              state={isReactivateVendorApiLoading ? 'loading' : undefined}
            />
          )}
      </Inline>
      {!!vendor && (
        <ProfileHeader
          vendor={vendor}
          from={searchParams.get('from') || 'waiting-for-approval'}
        />
      )}
      {status === 'in_progress' && (
        <Inline
          gap="4"
          flex={'1'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          marginTop={'4'}
        >
          <SpinnerIcon size="3" color="iconMedium" />
          <Text variation="b2">Loading...</Text>
        </Inline>
      )}
      <ConfirmationDialog
        id={`${idPrefix}-reactivate-profile`}
        title="Re-Activate Profile"
        description={`You want to reactivate '${vendor?.name}' vendor profile.`}
        isVisible={isReactivateConfirmationDialogVisible}
        confirmLabel="Yes, Reactivate"
        toggleButtons
        onCancel={closeReactivateVendorConfirmationDialog}
        onConfirm={reactivateVendor}
      />
    </Box>
  );
};

type TabOption = {
  tabLabel: string;
  name: 'undereview' | 'approved_unpaid' | 'approved_paid';
};

const tabOptions: TabOption[] = [
  {
    tabLabel: 'Invoices Under Review',
    name: 'undereview',
  },
  {
    tabLabel: 'Approved & Unpaid',
    name: 'approved_unpaid',
  },
  {
    tabLabel: 'Approved & Paid',
    name: 'approved_paid',
  },
];

type TabOptionNames = (typeof tabOptions)[number]['name'];

const ProfileHeader = ({
  vendor,
  from,
}: {
  vendor: VendorDetailsType;
  from: string;
}) => {
  const [selectedTab, setSelectedTab] = useState<TabOptionNames>(
    tabOptions[0].name
  );

  return (
    <Box>
      <Box bgColor={'surfaceDefault'} borderRadius="md" marginBottom={'4'}>
        <Stack
          flexDirection="row"
          alignItems="center"
          paddingY={'4'}
          marginTop={'3'}
          borderBottomWidth="1"
          borderColor="borderSeparator"
          marginX={'4'}
          gap={'4'}
          justifyContent="between"
        >
          <Inline marginRight="5" alignItems="start" flex="1">
            <Avatar
              marginRight={'2'}
              avatarText="R"
              avatarSize="md"
              id={vendor.id.toString()}
            />
            <Box flex="1">
              <Inline alignItems="start">
                <Text color="textHigh" variation={'t1'}>
                  {vendor.name}
                </Text>
                <VendorStatus
                  approvalStatus={vendor.approval_status}
                  vendorStatus={vendor.vendor_status}
                />
              </Inline>
              <Inline alignItems="center">
                <Text color={'textLow'} variation={'b2'}>
                  {vendor.vendor_type?.name || '-'}
                </Text>
                <Box
                  width="0.5"
                  height="0.5"
                  borderRadius="full"
                  backgroundColor="textLow"
                  marginX="1"
                />
                <Text color={'textLow'} variation={'b2'}>
                  {vendorRegistrationTypeOptions.find(
                    (item) => item.value === vendor.company_reg_type
                  )?.label || '-'}
                </Text>
                <Box
                  width="0.5"
                  height="0.5"
                  borderRadius="full"
                  backgroundColor="textLow"
                  marginX="1"
                />
                <Text color={'textLow'} variation={'b2'}>
                  {vendor.applicant_details?.email || '-'}
                </Text>
              </Inline>
            </Box>
          </Inline>
          <Inline
            as={Link}
            to={`/home/vendors/${vendor.id}/vendor-profile?from=${from}`}
            textDecoration="none"
            alignItems="center"
            width="fitContent"
          >
            <EyeIcon size="3" color="iconPrimary" marginRight={'1'} />
            <Text color={'textPrimary'} variation={'b2'}>
              View Profile
            </Text>
          </Inline>
        </Stack>
        <OverView vendor={vendor} />
      </Box>
      <Tabs
        selectedKey={selectedTab}
        onClick={(tabName: string) => {
          setSelectedTab(tabName as TabOptionNames);
        }}
      >
        {tabOptions.map((option) => (
          <Item
            key={option.name}
            textValue={option.name}
            title={
              <Box textDecoration="none" style={{ color: 'inherit' }}>
                {option.tabLabel}
              </Box>
            }
          >
            <InvoicesTable
              tabName={option.name}
              isActiveAndApprovedVendor={
                vendor.approval_status === 'approved' &&
                vendor.vendor_status === 'active'
              }
            />
          </Item>
        ))}
      </Tabs>
    </Box>
  );
};

const InvoicesTable = ({
  tabName,
  isActiveAndApprovedVendor,
}: {
  tabName: TabOptionNames;
  isActiveAndApprovedVendor: boolean;
}) => {
  const invoicesIdPrefix = `${idPrefix}-${tabName}-invoices`;
  const { vendorId } = useParams();
  const { status, branches } = useBranchesForFilter();
  const navigate = useNavigate();

  const getFiltersBasedOnTab = (): {
    invoice_status: Invoice_Status;
    payment_status?: Payment_status;
  } => {
    switch (tabName) {
      case 'undereview':
        return {
          invoice_status: 'under_review',
        };
      case 'approved_paid':
        return {
          invoice_status: 'approved',
          payment_status: 'paid',
        };

      case 'approved_unpaid':
        return {
          invoice_status: 'approved',
          payment_status: 'unpaid',
        };

      default:
        return {
          invoice_status: 'approved',
          payment_status: 'unpaid',
        };
    }
  };

  const filtersBasedUponTab = getFiltersBasedOnTab();

  const {
    invoices,
    loading,
    perPage,
    totalInvoices,
    canGoNext,
    canGoBack,
    syncingData,
    currentPage,
    lastPage,
    params,
    hasAppliedFilters,
    resetFilters,
    handlePageChange,
    handleDateChange,
    handleParamChange,
    exportExcelReport,
    reportLoading,
  } = useInvoices('all-invoices', {
    ...filtersBasedUponTab,
    vendors: [vendorId || ''],
  });

  const branchForFilters = useMemo(() => {
    return branches?.map((b) => {
      return {
        label: `${b.name} - ${b.branch_code}`,
        value: `${b.id}`,
      };
    });
  }, [branches]);

  return !invoices?.length && !hasAppliedFilters ? (
    <Stack paddingTop="10" alignItems="center" justifyContent="center">
      {loading === 'in_progress' ? (
        <Inline gap="4">
          <SpinnerIcon size="3" color="iconMedium" />
          <Text variation="b2">Loading...</Text>
        </Inline>
      ) : (
        <EmptyState
          renderIcon={(props) => <FileIcon {...props} />}
          title="No Invoices Found!"
          subText={
            isActiveAndApprovedVendor
              ? `Tap ‘Add New’ to create invoice for this vendor`
              : 'No invoices found for this vendor.'
          }
          renderButton={
            isActiveAndApprovedVendor
              ? () => <AddNewInvoiceButton />
              : undefined
          }
        />
      )}
    </Stack>
  ) : (
    <Stack paddingY="3.5" gap="6">
      <Inline justifyContent="between">
        <Inline gap="4">
          <DateSelect
            id={`${invoicesIdPrefix}-select-date`}
            value={params.dateFilter}
            onSave={(option) => {
              handleDateChange(option);
            }}
          />
          <MultiSelect
            id={`${invoicesIdPrefix}-select-branch`}
            label="Branch"
            actionBtnTitle="Show Results"
            value={params.branches}
            options={branchForFilters}
            loadingOptions={status === 'in_progress'}
            onSave={(values) => {
              handleParamChange('branches', values);
            }}
          />
        </Inline>
        <Button
          id={`${idPrefix}-export-excel-button`}
          title="Export To Excel"
          onClick={exportExcelReport}
          type="outlined"
          state={reportLoading ? 'loading' : undefined}
          leftIcon={(props) => <ReturnIcon {...props} />}
        />
      </Inline>
      <Box
        rounded="md"
        borderWidth="1"
        paddingTop="2.5"
        borderColor="borderSeparator"
        backgroundColor="surfaceDefault"
      >
        <Stack gap="6">
          <Inline paddingX="2.5" alignItems="center" justifyContent="between">
            <Inline style={{ width: 324 }}>
              <TextInput
                minHeight="5"
                id={`${invoicesIdPrefix}-search-input`}
                aria-label="search"
                placeholder="Search by invoice number or amount"
                leftIcon={(props) => <SearchIcon {...props} marginRight="1" />}
                fullWidth
                onChange={debounce((value) => {
                  handleParamChange('q', value);
                }, 500)}
              />
            </Inline>
            <Inline alignItems="center" gap="6">
              {totalInvoices ? (
                <Text color="textMedium" variation="c1">
                  Showing{' '}
                  {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}-
                  {lastPage === currentPage ? totalInvoices : invoices?.length}{' '}
                  of {totalInvoices}
                </Text>
              ) : null}
              <Inline gap="2">
                <Box
                  id={`${invoicesIdPrefix}-show-previous-page`}
                  as="button"
                  disabled={!canGoBack}
                  backgroundColor="transparent"
                  onClick={() =>
                    canGoBack ? handlePageChange('previous') : undefined
                  }
                >
                  <RightCaratIcon
                    cursor="pointer"
                    size="2.5"
                    rotate="180"
                    color={canGoBack ? 'iconMedium' : 'iconLowest'}
                  />
                </Box>
                <Box
                  as="hr"
                  width="px"
                  height="2.5"
                  backgroundColor="borderSeparator"
                />
                <Box
                  id={`${invoicesIdPrefix}-show-next-page`}
                  as="button"
                  disabled={!canGoNext}
                  backgroundColor="transparent"
                  onClick={() =>
                    canGoNext ? handlePageChange('next') : undefined
                  }
                >
                  <RightCaratIcon
                    cursor="pointer"
                    size="2.5"
                    color={canGoNext ? 'iconMedium' : 'iconLowest'}
                  />
                </Box>
              </Inline>
            </Inline>
          </Inline>
          <Box as="table" width="full" position="relative">
            <Box as="thead" bgColor="surfaceNeutralLowest">
              <Box as="tr">
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 82,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Date &amp; Time</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 82,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Creation Date &amp; Time</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Ticket ID</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                >
                  <Inline>
                    <Text variation="c1">Amount</Text>
                  </Inline>
                </Box>
                {tabName !== 'approved_paid' && (
                  <Box
                    as="th"
                    position="sticky"
                    paddingY="1.5"
                    paddingLeft="2"
                    paddingRight="3"
                    style={{
                      width: 120,
                    }}
                    top="0"
                    bgColor="surfaceNeutralLowest"
                    textAlign="left"
                  >
                    <Text variation="c1">Action</Text>
                  </Box>
                )}
              </Box>
            </Box>
            {syncingData ? (
              <SkeletonRows numOfRows={10} numOfCols={5} />
            ) : (
              <Box as="tbody">
                {invoices?.length ? (
                  invoices.map(
                    ({
                      id,
                      created_at,
                      vendor,
                      grand_total_amount,
                      invoice_status,
                      ticket_number,
                      invoice_date,
                    }) => {
                      return (
                        <React.Fragment key={id}>
                          <Box
                            as="tr"
                            borderTopWidth="1"
                            cursor="pointer"
                            tabIndex={-1}
                          >
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              textAlign="left"
                            >
                              <Stack gap="1">
                                <DateAndTime
                                  variation="b2"
                                  date={invoice_date}
                                />
                                <Time
                                  variation="c2"
                                  color="textMedium"
                                  date={invoice_date}
                                  format="hh:mm a"
                                />
                              </Stack>
                            </Box>
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              textAlign="left"
                            >
                              <Stack gap="1">
                                <DateAndTime variation="b2" date={created_at} />
                                <Time
                                  variation="c2"
                                  color="textMedium"
                                  date={created_at}
                                  format="hh:mm a"
                                />
                              </Stack>
                            </Box>
                            <Box as="td" paddingX="2" paddingY="1.5">
                              <Text variation="b2" marginBottom="2.5">
                                {ticket_number}
                              </Text>
                            </Box>
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="1.5"
                              className="whitespace-pre"
                            >
                              <Box marginBottom="2.5">
                                <Amount
                                  amount={Number(grand_total_amount || 0)}
                                  variation="t4"
                                />
                              </Box>
                            </Box>
                            {tabName !== 'approved_paid' && (
                              <Box
                                as="td"
                                paddingRight="3"
                                paddingY="1.5"
                                className="whitespace-pre"
                                textAlign="left"
                              >
                                <Box marginBottom="2.5">
                                  <Box width="fitContent">
                                    <Button
                                      id={`${invoicesIdPrefix}-review-button`}
                                      title="Review"
                                      type="link"
                                      onClick={() =>
                                        navigate(
                                          `invoice-details/${ticket_number}`
                                        )
                                      }
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </React.Fragment>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan={8}>
                      <EmptyState
                        renderIcon={(props) => <FileIcon {...props} />}
                        title="No Invoices Found!"
                        subText={`Please try changing your applied filters!`}
                        renderButton={() => (
                          <Button
                            id={`${invoicesIdPrefix}-empty-state-reset-filters-button`}
                            title="Reset Filters"
                            onClick={resetFilters}
                          />
                        )}
                      />
                    </td>
                  </tr>
                )}
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};

const VendorStatus = ({
  approvalStatus,
  vendorStatus,
}: {
  approvalStatus: string;
  vendorStatus: string;
}) => {
  const approvalStatusText = useMemo(() => {
    switch (vendorStatus) {
      case 'active':
        return approvalStatus === 'under_review' ? 'Under Review' : 'Active';
      case 'inactive':
        return 'Inactive';
      default:
        return null;
    }
  }, [approvalStatus, vendorStatus]);

  const approvalStatusBgColor = useMemo(() => {
    switch (vendorStatus) {
      case 'active':
        return approvalStatus === 'under_review'
          ? 'surfaceWarningLowest'
          : 'surfaceSuccessLowest';
      case 'inactive':
        return 'surfaceNeutralLowest';
      default:
        return 'surfaceNeutralLowest';
    }
  }, [approvalStatus, vendorStatus]);

  const approvalStatusTextColor = useMemo(() => {
    switch (vendorStatus) {
      case 'active':
        return approvalStatus === 'under_review'
          ? 'textWarning'
          : 'textSuccess';
      case 'inactive':
        return 'textLow';
      default:
        return 'textLow';
    }
  }, [approvalStatus, vendorStatus]);

  if (approvalStatusText && approvalStatusBgColor) {
    return (
      <Box
        marginLeft={'2.5'}
        bgColor={approvalStatusBgColor}
        paddingX="2"
        paddingY="0.5"
        borderRadius="md"
      >
        <Text variation={'c1'} color={approvalStatusTextColor}>
          {approvalStatusText}
        </Text>
      </Box>
    );
  }
  return null;
};

const OverView = ({ vendor }: { vendor: VendorDetailsType }) => {
  const overview = [
    {
      id: 'underReview',
      label: 'Under Review',
      value: vendor?.pending_invoices_count,
      totalAmount: vendor?.pending_invoices_sum_grand_total_amount,
    },
    {
      id: 'approved',
      label: 'Approved',
      value: vendor?.approved_invoices_count,
      totalAmount: vendor?.approved_invoices_sum_grand_total_amount,
    },
    {
      id: 'paid',
      label: 'Paid',
      value: vendor?.paid_invoices_count,
      totalAmount: vendor?.paid_invoices_sum_grand_total_amount,
    },
    {
      id: 'unpaid',
      label: 'Unpaid',
      value: vendor?.unpaid_invoices_count,
      totalAmount: vendor?.unpaid_invoices_sum_grand_total_amount,
    },
  ];

  return (
    <Box width="fitContent">
      <Inline as="ul" padding="1" backgroundColor="surfaceDefault">
        {overview.map(({ id, label, value, totalAmount }, i) => (
          <Box
            as="li"
            key={id}
            style={{
              listStyleType: 'none',
            }}
          >
            <Stack
              paddingY="2"
              paddingX="4"
              gap="1"
              position="relative"
              alignItems={i === 0 ? 'center' : 'start'}
              justifyContent="center"
              // as={Link}
              // to={path}
              textDecoration="none"
              style={{ color: 'inherit' }}
            >
              <Inline gap="3" alignItems="center">
                {id ? (
                  <Box
                    size="1.5"
                    rounded="full"
                    backgroundColor={
                      id === 'approved'
                        ? 'surfaceSuccess'
                        : id === 'underReview'
                        ? 'surfacePending'
                        : id === 'paid'
                        ? 'surfaceSuccess'
                        : id === 'unpaid'
                        ? 'surfaceRejected'
                        : 'transparent'
                    }
                  />
                ) : null}
                <Inline alignItems="center" gap="1">
                  <Text color="textLow" variation="c2">
                    {label}
                  </Text>
                </Inline>
              </Inline>
              <Text
                variation="h3"
                as="h3"
                marginLeft={i !== 0 ? '3' : undefined}
              >
                {value}
              </Text>
              <Amount
                variation="b2"
                color="textLow"
                marginLeft={i !== 0 ? '3' : undefined}
                amount={totalAmount || 0}
              />
              {overview.length - 1 === i ? null : (
                <Box
                  height="4"
                  right="0"
                  position="absolute"
                  style={{ width: 1 }}
                  backgroundColor="borderSeparator"
                  as="hr"
                />
              )}
            </Stack>
          </Box>
        ))}
      </Inline>
    </Box>
  );
};
