import { useBulkImportEmployees } from '@nbfc-expense-tool/data-store/dashboard';
import {
  Box,
  BreadcrumbItem,
  Breadcrumbs,
  SecondaryHeader,
  Stack,
} from '@nbfc-expense-tool/ui';
import React from 'react';
import { BulkImportEntities } from '../../Common';

const BulkImportEmployees: React.FC = () => {
  const { goBack } = useBulkImportEmployees();

  return (
    <Box paddingX="5" paddingY="4">
      <Stack display="flex" flex="1" width="full">
        <Breadcrumbs>
          <BreadcrumbItem isCurrent={false} isFirst onClick={goBack}>
            Employees
          </BreadcrumbItem>
          <BreadcrumbItem isCurrent>Employees Bulk Import</BreadcrumbItem>
        </Breadcrumbs>
        <SecondaryHeader
          onClickBack={goBack}
          headingText="Employees Bulk Import"
        />
      </Stack>
      <BulkImportEntities entityType="employees" marginTop="3" />
    </Box>
  );
};

export default BulkImportEmployees;
