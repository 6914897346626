import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import { DismissButton, useOverlay, FocusScope } from 'react-aria';
import classNames from 'classnames';
import { Button } from '../button/button';
import { menuStyles } from './menu.css';
import { Box } from '../box/box';

type TMenuContext = {
  isExpanded: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

function useMenu() {
  return React.useContext(MenuContext);
}

export const MenuContext = React.createContext<TMenuContext>({
  isExpanded: false,
  open: () => undefined,
  close: () => undefined,
  toggle: () => undefined,
});

export function Menu({
  children,
}: {
  children: React.ReactNode | ((props: TMenuContext) => React.ReactNode);
}) {
  const [state, dispatch] = useReducer<
    React.Reducer<{ isExpanded: boolean }, { type: 'OPEN' | 'CLOSE' }>
  >(
    (state, action) => {
      switch (action.type) {
        case 'OPEN':
          return { ...state, isExpanded: true };
        case 'CLOSE':
          return { ...state, isExpanded: false };
        default:
          return state;
      }
    },
    { isExpanded: false }
  );
  const contextValue = useMemo(() => {
    function open() {
      dispatch({ type: 'OPEN' });
    }
    function close() {
      dispatch({ type: 'CLOSE' });
    }
    return {
      isExpanded: state.isExpanded,
      open,
      close,
      toggle: () => (state.isExpanded ? close() : open()),
    };
  }, [state]);

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleDocumentClick(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        dispatch({ type: 'CLOSE' });
      }
    }

    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  return (
    <MenuContext.Provider value={contextValue}>
      <Box position="relative" ref={menuRef}>
        {typeof children === 'function' ? children(contextValue) : children}
      </Box>
    </MenuContext.Provider>
  );
}

export function MenuButton(props: React.ComponentProps<typeof Button>) {
  const { toggle } = useMenu();
  return <Button {...props} onClick={() => toggle()} />;
}

export function MenuList({
  alignRight,
  align,
  className,
  color,
  autoFocus = true,
  fullWidth,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLUListElement>,
  HTMLUListElement
> & {
  alignRight?: boolean;
  align?: 'top-right' | 'bottom-right';
  autoFocus?: boolean;
  fullWidth?: boolean;
}) {
  const { isExpanded, close } = useMenu();
  const overlayRef = React.useRef<HTMLDivElement | null>(null);
  const { overlayProps } = useOverlay(
    {
      onClose: close,
      shouldCloseOnBlur: true,
      isOpen: isExpanded,
      isDismissable: true,
    },
    overlayRef
  );

  if (!isExpanded) return null;

  const { ref, ...restProps } = props;

  return (
    <FocusScope restoreFocus autoFocus={autoFocus}>
      <div {...overlayProps}>
        <DismissButton onDismiss={close} />
        <Box
          borderRadius="md"
          borderWidth="1"
          borderColor="borderSeparator"
          as="ul"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={ref as any}
          className={classNames(
            menuStyles({
              position: 'absolute',
              zIndex: '50',
              backgroundColor: 'surfaceDefault',
              boxShadow: 'small',
            })
          )}
          tabIndex={-1}
          style={{
            width: fullWidth ? '100%' : 'fit-content',
            right: alignRight ? 0 : undefined,
          }}
          {...restProps}
        />
        <DismissButton onDismiss={close} />
      </div>
    </FocusScope>
  );
}

export function MenuAction(props: React.ComponentProps<typeof Box>) {
  const { toggle } = useMenu();
  return <Box {...props} cursor="pointer" onClick={() => toggle()} />;
}

export function MenuItem(
  props: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > & { action: string; onClick?: () => void }
) {
  const { close } = useMenu();
  return (
    <Box
      as="li"
      id={props.id}
      onClick={() => {
        close();
        props.onClick?.();
      }}
      paddingX="2"
      paddingY="1.5"
      display="block"
      cursor="pointer"
    >
      {props.children}
    </Box>
  );
}
