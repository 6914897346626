import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';
import { Box } from '../box/box';
import { ElementType, forwardRef } from 'react';
import { Text } from '../text/text';

const DefaultTagElement = 'div';

type TagOwnProps = {
  title: string;
  fontSize?: React.ComponentProps<typeof Text>['variation'];
  status?: 'error' | 'success' | 'warning' | 'info' | 'default';
};

export type TagProps<C extends React.ElementType = typeof DefaultTagElement> =
  PolymorphicComponentPropWithRef<C, TagOwnProps>;

type TagComponent = <C extends React.ElementType = typeof DefaultTagElement>(
  props: TagProps<C>
) => React.ReactNode;

export const Tag: TagComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultTagElement>(
    { as, title, fontSize, status = 'default' }: TagProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Element: ElementType = as || DefaultTagElement;
    return (
      <Box>
        <Box
          as={Element}
          ref={ref}
          width="fitContent"
          backgroundColor={
            status === 'success'
              ? 'surfaceSuccessLowest'
              : status === 'warning'
              ? 'borderWarningLowest'
              : 'surfaceNeutralLowest'
          }
          style={{
            paddingTop: 2,
            paddingBottom: 2,
          }}
          paddingX="1"
          rounded="md"
        >
          <Text
            variation={fontSize || 'c1'}
            color={
              status === 'success'
                ? 'textSuccess'
                : status === 'warning'
                ? 'textWarning'
                : 'textMedium'
            }
            textTransform="capitalize"
          >
            {title}
          </Text>
        </Box>
      </Box>
    );
  }
);
