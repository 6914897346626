import { Invoice_Status } from '@nbfc-expense-tool/data-store/dashboard';

export default function useInvoices() {
  function getTitleForTypeOfInvoice(type: Invoice_Status) {
    switch (type) {
      case 'approved':
        return 'Approved';
      case 'rejected':
        return 'Rejected';
      case 'returned':
        return 'Returned';
      case 'under_review':
        return 'Under Review';
    }
  }

  function getEmptyStateText(type: Invoice_Status, status: string) {
    if (type === 'approved') {
      if (status === 'unpaid') {
        return {
          title: 'No Unpaid Approved Invoices Found!',
          subtitle:
            'All the approved invoice with pending payments will appear here',
        };
      } else if (status === 'paid') {
        return {
          title: 'No Approved & Paid Invoices Found!',
          subtitle:
            'All the invoices which are approved & paid will appear here',
        };
      } else {
        return {
          title: 'No Approved Invoices Found!',
          subtitle: 'All the approved invoices will appear here',
        };
      }
    } else if (type === 'under_review') {
      return {
        title: 'No Under Review Invoices Found!',
        subtitle: 'All the under review invoices will appear here',
      };
    } else if (type === 'returned') {
      return {
        title: 'No Returned Invoices Found!',
        subtitle: 'All the returned invoices will appear here',
      };
    } else {
      return {
        title: 'No Rejected Invoices Found!',
        subtitle: 'All the rejected invoices will appear here',
      };
    }
  }

  function getFifthColumnBasedOnType(type: Invoice_Status, status: string) {
    if (type === 'approved') {
      if (status === 'unpaid') {
        return {
          title: 'Action',
        };
      } else if (status === 'paid') {
        return {
          title: 'Action',
        };
      } else {
        return {
          title: 'Paid Status',
        };
      }
    } else if (type === 'under_review') {
      return {
        title: 'Action',
      };
    } else if (type === 'returned') {
      return {
        title: 'Changes Suggested',
      };
    } else {
      return {
        title: 'Reason',
      };
    }
  }

  function getTitleFromLink(from?: string | null): string {
    switch (from) {
      case 'created-by-you':
        return 'Created By You';
      case 'waiting-for-your-approval':
        return 'Waiting For Your Approval';
      case 'all-invoices':
        return 'All Invoices';
      default:
        return 'Home';
    }
  }

  return {
    getTitleFromLink,
    getTitleForTypeOfInvoice,
    getEmptyStateText,
    getFifthColumnBasedOnType,
  };
}
