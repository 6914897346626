/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useMemo } from 'react';
import {
  Amount,
  Box,
  Button,
  EmptyState,
  FileIcon,
  Inline,
  RightCaratIcon,
  SearchIcon,
  SkeletonRows,
  SpinnerIcon,
  Stack,
  Text,
  TextInput,
  DateSelect,
  Date as DateAndTime,
  Time,
  MultiSelect,
  Avatar,
  ReturnIcon,
} from '@nbfc-expense-tool/ui';
import { useReimbursements } from '@nbfc-expense-tool/data-store/dashboard';
import { useNavigate } from 'react-router-dom';
import { debounce } from '@nbfc-expense-tool/data-store/utils';
import { FilterContextType, ReimbursementFilterContext } from '.';
import { EmptyReimbursementList } from '../../Reimbursement';

const idPrefix = 'waiting-for-approval-reimbursements';

export const WaitingForApproval: React.FC = () => {
  const navigate = useNavigate();
  const {
    loading,
    perPage,
    totalReimbursements,
    reimbursements,
    canGoNext,
    canGoBack,
    syncingData,
    currentPage,
    lastPage,
    hasAppliedFilters,
    resetFilters,
    handlePageChange,
    handleDateChange,
    handleParamChange,
    params,
    exportExcelReport,
    reportLoading,
  } = useReimbursements('waiting-for-your-approval');
  const filterOptions = useContext<FilterContextType | null>(
    ReimbursementFilterContext
  );
  const employees = filterOptions?.employees;
  const employeesFetchedStatus = filterOptions?.employeesStatus;

  const employeesForFilters = useMemo(() => {
    return employees?.length
      ? employees?.map((b) => {
          return {
            label: b.name,
            value: `${b.id}`,
          };
        })
      : [];
  }, [employees]);

  const onReimburementClickHandler = (reimbursementId: string) => {
    navigate(
      `/home/reimbursements/${reimbursementId}?from=waiting-for-your-approval`
    );
  };

  return !reimbursements?.length && !hasAppliedFilters ? (
    <Stack paddingTop="10" alignItems="center" justifyContent="center">
      {loading === 'in_progress' ? (
        <Inline gap="4">
          <SpinnerIcon size="3" color="iconMedium" />
          <Text variation="b2">Loading...</Text>
        </Inline>
      ) : (
        <EmptyReimbursementList title="No Pending Reimbursements Found!" />
      )}
    </Stack>
  ) : (
    <Stack paddingTop="3.5" gap="6">
      <Inline justifyContent="between">
        <Box gap="4" display="flex">
          <DateSelect
            id={`${idPrefix}-select-date`}
            value={params.dateFilter}
            onSave={(option) => {
              handleDateChange(option);
            }}
          />
          <MultiSelect
            id={`${idPrefix}-select-employees`}
            label="Employee"
            actionBtnTitle="Show Results"
            value={params.employees}
            options={employeesForFilters}
            loadingOptions={employeesFetchedStatus === 'in_progress'}
            onSave={(values) => {
              handleParamChange('employees', values);
            }}
          />
        </Box>
        <Button
          id={`${idPrefix}-export-excel-button`}
          title="Export To Excel"
          onClick={exportExcelReport}
          type="outlined"
          state={reportLoading ? 'loading' : undefined}
          leftIcon={(props) => <ReturnIcon {...props} />}
        />
      </Inline>

      <Box
        rounded="md"
        borderWidth="1"
        paddingTop="2.5"
        borderColor="borderSeparator"
        backgroundColor="surfaceDefault"
      >
        <Stack gap="6">
          <Inline paddingX="2.5" alignItems="center" justifyContent="between">
            <Inline style={{ width: 352 }}>
              <TextInput
                minHeight="5"
                id={`${idPrefix}-search-input`}
                aria-label="search"
                placeholder="Search by Reimbursement ID or amount"
                leftIcon={(props) => <SearchIcon {...props} marginRight="1" />}
                fullWidth
                onChange={debounce((value) => {
                  handleParamChange('q', [value]);
                })}
              />
            </Inline>
            <Inline alignItems="center" gap="6">
              {totalReimbursements ? (
                totalReimbursements <= 10 ? (
                  <Text color="textMedium" variation="c1">
                    Showing{' '}
                    {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1} of{' '}
                    {totalReimbursements}
                  </Text>
                ) : (
                  <Text color="textMedium" variation="c1">
                    Showing{' '}
                    {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}-
                    {lastPage === currentPage
                      ? totalReimbursements
                      : reimbursements
                      ? reimbursements.length * (currentPage - 1) +
                        reimbursements.length
                      : 0}{' '}
                    of {totalReimbursements}
                  </Text>
                )
              ) : null}
              <Inline gap="2">
                <Box
                  id={`${idPrefix}-show-previous-page`}
                  as="button"
                  disabled={!canGoBack}
                  backgroundColor="transparent"
                  onClick={() =>
                    canGoBack ? handlePageChange('previous') : undefined
                  }
                >
                  <RightCaratIcon
                    cursor="pointer"
                    size="2.5"
                    rotate="180"
                    color={canGoBack ? 'iconMedium' : 'iconLowest'}
                  />
                </Box>
                <Box
                  as="hr"
                  width="px"
                  height="2.5"
                  backgroundColor="borderSeparator"
                />
                <Box
                  id={`${idPrefix}-show-next-page`}
                  as="button"
                  disabled={!canGoNext}
                  backgroundColor="transparent"
                  onClick={() =>
                    canGoNext ? handlePageChange('next') : undefined
                  }
                >
                  <RightCaratIcon
                    cursor="pointer"
                    size="2.5"
                    color={canGoNext ? 'iconMedium' : 'iconLowest'}
                  />
                </Box>
              </Inline>
            </Inline>
          </Inline>
          <Box as="table" width="full" position="relative">
            <Box as="thead" bgColor="surfaceNeutralLowest">
              <Box as="tr">
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Requested On</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 100,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">ID</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 160,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Employee</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  textAlign="left"
                  bgColor="surfaceNeutralLowest"
                >
                  <Text variation="c1">Title</Text>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingX="2"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                >
                  <Inline justifyContent="end">
                    <Text variation="c1">Amount</Text>
                  </Inline>
                </Box>
                <Box
                  as="th"
                  position="sticky"
                  paddingY="1.5"
                  paddingLeft="2"
                  paddingRight="3"
                  style={{
                    width: 120,
                  }}
                  top="0"
                  bgColor="surfaceNeutralLowest"
                  textAlign="left"
                >
                  <Text variation="c1">Action</Text>
                </Box>
              </Box>
            </Box>
            {syncingData ? (
              <SkeletonRows numOfRows={10} numOfCols={5} />
            ) : (
              <Box as="tbody">
                {reimbursements?.length ? (
                  reimbursements.map((reimbursement) => {
                    return (
                      <React.Fragment key={reimbursement.ticket_number}>
                        <Box
                          id={`${idPrefix}-list-${reimbursement.ticket_number}`}
                          as="tr"
                          borderTopWidth="1"
                          cursor="pointer"
                          tabIndex={-1}
                          backgroundColor={{
                            hover: 'surfacePrimaryLowest',
                          }}
                          borderBottomWidth="1"
                          borderColor="borderSeparator"
                          onClick={() =>
                            onReimburementClickHandler(
                              String(reimbursement.ticket_number)
                            )
                          }
                        >
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Inline marginBottom="0.5" alignItems="center">
                              <Stack gap="1">
                                <DateAndTime
                                  variation="b2"
                                  date={reimbursement.created_at}
                                />
                                <Time
                                  variation="c2"
                                  color="textLow"
                                  date={reimbursement.created_at}
                                  format="hh:mm a"
                                />
                              </Stack>
                            </Inline>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Text variation="b2">
                                {reimbursement.ticket_number}
                              </Text>
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            valign="top"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Inline gap="2" marginBottom="0.5">
                              <Avatar
                                avatarSize="sm"
                                id={
                                  reimbursement?.creator?.id.toString() ||
                                  reimbursement?.creator?.employee_code?.toString()
                                }
                                avatarText={
                                  reimbursement?.creator?.name?.length
                                    ? reimbursement?.creator?.name[0]
                                    : 'U'
                                }
                              />
                              <Stack gap="1" flex="1">
                                <Text variation="b2">
                                  {reimbursement?.creator?.name}
                                </Text>
                                <Text variation="c2" color="textMedium">
                                  {reimbursement?.creator?.employee_code}
                                </Text>
                              </Stack>
                            </Inline>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Text variation="b2">{reimbursement.title}</Text>
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingX="2"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="right"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                            valign="top"
                          >
                            <Stack>
                              <Amount
                                variation="t4"
                                amount={Number(
                                  reimbursement.grand_total_amount
                                )}
                                color="textHigh"
                              />
                            </Stack>
                          </Box>
                          <Box
                            as="td"
                            paddingLeft="2"
                            paddingRight="3"
                            paddingY="2"
                            className="whitespace-pre"
                            textAlign="left"
                            valign="top"
                            borderBottomWidth="1"
                            borderColor="borderSeparator"
                          >
                            <Box width="fitContent">
                              <Text
                                variation="lk"
                                color="textPrimary"
                                textDecoration="underline"
                              >
                                Review
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <Box as="tr">
                    <Box as="td" colSpan={8}>
                      <EmptyState
                        renderIcon={(props) => <FileIcon {...props} />}
                        title="No Reimbursements Found!"
                        subText={`Please try changing your applied filters!`}
                        renderButton={() => (
                          <Button
                            id={`${idPrefix}-empty-state-reset-filter-button`}
                            title="Reset Filters"
                            onClick={resetFilters}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
