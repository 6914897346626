import type { ElementType } from 'react';
import { forwardRef } from 'react';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';
import { Box, BoxOwnProps } from '../box/box';
import { Text } from '../text/text';
import { IconParams } from '../../types';
import {
  AlertTriangleIcon,
  InfoCircle,
  CrossCircle,
  CheckCircle,
  CrossIcon,
} from '../icons/icons';

const DefaultBannerElement = 'div';

type BannerOwnProps = {
  bannerType: 'warning' | 'error' | 'neutral' | 'success';
  bannerText: string;
  actionText?: string;
  onClickClose?: React.MouseEventHandler<SVGSVGElement>;
  onClickAction?: React.MouseEventHandler<HTMLParagraphElement>;
};

export type BannerProps<
  C extends React.ElementType = typeof DefaultBannerElement
> = PolymorphicComponentPropWithRef<C, BoxOwnProps & BannerOwnProps>;

type BannerComponent = <
  C extends React.ElementType = typeof DefaultBannerElement
>(
  props: BannerProps<C>
) => React.ReactNode;

export const Banner: BannerComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultBannerElement>(
    {
      as,
      bannerType,
      bannerText,
      actionText,
      onClickClose,
      onClickAction,
      ...restProps
    }: BannerProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Element: ElementType = as || DefaultBannerElement;

    const getAvatarStyleBasedUponType = (): {
      backgroundColor: BoxOwnProps['backgroundColor'];
      textColor: BoxOwnProps['color'];
      bannerIcon: () => React.ReactElement;
      borderColor: BoxOwnProps['borderColor'];
      iconColor: IconParams['color'];
    } => {
      switch (bannerType) {
        case 'warning':
          return {
            backgroundColor: 'surfaceWarningLowest',
            textColor: 'textWarning',
            bannerIcon: () => (
              <AlertTriangleIcon color="iconWarning" size="3" />
            ),
            borderColor: 'borderWarningLow',
            iconColor: 'iconWarning',
          };

        case 'error':
          return {
            backgroundColor: 'surfaceErrorLowest',
            textColor: 'textError',
            bannerIcon: () => <CrossCircle color="iconError" size="3" />,
            borderColor: 'borderErrorLow',
            iconColor: 'iconError',
          };

        case 'neutral':
          return {
            backgroundColor: 'surfacePrimaryLowest',
            textColor: 'textPrimary',
            bannerIcon: () => <InfoCircle color="iconPrimary" size="3" />,
            borderColor: 'borderPrimaryLow',
            iconColor: 'iconPrimary',
          };

        default:
          return {
            backgroundColor: 'surfaceSuccessLowest',
            textColor: 'textSuccess',
            bannerIcon: () => <CheckCircle color="iconSuccess" size="3" />,
            borderColor: 'borderSuccessLow',
            iconColor: 'iconSuccess',
          };
      }
    };

    const { backgroundColor, textColor, borderColor, bannerIcon, iconColor } =
      getAvatarStyleBasedUponType();

    return (
      <Box
        {...restProps}
        as={Element}
        ref={ref}
        borderRadius="md"
        paddingX="3"
        paddingY="2"
        justifyContent="center"
        display="flex"
        alignItems="center"
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        borderWidth="1"
      >
        {bannerIcon()}
        <Text variation="b1" color={textColor} marginLeft="1" flex="1">
          {bannerText}
        </Text>
        {actionText ? (
          <Text
            variation="lk"
            color={textColor}
            marginLeft="4"
            onClick={onClickAction}
            cursor="pointer"
          >
            {actionText}
          </Text>
        ) : null}
        {onClickClose ? (
          <CrossIcon
            color={iconColor}
            size="3"
            marginLeft="3"
            onClick={onClickClose}
            cursor="pointer"
          />
        ) : null}
      </Box>
    );
  }
);
