import {
  InvoiceActivity,
  InvoiceActivityEvent,
  InvoiceItem,
  TypesOfInvoices,
  useInvoiceDetails,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  Amount,
  ArrowLeftIcon,
  Avatar,
  Box,
  BreadcrumbItem,
  Breadcrumbs,
  Date as DateUi,
  EmptyState,
  FileIcon,
  FilePlusIcon,
  Inline,
  PencilIcon,
  SpinnerIcon,
  Stack,
  Tabs,
  Text,
  IconParams,
  Button,
  CrossIcon,
  ReturnIcon,
  CheckIcon,
  ClockIcon,
  UserIcon,
  UploadDocument,
  CopyIcon,
  toastQueue,
  Modal,
  EditIconTwo,
  ConfirmationDialog,
} from '@nbfc-expense-tool/ui';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ActionOnInvoiceInModal, InvoiceStatusTag } from '../../Invoices';
import { Item, useOverlayTriggerState } from 'react-stately';
import { UserDetails, useUser } from '@nbfc-expense-tool/data-store/auth';
import useInvoicesLocal from './useInvoices';
import { VendorProfileModal } from '../../Vendors';

export default function InvoiceDetailsPage() {
  const { invoiceTicketNumber } = useParams();
  if (!invoiceTicketNumber) return null;
  return (
    <InvoiceDetails
      key={invoiceTicketNumber}
      ticketNumber={invoiceTicketNumber}
    />
  );
}

const idPrefix = 'invoice-details';

function InvoiceDetails({ ticketNumber }: { ticketNumber: string }) {
  const navigate = useNavigate();
  const { getTitleFromLink } = useInvoicesLocal();
  const [searchParams] = useSearchParams();
  const fromSearchParams = searchParams.get('from') as TypesOfInvoices;
  const previousLink = useMemo(() => {
    return getTitleFromLink(fromSearchParams);
  }, [getTitleFromLink, fromSearchParams]);
  const vendorProfileModalState = useOverlayTriggerState({});

  const {
    status,
    invoice,
    reload,
    onClickDeleteInvoice,
    closeDeleteInvoiceConfirmationDialog,
    onConfirmDeleteInvoice,
    isDeleteInvoiceConfirmationDialogVisible,
    isDeleteInvoiceApiLoading,
  } = useInvoiceDetails(ticketNumber);

  const { user } = useUser();

  const canUserMarkPaid = useMemo(
    () =>
      invoice?.i_can_mark_paid &&
      invoice?.payment_status === 'unpaid' &&
      invoice?.invoice_status === 'approved',
    [invoice]
  );

  const copyNumberToClipboard = useCallback(() => {
    navigator.clipboard.writeText(invoice?.utr_number || '');
    toastQueue.add(
      {
        title: 'UTR number copied successfully',
        type: 'success',
      },
      {
        timeout: 2000,
      }
    );
  }, [invoice?.utr_number]);

  const renderEditOrDeleteButton = () => {
    return (
      <Inline gap="2">
        {invoice?.i_can_delete && (
          <>
            <Button
              id={`${idPrefix}-delete-button`}
              buttonRole="destructive"
              type="outlined"
              leftIcon={(props) => <CrossIcon {...props} />}
              title="Delete"
              onClick={onClickDeleteInvoice}
            />
            <ConfirmationDialog
              id={`${idPrefix}-delete-invoice`}
              title="Delete Invoice"
              description={`You want to delete '${invoice?.ticket_number}' Invoice.`}
              isVisible={isDeleteInvoiceConfirmationDialogVisible}
              confirmLabel="Yes, Delete"
              confirmButtonRole="destructive"
              confirmButtonState={
                isDeleteInvoiceApiLoading ? 'loading' : undefined
              }
              onCancel={closeDeleteInvoiceConfirmationDialog}
              onConfirm={onConfirmDeleteInvoice}
            />
          </>
        )}
        {invoice?.i_can_edit && (
          <Button
            id={`${idPrefix}-edit-button`}
            leftIcon={(props) => <EditIconTwo {...props} />}
            title="Edit"
            onClick={() => {
              navigate(`/edit-invoice/${ticketNumber}`, {
                state: {
                  invoiceDetails: invoice,
                },
              });
            }}
          />
        )}
      </Inline>
    );
  };

  return (
    <Box paddingY="4" paddingX="5" marginBottom="10">
      <Stack gap="8">
        <Stack gap="2">
          <Breadcrumbs>
            <BreadcrumbItem
              isCurrent={false}
              isFirst
              onClick={() => navigate(-1)}
            >
              {previousLink}
            </BreadcrumbItem>
            <BreadcrumbItem isCurrent>Invoice Details</BreadcrumbItem>
          </Breadcrumbs>
          <Inline alignItems="center" justifyContent="between">
            <Inline gap="6">
              <ArrowLeftIcon
                size="4"
                onClick={() => navigate(-1)}
                cursor="pointer"
              />
              <Text as="h3" variation="h3">
                Invoices: {ticketNumber}
              </Text>
            </Inline>
            {invoice?.i_can_act_on ? (
              <ActionOnInvoiceInModal invoice={invoice} onSuccess={reload}>
                {({ onOpen }) => (
                  <Inline gap="4">
                    <Button
                      id={`${idPrefix}-reject-button`}
                      buttonRole="destructive"
                      leftIcon={(props) => <CrossIcon {...props} />}
                      type="outlined"
                      title="Reject"
                      onClick={() => onOpen('reject')}
                    />
                    <Button
                      id={`${idPrefix}-return-button`}
                      leftIcon={(props) => <ReturnIcon {...props} />}
                      type="outlined"
                      title="Return"
                      onClick={() => onOpen('return')}
                    />
                    <Button
                      id={`${idPrefix}-approve-button`}
                      leftIcon={(props) => <CheckIcon {...props} />}
                      title="Approve"
                      buttonRole="success"
                      onClick={() => onOpen('approve')}
                    />
                    {renderEditOrDeleteButton()}
                  </Inline>
                )}
              </ActionOnInvoiceInModal>
            ) : null}
            {canUserMarkPaid && invoice ? (
              <ActionOnInvoiceInModal invoice={invoice} onSuccess={reload}>
                {({ onOpen }) => (
                  <Inline gap="4">
                    <Button
                      id={`${idPrefix}-mark-paid-button`}
                      leftIcon={(props) => <CheckIcon {...props} />}
                      title="Mark Paid"
                      onClick={() => onOpen('markPaid')}
                    />
                    {renderEditOrDeleteButton()}
                  </Inline>
                )}
              </ActionOnInvoiceInModal>
            ) : null}
            {!invoice?.i_can_act_on && !canUserMarkPaid
              ? renderEditOrDeleteButton()
              : null}
          </Inline>
        </Stack>
        <Inline gap="6">
          <Box width="full">
            {status === 'in_progress' ? (
              <Stack
                borderColor="borderSeparator"
                backgroundColor="surfaceDefault"
                borderWidth="1"
                rounded="md"
                width="full"
                alignItems="center"
                justifyContent="center"
                height={'screen'}
              >
                <Inline gap="3">
                  <SpinnerIcon color="iconMedium" size="3" />
                  <Text variation="b2">Fetching Invoice Details...</Text>
                </Inline>
              </Stack>
            ) : invoice?.id ? (
              <Stack>
                {invoice.payment_status === 'paid' ? (
                  <Inline
                    paddingY="2"
                    paddingX="3"
                    borderColor="borderSeparator"
                    alignItems="center"
                    justifyContent="between"
                    borderWidth="1"
                    borderTopWidth="4"
                    marginBottom={'3'}
                    backgroundColor={'surfaceDefault'}
                    roundedTop="md"
                    gap="1"
                    borderTopColor="borderSuccess"
                  >
                    <Stack alignItems={'start'}>
                      <Text
                        variation="t4"
                        color={'textMedium'}
                        marginBottom={'0.5'}
                      >
                        Payment Status:
                      </Text>
                      <InvoiceStatusTag status={invoice.payment_status} />
                    </Stack>
                    <Stack alignItems={'end'}>
                      <Inline
                        marginBottom={'0.5'}
                        gap={'1'}
                        alignItems={'center'}
                      >
                        <Text variation="t4" color={'textMedium'}>
                          UTR:{' '}
                        </Text>
                        <Text variation="b2">{invoice?.utr_number}</Text>
                        <CopyIcon
                          id={`${idPrefix}-copy-utr-number`}
                          color={'iconMedium'}
                          size={'2.5'}
                          onClick={copyNumberToClipboard}
                        />
                      </Inline>
                      <Inline gap={'1'}>
                        <Text variation={'c2'} color={'textLow'}>
                          Paid on:
                        </Text>
                        <DateUi
                          variation="c2"
                          color={'textLow'}
                          date={invoice?.paid_at}
                        />
                      </Inline>
                    </Stack>
                  </Inline>
                ) : invoice.invoice_status === 'returned' &&
                  invoice.last_returned_remarks?.length ? (
                  <Stack
                    paddingY="2"
                    paddingX="3"
                    borderColor="borderSeparator"
                    justifyContent="between"
                    borderWidth="1"
                    borderTopWidth="4"
                    marginBottom={'3'}
                    backgroundColor={'surfaceDefault'}
                    roundedTop="md"
                    gap="2"
                    borderTopColor="borderWarning"
                  >
                    <Text variation="t4">
                      Suggested changes for invoice creator:
                    </Text>
                    <Text variation="c0" color="textMedium">
                      {invoice.last_returned_remarks}
                    </Text>
                  </Stack>
                ) : invoice.invoice_status === 'rejected' &&
                  invoice.last_rejected_remarks?.length ? (
                  <Stack
                    paddingY="2"
                    paddingX="3"
                    borderColor="borderSeparator"
                    justifyContent="between"
                    borderWidth="1"
                    borderTopWidth="4"
                    marginBottom={'3'}
                    backgroundColor={'surfaceDefault'}
                    roundedTop="md"
                    gap="2"
                    borderTopColor="borderWarning"
                  >
                    <Text variation="t4">Rejection Reason:</Text>
                    <Text variation="c0" color="textMedium">
                      {invoice.last_rejected_remarks}
                    </Text>
                  </Stack>
                ) : null}
                <Stack
                  borderColor="borderSeparator"
                  backgroundColor="surfaceDefault"
                  borderWidth="1"
                  rounded="md"
                  width="full"
                >
                  <Inline
                    borderBottomWidth="1"
                    borderColor="borderSeparator"
                    alignItems="center"
                    justifyContent="between"
                    paddingY="2"
                    paddingX="3"
                  >
                    <Inline gap="2">
                      <Text variation="t4" color="textMedium">
                        Invoice Status:
                      </Text>
                      <InvoiceStatusTag status={invoice.invoice_status} />
                    </Inline>
                    <Inline>
                      <Text variation="t3" color="textMedium">
                        Amount:{' '}
                        <Amount
                          color="textHigh"
                          variation="t3"
                          amount={Number(invoice.grand_total_amount)}
                        />
                      </Text>
                    </Inline>
                  </Inline>
                  <Box paddingX="3">
                    <Inline
                      justifyContent="between"
                      paddingY="2"
                      borderBottomWidth="1"
                      borderColor="borderSeparator"
                    >
                      <Inline gap="4">
                        <Avatar
                          avatarSize="lg"
                          id={invoice.vendor_id.toString()}
                          avatarText={invoice.vendor?.name?.[0]}
                        />
                        <Stack gap="1">
                          <Text variation="t3">{invoice.vendor?.name}</Text>
                          <Text variation="b2" color="textMedium">
                            {invoice.vendor?.vendor_type?.name}
                          </Text>
                        </Stack>
                      </Inline>
                      <Text
                        id={`${idPrefix}-view-vendor-details-button`}
                        variation="lk2"
                        color="textMedium"
                        cursor="pointer"
                        onClick={vendorProfileModalState.open}
                      >
                        View Vendor Details
                      </Text>
                    </Inline>
                  </Box>

                  <Stack gap="6" paddingY="4" paddingX="3">
                    <Inline justifyContent="between">
                      <Inline width="1/2">
                        <Stack>
                          <Text variation="c2" color="textMedium">
                            Branch
                          </Text>
                          <Text variation="b2">
                            {invoice.branch.name} - {invoice.branch.branch_code}
                          </Text>
                        </Stack>
                      </Inline>
                      <Stack width="1/2">
                        <Text variation="c2" color="textMedium">
                          Invoice Number
                        </Text>
                        <Text variation="b2">
                          {invoice.invoice_number || '-'}
                        </Text>
                      </Stack>
                    </Inline>
                    <Inline>
                      <Inline width="1/2">
                        <Stack>
                          <Text variation="c2" color="textMedium">
                            Invoice Date
                          </Text>
                          <DateUi variation="b2" date={invoice.invoice_date} />
                        </Stack>
                      </Inline>
                      <Inline width="1/2">
                        <Stack>
                          <Text variation="c2" color="textMedium">
                            Due Date
                          </Text>
                          {invoice.due_date ? (
                            <DateUi variation="b2" date={invoice.due_date} />
                          ) : (
                            <Text variation="b2">-</Text>
                          )}
                        </Stack>
                      </Inline>
                    </Inline>
                  </Stack>
                  <Box paddingTop="1" paddingX="2">
                    <Tabs gap="7">
                      <Item textValue="items" title="Items">
                        <InvoiceItemDetails
                          items={invoice.invoice_items}
                          priceAfterTax={Number(invoice.grand_total_amount)}
                          priceBeforeTax={Number(invoice.sub_total_amount)}
                        />
                      </Item>
                      <Item textValue="documents" title="Documents">
                        {invoice.documents?.length ? (
                          <Stack
                            as="ul"
                            paddingBottom="6"
                            gap="10"
                            style={{ listStyle: 'none' }}
                          >
                            {invoice.documents.map((doc) => (
                              <Box key={doc.url} as="li">
                                <UploadDocument
                                  id={`${idPrefix}`}
                                  documentInfo={{
                                    imgSrc: doc.url,
                                    name: doc.file_name,
                                    type: doc.mime_type,
                                    size: doc.size,
                                  }}
                                  showDownloadButton
                                />
                              </Box>
                            ))}
                          </Stack>
                        ) : (
                          <EmptyState
                            renderIcon={({ color }) => (
                              <FileIcon size="6" color={color} />
                            )}
                            title="No Documents Found!"
                            subText="Please attach documents by editing this invoice."
                          />
                        )}
                      </Item>
                      <Item textValue="remarksAndTerms" title="Remarks & Terms">
                        {!invoice.remarks?.length && !invoice.terms?.length ? (
                          <EmptyState
                            title="No Remark or Terms found!"
                            subText="Please edit this invoice and add a remark or terms."
                          />
                        ) : (
                          <Stack gap="10" marginBottom="10">
                            {invoice.remarks ? (
                              <Stack gap="1">
                                <Text variation="c2" color="textLow">
                                  Remarks
                                </Text>
                                <Text variation="b2">{invoice.remarks}</Text>
                              </Stack>
                            ) : null}
                            {invoice.terms ? (
                              <Stack gap="1">
                                <Text variation="c2" color="textLow">
                                  Terms
                                </Text>
                                <Text variation="b2">{invoice.terms}</Text>
                              </Stack>
                            ) : null}
                          </Stack>
                        )}
                      </Item>
                    </Tabs>
                  </Box>
                </Stack>
              </Stack>
            ) : null}
          </Box>
          {invoice?.activities.length ? (
            <Stack
              gap="6"
              borderWidth="1"
              rounded="md"
              width="full"
              backgroundColor="surfaceDefault"
              borderColor="borderSeparator"
              maxWidth="xs"
            >
              <Box
                borderBottomWidth="1"
                borderColor="borderSeparator"
                paddingX="3"
                paddingY="1.5"
              >
                <Text variation="t3">Activity Log</Text>
              </Box>
              <Stack
                as="ul"
                gap="10"
                style={{ listStyleType: 'none' }}
                paddingX="3"
                paddingBottom="6"
              >
                {invoice.current_actor?.id &&
                invoice.invoice_status === 'under_review' ? (
                  <Inline
                    as="li"
                    backgroundColor="surfaceDefault"
                    key={invoice.current_actor.id}
                  >
                    <Inline gap="5" zIndex="10" position="relative">
                      <IconForActivityLog event="pending" />
                      {invoice.activities.length ? (
                        <Box
                          as="hr"
                          position="absolute"
                          height="full"
                          marginX="1.5"
                          backgroundColor="borderSeparator"
                          style={{ width: 2, top: 30, zIndex: 1 }}
                        />
                      ) : null}
                      <Stack gap="3">
                        <TitleForActivityLog event="pending" />
                        <Inline gap="3" alignItems="center">
                          <Avatar
                            avatarSize="lg"
                            backgroundColor="AvatarDisabled"
                            avatarIcon={(props) => <UserIcon {...props} />}
                          />
                          <Text variation="b2">
                            {invoice.current_actor?.display_name}
                          </Text>
                        </Inline>
                      </Stack>
                    </Inline>
                  </Inline>
                ) : null}
                {invoice?.activities.map((activity, i) => (
                  <ActivityLogItem
                    user={user}
                    key={activity.id}
                    activity={activity}
                    isLastItem={i + 1 === invoice.activities.length}
                  />
                ))}
              </Stack>
            </Stack>
          ) : null}
        </Inline>
      </Stack>
      <Modal
        isOpen={vendorProfileModalState.isOpen}
        title={`Vendor's Full Details`}
        placement="right"
        status={'success'}
        onClose={vendorProfileModalState.close}
        size="lg"
      >
        <VendorProfileModal vendorId={String(invoice?.vendor?.id || '')} />
      </Modal>
    </Box>
  );
}

function InvoiceItemDetails({
  items,
  priceAfterTax,
  priceBeforeTax,
  tdsPercentage,
}: {
  items: InvoiceItem[];
  priceAfterTax: number;
  priceBeforeTax: number;
  tdsPercentage?: string;
}) {
  return (
    <Box as="table" width="full" position="relative">
      <Box as="thead" bgColor="surfaceNeutralLowest">
        <Box as="tr">
          <Box
            as="th"
            position="sticky"
            paddingY="1.5"
            paddingX="2"
            style={{
              width: 155,
            }}
            top="0"
            textAlign="left"
            bgColor="surfaceNeutralLowest"
          >
            <Text variation="c1">Item Details</Text>
          </Box>
          <Box
            as="th"
            position="sticky"
            paddingY="1.5"
            paddingX="2"
            style={{
              width: 120,
            }}
            top="0"
            textAlign="right"
            bgColor="surfaceNeutralLowest"
          >
            <Text variation="c1">Price before Tax</Text>
          </Box>
          <Box
            as="th"
            position="sticky"
            paddingY="1.5"
            paddingX="2"
            style={{
              width: 108,
            }}
            top="0"
            textAlign="right"
            bgColor="surfaceNeutralLowest"
          >
            <Text variation="c1">GST</Text>
          </Box>
          <Box
            as="th"
            position="sticky"
            paddingY="1.5"
            paddingX="2"
            style={{
              width: 120,
            }}
            top="0"
            textAlign="right"
            bgColor="surfaceNeutralLowest"
          >
            <Text variation="c1">Total Price</Text>
          </Box>
        </Box>
      </Box>
      <Box as="tbody">
        {items.map((item) => (
          <React.Fragment key={item.id}>
            <Box as="tr" borderTopWidth="1" tabIndex={-1}>
              <Box as="td" paddingX="2" paddingY="1.5" textAlign="left">
                <Stack gap="1">
                  <Text variation="c1">{item.description}</Text>
                  <Text variation="c2" color="textLow">
                    Qty - {item.unit_number}, Rate -{' '}
                    <Amount
                      as="span"
                      color="textLow"
                      amount={Number(item.unit_price)}
                    />
                  </Text>
                </Stack>
              </Box>
              <Box as="td" paddingX="2" paddingY="1.5" textAlign="right">
                <Stack gap="1" textAlign="right">
                  <Amount variation="c2" amount={Number(item.total_price)} />
                  <Text visibility="none" color="textLow" variation="c5">
                    Perc
                  </Text>
                </Stack>
              </Box>
              <Box as="td" paddingX="2" paddingY="1.5" textAlign="right">
                <Stack gap="1">
                  <Amount variation="c2" amount={Number(item.gst_amount)} />
                  {Number(item.igst_percent) ? (
                    <Text variation="c5" color="textLow">
                      {item.igst_percent}% IGST
                    </Text>
                  ) : item.cgst_percent && item.sgst_percent ? (
                    <Text variation="c5" color="textLow">
                      {item.cgst_percent}% CGST+{item.sgst_percent}% SGST
                    </Text>
                  ) : null}
                </Stack>
              </Box>
              <Box as="td" paddingX="2" paddingY="1.5" textAlign="right">
                <Stack gap="1" textAlign="right">
                  <Amount variation="c1" amount={Number(item.total_amount)} />
                  <Text visibility="none" color="textLow" variation="c5">
                    Perc
                  </Text>
                </Stack>
              </Box>
            </Box>
          </React.Fragment>
        ))}
      </Box>
      <Box as="tfoot">
        <Box as="tr">
          <Box
            as="td"
            paddingX="2"
            colSpan={3}
            paddingY="2"
            borderYWidth="1"
            borderColor="borderSeparator"
          >
            <Text variation="c1">Subtotal (Total Amount before tax)</Text>
          </Box>
          <Box
            as="td"
            paddingY="2"
            paddingX="2"
            borderYWidth="1"
            borderColor="borderSeparator"
            textAlign="right"
          >
            <Amount variation="c1" amount={priceBeforeTax} />
          </Box>
        </Box>
        <Box as="tr">
          <Box
            as="td"
            paddingX="2"
            colSpan={3}
            paddingY="2"
            borderColor="borderSeparator"
          >
            <Text variation="c1">Total (Total Amount inclusive of GST)</Text>
          </Box>
          <Box as="td" paddingY="2" paddingX="2" borderColor="borderSeparator">
            <Stack gap="1" textAlign="right">
              <Amount variation="c1" amount={priceAfterTax} />
              {tdsPercentage ? (
                <Text variation="c5" color="textLow">
                  Incl. of {tdsPercentage}% TDS
                </Text>
              ) : null}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function ActivityLogItem({
  user,
  activity,
  isLastItem,
}: {
  user: UserDetails;
  activity: InvoiceActivity;
  isLastItem?: boolean;
}) {
  const { created_at, event, causer } = activity;

  return (
    <Inline as="li" backgroundColor="surfaceDefault">
      <Inline gap="5" zIndex="10" position="relative">
        <IconForActivityLog event={event} />
        {isLastItem ? null : (
          <Box
            as="hr"
            position="absolute"
            height="full"
            marginX="1.5"
            backgroundColor="borderSeparator"
            style={{ width: 2, top: 30, zIndex: 1 }}
          />
        )}

        <Stack gap="3">
          <TitleForActivityLog event={event} date={created_at} />
          <Inline gap="3">
            <Avatar
              as="img"
              src={causer?.avatar?.preview || ''}
              avatarSize="md"
            />
            <Stack gap="1">
              <Text variation="b2">
                {causer?.name} {user.id === causer?.id ? `(You)` : ''}
              </Text>
              {causer?.roles?.map((role) => (
                <Text variation="c2" color="textMedium">
                  {role.display_name}
                </Text>
              ))}
            </Stack>
          </Inline>
        </Stack>
      </Inline>
    </Inline>
  );
}

type IconType = (props: IconParams) => React.ReactElement;
function IconForActivityLog({ event }: { event: InvoiceActivityEvent }) {
  const iconBody: IconType | undefined = useMemo(() => {
    switch (event) {
      case 'created':
        return (props) => <FilePlusIcon {...props} />;
      case 'updated':
        return (props) => <PencilIcon {...props} />;
      case 'approved':
      case 'mark_paid':
        return (props) => <CheckIcon {...props} />;
      case 'pending':
        return (props) => <ClockIcon {...props} />;
      case 'rejected':
        return (props) => <CrossIcon {...props} />;
      default:
        return (props) => <ReturnIcon {...props} />;
    }
  }, [event]);

  return iconBody ? (
    <Avatar
      avatarSize="sm"
      backgroundColor={
        event === 'approved' || event === 'mark_paid'
          ? 'AvatarSuccess'
          : event === 'pending'
          ? 'AvatarWarning'
          : event === 'rejected'
          ? 'AvatarError'
          : 'Avatar9'
      }
      avatarIcon={(props) => iconBody(props)}
    />
  ) : null;
}

function TitleForActivityLog({
  event,
  date,
}: {
  event: InvoiceActivityEvent;
  date?: Date | string;
}) {
  return (
    <Text variation="c4" color="textMedium">
      {event === 'created'
        ? `Created on `
        : event === 'updated'
        ? 'Updated on '
        : event === 'approved'
        ? 'Approved on '
        : event === 'pending'
        ? 'Pending'
        : event === 'rejected'
        ? 'Rejected on '
        : event === 'returned'
        ? 'Returned on '
        : event === 'mark_paid'
        ? 'Paid on '
        : ''}
      {date ? (
        <DateUi
          date={date}
          color="textMedium"
          variation="c4"
          format="dd MMM yyyy, hh:mm a"
        />
      ) : (
        ''
      )}
    </Text>
  );
}
