import { useRadioGroupState } from 'react-stately';
import { RadioGroupState } from 'react-stately/dist/types';
import {
  AriaRadioProps,
  useRadio,
  useRadioGroup,
  AriaRadioGroupProps,
} from '@react-aria/radio';
import { createContext, useContext, useRef } from 'react';
import { Box } from '../box/box';
import { Text } from '../text/text';
import { VisuallyHidden, useFocusRing } from 'react-aria';
import { Icon, IconProps } from '../icons/icons';
import { Inline } from '../inline/inline';

const RadioContext = createContext<null | RadioGroupState>(null);

interface RadioGroupProps extends AriaRadioGroupProps {
  children: React.ReactNode;
}

export function RadioGroup(props: RadioGroupProps) {
  const { children, label, description, errorMessage, validationState } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } =
    useRadioGroup(props, state);

  return (
    <Box {...radioGroupProps} width={'full'}>
      {label ? (
        <Text marginBottom={'2'} variation="b2" {...labelProps}>
          {label}
        </Text>
      ) : null}
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
      {description && (
        <div {...descriptionProps} style={{ fontSize: 12 }}>
          {description}
        </div>
      )}
      {errorMessage && validationState === 'invalid' && (
        <div {...errorMessageProps} style={{ color: 'red', fontSize: 12 }}>
          {errorMessage}
        </div>
      )}
    </Box>
  );
}

const UnCheckedIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
      fill={'currentColor'}
    />
  </Icon>
);

const CheckedIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
      fill={'currentColor'}
    />
    <circle cx="12" cy="12" r="5" fill={'currentColor'} />
  </Icon>
);

export function Radio(
  props: AriaRadioProps & {
    paddingX?: React.ComponentProps<typeof Box>['paddingX'];
    paddingY?: React.ComponentProps<typeof Box>['paddingY'];
    alignItems?: React.ComponentProps<typeof Box>['alignItems'];
  }
) {
  const { children, alignItems = 'center' } = props;
  const state = useContext(RadioContext);
  const ref = useRef(null);
  const { inputProps, isSelected, isDisabled } = useRadio(
    props,
    state as RadioGroupState,
    ref
  );
  const { focusProps } = useFocusRing();

  return (
    <Inline
      gap="3"
      paddingY={props.paddingY || '1'}
      as={'label'}
      alignItems={alignItems}
      cursor="pointer"
      paddingX={props.paddingX}
      width="full"
      id={inputProps.id}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      {isSelected ? (
        <CheckedIcon
          color={{
            hover: isDisabled ? 'iconLowest' : 'iconPrimaryHover',
            default: isDisabled ? 'iconLowest' : 'iconPrimary',
          }}
          size={'3'}
        />
      ) : (
        <UnCheckedIcon
          color={{
            hover: isDisabled ? undefined : 'iconMedium',
            focus: 'iconMedium',
            default: isDisabled ? 'iconLowest' : 'iconLow',
          }}
          size={'3'}
        />
      )}
      {children}
    </Inline>
  );
}
