import {
  VendorDetails,
  useVendorActions,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  Alert,
  ApprovedVendorIcon,
  Avatar,
  Box,
  Button,
  CancelIcon,
  CheckIcon,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  RejectedVendorIcon,
  Stack,
  Tag,
  Text,
} from '@nbfc-expense-tool/ui';
import { useState } from 'react';

type VendorActionType = 'reject' | 'approve';
export function ApproveOrRejectFlowInModal({
  vendor_details,
  children,
  onSuccess,
}: {
  vendor_details?: VendorDetails;
  onSuccess?: () => void;
  children: (props: {
    onOpen: (type: VendorActionType) => void;
  }) => React.ReactNode;
}) {
  const [type, setType] = useState<VendorActionType | null>(null);
  const { loading, isCompleted, approve, reject } = useVendorActions(
    vendor_details?.id.toString() || ''
  );

  const onClose = () => {
    if (isCompleted) {
      onSuccess?.();
    }
    setType(null);
  };

  const vendorActionHandler = async () => {
    if (type === 'approve') {
      await approve();
    } else {
      await reject();
    }
  };

  return (
    <>
      {children({
        onOpen: (type) => setType(type),
      })}
      <Modal
        isOpen={Boolean(type)}
        onClose={onClose}
        placement="right"
        size="lg"
        title={
          type === 'approve'
            ? isCompleted
              ? 'Success!'
              : 'Approve Vendor?'
            : isCompleted
            ? 'Rejected!'
            : 'Reject Vendor?'
        }
      >
        <ModalBody>
          <Stack gap="12">
            {isCompleted ? (
              <Stack alignItems="center" justifyContent="center">
                <Stack
                  gap="3"
                  maxWidth="xs"
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                >
                  {type === 'approve' ? (
                    <ApprovedVendorIcon size="10" />
                  ) : (
                    <RejectedVendorIcon size="10" />
                  )}
                  <Text as="h2" variation="h2">{`Vendor ${
                    type === 'approve' ? 'Approved' : 'Rejected'
                  } Successfully!`}</Text>
                </Stack>
              </Stack>
            ) : type === 'reject' ? (
              <Alert status="warning">
                <Text variation="t3">
                  Once the vendor is rejected, it needs to be created once again
                  for approval. And the rejected vendor account will not be
                  visible anywhere.
                </Text>
              </Alert>
            ) : null}
            <Stack gap="4">
              {isCompleted ? null : (
                <Text variation="t1">Confirm Vendor Details</Text>
              )}
              <Box borderWidth="1" borderColor="borderOutline" rounded="md">
                <Inline
                  paddingY="2"
                  paddingX="3"
                  borderBottomWidth="1"
                  borderColor="borderOutline"
                >
                  <Inline gap="2" alignItems="center">
                    <Text variation="t4" color="textMedium">
                      Status:{' '}
                    </Text>
                    <Tag
                      status={
                        vendor_details?.approval_status === 'under_review'
                          ? 'warning'
                          : 'default'
                      }
                      title={
                        vendor_details?.approval_status === 'under_review'
                          ? 'Under Review'
                          : vendor_details?.approval_status || 'Default'
                      }
                    />
                  </Inline>
                </Inline>
                <Stack
                  gap="8"
                  paddingX="3"
                  paddingTop="2"
                  paddingBottom="3"
                  backgroundColor="surfaceNeutralLowest"
                >
                  <Inline
                    gap="4"
                    borderBottomWidth="1"
                    paddingBottom="2"
                    borderColor="borderOutline"
                  >
                    <Avatar
                      avatarSize="lg"
                      id={vendor_details?.id.toString()}
                      avatarText={
                        vendor_details?.name?.length
                          ? vendor_details?.name[0]
                          : 'U'
                      }
                      marginTop="0.5"
                    />
                    <Stack gap="1">
                      <Text variation="t1">{vendor_details?.name}</Text>
                      <Inline gap="2" color="textLow">
                        <Text variation="b2">
                          {vendor_details?.vendor_type.name}
                        </Text>
                        <Text variation="b2">•</Text>
                        <Text variation="b2">
                          {vendor_details?.company_reg_type}
                        </Text>
                      </Inline>
                    </Stack>
                  </Inline>
                  <Stack gap="6">
                    <Inline gap="10">
                      <Stack style={{ width: 200 }}>
                        <Text variation="c2" color={'textLow'}>
                          Legal Entity Name
                        </Text>
                        <Text variation="b2">{vendor_details?.legal_name}</Text>
                      </Stack>
                      <Stack style={{ width: 200 }}>
                        <Text variation="c2" color={'textLow'}>
                          PAN
                        </Text>
                        <Text variation="b2">{vendor_details?.pan_number}</Text>
                      </Stack>
                      <Stack style={{ width: 200 }}>
                        <Text variation="c2" color={'textLow'}>
                          CIN
                        </Text>
                        <Text variation="b2">{vendor_details?.cin_number}</Text>
                      </Stack>
                    </Inline>
                    <Stack style={{ width: 200 }}>
                      <Text variation="c2" color={'textLow'}>
                        Branches
                      </Text>
                      {vendor_details?.branches.map((branch, i) => (
                        <Text variation="b2" key={branch.id}>
                          {branch?.name}{' '}
                          {i !== vendor_details.branches.length - 1 ? ',' : ''}
                        </Text>
                      ))}
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </ModalBody>
        {isCompleted ? (
          <ModalFooter>
            <Button
              id="action-on-vendor-success"
              title="Ok, Got It"
              leftIcon={(props) => <CheckIcon {...props} />}
              size="lg"
              onClick={onClose}
            />
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button
              id="action-on-vendor-cancel"
              title="Cancel"
              size="lg"
              leftIcon={(props) => <CancelIcon {...props} />}
              type="outlined"
              state={loading ? 'disabled' : undefined}
              onClick={onClose}
            />
            {type === 'reject' ? (
              <Button
                id="action-on-vendor-reject"
                title="Yes, Reject Vendor"
                leftIcon={(props) => <CheckIcon {...props} />}
                buttonRole="destructive"
                state={loading ? 'loading' : undefined}
                size="lg"
                onClick={vendorActionHandler}
              />
            ) : (
              <Button
                id="action-on-vendor-approve"
                title="Yes, Approve Vendor"
                leftIcon={(props) => <CheckIcon {...props} />}
                buttonRole="success"
                size="lg"
                state={loading ? 'loading' : undefined}
                onClick={vendorActionHandler}
              />
            )}
          </ModalFooter>
        )}
      </Modal>
    </>
  );
}
