import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useRef } from 'react';

import { Text } from '../text/text';
import { Box, BoxOwnProps } from '../box/box';
import Stack from '../stack/stack';
import { IconParams } from '../../types';
import { borderColorStyles } from '../css/common.css';
import { CalendarIcon, DownIconFilled } from '../icons/icons';
import './datepicker.css';
import { Inline } from '../inline/inline';

export type DatePickerProps = {
  label: string;
  isRequired: boolean;
  leftIcon?: ({ color, size }: IconParams) => React.ReactElement;
  rightIcon?: ({ color, size }: IconParams) => React.ReactElement;
  fullWidth?: boolean;
  errorMessage?: string;
  isDisabled?: boolean;
  description?: string;
  marginTop?: BoxOwnProps['marginTop'];
  marginBottom?: BoxOwnProps['marginBottom'];
  marginLeft?: BoxOwnProps['marginLeft'];
  marginRight?: BoxOwnProps['marginRight'];
  marginX?: BoxOwnProps['marginX'];
  marginY?: BoxOwnProps['marginY'];
  width?: BoxOwnProps['width'];
  onChangeDate: (date: Date | null) => void;
  datePickerProps?: Omit<React.ComponentProps<typeof DatePicker>, 'onChange'>;
  selectedDate?: Date | null;
  isControlled?: boolean;
  id: string;
};

export const DatePickerInput: React.FC<DatePickerProps> = ({
  label,
  leftIcon,
  rightIcon,
  datePickerProps,
  selectedDate = null,
  isControlled = false,
  fullWidth = false,
  ...props
}) => {
  const [startDate, setStartDate] = useState<Date | null>(selectedDate);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const startRef = useRef<DatePicker>();

  useEffect(() => {
    if (isControlled) {
      if (selectedDate) {
        setStartDate(selectedDate);
      } else {
        setStartDate(null);
      }
    }
  }, [isControlled, selectedDate]);

  const onStartChange = () => {
    if (startRef.current?.isCalendarOpen()) {
      startRef.current?.setBlur();
    } else {
      startRef.current?.setFocus();
    }
  };

  return (
    <Stack
      display={'flex'}
      style={{
        width: fullWidth ? '100%' : '324px',
      }}
      {...props}
    >
      <Text as="label" color={'textHigh'} variation="c2">
        {label}
        {props.isRequired && (
          <Text marginLeft={'0.5'} as="span" color={'textError'}>
            *
          </Text>
        )}
      </Text>
      <Box borderWidth={isFocused ? '0' : '1'} borderColor="transparent">
        <Inline
          marginTop={'0.5'}
          paddingX="1"
          paddingLeft="1.5"
          borderWidth={isFocused ? '2' : '1'}
          borderRadius="md"
          bgColor={props.isDisabled ? 'surfaceNeutralLowest' : undefined}
          className={borderColorStyles({
            color: props.errorMessage
              ? 'borderError'
              : isFocused
              ? 'borderPrimary'
              : 'borderOutline',
          })}
          alignItems="center"
          justifyContent="between"
          onClick={onStartChange}
        >
          <Box display={'flex'} alignSelf={'center'} alignItems="center">
            <CalendarIcon
              color={
                props.errorMessage
                  ? 'iconError'
                  : isFocused
                  ? 'iconPrimary'
                  : 'iconLow'
              }
              size="3"
              marginRight="1.5"
            />
            <DatePicker
              {...datePickerProps}
              selected={startDate}
              onChange={(date: Date | null) => {
                props.onChangeDate(date);
                setStartDate(date);
              }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ref={startRef as any}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onCalendarOpen={() => setIsFocused(true)}
              onCalendarClose={() => setIsFocused(false)}
              wrapperClassName="wrapper"
              className="inputBox"
              showYearDropdown
              dateFormat="dd/MM/yyyy"
            />
          </Box>
          <DownIconFilled size="3" color="iconMedium" />
        </Inline>
      </Box>
      {props.description && (
        <Text marginTop={'0.5'} as="p" variation={'c2'} color={'textLow'}>
          {props.description}
        </Text>
      )}
      {props.errorMessage && (
        <Text
          marginTop={'0.5'}
          borderRadius="md"
          paddingY={'0.5'}
          paddingX={'1'}
          display={'flex'}
          color={'textError'}
          variation={'c2'}
          bgColor={'surfaceErrorLowest'}
        >
          {props.errorMessage}
        </Text>
      )}
    </Stack>
  );
};
