import { FileIcon, Stack, Text } from '@nbfc-expense-tool/ui';
import { useUser } from '@nbfc-expense-tool/data-store/auth';
import { checkForSinglePermission } from '@nbfc-expense-tool/data-store/permissions';
import { AddNewReimbursementButton } from './AddNewReimbursement';

type ComponentProps = {
  title: string;
};

const EmptyReimbursementList: React.FC<ComponentProps> = ({ title }) => {
  const { user } = useUser();

  const canAddNewReimbursement = checkForSinglePermission(
    'createReimbursement',
    user?.roles[0]?.permissions || null
  );

  return (
    <Stack
      gap="4"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
    >
      <FileIcon size="8" color="iconLowest" />
      <Stack gap="2" paddingBottom="0.5">
        <Text variation="t2">{title}</Text>
        {canAddNewReimbursement && (
          <Text variation="b2">
            Tap ‘Add New’ to create a new reimbursement
          </Text>
        )}
      </Stack>
      {canAddNewReimbursement && <AddNewReimbursementButton id="empty-state" />}
    </Stack>
  );
};

export default EmptyReimbursementList;
