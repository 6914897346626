import { atom } from 'jotai';

type UserRole = {
  id: number;
  name: string;
  display_name: string;
  level: number;
  permissions: {
    id: number;
    name: string;
  };
};

export type UserDetails = {
  id: number;
  name: string;
  email: string;
  email_verified_at: Date | null;
  phone_number: null | string;
  dob: Date | null;
  gender: string | null;
  disabled_at: Date | null;
  branch_id: string | null;
  created_at: Date | null;
  updated_at: Date | null;
  avatar: {
    original: string | null;
    preview: string | null;
  };
  roles: UserRole[];
};

export const userDetails = atom<UserDetails | null>(null);

type ExpenseHeadPayloadType = {
  value: string;
  label: string;
};

export const expenseHeadList = atom<ExpenseHeadPayloadType[]>([]);

export type InvoiceItemPayloadType = {
  type: 'product' | 'service';
  name: string;
  description?: string;
  hsn_code?: string;
  sac_code?: string;
  unit_type: 'pieces' | 'items' | 'kg' | 'km' | 'grams' | 'meters';
  unit_number: number;
  unit_price: number;
  sgst_percent: number;
  cgst_percent: number;
  igst_percent: number;
  gst_amount: number;
  total_amount: number;
  expense_head_id: number;
  expense_particular_id: number;
  total_price: number;
  tds_percent: number;
  tds_amount: number;
  tds_id?: number;
  gst_id?: number;
  id?: number;
  is_complete?: boolean;
};

export type TenantIndividualConfigPayloadType = {
  name: string;
  type: string;
  default: boolean;
  is_public: boolean;
  display_name: string;
  value: boolean;
};

export type TenantConfigCompletePayloadType = {
  [key: string]: TenantIndividualConfigPayloadType;
};

export const invoiceItems = atom<InvoiceItemPayloadType[]>([]);
export const isSidebarExpanded = atom<boolean>(true);

export const gstSlabsList = atom<ExpenseHeadPayloadType[]>([]);

export const tenantConfig = atom<TenantConfigCompletePayloadType | null>(null);
