import {
  Payment_status,
  ReimbursementDetails,
  ReimbursementStatus,
  useActionOnReimbursement,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  Alert,
  Amount,
  ApprovedInvoiceIcon as ApprovedReimbursementIcon,
  Avatar,
  Box,
  Button,
  CheckIcon,
  CrossIcon,
  Date,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  RejectedInvoiceIcon as RejectedReimbursementIcon,
  ReturnedInvoiceIcon as ReturnedReimbursementIcon,
  Stack,
  Text,
  TextArea,
  TextInput,
  toastQueue,
} from '@nbfc-expense-tool/ui';
import React, { useMemo, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorResponse } from '@nbfc-expense-tool/data-store/utils';

const ReimbursementStatusTag = ({
  status,
}: {
  status: ReimbursementStatus | Payment_status;
}) => {
  const colorsAndText: {
    color: React.ComponentProps<typeof Stack>['color'];
    bgColor: React.ComponentProps<typeof Stack>['backgroundColor'];
    label: string;
  } = useMemo(() => {
    switch (status) {
      case 'under_review':
        return {
          label: 'Under Review',
          color: 'textWarning',
          bgColor: 'surfaceWarningLowest',
        };
      case 'rejected':
        return {
          label: 'Rejected',
          color: 'textError',
          bgColor: 'surfaceErrorLowest',
        };
      case 'approved':
        return {
          label: 'Approved',
          color: 'textSuccess',
          bgColor: 'surfaceSuccessLowest',
        };
      case 'returned':
        return {
          label: 'Returned',
          color: 'textMedium',
          bgColor: 'surfaceNeutralLowest',
        };
      case 'paid':
        return {
          label: 'Paid',
          color: 'textSuccess',
          bgColor: 'surfaceSuccessLowest',
        };
      case 'unpaid':
        return {
          label: 'Unpaid',
          color: 'textWarning',
          bgColor: 'surfaceWarningLowest',
        };
      default:
        return {
          label: 'Returned',
          color: 'textMedium',
          bgColor: 'surfaceNeutralLowest',
        };
    }
  }, [status]);
  return (
    <Stack
      backgroundColor={colorsAndText.bgColor}
      paddingX="1"
      alignItems="center"
      justifyContent="center"
      rounded="md"
      style={{ paddingTop: 2, paddingBottom: 2 }}
    >
      <Text variation="c1" color={colorsAndText.color}>
        {colorsAndText.label}
      </Text>
    </Stack>
  );
};

type ActionTypes = 'approve' | 'reject' | 'return' | 'markPaid';
const ActionOnReimbursementInModal = ({
  children,
  ...props
}: React.ComponentProps<typeof ApproveAnReimbursement> & {
  children: (props: { onOpen: (type: ActionTypes) => void }) => React.ReactNode;
}) => {
  const [actionType, setActionType] = useState<ActionTypes | undefined>(
    undefined
  );
  const [isActionDone, setIsActionDone] = useState<boolean>(false);
  const state = useOverlayTriggerState({});

  const markActionStatus = () => {
    setIsActionDone(true);
  };

  return (
    <>
      {children({
        onOpen: (type: ActionTypes) => {
          state.open();
          setActionType(type);
        },
      })}
      <Modal
        isOpen={state.isOpen && Boolean(actionType)}
        onClose={state.close}
        placement="right"
        title={
          actionType === 'approve'
            ? isActionDone
              ? 'Approved'
              : 'Approve Reimbursement?'
            : actionType === 'reject'
            ? isActionDone
              ? 'Rejected'
              : 'Reject Reimbursement?'
            : actionType === 'return'
            ? isActionDone
              ? 'Returned'
              : 'Return Reimbursement?'
            : actionType === 'markPaid'
            ? isActionDone
              ? 'Marked Paid'
              : 'Mark Paid?'
            : ''
        }
        isDismissable
        size="lg"
      >
        {actionType === 'approve' ? (
          <ApproveAnReimbursement
            {...props}
            onClose={state.close}
            setActionStatus={markActionStatus}
          />
        ) : actionType === 'reject' ? (
          <RejectAnReimbursement
            {...props}
            onClose={state.close}
            setActionStatus={markActionStatus}
          />
        ) : actionType === 'return' ? (
          <ReturnAnReimbursement
            {...props}
            onClose={state.close}
            setActionStatus={markActionStatus}
          />
        ) : actionType === 'markPaid' ? (
          <MarkPaidAnReimbursement
            {...props}
            onClose={state.close}
            setActionStatus={markActionStatus}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default ActionOnReimbursementInModal;

const approveReimbursementSchema = z.object({
  remark: z.string().optional(),
});

const ApproveAnReimbursement: React.FC<{
  reimbursement: ReimbursementDetails;
  onClose?: () => void;
  onSuccess?: () => void;
  setActionStatus?: () => void;
}> = ({ reimbursement, onClose, onSuccess, setActionStatus }) => {
  const idPrefix = 'approve-reimbursement';
  const [approved, setApproved] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const form = useForm<{ remark?: string }>({
    resolver: zodResolver(approveReimbursementSchema),
  });
  const { control, getValues, handleSubmit } = form;
  const {
    ticket_number,
    grand_total_amount,
    creator,
    status: reimbursement_status,
  } = reimbursement;

  const approveAnReimbursement = useActionOnReimbursement(
    ticket_number,
    'approve'
  );

  const approve = async (data: { remark?: string }) => {
    setIsSubmitting(true);
    try {
      await approveAnReimbursement(data.remark);
      setApproved(true);
      setActionStatus?.();
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return approved ? (
    <>
      <ModalBody>
        <Stack gap="10">
          <Stack gap="5" justifyContent="center" alignItems="center">
            <ApprovedReimbursementIcon size="10" />
            <Box maxWidth="sm" textAlign="center">
              <Text as="h2" variation="h2">
                Reimbursement Approved Successfully!
              </Text>
            </Box>
          </Stack>
          <Box borderWidth="1" borderColor="borderOutline" rounded="md">
            <Inline
              paddingY="2"
              paddingX="3"
              alignItems="center"
              justifyContent="between"
              borderBottomWidth="1"
              borderColor="borderOutline"
            >
              <Text variation="t3">Reimbursement ID: {ticket_number}</Text>
              <Inline gap="2">
                <Text variation="t4" color="textMedium">
                  Status:{' '}
                </Text>
                <ReimbursementStatusTag status={reimbursement_status} />
              </Inline>
            </Inline>
            <Stack
              gap="8"
              backgroundColor="surfaceNeutralLowest"
              paddingX="3"
              paddingBottom="3"
            >
              <Inline
                justifyContent="between"
                borderBottomWidth="1"
                paddingY="2"
                borderColor="borderOutline"
              >
                <Inline gap="4">
                  <Avatar
                    as="img"
                    src={creator.avatar.preview || ''}
                    avatarSize="lg"
                  />
                  <Stack gap="1">
                    <Text variation="t1">{creator.name}</Text>
                    <Text variation="b2" color="textLow">
                      {creator?.employee_code || '-'}
                    </Text>
                  </Stack>
                </Inline>
                <Stack textAlign="right">
                  <Amount variation="t1" amount={Number(grand_total_amount)} />
                  <Text variation="b2" color="textMedium">
                    Amount
                  </Text>
                </Stack>
              </Inline>
              <Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Title
                    </Text>
                    <Text variation="b2">{reimbursement.title}</Text>
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Requested On
                    </Text>
                    <Date variation="b2" date={reimbursement.created_at} />
                  </Stack>
                </Inline>
              </Inline>
            </Stack>
          </Box>
          {getValues('remark')?.length ? (
            <Stack gap="1">
              <Text color="textLow" variation="c1">
                Remark
              </Text>
              <Text variation="b1">{getValues('remark')}</Text>
            </Stack>
          ) : null}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={`${idPrefix}-success-got-it-button`}
          size="lg"
          title="Ok, Got it"
          onClick={() => {
            onSuccess?.();
            onClose?.();
          }}
          leftIcon={(props) => <CheckIcon {...props} />}
        />
      </ModalFooter>
    </>
  ) : (
    <>
      <ModalBody>
        <Stack gap="4">
          <Text variation="t1">Confirm Reimbursement Details</Text>
          <Stack gap="12">
            <Box borderWidth="1" borderColor="borderOutline" rounded="md">
              <Inline
                paddingY="2"
                paddingX="3"
                alignItems="center"
                justifyContent="between"
                borderBottomWidth="1"
                borderColor="borderOutline"
              >
                <Text variation="t3">Reimbursement ID: {ticket_number}</Text>
                <Inline gap="2">
                  <Text variation="t4" color="textMedium">
                    Status:{' '}
                  </Text>
                  <ReimbursementStatusTag status={reimbursement_status} />
                </Inline>
              </Inline>
              <Stack
                gap="8"
                backgroundColor="surfaceNeutralLowest"
                paddingX="3"
                paddingBottom="3"
              >
                <Inline
                  justifyContent="between"
                  borderBottomWidth="1"
                  paddingY="2"
                  borderColor="borderOutline"
                >
                  <Inline gap="4">
                    <Avatar
                      as="img"
                      src={creator.avatar.preview || ''}
                      avatarSize="lg"
                    />
                    <Stack gap="1">
                      <Text variation="t1">{creator.name}</Text>
                      <Text variation="b2" color="textLow">
                        {creator?.employee_code || '-'}
                      </Text>
                    </Stack>
                  </Inline>
                  <Stack textAlign="right">
                    <Amount
                      variation="t1"
                      amount={Number(grand_total_amount)}
                    />
                    <Text variation="b2" color="textMedium">
                      Amount
                    </Text>
                  </Stack>
                </Inline>
                <Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Title
                      </Text>
                      <Text variation="b2">{reimbursement.title}</Text>
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Requested On
                      </Text>
                      <Date variation="b2" date={reimbursement.created_at} />
                    </Stack>
                  </Inline>
                </Inline>
              </Stack>
            </Box>

            <Controller
              control={control}
              name="remark"
              render={(props) => {
                const {
                  field: { onChange },
                  fieldState: { error },
                } = props;
                return (
                  <Stack gap="1">
                    <Text variation="t1">Enter Remark</Text>
                    <TextArea
                      id={`${idPrefix}-add-remark`}
                      name="remark"
                      placeholder="Add remark here"
                      onChange={onChange}
                      errorMessage={error?.message}
                    />
                  </Stack>
                );
              }}
            />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={`${idPrefix}-cancel-button`}
          title="Cancel"
          type="outlined"
          size="lg"
          leftIcon={(props) => <CrossIcon {...props} />}
          onClick={onClose}
        />
        <Button
          id={`${idPrefix}-approve-button`}
          title={
            isSubmitting
              ? 'Approving Reimbursement...'
              : 'Yes, Approve Reimbursement'
          }
          size="lg"
          buttonRole="success"
          state={isSubmitting ? 'loading' : undefined}
          onClick={handleSubmit(approve)}
          leftIcon={(props) => <CheckIcon {...props} />}
        />
      </ModalFooter>
    </>
  );
};

const rejectOnReimbursementSchema = z.object({
  remark: z
    .string({ required_error: 'Please enter a reason for rejection.' })
    .nonempty(),
});

const RejectAnReimbursement: React.FC<{
  reimbursement: ReimbursementDetails;
  onClose?: () => void;
  onSuccess?: () => void;
  setActionStatus?: () => void;
}> = ({ reimbursement, onClose, onSuccess, setActionStatus }) => {
  const idPrefix = 'reject-reimbursement';
  const [rejected, setRejected] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const form = useForm<{ remark?: string }>({
    resolver: zodResolver(rejectOnReimbursementSchema),
  });
  const { control, getValues, handleSubmit } = form;
  const {
    ticket_number,
    grand_total_amount,
    creator,
    status: reimbursement_status,
  } = reimbursement;

  const rejectAnReimbursement = useActionOnReimbursement(
    ticket_number,
    'reject'
  );

  const reject = async (data: { remark?: string }) => {
    setIsSubmitting(true);
    try {
      await rejectAnReimbursement(data.remark);
      setRejected(true);
      setActionStatus?.();
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return rejected ? (
    <>
      <ModalBody>
        <Stack gap="10">
          <Stack gap="5" justifyContent="center" alignItems="center">
            <RejectedReimbursementIcon size="10" />
            <Box maxWidth="sm" textAlign="center">
              <Text as="h2" variation="h2">
                Reimbursement Rejected Successfully!
              </Text>
            </Box>
          </Stack>
          <Box borderWidth="1" borderColor="borderOutline" rounded="md">
            <Inline
              paddingY="2"
              paddingX="3"
              alignItems="center"
              justifyContent="between"
              borderBottomWidth="1"
              borderColor="borderOutline"
            >
              <Text variation="t3">Reimbursement: {ticket_number}</Text>
              <Inline gap="2">
                <Text variation="t4" color="textMedium">
                  Status:{' '}
                </Text>
                <ReimbursementStatusTag status={reimbursement_status} />
              </Inline>
            </Inline>
            <Stack
              gap="8"
              backgroundColor="surfaceNeutralLowest"
              paddingX="3"
              paddingBottom="3"
            >
              <Inline
                justifyContent="between"
                borderBottomWidth="1"
                paddingY="2"
                borderColor="borderOutline"
              >
                <Inline gap="4">
                  <Avatar
                    as="img"
                    src={creator.avatar.preview || ''}
                    avatarSize="lg"
                  />
                  <Stack gap="1">
                    <Text variation="t1">{creator?.name}</Text>
                    <Text variation="b2" color="textLow">
                      {creator?.employee_code || '-'}
                    </Text>
                  </Stack>
                </Inline>
                <Stack textAlign="right">
                  <Amount variation="t1" amount={Number(grand_total_amount)} />
                  <Text variation="b2" color="textMedium">
                    Amount
                  </Text>
                </Stack>
              </Inline>
              <Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Title
                    </Text>
                    <Text variation="b2">{reimbursement.title}</Text>
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Requested On
                    </Text>
                    <Date variation="b2" date={reimbursement.created_at} />
                  </Stack>
                </Inline>
              </Inline>
            </Stack>
          </Box>
          {getValues('remark')?.length ? (
            <Stack gap="1">
              <Text color="textLow" variation="c1">
                Reason for rejection
              </Text>
              <Text variation="b1">{getValues('remark')}</Text>
            </Stack>
          ) : null}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={`${idPrefix}-success-got-it-button`}
          title="Ok, Got it"
          size="lg"
          onClick={() => {
            onSuccess?.();
            onClose?.();
          }}
        />
      </ModalFooter>
    </>
  ) : (
    <>
      <ModalBody>
        <Stack gap="4">
          <Alert status="warning">
            <Text variation="t3" color="textWarning">
              If you reject the reimbursement then it needs to be created once
              again for approval. Consider using ‘Return’ if you want to make
              changes
            </Text>
          </Alert>
          <Text variation="t1">Confirm Reimbursement Details</Text>
          <Stack gap="12">
            <Box borderWidth="1" borderColor="borderOutline" rounded="md">
              <Inline
                paddingY="2"
                paddingX="3"
                alignItems="center"
                justifyContent="between"
                borderBottomWidth="1"
                borderColor="borderOutline"
              >
                <Text variation="t3">Reimbursement: {ticket_number}</Text>
                <Inline gap="2">
                  <Text variation="t4" color="textMedium">
                    Status:{' '}
                  </Text>
                  <ReimbursementStatusTag status={reimbursement_status} />
                </Inline>
              </Inline>
              <Stack
                gap="8"
                backgroundColor="surfaceNeutralLowest"
                paddingX="3"
                paddingBottom="3"
              >
                <Inline
                  justifyContent="between"
                  borderBottomWidth="1"
                  paddingY="2"
                  borderColor="borderOutline"
                >
                  <Inline gap="4">
                    <Avatar
                      as="img"
                      src={creator.avatar.preview || ''}
                      avatarSize="lg"
                    />
                    <Stack gap="1">
                      <Text variation="t1">{creator?.name}</Text>
                      <Text variation="b2" color="textLow">
                        {creator?.employee_code || '-'}
                      </Text>
                    </Stack>
                  </Inline>
                  <Stack textAlign="right">
                    <Amount
                      variation="t1"
                      amount={Number(grand_total_amount)}
                    />
                    <Text variation="b2" color="textMedium">
                      Amount
                    </Text>
                  </Stack>
                </Inline>
                <Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Title
                      </Text>
                      <Text variation="b2">{reimbursement.title}</Text>
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Requested On
                      </Text>
                      <Date variation="b2" date={reimbursement.created_at} />
                    </Stack>
                  </Inline>
                </Inline>
              </Stack>
            </Box>

            <Controller
              control={control}
              name="remark"
              render={(props) => {
                const {
                  field: { onChange },
                  fieldState: { error },
                } = props;
                return (
                  <Stack gap="1">
                    <TextArea
                      id={`${idPrefix}-add-remark`}
                      name="remark"
                      labelVariation="t1"
                      label="Enter reason for rejection"
                      placeholder="Please mention why are you rejecting this reimbursement?"
                      onChange={onChange}
                      isRequired
                      errorMessage={error?.message}
                    />
                  </Stack>
                );
              }}
            />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={`${idPrefix}-cancel-button`}
          title="Cancel"
          type="outlined"
          size="lg"
          leftIcon={(props) => <CrossIcon {...props} />}
          onClick={onClose}
        />
        <Button
          id={`${idPrefix}-reject-button`}
          title="Yes, Reject Reimbursement"
          size="lg"
          buttonRole="destructive"
          state={isSubmitting ? 'loading' : undefined}
          onClick={handleSubmit(reject)}
          leftIcon={(props) => <CheckIcon {...props} />}
        />
      </ModalFooter>
    </>
  );
};

const ReturnAnReimbursement: React.FC<{
  reimbursement: ReimbursementDetails;
  onClose?: () => void;
  onSuccess?: () => void;
  setActionStatus?: () => void;
}> = ({ reimbursement, onClose, onSuccess, setActionStatus }) => {
  const idPrefix = 'return-reimbursement';
  const [returned, setReturned] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const form = useForm<{ remark?: string }>({
    resolver: zodResolver(rejectOnReimbursementSchema),
  });
  const { control, getValues, handleSubmit } = form;
  const {
    ticket_number,
    grand_total_amount,
    creator,
    status: reimbursement_status,
  } = reimbursement;

  const returnAnReimbursement = useActionOnReimbursement(
    ticket_number,
    'return'
  );

  const returnReimbursement = async (data: { remark?: string }) => {
    setIsSubmitting(true);
    try {
      await returnAnReimbursement(data.remark);
      setReturned(true);
      setActionStatus?.();
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return returned ? (
    <>
      <ModalBody>
        <Stack gap="10">
          <Stack gap="5" justifyContent="center" alignItems="center">
            <ReturnedReimbursementIcon size="10" />
            <Box maxWidth="sm" textAlign="center">
              <Text as="h2" variation="h2">
                Reimbursement Returned Successfully!
              </Text>
            </Box>
          </Stack>
          <Box borderWidth="1" borderColor="borderOutline" rounded="md">
            <Inline
              paddingY="2"
              paddingX="3"
              alignItems="center"
              justifyContent="between"
              borderBottomWidth="1"
              borderColor="borderOutline"
            >
              <Text variation="t3">Reimbursement: {ticket_number}</Text>
              <Inline gap="2">
                <Text variation="t4" color="textMedium">
                  Status:{' '}
                </Text>
                <ReimbursementStatusTag status={reimbursement_status} />
              </Inline>
            </Inline>
            <Stack
              gap="8"
              backgroundColor="surfaceNeutralLowest"
              paddingX="3"
              paddingBottom="3"
            >
              <Inline
                justifyContent="between"
                borderBottomWidth="1"
                paddingY="2"
                borderColor="borderOutline"
              >
                <Inline gap="4">
                  <Avatar
                    as="img"
                    src={creator.avatar.preview || ''}
                    avatarSize="lg"
                  />
                  <Stack gap="1">
                    <Text variation="t1">{creator?.name}</Text>
                    <Text variation="b2" color="textLow">
                      {creator?.employee_code || '-'}
                    </Text>
                  </Stack>
                </Inline>
                <Stack textAlign="right">
                  <Amount variation="t1" amount={Number(grand_total_amount)} />
                  <Text variation="b2" color="textMedium">
                    Amount
                  </Text>
                </Stack>
              </Inline>
              <Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Title
                    </Text>
                    <Text variation="b2">{reimbursement.title}</Text>
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Requested On
                    </Text>
                    <Date variation="b2" date={reimbursement.created_at} />
                  </Stack>
                </Inline>
              </Inline>
            </Stack>
          </Box>
          {getValues('remark')?.length ? (
            <Stack gap="1">
              <Text color="textLow" variation="c1">
                Changes to be made
              </Text>
              <Text variation="b1">{getValues('remark')}</Text>
            </Stack>
          ) : null}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={`${idPrefix}-success-got-it-button`}
          title="Ok, Got it"
          size="lg"
          onClick={() => {
            onSuccess?.();
            onClose?.();
          }}
        />
      </ModalFooter>
    </>
  ) : (
    <>
      <ModalBody>
        <Stack gap="4">
          <Alert status="warning">
            <Text variation="t3" color="textWarning">
              If you return this reimbursement, this will go to reimbursement
              creator to make changes. All the other people in the approval
              chain will have to approve it again
            </Text>
          </Alert>
          <Text variation="t1">Confirm Reimbursement Details</Text>
          <Stack gap="12">
            <Box borderWidth="1" borderColor="borderOutline" rounded="md">
              <Inline
                paddingY="2"
                paddingX="3"
                alignItems="center"
                justifyContent="between"
                borderBottomWidth="1"
                borderColor="borderOutline"
              >
                <Text variation="t3">Reimbursement: {ticket_number}</Text>
                <Inline gap="2">
                  <Text variation="t4" color="textMedium">
                    Status:{' '}
                  </Text>
                  <ReimbursementStatusTag status={reimbursement_status} />
                </Inline>
              </Inline>
              <Stack
                gap="8"
                backgroundColor="surfaceNeutralLowest"
                paddingX="3"
                paddingBottom="3"
              >
                <Inline
                  justifyContent="between"
                  borderBottomWidth="1"
                  paddingY="2"
                  borderColor="borderOutline"
                >
                  <Inline gap="4">
                    <Avatar
                      as="img"
                      src={creator.avatar.preview || ''}
                      avatarSize="lg"
                    />
                    <Stack gap="1">
                      <Text variation="t1">{creator?.name}</Text>
                      <Text variation="b2" color="textLow">
                        {creator?.employee_code || '-'}
                      </Text>
                    </Stack>
                  </Inline>
                  <Stack textAlign="right">
                    <Amount
                      variation="t1"
                      amount={Number(grand_total_amount)}
                    />
                    <Text variation="b2" color="textMedium">
                      Amount
                    </Text>
                  </Stack>
                </Inline>
                <Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Title
                      </Text>
                      <Text variation="b2">{reimbursement.title}</Text>
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Requested On
                      </Text>
                      <Date variation="b2" date={reimbursement.created_at} />
                    </Stack>
                  </Inline>
                </Inline>
              </Stack>
            </Box>

            <Controller
              control={control}
              name="remark"
              render={(props) => {
                const {
                  field: { onChange },
                  fieldState: { error },
                } = props;
                return (
                  <TextArea
                    id={`${idPrefix}-add-remark`}
                    name="remark"
                    labelVariation="t1"
                    label="Suggest changes to be made"
                    placeholder="Please mention specific issues here?"
                    onChange={onChange}
                    isRequired
                    errorMessage={error?.message}
                  />
                );
              }}
            />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={`${idPrefix}-cancel-button`}
          title="Cancel"
          type="outlined"
          size="lg"
          leftIcon={(props) => <CrossIcon {...props} />}
          onClick={onClose}
        />
        <Button
          id={`${idPrefix}-return-button`}
          title="Yes, Return Reimbursement"
          size="lg"
          buttonRole="destructive"
          state={isSubmitting ? 'loading' : undefined}
          onClick={handleSubmit(returnReimbursement)}
          leftIcon={(props) => <CheckIcon {...props} />}
        />
      </ModalFooter>
    </>
  );
};

const markPaidOnReimbursementSchema = z.object({
  utr_number: z.string(),
});

const MarkPaidAnReimbursement: React.FC<{
  reimbursement: ReimbursementDetails;
  onClose?: () => void;
  onSuccess?: () => void;
  setActionStatus?: () => void;
}> = ({ reimbursement, onClose, onSuccess, setActionStatus }) => {
  const idPrefix = 'mark-paid-reimbursement';
  const [ismarkPaid, setIsMarkPaid] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const form = useForm<{ utr_number?: string }>({
    resolver: zodResolver(markPaidOnReimbursementSchema),
  });
  const { control, getValues, handleSubmit } = form;

  const { ticket_number, grand_total_amount, creator, payment_status } =
    reimbursement;

  const markPaidAnReimbursement = useActionOnReimbursement(
    ticket_number,
    'markPaid'
  );

  const markPaid = async (data: { utr_number?: string }) => {
    setIsSubmitting(true);
    try {
      await markPaidAnReimbursement('', data.utr_number);
      setIsMarkPaid(true);
      setActionStatus?.();
      setIsSubmitting(false);
    } catch (e) {
      const err = e as ErrorResponse;
      toastQueue.add(
        {
          title: err?.message || 'Something Went Wrong! Please try again',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
      setIsSubmitting(false);
    }
  };

  return ismarkPaid ? (
    <>
      <ModalBody>
        <Stack gap="10">
          <Stack gap="5" justifyContent="center" alignItems="center">
            <ApprovedReimbursementIcon size="10" />
            <Box maxWidth="sm" textAlign="center">
              <Text as="h2" variation="h2">
                Reimbursement Marked Paid Successfully!
              </Text>
            </Box>
          </Stack>
          <Box borderWidth="1" borderColor="borderOutline" rounded="md">
            <Inline
              paddingY="2"
              paddingX="3"
              alignItems="center"
              justifyContent="between"
              borderBottomWidth="1"
              borderColor="borderOutline"
            >
              <Text variation="t3">Reimbursement: {ticket_number}</Text>
              <Inline gap="2">
                <Text variation="t4" color="textMedium">
                  Payment Status:{' '}
                </Text>
                <ReimbursementStatusTag status={payment_status} />
              </Inline>
            </Inline>
            <Stack
              gap="8"
              backgroundColor="surfaceNeutralLowest"
              paddingX="3"
              paddingBottom="3"
            >
              <Inline
                justifyContent="between"
                borderBottomWidth="1"
                paddingY="2"
                borderColor="borderOutline"
              >
                <Inline gap="4">
                  <Avatar
                    as="img"
                    src={creator.avatar.preview || ''}
                    avatarSize="lg"
                  />
                  <Stack gap="1">
                    <Text variation="t1">{creator?.name}</Text>
                    <Text variation="b2" color="textLow">
                      {creator?.employee_code || '-'}
                    </Text>
                  </Stack>
                </Inline>
                <Stack textAlign="right">
                  <Amount variation="t1" amount={Number(grand_total_amount)} />
                  <Text variation="b2" color="textMedium">
                    Amount
                  </Text>
                </Stack>
              </Inline>
              <Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Title
                    </Text>
                    <Text variation="b2">{reimbursement.title}</Text>
                  </Stack>
                </Inline>
                <Inline width="1/4">
                  <Stack>
                    <Text variation="c2" color="textLow">
                      Requested On
                    </Text>
                    <Date variation="b2" date={reimbursement.created_at} />
                  </Stack>
                </Inline>
              </Inline>
            </Stack>
          </Box>
          <Stack gap="1">
            <Text variation="c0" color="textLow">
              Remark
            </Text>
            <Text variation="b1">
              {reimbursement.last_approved_remarks || '-'}
            </Text>
          </Stack>
          {getValues('utr_number')?.length ? (
            <Stack gap="1">
              <Text color="textLow" variation="c1">
                UTR Number
              </Text>
              <Text variation="b1">{getValues('utr_number')}</Text>
            </Stack>
          ) : null}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={`${idPrefix}-success-got-it-button`}
          size="lg"
          title="Ok, Got it"
          onClick={() => {
            onSuccess?.();
            onClose?.();
          }}
          leftIcon={(props) => <CheckIcon {...props} />}
        />
      </ModalFooter>
    </>
  ) : (
    <>
      <ModalBody>
        <Stack gap="4">
          <Text variation="t1">Confirm Reimbursement Details</Text>
          <Stack gap="12">
            <Box borderWidth="1" borderColor="borderOutline" rounded="md">
              <Inline
                paddingY="2"
                paddingX="3"
                alignItems="center"
                justifyContent="between"
                borderBottomWidth="1"
                borderColor="borderOutline"
              >
                <Text variation="t3">Reimbursement: {ticket_number}</Text>
                <Inline gap="2">
                  <Text variation="t4" color="textMedium">
                    Payment Status:{' '}
                  </Text>
                  <ReimbursementStatusTag status={payment_status} />
                </Inline>
              </Inline>
              <Stack
                gap="8"
                backgroundColor="surfaceNeutralLowest"
                paddingX="3"
                paddingBottom="3"
              >
                <Inline
                  justifyContent="between"
                  borderBottomWidth="1"
                  paddingY="2"
                  borderColor="borderOutline"
                >
                  <Inline gap="4">
                    <Avatar
                      as="img"
                      src={creator.avatar.preview || ''}
                      avatarSize="lg"
                    />
                    <Stack gap="1">
                      <Text variation="t1">{creator?.name}</Text>
                      <Text variation="b2" color="textLow">
                        {creator?.employee_code || '-'}
                      </Text>
                    </Stack>
                  </Inline>
                  <Stack textAlign="right">
                    <Amount
                      variation="t1"
                      amount={Number(grand_total_amount)}
                    />
                    <Text variation="b2" color="textMedium">
                      Amount
                    </Text>
                  </Stack>
                </Inline>
                <Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Title
                      </Text>
                      <Text variation="b2">{reimbursement.title}</Text>
                    </Stack>
                  </Inline>
                  <Inline width="1/4">
                    <Stack>
                      <Text variation="c2" color="textLow">
                        Requested On
                      </Text>
                      <Date variation="b2" date={reimbursement.created_at} />
                    </Stack>
                  </Inline>
                </Inline>
              </Stack>
            </Box>
            <Stack gap="1">
              <Text variation="c0" color="textLow">
                Remark
              </Text>
              <Text variation="b1">
                {reimbursement.last_approved_remarks || '-'}
              </Text>
            </Stack>
            <Controller
              control={control}
              name="utr_number"
              render={(props) => {
                const {
                  field: { onChange },
                  fieldState: { error },
                } = props;
                return (
                  <Stack gap="1">
                    <Inline>
                      <Text variation="t1">{'Enter UTR Number '}</Text>
                      <Text variation="t1" color={'textError'}>
                        *
                      </Text>
                    </Inline>
                    <TextInput
                      id={`${idPrefix}-add-utr-number`}
                      name="Utr_number"
                      placeholder="Example: HDFCR92023012200543116"
                      onChange={onChange}
                      errorMessage={error?.message}
                    />
                    <Text variation="c2" color="textLow">
                      UTR reference number helps in identifying a RTGS, IMPS or
                      NEFT transaction
                    </Text>
                  </Stack>
                );
              }}
            />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          id={`${idPrefix}-cancel-button`}
          title="Cancel"
          type="outlined"
          size="lg"
          leftIcon={(props) => <CrossIcon {...props} />}
          onClick={onClose}
        />
        <Button
          id={`${idPrefix}-mark-paid-button`}
          title="Yes, mark paid"
          size="lg"
          state={isSubmitting ? 'loading' : undefined}
          onClick={handleSubmit(markPaid)}
          leftIcon={(props) => <CheckIcon {...props} />}
        />
      </ModalFooter>
    </>
  );
};
