/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import {
  Box,
  FullScreenHeader,
  TextInput,
  TextArea,
  Stack,
  Text,
  SingleSelect,
  Button,
  BaseOption,
  Amount,
} from '@nbfc-expense-tool/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom, useAtomValue } from 'jotai';
import {
  InvoiceItemPayloadType,
  expenseHeadList,
  gstSlabsList,
  invoiceItems,
} from '@nbfc-expense-tool/data-store/jotai-store';
import { z } from 'zod';
import {
  debounce,
  getGst,
  getHsnCodesList,
  getSacCodesList,
} from '@nbfc-expense-tool/data-store/utils';

type SacOrHsnCodePayloadType = {
  value: string;
  label: string;
  subText?: string;
};

type QuantityUnitOptions = {
  value: 'pieces' | 'items' | 'kg' | 'km' | 'grams' | 'meters';
  label: string;
};

export const quantityOptions: QuantityUnitOptions[] = [
  {
    value: 'pieces',
    label: 'Pieces',
  },
  {
    value: 'items',
    label: 'Items',
  },
  {
    value: 'kg',
    label: 'Kgs',
  },
  {
    value: 'km',
    label: 'Kms',
  },
  {
    value: 'grams',
    label: 'Grams',
  },
  {
    value: 'meters',
    label: 'Meters',
  },
];

type AddNewItemProps = {
  onClickClose: () => void;
  selectedBranchId: number | null;
  selectedVendorId: number | null;
  editItemIndex: number | null;
  onEditItem: () => void;
  isVendorGstRegistered: boolean;
  isScanInvoiceFlow?: boolean;
};

type FormInputs = {
  itemName: string;
  description: string;
  quantity: string;
  rate: string;
  hsnCode: string;
  sacCode: string;
  expenseHead: number;
  expenseParticular: number;
  quantityUnit: 'pieces' | 'items' | 'kg' | 'km' | 'grams' | 'meters';
  itemType?: 'product' | 'service';
  gstSlab: number;
};

const numberRegex = /^[+]?\d*\.?\d+/;

const stringValidation = z
  .string({
    required_error: 'This field cannot be empty',
  })
  .nonempty('This field cannot be empty');

const baseFormSchema = z.object({
  itemName: stringValidation,
  description: z.union([z.string().nullable(), z.undefined()]),
  quantity: z
    .string({
      required_error: 'This field cannot be empty',
      invalid_type_error:
        'Quantity should be a positive numeric value. Please re-enter.',
    })
    .nonempty('This field cannot be empty')
    .regex(
      numberRegex,
      'Quantity should be a positive numeric value. Please re-enter.'
    ),
  quantityUnit: z
    .string({
      required_error: 'This field cannot be empty',
    })
    .nonempty(),
  rate: z
    .string({
      required_error: 'This field cannot be empty',
      invalid_type_error:
        'Rate should be a positive numeric value. Please re-enter.',
    })
    .nonempty('This field cannot be empty')
    .regex(
      numberRegex,
      'Rate should be a positive numeric value. Please re-enter.'
    ),
  expenseHead: z
    .number({ required_error: 'This field cannot be empty' })
    .positive('This field cannot be empty'),
  expenseParticular: z
    .number({ required_error: 'This field cannot be empty' })
    .positive('This field cannot be empty'),
  itemType: z.literal('product'),
  gstSlab: z
    .number({ required_error: 'This field cannot be empty' })
    .positive('This field cannot be empty'),
});

const optionalHsnCodeFormSchema = baseFormSchema.extend({
  itemType: z.literal('product'),
  hsnCode: z.union([z.string().nullable(), z.undefined()]),
  gstSlab: z.union([z.string().nullable(), z.undefined()]),
});

const optionalSacCodeFormSchema = baseFormSchema.extend({
  itemType: z.literal('service'),
  sacCode: z.union([z.string().nullable(), z.undefined()]),
  gstSlab: z.union([z.string().nullable(), z.undefined()]),
});

const nonGstRegisteredVendorFormSchema = z.discriminatedUnion('itemType', [
  optionalHsnCodeFormSchema,
  optionalSacCodeFormSchema,
]);

const mandatoryHsnCodeFormSchema = baseFormSchema.extend({
  itemType: z.literal('product'),
  hsnCode: z
    .string({
      required_error: 'This field cannot be empty',
    })
    .nonempty('This field cannot be empty'),
});

const mandatorySacCodeFormSchema = baseFormSchema.extend({
  itemType: z.literal('service'),
  sacCode: z
    .string({
      required_error: 'This field cannot be empty',
    })
    .nonempty('This field cannot be empty'),
});

const gstRegisteredVendorFormSchema = z.discriminatedUnion('itemType', [
  mandatoryHsnCodeFormSchema,
  mandatorySacCodeFormSchema,
]);

export type GstPayloadType = {
  cgst_percent: number;
  gst_rcm_percent: number;
  is_gst_rcm: boolean;
  is_igst: boolean;
  sgst_percent: number;
  tds_percent: number;
  igst_percent: number;
  gst_id: number;
  tds_id: number;
};

export const AddNewItem: React.FC<AddNewItemProps> = ({
  onClickClose,
  selectedBranchId,
  selectedVendorId,
  editItemIndex,
  onEditItem,
  isVendorGstRegistered,
  isScanInvoiceFlow = false,
}) => {
  const isEditFlow = editItemIndex !== null;
  const [invoiceItemsList, setInvoiceItems] = useAtom(invoiceItems);
  const [hsnCodeOptions, setHsnCodesList] = useState<SacOrHsnCodePayloadType[]>(
    []
  );
  const [sacCodeOptions, setSacCodesList] = useState<SacOrHsnCodePayloadType[]>(
    []
  );
  const [gstDetails, setGstDetails] = useState<GstPayloadType | null>(null);
  const expenseHeadOptions = useAtomValue(expenseHeadList);
  const [expenseParticularOptions, setExpenseParticularOptions] = useState<
    BaseOption[]
  >([]);
  const gstSlabOptions = useAtomValue(gstSlabsList);
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
  const prefilledInvoiceDetails: any = isEditFlow
    ? invoiceItemsList[editItemIndex]
    : null;
  const isExpenseHeadSelectedManually = useRef(false);
  const isExpenseParticularSeleectedManually = useRef(false);
  const selectedTdsIdRef = useRef<number | undefined>();
  const idPrefix = isEditFlow
    ? 'edit-new-invoice-item'
    : 'add-new-invoice-item';

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { isDirty, defaultValues },
    getValues,
    resetField,
    trigger,
  } = useForm<FormInputs>({
    resolver: zodResolver(
      isVendorGstRegistered
        ? gstRegisteredVendorFormSchema
        : nonGstRegisteredVendorFormSchema
    ),
    defaultValues: isEditFlow
      ? {
          quantityUnit:
            quantityOptions.find(
              (option) => option.value === prefilledInvoiceDetails.unit_type
            )?.value || 'items',
          description: prefilledInvoiceDetails.description
            ? prefilledInvoiceDetails.description
            : '',
          itemName: prefilledInvoiceDetails.name,
          itemType: prefilledInvoiceDetails.type || 'service',
          quantity: String(prefilledInvoiceDetails.unit_number),
          rate: String(prefilledInvoiceDetails.unit_price),
          expenseHead: prefilledInvoiceDetails.expense_head_id,
          expenseParticular: prefilledInvoiceDetails.expense_particular_id,
          gstSlab: prefilledInvoiceDetails.gst_id,
        }
      : {
          quantityUnit: 'items',
          itemType: 'service',
        },
  });
  const itemTypeValue = useWatch({
    control,
    name: 'itemType',
  });
  const isProductSelected = itemTypeValue === 'product';
  const quantity = useWatch({
    control,
    name: 'quantity',
  });
  const rate = useWatch({
    control,
    name: 'rate',
  });
  const expenseHead = useWatch({
    control,
    name: 'expenseHead',
  });
  const expenseParticular = useWatch({
    control,
    name: 'expenseParticular',
  });
  const gstSlabId = useWatch({
    control,
    name: 'gstSlab',
  });
  const selectedHsnCode = useWatch({
    control,
    name: 'hsnCode',
  });
  const selectedSacCode = useWatch({
    control,
    name: 'sacCode',
  });
  const totalTaxableAmount = Number(quantity) * Number(rate);
  const totalGstPercentage = gstDetails
    ? gstDetails.is_igst
      ? gstDetails.igst_percent
      : gstDetails.cgst_percent + gstDetails.sgst_percent
    : 0;
  const totalGstAmount =
    gstDetails && !gstDetails.is_gst_rcm && totalTaxableAmount
      ? (totalTaxableAmount * totalGstPercentage) / 100
      : 0;
  const totalAmountIncludingTaxes = totalGstAmount + totalTaxableAmount;

  useEffect(() => {
    let timeoutRef: NodeJS.Timeout | null = null;
    if (isScanInvoiceFlow && isEditFlow) {
      timeoutRef = setTimeout(() => {
        trigger([
          'itemName',
          'quantity',
          'rate',
          'expenseHead',
          'expenseParticular',
          'gstSlab',
          isProductSelected ? 'hsnCode' : 'sacCode',
        ]);
      }, 500);
    }
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, [isProductSelected, isScanInvoiceFlow, trigger, isEditFlow]);

  useEffect(() => {
    if (isEditFlow) {
      if (defaultValues?.expenseHead !== expenseHead) {
        setIsFormEdited(true);
        return;
      }
      if (defaultValues?.expenseParticular !== expenseParticular) {
        setIsFormEdited(true);
        return;
      }
      if (defaultValues?.itemType !== itemTypeValue) {
        setIsFormEdited(true);
        return;
      }
      if (defaultValues?.quantityUnit !== getValues('quantityUnit')) {
        setIsFormEdited(true);
        return;
      }
      if (defaultValues?.hsnCode !== selectedHsnCode) {
        setIsFormEdited(true);
        return;
      }
      if (defaultValues?.sacCode !== selectedSacCode) {
        setIsFormEdited(true);
        return;
      }
      if (defaultValues?.gstSlab !== gstSlabId) {
        setIsFormEdited(true);
        return;
      }
      setIsFormEdited(false);
    }
  }, [
    defaultValues?.expenseHead,
    defaultValues?.expenseParticular,
    defaultValues?.gstSlab,
    defaultValues?.hsnCode,
    defaultValues?.itemType,
    defaultValues?.quantityUnit,
    defaultValues?.sacCode,
    expenseHead,
    expenseParticular,
    getValues,
    gstSlabId,
    isEditFlow,
    itemTypeValue,
    selectedHsnCode,
    selectedSacCode,
  ]);

  useEffect(() => {
    if (isEditFlow) {
      const prefilledInvoiceDetails: any = invoiceItemsList[editItemIndex];
      if (prefilledInvoiceDetails.tds_id) {
        selectedTdsIdRef.current = prefilledInvoiceDetails.tds_id;
      }
      if (
        prefilledInvoiceDetails.type === 'product' &&
        prefilledInvoiceDetails.hsn_code
      ) {
        setValue('hsnCode', prefilledInvoiceDetails.hsn_code);
        setHsnCodesList([
          {
            value: prefilledInvoiceDetails.hsn_code,
            label: prefilledInvoiceDetails.hsn_code,
          },
        ]);
      } else {
        if (prefilledInvoiceDetails.sac_code) {
          setSacCodesList([
            {
              value: prefilledInvoiceDetails.sac_code,
              label: prefilledInvoiceDetails.sac_code,
            },
          ]);
          setValue('sacCode', prefilledInvoiceDetails.sac_code);
        }
      }
    }
  }, [editItemIndex, invoiceItemsList, isEditFlow, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hsnCode' && !type) {
        const hsnObject: any = hsnCodeOptions.find(
          (item) => item.value === value.hsnCode
        );
        if (
          hsnObject &&
          hsnObject.expense_particular &&
          hsnObject.expense_particular.parent_id
        ) {
          const expenseHeadId = hsnObject.expense_particular.parent_id;
          setValue('expenseHead', expenseHeadId);
          setValue('expenseParticular', hsnObject.expense_particular_id);
        }
      }
      if (name === 'sacCode' && !type) {
        const sacObject: any = sacCodeOptions.find(
          (item) => item.value === value.sacCode
        );
        if (
          sacObject &&
          sacObject.expense_particular &&
          sacObject.expense_particular.parent_id
        ) {
          const expenseHeadId = sacObject.expense_particular.parent_id;
          setValue('expenseHead', expenseHeadId);
          setValue('expenseParticular', sacObject.expense_particular_id);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [hsnCodeOptions, sacCodeOptions, setValue, watch]);

  const setInitialExpenseParticularOptions = useCallback(() => {
    const expenseParticularList: any = [];
    expenseHeadOptions.forEach((headOption: any) => {
      headOption?.particulars.forEach((particularItem: any) => {
        expenseParticularList.push(particularItem);
      });
    });
    const expenseParticularObjectArray = expenseParticularList.map(
      (item: any) => {
        return {
          ...item,
          label: item.name,
          value: String(item.id),
        };
      }
    );
    setExpenseParticularOptions(expenseParticularObjectArray);
  }, [expenseHeadOptions]);

  useEffect(() => {
    if (expenseHeadOptions) {
      const expenseParticularList: any = [];
      expenseHeadOptions.forEach((headOption: any) => {
        headOption?.particulars.forEach((particularItem: any) => {
          expenseParticularList.push(particularItem);
        });
      });
      const expenseParticularObjectArray = expenseParticularList.map(
        (item: any) => {
          return {
            ...item,
            label: item.name,
            value: String(item.id),
          };
        }
      );
      setExpenseParticularOptions(expenseParticularObjectArray);
    }
  }, [expenseHeadOptions, setInitialExpenseParticularOptions]);

  useEffect(() => {
    if (expenseParticular) {
      const selectedExpenseParticular: any = expenseParticularOptions.find(
        (item) => item.value === String(expenseParticular)
      );
      if (selectedExpenseParticular) {
        setValue('expenseHead', selectedExpenseParticular.parent_id);
      }
    }
  }, [expenseParticular, expenseParticularOptions, setValue]);

  useEffect(() => {
    if (expenseHead && isExpenseHeadSelectedManually.current) {
      resetField('expenseParticular');
      const selectedExpenseHead: any = expenseHeadOptions.find(
        (item) => item.value === String(expenseHead)
      );
      const expenseParticularList: any = selectedExpenseHead?.particulars || [];
      const expenseParticularObjectArray = expenseParticularList.map(
        (item: any) => {
          return {
            ...item,
            label: item.name,
            value: String(item.id),
          };
        }
      );
      setExpenseParticularOptions(expenseParticularObjectArray);
    }
  }, [expenseHead, expenseHeadOptions, resetField]);

  useEffect(() => {
    if (
      selectedBranchId &&
      selectedVendorId &&
      expenseHead &&
      expenseParticular
    ) {
      const selectedGstSlab = getValues('gstSlab');
      if (
        selectedGstSlab &&
        selectedTdsIdRef.current &&
        !isExpenseHeadSelectedManually.current &&
        !isExpenseParticularSeleectedManually.current
      ) {
        getGst({
          vendor_id: selectedVendorId,
          branch_id: selectedBranchId,
          gst_id: selectedGstSlab,
          tds_id: selectedTdsIdRef.current,
        })
          .then((res: any) => {
            setGstDetails(res.data);
            clearErrors('gstSlab');
            setValue('gstSlab', res?.data?.gst_id);
            selectedTdsIdRef.current = res?.data?.tds_id;
          })
          .catch((err) => {
            setGstDetails(null);
          });
      } else {
        getGst({
          vendor_id: selectedVendorId,
          branch_id: selectedBranchId,
          expense_head_id: expenseHead,
          expense_particular_id: expenseParticular,
        })
          .then((res: any) => {
            setGstDetails(res.data);
            clearErrors('gstSlab');
            setValue('gstSlab', res?.data?.gst_id);
            selectedTdsIdRef.current = res?.data?.tds_id;
          })
          .catch((err) => {
            setGstDetails(null);
          });
      }
    }
  }, [
    clearErrors,
    expenseHead,
    expenseParticular,
    getValues,
    selectedBranchId,
    selectedVendorId,
    setValue,
  ]);

  const submitDetails = (values: FormInputs) => {
    const invoiceItemsClone: InvoiceItemPayloadType[] = [...invoiceItemsList];
    const totalTdsAmount = gstDetails?.tds_percent
      ? (totalTaxableAmount * gstDetails?.tds_percent) / 100
      : 0;
    const currentItem: InvoiceItemPayloadType = {
      type: values.itemType || 'service',
      name: values.itemName,
      description: values.description,
      unit_type: values.quantityUnit,
      unit_number: Number(values.quantity),
      unit_price: Number(values.rate),
      gst_amount: totalGstAmount,
      total_amount: totalGstAmount + totalTaxableAmount,
      expense_head_id: values.expenseHead,
      expense_particular_id: values.expenseParticular,
      total_price: totalTaxableAmount,
      igst_percent: 0,
      sgst_percent: 0,
      cgst_percent: 0,
      tds_percent: gstDetails?.tds_percent || 0,
      tds_amount: totalTdsAmount,
      gst_id: values.gstSlab,
      tds_id: selectedTdsIdRef.current,
      is_complete: true,
    };
    if (isEditFlow) {
      currentItem['id'] = prefilledInvoiceDetails.id;
    }
    if (isProductSelected && values.hsnCode) {
      const selectedHsnObject = hsnCodeOptions.find(
        (item) => item.value === values.hsnCode
      );
      currentItem['hsn_code'] = selectedHsnObject?.label;
    } else if (values.sacCode) {
      const selectedSacObject = sacCodeOptions.find(
        (item) => item.value === values.sacCode
      );
      currentItem['sac_code'] = selectedSacObject?.label;
    }
    if (!gstDetails?.is_gst_rcm) {
      if (gstDetails?.is_igst) {
        currentItem['igst_percent'] = gstDetails.igst_percent || 0;
      } else {
        currentItem['sgst_percent'] = gstDetails?.sgst_percent || 0;
        currentItem['cgst_percent'] = gstDetails?.cgst_percent || 0;
      }
    }
    if (isEditFlow) {
      invoiceItemsClone[editItemIndex] = currentItem;
    } else {
      invoiceItemsClone.push(currentItem);
    }
    setInvoiceItems(invoiceItemsClone);
    onClickClose();
    onEditItem();
  };

  const clearExpenseRelatedFields = () => {
    if (expenseHead) {
      setValue('expenseHead', 0);
    }
    if (expenseParticular) {
      setValue('expenseParticular', 0);
    }
    setValue('hsnCode', '');
    setValue('sacCode', '');
    clearErrors(['expenseHead', 'expenseHead', 'expenseParticular']);
    setGstDetails(null);
    if (isExpenseHeadSelectedManually.current) {
      setInitialExpenseParticularOptions();
    }
  };

  const onChangeSearchTerm = debounce((q: string) => {
    if (!q) return;
    if (isProductSelected) {
      getHsnCodesList({
        q,
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          const hsnCodesResp = res.data.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: item.code,
              subText: item.title,
            };
          });
          setHsnCodesList(hsnCodesResp);
        })
        .catch((err) => null);
    } else {
      getSacCodesList({
        q,
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          const sacCodesResp = res.data.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: item.code,
              subText: item.title,
            };
          });
          setSacCodesList(sacCodesResp);
        })
        .catch((err) => null);
    }
  }, 600);

  return (
    <Box>
      <FullScreenHeader
        headingText={isEditFlow ? 'Edit Item' : 'Add Item'}
        onClickCross={onClickClose}
        borderBottomWidth="1"
        borderColor="borderSeparator"
        shadowLevel="s1"
        position="sticky"
        top="0"
        zIndex="50"
      />
      <Stack
        paddingX="5"
        paddingTop="5"
        display="flex"
        flex="1"
        minHeight="screen"
        overflow="auto"
        paddingBottom="20"
      >
        <Box display="flex">
          <Box
            id={`${idPrefix}-service`}
            paddingX="2"
            paddingY="1"
            borderWidth="1"
            borderColor={
              isProductSelected ? 'borderSeparator' : 'borderPrimaryLow'
            }
            backgroundColor={
              isProductSelected ? 'surfaceDefault' : 'surfacePrimaryLowest'
            }
            rounded="full"
            onClick={() => {
              if (isProductSelected) {
                clearExpenseRelatedFields();
                setValue('itemType', 'service');
              }
            }}
            cursor="pointer"
          >
            <Text
              variation={isProductSelected ? 'c2' : 'c1'}
              color={isProductSelected ? 'textMedium' : 'textPrimary'}
            >
              Service
            </Text>
          </Box>
          <Box
            id={`${idPrefix}-product`}
            marginLeft="2"
            paddingX="2"
            paddingY="1"
            borderWidth="1"
            borderColor={
              isProductSelected ? 'borderPrimaryLow' : 'borderSeparator'
            }
            backgroundColor={
              !isProductSelected ? 'surfaceDefault' : 'surfacePrimaryLowest'
            }
            rounded="full"
            onClick={() => {
              if (!isProductSelected) {
                clearExpenseRelatedFields();
                setValue('itemType', 'product');
              }
            }}
            cursor="pointer"
          >
            <Text
              variation={isProductSelected ? 'c1' : 'c2'}
              color={!isProductSelected ? 'textMedium' : 'textPrimary'}
            >
              Product
            </Text>
          </Box>
        </Box>
        <Box style={{ width: 324 }} marginTop="4">
          <Controller
            control={control}
            name="itemName"
            render={(props) => {
              const {
                field: { onChange, onBlur, value },
                fieldState: { error },
              } = props;
              return (
                <TextInput
                  id={`${idPrefix}-name-of-goods`}
                  label={isProductSelected ? 'Item Name' : 'Service Name'}
                  isRequired
                  fullWidth
                  placeholder="Name of the goods or service"
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                  value={value}
                />
              );
            }}
          />
        </Box>
        <Box style={{ width: 324 }} marginTop="6">
          <Controller
            control={control}
            name="description"
            render={(props) => {
              const {
                field: { onChange, onBlur, value },
              } = props;
              return (
                <TextArea
                  id={`${idPrefix}-enter-description`}
                  label="Description"
                  placeholder="Add additional item details"
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              );
            }}
          />
        </Box>
        <Box display="flex" marginTop="6" alignItems="start">
          <Box style={{ width: 236 }}>
            <Controller
              control={control}
              name="quantity"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-enter-quantity`}
                    label={'Quantity'}
                    isRequired
                    fullWidth
                    placeholder="E.g. 4, 10 etc."
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    value={value !== undefined ? String(value) : undefined}
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
          <Box style={{ width: 80 }} marginLeft="1" marginTop="2">
            <Controller
              control={control}
              name="quantityUnit"
              render={(props) => {
                const {
                  field: { value },
                } = props;
                const selectedQuantityType = quantityOptions.find(
                  (item) => item.value === value
                );
                return (
                  <SingleSelect
                    id={`${idPrefix}-select-quantity-unit`}
                    options={quantityOptions}
                    onSave={(option) => {
                      setValue('quantityUnit', option.value as any);
                    }}
                    label=""
                    value={selectedQuantityType}
                  />
                );
              }}
            />
          </Box>
          <Box style={{ width: 324 }} marginLeft="5">
            <Controller
              control={control}
              name="rate"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-enter-rate`}
                    label={
                      isProductSelected ? 'Rate Per Item' : 'Rate Per Service'
                    }
                    isRequired
                    fullWidth
                    placeholder="Price per item"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    leftIcon={() => (
                      <Text variation="b2" color="textMedium">
                        ₹
                      </Text>
                    )}
                    description="Please enter amount after discount"
                    value={value !== undefined ? String(value) : undefined}
                  />
                );
              }}
            />
          </Box>
          <Stack display="flex" marginLeft="5">
            <Text variation="b2" color="textLow">
              Total Taxable Amt:
            </Text>
            {Number(totalTaxableAmount) ? (
              <Amount
                variation="t3"
                color="textHigh"
                marginTop="0.5"
                amount={Number(totalTaxableAmount)}
              />
            ) : (
              <Text variation="t3" color="textHigh" marginTop="0.5">
                -
              </Text>
            )}
          </Stack>
        </Box>
        <Box style={{ width: 324 }} marginTop="6">
          <Controller
            control={control}
            name={isProductSelected ? 'hsnCode' : 'sacCode'}
            render={(props) => {
              const {
                field: { value },
                fieldState: { error },
              } = props;
              const selectedCodeObject = isProductSelected
                ? hsnCodeOptions.find((item) => item.label === value)
                : sacCodeOptions.find((item) => item.label === value);
              return (
                <SingleSelect
                  id={`${idPrefix}-select-code`}
                  options={isProductSelected ? hsnCodeOptions : sacCodeOptions}
                  onSave={(option) => {
                    clearErrors(isProductSelected ? 'hsnCode' : 'sacCode');
                    setValue(
                      isProductSelected ? 'hsnCode' : 'sacCode',
                      option.value
                    );
                  }}
                  label={isProductSelected ? 'HSN/SAC Code' : 'SAC Code'}
                  isRequired={isVendorGstRegistered}
                  placeholder={
                    isProductSelected
                      ? 'Search or Select HSN code'
                      : 'Search or Select SAC code'
                  }
                  value={selectedCodeObject}
                  errorMessage={error?.message}
                  onChangeSearchTerm={onChangeSearchTerm}
                  emptyTitle={
                    isProductSelected ? 'Search HSN Codes' : 'Search SAC Codes'
                  }
                  fieldInfo={
                    isProductSelected
                      ? 'Tax classification for services/goods'
                      : 'Service tax classification'
                  }
                  onPressClear={
                    !isVendorGstRegistered && selectedCodeObject
                      ? () => {
                          resetField(isProductSelected ? 'hsnCode' : 'sacCode');
                        }
                      : undefined
                  }
                />
              );
            }}
          />
        </Box>
        <Box display="flex" marginTop="6">
          <Box style={{ width: 324 }}>
            <Controller
              control={control}
              name="expenseParticular"
              render={(props) => {
                const {
                  field: { value },
                  fieldState: { error },
                } = props;
                const selectedExpenseParticular = expenseParticularOptions.find(
                  (item) => item.value === String(value)
                );
                return (
                  <SingleSelect
                    id={`${idPrefix}-select-expense-particular`}
                    options={expenseParticularOptions}
                    onSave={(option) => {
                      isExpenseParticularSeleectedManually.current = true;
                      clearErrors('expenseParticular');
                      setValue('expenseParticular', Number(option.value));
                    }}
                    label="Expense Particular"
                    isRequired
                    placeholder="Search or Select"
                    value={selectedExpenseParticular}
                    errorMessage={error?.message}
                  />
                );
              }}
            />
          </Box>
          <Box style={{ width: 324 }} marginLeft="5">
            <Controller
              control={control}
              name="expenseHead"
              render={(props) => {
                const {
                  field: { value },
                  fieldState: { error },
                } = props;
                const selectedExpenseHead = expenseHeadOptions.find(
                  (item) => item.value === String(value)
                );
                return (
                  <SingleSelect
                    id={`${idPrefix}-select-expense-head`}
                    options={expenseHeadOptions}
                    onSave={(option) => {
                      isExpenseHeadSelectedManually.current = true;
                      clearErrors('expenseHead');
                      setValue('expenseHead', Number(option.value));
                    }}
                    label="Expense Head"
                    isRequired
                    placeholder="Search or Select"
                    value={selectedExpenseHead}
                    errorMessage={error?.message}
                  />
                );
              }}
            />
          </Box>
        </Box>
        <Box display="flex" marginTop="6">
          {isVendorGstRegistered ? (
            <Box display="flex" style={{ width: 324 }}>
              <Controller
                control={control}
                name="gstSlab"
                render={(props) => {
                  const {
                    field: { value },
                    fieldState: { error },
                  } = props;
                  const selectedGstSlab = gstSlabOptions.find(
                    (item) => item.value === String(value)
                  );
                  return (
                    <SingleSelect
                      id={`${idPrefix}-gst-percentage`}
                      options={gstSlabOptions}
                      onSave={(option) => {
                        clearErrors('gstSlab');
                        setValue('gstSlab', Number(option.value));
                        if (
                          selectedBranchId &&
                          selectedVendorId &&
                          Number(option.value) !== gstSlabId
                        ) {
                          getGst({
                            vendor_id: selectedVendorId,
                            branch_id: selectedBranchId,
                            gst_id: Number(option.value),
                            tds_id: selectedTdsIdRef.current,
                          })
                            .then((res: any) => {
                              setGstDetails(res.data);
                              if (res?.data?.tds_id) {
                                selectedTdsIdRef.current = res.data.tds_id;
                              }
                            })
                            .catch((err) => {
                              setGstDetails(null);
                            });
                        }
                      }}
                      label="GST Percentage"
                      isRequired
                      placeholder="Search or Select"
                      value={selectedGstSlab}
                      errorMessage={error?.message}
                    />
                  );
                }}
              />
            </Box>
          ) : null}
          <Stack
            display="flex"
            marginLeft={isVendorGstRegistered ? '5' : undefined}
            style={{ width: 324 }}
          >
            <Text variation="b2" color="textLow">
              Total GST Amount{' '}
              {Number(totalGstAmount)
                ? gstDetails?.is_igst
                  ? '(IGST)'
                  : '(CSGT+SGST)'
                : ''}{' '}
            </Text>
            {Number(totalGstAmount) ? (
              <Amount
                variation="t3"
                color="textHigh"
                marginTop="0.5"
                amount={Number(totalGstAmount)}
              />
            ) : (
              <Text variation="t3" color="textHigh" marginTop="0.5">
                -
              </Text>
            )}
          </Stack>
          <Stack display="flex" marginLeft="5">
            <Text variation="b2" color="textLow">
              Total Amount (incl. tax)
            </Text>
            {Number(totalAmountIncludingTaxes) ? (
              <Amount
                variation="t3"
                color="textHigh"
                marginTop="0.5"
                amount={Number(totalAmountIncludingTaxes)}
              />
            ) : (
              <Text variation="t3" color="textHigh" marginTop="0.5">
                -
              </Text>
            )}
          </Stack>
        </Box>
      </Stack>
      <Box
        width={'auto'}
        display={'flex'}
        alignItems={'center'}
        justifyContent="end"
        backgroundColor="surfaceDefault"
        paddingY="2"
        paddingX="5"
        borderTopWidth="1"
        borderColor="borderSeparator"
        position="sticky"
        bottom="0"
      >
        <Box marginRight="2">
          <Button
            id={`${idPrefix}-close`}
            title="Close"
            type="outlined"
            onClick={onClickClose}
          />
        </Box>
        <Box>
          <Button
            id={`${idPrefix}-save`}
            onClick={handleSubmit(submitDetails)}
            title={'Save Item'}
            type="filled"
            state={
              isEditFlow && !isDirty && !isFormEdited ? 'disabled' : undefined
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
