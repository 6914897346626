import { useUser } from '@nbfc-expense-tool/data-store/auth';
import {
  ArrowLeftIcon,
  Avatar,
  Box,
  H3,
  Inline,
  Text,
} from '@nbfc-expense-tool/ui';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const { user } = useUser();

  const navigate = useNavigate();

  const profileData = useMemo(() => {
    return {
      Branch: user?.branches.map((branch) => branch.name).join(', '),
      Email: user?.email,
      'Employee Code': user?.employee_code,
      'Mobile Number': user?.phone_number,
    };
  }, [user]);

  return (
    <Box
      width={'full'}
      height={'full'}
      display={'flex'}
      justifyContent={'center'}
    >
      <Box>
        <Inline
          cursor="pointer"
          marginTop={'10'}
          marginBottom={'2'}
          display={'flex'}
          alignItems={'center'}
        >
          <ArrowLeftIcon
            size="3"
            marginRight={'1'}
            color={'iconPrimary'}
            onClick={() => navigate(-1)}
          />
          <Text
            color={'textPrimary'}
            variation={'lk'}
            onClick={() => navigate(-1)}
          >
            Back to Dashboard
          </Text>
        </Inline>
        <Box
          style={{ width: 700 }}
          bgColor={'surfaceDefault'}
          height={'fitContent'}
          padding={'3'}
          borderRadius="md"
          borderWidth="1"
          borderColor="borderSeparator"
        >
          {user?.avatar?.preview?.length ? (
            <Avatar avatarSize="lg" as="img" src={user?.avatar.preview || ''} />
          ) : (
            <Avatar
              avatarSize="lg"
              avatarText={user?.name[0]}
              id={user?.id.toString()}
              title={user?.name}
            />
          )}
          <Box>
            <H3 marginTop={'1'} color={'textHigh'}>
              {user?.name}
            </H3>
            <Text
              variation="c1"
              color={'textHigh'}
              marginTop={'1'}
              marginBottom={'0.5'}
            >
              {user?.roles.map((role) => role.display_name).join(', ')}
            </Text>
          </Box>
          <Box borderTopWidth="1" marginY={'4'} borderColor="borderSeparator" />
          <Inline gap={'12'} display={'flex'}>
            {Object.keys(profileData).map((key) => {
              return (
                <Box key={key}>
                  <Text variation="c2" color={'textLow'}>
                    {key}
                  </Text>
                  <Text variation="b2" color={'textHigh'}>
                    {profileData[key as keyof typeof profileData] || '-'}
                  </Text>
                </Box>
              );
            })}
          </Inline>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
