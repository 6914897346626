import { Stack, Text, UserCheckIcon } from '@nbfc-expense-tool/ui';
import AddNewVendorButton from './AddNewVendorButton';
import { useUser } from '@nbfc-expense-tool/data-store/auth';
import { checkForSinglePermission } from '@nbfc-expense-tool/data-store/permissions';

const EmptyVendorList: React.FC<{
  vendorStatus: 'waiting-for-approval' | 'active-vendors' | 'inactive-vendors';
}> = ({ vendorStatus }) => {
  const { user } = useUser();
  const canAddNewVendor = checkForSinglePermission(
    'createVendor',
    user?.roles[0]?.permissions || null
  );

  return (
    <Stack
      gap="4"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
    >
      <UserCheckIcon size="8" color="iconMedium" />
      <Stack gap="2" paddingBottom="0.5">
        <Text variation="t2">No Vendors Found!</Text>
        {vendorStatus !== 'inactive-vendors' && canAddNewVendor && (
          <Text variation="b2">Tap ‘Add New‘ to create a new vendor</Text>
        )}
      </Stack>
      {vendorStatus !== 'inactive-vendors' && canAddNewVendor && (
        <AddNewVendorButton />
      )}
    </Stack>
  );
};

export default EmptyVendorList;
