import {
  Inline,
  Stack,
  Text,
  Box,
  ArrowLeftIcon,
  Tabs,
  DateSelect,
  MultiSelect,
  TextInput,
  SearchIcon,
  RightCaratIcon,
  SkeletonRows,
  Date as DateAndTime,
  Avatar,
  Amount,
  EmptyState,
  FileIcon,
  Button,
  SpinnerIcon,
  ExtendedBreadcrumbs,
  ReturnIcon,
} from '@nbfc-expense-tool/ui';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useInvoicesLocal from './useInvoices';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Invoice_Status,
  Payment_status,
  TypesOfInvoices,
  useBranchesForFilter,
  useInvoices,
  useVendorsForFilter,
} from '@nbfc-expense-tool/data-store/dashboard';
import { Item } from 'react-stately';
import { InvoiceStatusTag } from '../../Invoices';
import { debounce } from '@nbfc-expense-tool/data-store/utils';

export default function TypedInvoicesPage() {
  const { type } = useParams();
  if (!type) return null;
  return <TypedInvoices key={type} type={type as Invoice_Status} />;
}

const InvoiceTabOptions = [
  {
    title: 'Unpaid',
    path: 'unpaid',
  },
  {
    title: 'Paid',
    path: 'paid',
  },
  {
    title: 'All Approved Invoices',
    path: 'all',
  },
];

const initialOwnInvoiceFilters = {
  dateFilter: {
    label: 'All Time',
    value: 'all',
  },
};

function TypedInvoices({ type }: { type: Invoice_Status }) {
  const [searchParams] = useSearchParams();
  const fromSearchParams = searchParams.get('from') as TypesOfInvoices;
  const toSearchParams = searchParams.get('to');
  const [activePath, setActivePath] = useState(
    toSearchParams || InvoiceTabOptions[0].path
  );
  const idPrefix = `typed-invoices-${type}-${activePath}-from-${fromSearchParams}`;

  const initialFilters = {
    ...initialOwnInvoiceFilters,
    invoice_status: type,
    payment_status:
      activePath === 'all' ? null : (activePath as Payment_status),
  };

  const {
    invoices,
    loading,
    perPage,
    totalInvoices,
    canGoNext,
    canGoBack,
    syncingData,
    currentPage,
    lastPage,
    params,
    hasAppliedFilters,
    resetFilters,
    handlePageChange,
    handleDateChange,
    handleParamChange,
    exportExcelReport,
    reportLoading,
  } = useInvoices(fromSearchParams, initialFilters);

  const navigate = useNavigate();

  const { status, branches } = useBranchesForFilter();
  const { status: vendorStatus, vendors } = useVendorsForFilter();
  const branchForFilters = useMemo(() => {
    return branches?.map((b) => {
      return {
        label: `${b.name} - ${b.branch_code}`,
        value: `${b.id}`,
      };
    });
  }, [branches]);

  const vendorForFilters = useMemo(() => {
    return vendors?.map((b) => {
      return {
        label: b.name,
        value: `${b.id}`,
      };
    });
  }, [vendors]);

  const {
    getTitleFromLink,
    getTitleForTypeOfInvoice,
    getEmptyStateText,
    getFifthColumnBasedOnType,
  } = useInvoicesLocal();

  const title = useMemo(() => {
    return getTitleForTypeOfInvoice(type as Invoice_Status);
  }, [type, getTitleForTypeOfInvoice]);

  const { title: emptyTitle, subtitle: emptySubtitle } = useMemo(() => {
    return getEmptyStateText(type, activePath);
  }, [type, activePath, getEmptyStateText]);

  const { title: columnTitle } = useMemo(() => {
    return getFifthColumnBasedOnType(type, activePath);
  }, [type, activePath, getFifthColumnBasedOnType]);

  const onInvoiceClickHandler = (ticketNumber: string) => {
    navigate(`/home/invoices/${ticketNumber}?from=created-by-you`);
  };

  const renderComponent = useCallback(
    (
      payment_status: Payment_status,
      last_rejected_remarks: string,
      last_returned_remarks: string,
      i_can_mark_paid: boolean
    ) => {
      if (type === 'approved') {
        if (activePath === 'unpaid') {
          return i_can_mark_paid ? (
            <Button
              id={`${idPrefix}-mark-paid-button`}
              type="link"
              title="Mark Paid"
            />
          ) : null;
        } else if (activePath === 'paid') {
          return (
            <Button id={`${idPrefix}-view-button`} type="link" title="View" />
          );
        } else {
          return <InvoiceStatusTag status={payment_status} />;
        }
      } else if (type === 'under_review') {
        return (
          <Button id={`${idPrefix}-review-button`} type="link" title="Review" />
        );
      } else if (type === 'returned') {
        return <Text variation={'b2'}>{last_returned_remarks}</Text>;
      } else if (type === 'rejected') {
        return <Text variation={'b2'}>{last_rejected_remarks}</Text>;
      }
    },
    [activePath, type, idPrefix]
  );

  return (
    <Box paddingY="4" paddingX="5">
      <Stack gap="2">
        <ExtendedBreadcrumbs
          links={[
            {
              title: getTitleFromLink(fromSearchParams),
              to: fromSearchParams || '',
            },
            { title: `${title} Invoices`, to: '', isCurrent: true },
          ]}
        />
        <Inline gap="6">
          <ArrowLeftIcon
            size="4"
            onClick={() => navigate(-1)}
            cursor="pointer"
          />
          <Text as="h3" variation="h3">
            {title} Invoices
          </Text>
        </Inline>
        {type === 'approved' && (
          <Tabs
            selectedKey={activePath}
            onClick={(path: string) => {
              setActivePath(path);
              if (path === 'all') {
                handleParamChange('payment_status', null);
              } else {
                handleParamChange('payment_status', path as Payment_status);
              }
            }}
          >
            {InvoiceTabOptions.map((option) => (
              <Item
                key={option.path}
                textValue={option.path}
                title={
                  <Box textDecoration="none" style={{ color: 'inherit' }}>
                    {option.title}
                  </Box>
                }
                children={undefined}
              ></Item>
            ))}
          </Tabs>
        )}
      </Stack>
      {!invoices?.length && !hasAppliedFilters ? (
        <Stack paddingTop="10" alignItems="center" justifyContent="center">
          {loading === 'in_progress' ? (
            <Inline gap="4">
              <SpinnerIcon size="3" color="iconMedium" />
              <Text variation="b2">Loading...</Text>
            </Inline>
          ) : (
            <EmptyState
              renderIcon={(props) => <FileIcon {...props} />}
              title={emptyTitle}
              subText={emptySubtitle}
            />
          )}
        </Stack>
      ) : (
        <Stack paddingTop="3.5" gap="6">
          <Inline justifyContent="between">
            <Inline gap="4">
              <DateSelect
                id={`${idPrefix}-select-date`}
                value={params.dateFilter}
                onSave={(option) => {
                  handleDateChange(option);
                }}
              />
              <MultiSelect
                id={`${idPrefix}-select-branches`}
                label="Branch"
                actionBtnTitle="Show Results"
                value={params.branches}
                options={branchForFilters}
                loadingOptions={status === 'in_progress'}
                onSave={(values) => {
                  handleParamChange('branches', values);
                }}
              />
              <MultiSelect
                id={`${idPrefix}-select-vendors`}
                label="Vendors"
                actionBtnTitle="Show Results"
                value={params.vendors}
                options={vendorForFilters}
                loadingOptions={vendorStatus === 'in_progress'}
                onSave={(values) => {
                  handleParamChange('vendors', values);
                }}
              />
            </Inline>
            <Button
              id={`${idPrefix}-export-excel-button`}
              title="Export To Excel"
              onClick={exportExcelReport}
              type="outlined"
              state={reportLoading ? 'loading' : undefined}
              leftIcon={(props) => <ReturnIcon {...props} />}
            />
          </Inline>
          <Box
            rounded="md"
            borderWidth="1"
            paddingTop="2.5"
            borderColor="borderSeparator"
            backgroundColor="surfaceDefault"
          >
            <Stack gap="6">
              <Inline
                paddingX="2.5"
                alignItems="center"
                justifyContent="between"
              >
                <Inline style={{ width: 324 }}>
                  <TextInput
                    minHeight="5"
                    id={`${idPrefix}-search-input`}
                    aria-label="search"
                    placeholder="Search by invoice number or amount"
                    leftIcon={(props) => (
                      <SearchIcon {...props} marginRight="1" />
                    )}
                    fullWidth
                    onChange={debounce((value) => {
                      handleParamChange('q', value);
                    })}
                  />
                </Inline>
                <Inline alignItems="center" gap="6">
                  {totalInvoices ? (
                    <Text color="textMedium" variation="c1">
                      Showing{' '}
                      {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}-
                      {lastPage === currentPage
                        ? totalInvoices
                        : invoices?.length}{' '}
                      of {totalInvoices}
                    </Text>
                  ) : null}
                  <Inline gap="2">
                    <Box
                      id={`${idPrefix}-show-previous-page`}
                      as="button"
                      disabled={!canGoBack}
                      backgroundColor="transparent"
                      onClick={() =>
                        canGoBack ? handlePageChange('previous') : undefined
                      }
                    >
                      <RightCaratIcon
                        cursor="pointer"
                        size="2.5"
                        rotate="180"
                        color={canGoBack ? 'iconMedium' : 'iconLowest'}
                      />
                    </Box>
                    <Box
                      as="hr"
                      width="px"
                      height="2.5"
                      backgroundColor="borderSeparator"
                    />
                    <Box
                      id={`${idPrefix}-show-next-page`}
                      as="button"
                      disabled={!canGoNext}
                      backgroundColor="transparent"
                      onClick={() =>
                        canGoNext ? handlePageChange('next') : undefined
                      }
                    >
                      <RightCaratIcon
                        cursor="pointer"
                        size="2.5"
                        color={canGoNext ? 'iconMedium' : 'iconLowest'}
                      />
                    </Box>
                  </Inline>
                </Inline>
              </Inline>
              <Box as="table" width="full" position="relative">
                <Box as="thead" bgColor="surfaceNeutralLowest">
                  <Box as="tr">
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 82,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">Invoice Date</Text>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 88,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">Creation Date</Text>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 160,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">Invoice Number</Text>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 120,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">Ticket ID</Text>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 160,
                      }}
                      top="0"
                      textAlign="left"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Text variation="c1">Vendor</Text>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingX="2"
                      style={{
                        width: 120,
                      }}
                      top="0"
                      bgColor="surfaceNeutralLowest"
                    >
                      <Inline justifyContent="end">
                        <Text variation="c1">Amount</Text>
                      </Inline>
                    </Box>
                    <Box
                      as="th"
                      position="sticky"
                      paddingY="1.5"
                      paddingLeft="2"
                      paddingRight="3"
                      style={{
                        width: 120,
                      }}
                      top="0"
                      bgColor="surfaceNeutralLowest"
                      textAlign="left"
                    >
                      <Text variation="c1">{columnTitle}</Text>
                    </Box>
                  </Box>
                </Box>
                {syncingData ? (
                  <SkeletonRows numOfRows={10} numOfCols={5} />
                ) : (
                  <Box as="tbody">
                    {invoices?.length ? (
                      invoices.map(
                        ({
                          id,
                          vendor,
                          grand_total_amount,
                          ticket_number,
                          payment_status,
                          last_rejected_remarks,
                          last_returned_remarks,
                          i_can_mark_paid,
                          invoice_date,
                          invoice_number,
                          created_at,
                        }) => {
                          return (
                            <React.Fragment key={id}>
                              <Box
                                id={`${idPrefix}-list-${id}`}
                                as="tr"
                                borderTopWidth="1"
                                cursor="pointer"
                                tabIndex={-1}
                                backgroundColor={{
                                  hover: 'surfacePrimaryLowest',
                                }}
                                onClick={() =>
                                  onInvoiceClickHandler(ticket_number)
                                }
                              >
                                <Box
                                  as="td"
                                  paddingX="2"
                                  paddingY="1.5"
                                  textAlign="left"
                                  valign="top"
                                >
                                  <Stack gap="1">
                                    <DateAndTime
                                      variation="b2"
                                      date={invoice_date}
                                    />
                                  </Stack>
                                </Box>
                                <Box
                                  as="td"
                                  paddingX="2"
                                  paddingY="1.5"
                                  textAlign="left"
                                  valign="top"
                                >
                                  <Stack gap="1">
                                    <DateAndTime
                                      variation="b2"
                                      date={created_at}
                                    />
                                  </Stack>
                                </Box>
                                <Box
                                  as="td"
                                  paddingX="2"
                                  paddingY="1.5"
                                  valign="top"
                                >
                                  <Text variation="b2" marginBottom="2.5">
                                    {invoice_number}
                                  </Text>
                                </Box>
                                <Box
                                  as="td"
                                  paddingX="2"
                                  paddingY="1.5"
                                  valign="top"
                                >
                                  <Text variation="b2" marginBottom="2.5">
                                    {ticket_number}
                                  </Text>
                                </Box>
                                <Box
                                  as="td"
                                  paddingX="2"
                                  paddingY="1.5"
                                  valign="top"
                                >
                                  <Inline gap="2" marginBottom="0.5">
                                    <Avatar
                                      avatarSize="sm"
                                      id={
                                        vendor?.id.toString() || id.toString()
                                      }
                                      avatarText={
                                        vendor?.name?.length
                                          ? vendor?.name[0]
                                          : 'U'
                                      }
                                    />
                                    <Stack gap="1" flex="1">
                                      <Text variation="b2">{vendor?.name}</Text>
                                      <Text variation="c2" color="textMedium">
                                        {vendor?.vendor_type?.name}
                                      </Text>
                                    </Stack>
                                  </Inline>
                                </Box>
                                <Box
                                  as="td"
                                  paddingX="2"
                                  paddingY="1.5"
                                  className="whitespace-pre"
                                  textAlign="right"
                                  valign="top"
                                >
                                  <Box marginBottom="2.5">
                                    <Amount
                                      amount={Number(grand_total_amount || 0)}
                                      variation="t4"
                                    />
                                  </Box>
                                </Box>
                                <Box
                                  as="td"
                                  paddingLeft="2"
                                  paddingRight="3"
                                  paddingY="1.5"
                                  className="whitespace-pre"
                                  textAlign="left"
                                  valign="top"
                                >
                                  <Box marginBottom="2.5">
                                    <Box width="fitContent">
                                      {renderComponent(
                                        payment_status,
                                        last_rejected_remarks || '',
                                        last_returned_remarks || '',
                                        i_can_mark_paid || false
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </React.Fragment>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          <EmptyState
                            renderIcon={(props) => <FileIcon {...props} />}
                            title="No Invoices Found!"
                            subText={`Please try changing your applied filters!`}
                            renderButton={() => (
                              <Button
                                id={`${idPrefix}-reset-filters-button`}
                                title="Reset Filters"
                                onClick={resetFilters}
                              />
                            )}
                          />
                        </td>
                      </tr>
                    )}
                  </Box>
                )}
              </Box>
            </Stack>
          </Box>
        </Stack>
      )}
    </Box>
  );
}
