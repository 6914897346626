import { getRolesListing } from '@nbfc-expense-tool/data-store/utils';
import { useCallback, useEffect, useReducer } from 'react';

interface ROLE {
  id: number;
  name: string;
  guard_name: string;
  display_name: string;
  level: number;
  created_at: string; // Assuming it's always a string in ISO format
  updated_at: string; // Assuming it's always a string in ISO format
  users_count: number;
  permissions: Permission[];
}

interface Permission {
  id: number;
  name: string;
}

type RolesResponse = {
  data: ROLE[];
};

type RolesListState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  roles?: ROLE[];
};

const initialRoles: RolesListState = {
  status: 'in_progress',
  error: null,
  roles: undefined,
};

type ROLES_LIST_TYPES =
  | { type: 'FETCHING_ROLES' }
  | { type: 'FETCHED_ROLES'; payload: RolesResponse }
  | { type: 'FETCHING_ROLES_FAILED'; payload: Error }
  | { type: 'FETCHING_ROLES_WITH_FILTERS' };

const reducer = (
  state: RolesListState,
  action: ROLES_LIST_TYPES
): RolesListState => {
  switch (action.type) {
    case 'FETCHING_ROLES':
      return {
        ...state,
        ...initialRoles,
      };
    case 'FETCHED_ROLES':
      return {
        ...state,
        status: 'success',
        error: null,
        roles: action.payload.data,
      };
    default:
      return state;
  }
};

export function useRoleTypes() {
  const [state, dispatch] = useReducer(reducer, initialRoles);

  const getRoles = useCallback(async () => {
    const response = await getRolesListing<RolesResponse>();
    dispatch({ type: 'FETCHED_ROLES', payload: response });
  }, []);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  return {
    status: state.status,
    roles: state.roles,
  };
}
