import type { ElementType } from 'react';
import { forwardRef, useMemo } from 'react';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';
import { Box, BoxOwnProps } from '../box/box';
import { TTextStyles } from '../css/text.css';
import { Text } from '../text/text';
import { IconParams } from '../../types';
import { TBackgroundColorStyles, backgroundColorStyles } from './avatar.css';
import classNames from 'classnames';
import { AVATAR_COLORS } from '../css/theme';

const DefaultAvatarElement = 'div';

type AvatarOwnProps = {
  id?: string;
  avatarSize: 'sm' | 'md' | 'lg' | 'xl';
  avatarText?: string;
  avatarIcon?: ({ color, size }: IconParams) => React.ReactElement;
} & TBackgroundColorStyles;

export type AvatarProps<
  C extends React.ElementType = typeof DefaultAvatarElement
> = PolymorphicComponentPropWithRef<
  C,
  Omit<BoxOwnProps, 'backgroundColor' | 'bgColor'> & AvatarOwnProps
>;

type AvatarComponent = <
  C extends React.ElementType = typeof DefaultAvatarElement
>(
  props: AvatarProps<C>
) => React.ReactNode;

export const Avatar: AvatarComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultAvatarElement>(
    {
      as,
      id,
      textTransform,
      whiteSpace,
      avatarSize,
      logoImageUrl,
      avatarText = '',
      avatarIcon,
      backgroundColor,
      ...restProps
    }: AvatarProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Element: ElementType = as || DefaultAvatarElement;
    const showOnlineImage = Boolean(Element !== 'div');
    const bgForAvatar = useMemo(() => {
      return id ? getColorForUID(id) : undefined;
    }, [id]);

    const getAvatarStyleBasedUponType = (): {
      size: BoxOwnProps['size'];
      variation: TTextStyles['variation'];
      iconSize: IconParams['size'];
    } => {
      switch (avatarSize) {
        case 'sm':
          return {
            variation: 'c1',
            size: '3',
            iconSize: '2',
          };

        case 'md':
          return {
            variation: 't3',
            iconSize: '2.5',
            size: '4',
          };

        case 'lg':
          return {
            variation: 't1',
            iconSize: '3',
            size: '5',
          };

        default:
          return {
            variation: 't1',
            iconSize: '4',
            size: '6',
          };
      }
    };

    const { size, iconSize, variation } = getAvatarStyleBasedUponType();

    if (showOnlineImage) {
      return (
        <Box
          {...restProps}
          as={Element}
          ref={ref}
          height={size}
          width={size}
          borderRadius={'full'}
        />
      );
    }

    return (
      <Box
        {...restProps}
        ref={ref}
        height={size}
        width={size}
        borderRadius={'full'}
        justifyContent="center"
        display="flex"
        alignItems="center"
        className={classNames(
          backgroundColorStyles({
            backgroundColor,
          })
        )}
        style={{
          backgroundColor: bgForAvatar,
        }}
      >
        {avatarIcon
          ? avatarIcon({ color: 'iconOnSurface', size: iconSize })
          : null}
        {avatarText ? (
          <Text
            variation={variation}
            textTransform="uppercase"
            color="textOnSurface"
          >
            {avatarText}
          </Text>
        ) : null}
      </Box>
    );
  }
);

function getColorForUID(uid: string): string {
  const avatarColors = Object.values(AVATAR_COLORS);
  const str = String(uid);
  const uidHash = Array.from(str).reduce((hash, l) => {
    const val = l.charCodeAt(0);
    return val + hash;
  }, 0);
  const colorIndex = uidHash % avatarColors.length;
  return avatarColors[colorIndex];
}
