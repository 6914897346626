import React, { useCallback, useRef } from 'react';
import {
  useBreadcrumbItem,
  useBreadcrumbs,
  AriaBreadcrumbsProps,
  AriaBreadcrumbItemProps,
} from 'react-aria';

import { Box, BoxOwnProps } from '../box/box';
import { Text } from '../text/text';
import { RightCaratIcon } from '../icons/icons';
import { useLocation, useNavigate } from 'react-router-dom';

type BreadcrumbsProps = {
  children: React.ReactNode; // 👈️ added type for children
};

export function Breadcrumbs(
  props: BoxOwnProps & BreadcrumbsProps & AriaBreadcrumbsProps
) {
  const { navProps } = useBreadcrumbs(props);

  return (
    <Box {...props} as="nav" {...navProps}>
      <Box display="flex" margin={'0'} padding={'0'}>
        {props.children}
      </Box>
    </Box>
  );
}

export function ExtendedBreadcrumbs({
  links,
}: {
  links: Array<{
    title: string;
    to: string;
    query?: string;
    isCurrent?: boolean;
  }>;
}) {
  const navigate = useNavigate();
  const resolvePath = useResolvedValidNavigationLink();
  return (
    <Breadcrumbs>
      {links.map((link, i) => {
        return (
          <BreadcrumbItem
            isFirst={i === 0}
            key={`${link.to}_${i}`}
            isCurrent={link?.isCurrent || false}
            onClick={() => {
              navigate(resolvePath(link.to, link.query));
            }}
          >
            {link.title}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumbs>
  );
}

type BreadcrumbItemProps = {
  isFirst?: boolean;
  isCurrent?: boolean;
  onClick?: () => void;
};

export function BreadcrumbItem(
  props: BreadcrumbItemProps & AriaBreadcrumbItemProps
) {
  const ref = useRef(null);
  const { itemProps } = useBreadcrumbItem(
    { ...props, elementType: 'span' },
    ref
  );
  return (
    <Box
      {...itemProps}
      flexDirection="row"
      display="flex"
      marginLeft={props.isFirst ? '0' : '0.5'}
      onClick={props?.onClick}
    >
      <Text
        ref={ref}
        variation={{
          hover: props.isCurrent || props.isDisabled ? 'c2' : 'lk2',
          default: 'c2',
        }}
        color="textLow"
        textTransform="capitalize"
        cursor={props.isCurrent || props.isDisabled ? 'default' : 'pointer'}
      >
        {props.children}
      </Text>
      {!props.isCurrent ? (
        <RightCaratIcon color="iconLow" height="2" width="2" marginLeft="0.5" />
      ) : null}
    </Box>
  );
}

function useResolvedValidNavigationLink() {
  const { pathname } = useLocation();
  return useCallback(
    (path: string, query?: string) => {
      let resolvedLink: string =
        pathname.substring(0, pathname.indexOf(path)) + path;
      if (query) {
        resolvedLink = resolvedLink + `?${query}`;
      }
      return resolvedLink;
    },
    [pathname]
  );
}
