import { Box, Stack, Tabs, Header, Inline } from '@nbfc-expense-tool/ui';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Item } from 'react-stately';
import { useMemo } from 'react';
import { ActiveVendors } from './ActiveVendors';
import { InactiveVendors } from './InactiveVendors';
import { WaitingForApproval } from './WaitingForApproval';
import { VendorDetails } from './VendorDetails';
import AddOrEditVendor from './AddOrEditVendor';
import VendorProfile, { VendorCompleteDetails } from './VendorProfile';
import BulkImportVendors from './BulkImportVendors';
import { AddNewVendorButton } from '../../Vendors';
import {
  BRANCH_FOR_FILTER,
  useBranchesForFilter,
  useVendorTypesForFilter,
  VENDOR_TYPES_FOR_FILTER,
} from '@nbfc-expense-tool/data-store/dashboard';
import React from 'react';
import { BulkImportButton } from '../../Common';

export {
  ActiveVendors,
  InactiveVendors,
  WaitingForApproval,
  VendorDetails,
  AddOrEditVendor,
  VendorProfile,
  VendorCompleteDetails,
  BulkImportVendors,
};

export type FilterContextType = {
  branchesStatus: 'success' | 'in_progress' | 'failed';
  branches?: BRANCH_FOR_FILTER[];
  vendorTypesStatus: 'success' | 'in_progress' | 'failed';
  vendorTypes?: VENDOR_TYPES_FOR_FILTER[];
};

export const VendorFilterContext =
  React.createContext<FilterContextType | null>(null);

type FilterContexProps = {
  children: React.ReactNode;
};

// Create a provider component that holds the state/props you want to pass
const FilterContextProvider: React.FC<FilterContexProps> = ({ children }) => {
  const { status: branchesStatus, branches } = useBranchesForFilter();
  const { status: vendorTypesStatus, vendorTypes } = useVendorTypesForFilter();

  return (
    <VendorFilterContext.Provider
      value={{ branchesStatus, branches, vendorTypesStatus, vendorTypes }}
    >
      {children}
    </VendorFilterContext.Provider>
  );
};

const VendorTabOptions = [
  {
    title: 'Active Vendors',
    path: 'active-vendors',
  },
  {
    title: 'Waiting For Approval',
    path: 'waiting-for-approval',
  },
  {
    title: 'Inactive Vendors',
    path: 'inactive-vendors',
  },
];

export function VendorsLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const activePath = useMemo(() => {
    const activePath = VendorTabOptions.find((option) =>
      pathname.startsWith(`/home/vendors/vendors-list/${option.path}`)
    );
    return activePath?.path || VendorTabOptions[0].path;
  }, [pathname]);

  const { vendorId } = useParams();

  const option = useMemo(() => {
    return getLayoutOption(Boolean(vendorId));
  }, [vendorId]);

  return option === 'vendor_details' ? (
    <Outlet />
  ) : (
    <FilterContextProvider>
      <Stack flex="1" paddingX="4" paddingY="5" gap="3">
        <Header
          headingText="Vendors"
          renderRight={() => (
            <Inline gap="4">
              <BulkImportButton entityType="vendors" />
              <AddNewVendorButton />
            </Inline>
          )}
        />
        <Tabs
          selectedKey={activePath}
          onClick={(path: string) => navigate(path)}
        >
          {VendorTabOptions.map((option) => (
            <Item
              key={option.path}
              textValue={option.path}
              title={
                <Box textDecoration="none" style={{ color: 'inherit' }}>
                  {option.title}
                </Box>
              }
            >
              <Outlet />
            </Item>
          ))}
        </Tabs>
      </Stack>
    </FilterContextProvider>
  );
}

function getLayoutOption(
  paramsIncluded: boolean
): 'vendor_details' | 'vendors' {
  return paramsIncluded ? 'vendor_details' : 'vendors';
}
