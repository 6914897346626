import { Box } from '../box/box';
import './skeletons.css';

type SkeletonProps = {
  width?: React.ComponentProps<typeof Box>['width'];
  height?: React.ComponentProps<typeof Box>['height'];
  backgroundColor?: React.ComponentProps<typeof Box>['backgroundColor'];
};

export function SkeletonTitle({
  width,
  height,
  backgroundColor,
}: SkeletonProps) {
  return (
    <Box role="status">
      <Box
        className="skeleton"
        backgroundColor={backgroundColor || 'surfaceNeutralLowest'}
        height={height || '1'}
        width={width || '3'}
        rounded="full"
      ></Box>
    </Box>
  );
}

type SkeletonRowsProps = SkeletonProps & {
  numOfRows: number;
  numOfCols?: number;
};
export function SkeletonRows({
  numOfCols,
  numOfRows,
  ...props
}: SkeletonRowsProps) {
  const rows = new Array(numOfRows).fill(0);
  const cols = new Array(numOfCols || numOfRows).fill(0);
  return (
    <tbody>
      {rows.map((_, i) => (
        <tr key={i}>
          {cols.map((_, i) => (
            <Box as="td" key={i} paddingX="3" paddingY="4">
              <SkeletonTitle width="full" {...props} />
            </Box>
          ))}
        </tr>
      ))}
    </tbody>
  );
}
