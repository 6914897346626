import { useAddOrEditVendor } from '@nbfc-expense-tool/data-store/dashboard';
import {
  Box,
  Button,
  CheckCircleFilled,
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  FullScreenHeader,
  Inline,
  Radio,
  RadioGroup,
  RightCaratIcon,
  Stack,
  Text,
  TextInput,
  UploadDocument,
  Checkbox,
  SingleSelect,
  MultiSelect,
  SpinnerIcon,
} from '@nbfc-expense-tool/ui';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';

const idPrefix = 'add-or-edit-vendor';

const AddOrEditVendor: React.FC<{
  flowType?: 'add' | 'edit';
}> = ({ flowType = 'add' }) => {
  const {
    formFillingStates,
    currentFormFillingStateIndex,
    vendorPrimaryDetailsFormControl,
    vendorAddressDetailsFormControl,
    vendorPaymentDetailsFormControl,
    vendorLinkFormControl,
    createOrUpdateVendorApiLoading,
    vendorRegistrationTypeOptions,
    vendorTypeOptions,
    vendorBranchOptions,
    selectStateOptions,
    scrollViewRef,
    goBack,
    validateFormAndNext,
    selectPreviousFormState,
    changeVendorDetailsFormState,
    updateVendorDetails,
    handleFetchIfscDetailsOnChange,
    isFetchingIfscForPaymentDetails,
  } = useAddOrEditVendor(flowType);

  const renderFormFillingStatesIndicator = () => {
    if (flowType === 'edit') return null;
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        {formFillingStates.map((formFillingState, index) => {
          const isSelected = index === currentFormFillingStateIndex;
          const isCompleted = index < currentFormFillingStateIndex;
          return (
            <React.Fragment key={formFillingState.stateId}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding="1"
                backgroundColor={
                  isSelected ? 'surfacePrimaryLowest' : undefined
                }
                borderRadius="md"
              >
                {isCompleted ? (
                  <CheckCircleFilled
                    color="iconSuccess"
                    width="3"
                    marginRight="1"
                  />
                ) : (
                  <Box
                    borderRadius="full"
                    backgroundColor={
                      isSelected ? 'surfacePrimary' : 'surfaceNeutralLowest'
                    }
                    marginRight="1"
                    width="3"
                    height="3"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text
                      variation="c1"
                      color={isSelected ? 'textOnSurface' : 'textLow'}
                    >
                      {index + 1}
                    </Text>
                  </Box>
                )}
                <Text
                  variation="c2"
                  color={isSelected ? 'textPrimary' : 'textMedium'}
                >
                  {formFillingState.title}
                </Text>
              </Box>
              {index !== formFillingStates.length - 1 && (
                <Box
                  width="6"
                  height="0.5"
                  backgroundColor="borderSeparator"
                  marginLeft="1"
                  marginRight="1"
                />
              )}
            </React.Fragment>
          );
        })}
      </Box>
    );
  };

  const renderVendorDetailsFormStateNavitgation = useMemo(() => {
    return (
      <Inline
        borderBottomWidth="1"
        borderColor="borderSeparator"
        marginBottom="4"
      >
        {formFillingStates.map((formFillingState, index) => {
          const isSelected = index === currentFormFillingStateIndex;
          return (
            <Box
              id={`${idPrefix}-change-${formFillingState.stateId}`}
              borderBottomWidth="2"
              borderColor={isSelected ? 'borderPrimary' : 'transparent'}
              padding="1.5"
              onClick={() => changeVendorDetailsFormState(index)}
              cursor="pointer"
            >
              <Text
                variation="t4"
                color={isSelected ? 'textPrimary' : 'textMedium'}
              >
                {formFillingState.title}
              </Text>
            </Box>
          );
        })}
      </Inline>
    );
  }, [
    formFillingStates,
    currentFormFillingStateIndex,
    changeVendorDetailsFormState,
  ]);

  const renderVendorPrimaryDetailsForm = useMemo(() => {
    return (
      <Box key="primaryDetails" ref={scrollViewRef}>
        <Box marginBottom="6">
          <Text variation="t3" marginBottom="3">
            Basic Details
          </Text>
          <Inline marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="legalName"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-primary-details-enter-legal-entity-name`}
                      label={'Legal Entity Name'}
                      fullWidth
                      isRequired
                      placeholder="Enter legal entity name"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="displayName"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-primary-details-enter-vendor-name`}
                      label={'Vendor Name'}
                      fullWidth
                      isRequired
                      placeholder="Enter vendor name"
                      description="This can be same as Legal Entity Name"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5" />
          </Inline>
          <Inline marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="vendorType"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <SingleSelect
                      id={`${idPrefix}-primary-details-select-vendor-type`}
                      options={vendorTypeOptions}
                      onSave={(option) => {
                        onChange(option.value);
                      }}
                      label="Vendor Type"
                      placeholder="Select Vendor Type"
                      isRequired
                      errorMessage={error?.message}
                      value={{ label: value, value: value }}
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="registrationType"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <SingleSelect
                      id={`${idPrefix}-primary-details-select-registration-type`}
                      options={vendorRegistrationTypeOptions}
                      onSave={(option) => {
                        onChange(option.value);
                      }}
                      label="Registration Type"
                      placeholder="Select Registration Type"
                      isRequired
                      errorMessage={error?.message}
                      value={{ label: value, value: value }}
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5" />
          </Inline>
          <Box width="fitContent" marginBottom="6">
            <Controller
              control={vendorPrimaryDetailsFormControl}
              name="registrationCert"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <>
                    <Text variation="c2" marginBottom="1">
                      Company Registration Certificate
                      <Text marginLeft="0.5" as="span" color="textError">
                        *
                      </Text>
                    </Text>
                    <UploadDocument
                      id={`${idPrefix}-primary-details-registration-cert`}
                      showDownloadButton={false}
                      onClickSelectDocument={(fileBlobValue, documentInfo) =>
                        onChange({
                          fileBlobValue: fileBlobValue,
                          newDocument: true,
                          ...documentInfo,
                        })
                      }
                      documentInfo={value}
                      rightIcon={(props, onClick) => (
                        <DeleteIcon
                          {...props}
                          id={`${idPrefix}-primary-details-delete-selected-registration-cert`}
                          onClick={() => {
                            onChange(undefined);
                            onClick();
                          }}
                          color="iconError"
                          cursor="pointer"
                        />
                      )}
                    />
                    {error?.message && (
                      <Text
                        marginTop="1"
                        borderRadius="md"
                        paddingY="0.5"
                        paddingX="1"
                        display="flex"
                        color="textError"
                        variation="c2"
                        bgColor="surfaceErrorLowest"
                      >
                        {error?.message}
                      </Text>
                    )}
                  </>
                );
              }}
            />
          </Box>
          <Inline marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="branchIds"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <>
                      <MultiSelect
                        id={`${idPrefix}-primary-details-select-branches`}
                        type="textInput"
                        label="Branches"
                        value={value}
                        options={vendorBranchOptions}
                        onSave={onChange}
                      />
                      {error?.message && (
                        <Text
                          marginTop="1"
                          borderRadius="md"
                          paddingY="0.5"
                          paddingX="1"
                          display="flex"
                          color="textError"
                          variation="c2"
                          bgColor="surfaceErrorLowest"
                        >
                          {error?.message}
                        </Text>
                      )}
                    </>
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5" />
            <Box flex="1" marginRight="5" />
          </Inline>
          <Inline>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="isEInvoicingEnabled"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <>
                      <Text variation="b2">
                        E-invoicing
                        <Text marginLeft="0.5" as="span" color="textError">
                          *
                        </Text>
                      </Text>
                      <RadioGroup
                        value={
                          value !== undefined ? (value ? '1' : '0') : undefined
                        }
                        onChange={(val) => onChange(val === '1' ? true : false)}
                      >
                        <Inline gap="6">
                          <Radio value="1" id={`${idPrefix}-e-invoicing-yes`}>
                            <Text variation="b2" color="textMedium">
                              Yes
                            </Text>
                          </Radio>
                          <Radio value="0" id={`${idPrefix}-e-invoicing-no`}>
                            <Text variation="b2" color="textMedium">
                              No
                            </Text>
                          </Radio>
                        </Inline>
                      </RadioGroup>
                      {error?.message && (
                        <Text
                          marginTop="1"
                          borderRadius="md"
                          paddingY="0.5"
                          paddingX="1"
                          display="flex"
                          color="textError"
                          variation="c2"
                          bgColor="surfaceErrorLowest"
                        >
                          {error?.message}
                        </Text>
                      )}
                    </>
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5" />
            <Box flex="1" marginRight="5" />
          </Inline>
        </Box>
        <Box marginBottom="6">
          <Text variation="t3" marginBottom="3">
            Official Details
          </Text>
          <Inline marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="panNumber"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-primary-details-enter-pan-number`}
                      label={'PAN Number'}
                      fullWidth
                      isRequired
                      placeholder="E.g. ABCDE1234F"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                      fieldInfo="Ten-character Income Tax code"
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="tanNumber"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-primary-details-enter-tan-number`}
                      label={'TAN Number'}
                      fullWidth
                      placeholder="E.g. RAJA99999B"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                      fieldInfo="Tax-related entity identifier"
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorPrimaryDetailsFormControl}
                name="cinNumber"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-primary-details-enter-entity-cin-number`}
                      label={'Entity CIN Number'}
                      fullWidth
                      placeholder="E.g. U67190TN2014PTC096078"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                      fieldInfo="Registered company ID"
                    />
                  );
                }}
              />
            </Box>
          </Inline>
          <Box width="fitContent" marginBottom="6">
            <Controller
              control={vendorPrimaryDetailsFormControl}
              name="pancard"
              render={(props) => {
                const {
                  field: { onChange, value },
                } = props;
                return (
                  <>
                    <Text variation="c2" marginBottom="1">
                      PAN Card
                    </Text>
                    <UploadDocument
                      id={`${idPrefix}-primary-details-pan-card`}
                      showDownloadButton={false}
                      onClickSelectDocument={(fileBlobValue, documentInfo) =>
                        onChange({
                          fileBlobValue: fileBlobValue,
                          newDocument: true,
                          ...documentInfo,
                        })
                      }
                      documentInfo={value}
                      rightIcon={(props, onClick) => (
                        <DeleteIcon
                          {...props}
                          id={`${idPrefix}-primary-details-delete-selected-pan-card`}
                          onClick={() => {
                            onChange(undefined);
                            onClick();
                          }}
                          color="iconError"
                          cursor="pointer"
                        />
                      )}
                    />
                  </>
                );
              }}
            />
          </Box>
          <Box marginBottom="6">
            <Controller
              control={vendorPrimaryDetailsFormControl}
              name="isDpiitCertified"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <>
                    <Inline marginBottom="3">
                      <Box flex="1" marginRight="5">
                        <Text variation="b2">
                          Is DPIIT Certified?
                          <Text marginLeft="0.5" as="span" color="textError">
                            *
                          </Text>
                        </Text>
                        <RadioGroup
                          value={
                            value !== undefined
                              ? value
                                ? '1'
                                : '0'
                              : undefined
                          }
                          onChange={(val) =>
                            onChange(val === '1' ? true : false)
                          }
                        >
                          <Inline gap="6">
                            <Radio
                              value="1"
                              id={`${idPrefix}-dpiit-certified-yes`}
                            >
                              <Text variation="b2" color="textMedium">
                                Yes
                              </Text>
                            </Radio>
                            <Radio
                              value="0"
                              id={`${idPrefix}-dpiit-certified-no`}
                            >
                              <Text variation="b2" color="textMedium">
                                No
                              </Text>
                            </Radio>
                          </Inline>
                        </RadioGroup>
                        {error?.message && (
                          <Text
                            marginTop="1"
                            borderRadius="md"
                            paddingY="0.5"
                            paddingX="1"
                            display="flex"
                            color="textError"
                            variation="c2"
                            bgColor="surfaceErrorLowest"
                          >
                            {error?.message}
                          </Text>
                        )}
                      </Box>
                      {value ? (
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorPrimaryDetailsFormControl}
                            name="dpiitNumber"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-primary-details-enter-dpiit-number`}
                                  label={'DPIIT Number'}
                                  fullWidth
                                  isRequired
                                  placeholder="Enter DPIIT Number"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                      ) : (
                        <Box flex="1" marginRight="5" />
                      )}
                      <Box flex="1" marginRight="5" />
                    </Inline>
                    {value && (
                      <Box width="fitContent">
                        <Controller
                          control={vendorPrimaryDetailsFormControl}
                          name="dpiitCert"
                          render={(props) => {
                            const {
                              field: { onChange, value },
                            } = props;
                            return (
                              <>
                                <Text variation="c2" marginBottom="1">
                                  DPIIT Certificate
                                </Text>
                                <UploadDocument
                                  id={`${idPrefix}-primary-details-dpiit-certificate`}
                                  showDownloadButton={false}
                                  onClickSelectDocument={(
                                    fileBlobValue,
                                    documentInfo
                                  ) =>
                                    onChange({
                                      fileBlobValue: fileBlobValue,
                                      newDocument: true,
                                      ...documentInfo,
                                    })
                                  }
                                  documentInfo={value}
                                  rightIcon={(props, onClick) => (
                                    <DeleteIcon
                                      {...props}
                                      id={`${idPrefix}-primary-details-delete-selected-dpiit-certificate`}
                                      onClick={() => {
                                        onClick();
                                        onChange(undefined);
                                      }}
                                      color="iconError"
                                      cursor="pointer"
                                    />
                                  )}
                                />
                              </>
                            );
                          }}
                        />
                      </Box>
                    )}
                  </>
                );
              }}
            />
          </Box>
          <Box marginBottom="6">
            <Controller
              control={vendorPrimaryDetailsFormControl}
              name="isGstRegistered"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <>
                    <Inline marginBottom="3">
                      <Box flex="1" marginRight="5">
                        <Text variation="b2">
                          Is GST Registered?
                          <Text marginLeft="0.5" as="span" color="textError">
                            *
                          </Text>
                        </Text>
                        <RadioGroup
                          value={
                            value !== undefined
                              ? value
                                ? '1'
                                : '0'
                              : undefined
                          }
                          onChange={(val) =>
                            onChange(val === '1' ? true : false)
                          }
                        >
                          <Inline gap="6">
                            <Radio
                              value="1"
                              id={`${idPrefix}-gst-registered-yes`}
                            >
                              <Text variation="b2" color="textMedium">
                                Yes
                              </Text>
                            </Radio>
                            <Radio
                              value="0"
                              id={`${idPrefix}-gst-registered-no`}
                            >
                              <Text variation="b2" color="textMedium">
                                No
                              </Text>
                            </Radio>
                          </Inline>
                        </RadioGroup>
                        {error?.message && (
                          <Text
                            marginTop="1"
                            borderRadius="md"
                            paddingY="0.5"
                            paddingX="1"
                            display="flex"
                            color="textError"
                            variation="c2"
                            bgColor="surfaceErrorLowest"
                          >
                            {error?.message}
                          </Text>
                        )}
                      </Box>
                      {value ? (
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorPrimaryDetailsFormControl}
                            name="gstNumber"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-primary-details-enter-gst-number`}
                                  label={'GST Number'}
                                  fullWidth
                                  isRequired
                                  placeholder="E.g. 22AAAAA0000A1Z5"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                      ) : (
                        <Box flex="1" marginRight="5" />
                      )}
                      <Box flex="1" marginRight="5" />
                    </Inline>
                    {value && (
                      <Box width="fitContent">
                        <Controller
                          control={vendorPrimaryDetailsFormControl}
                          name="gstCert"
                          render={(props) => {
                            const {
                              field: { onChange, value },
                            } = props;
                            return (
                              <>
                                <Text variation="c2" marginBottom="1">
                                  GST Certificate
                                </Text>
                                <UploadDocument
                                  id={`${idPrefix}-primary-details-gst-certificate`}
                                  showDownloadButton={false}
                                  onClickSelectDocument={(
                                    fileBlobValue,
                                    documentInfo
                                  ) =>
                                    onChange({
                                      fileBlobValue: fileBlobValue,
                                      newDocument: true,
                                      ...documentInfo,
                                    })
                                  }
                                  documentInfo={value}
                                  rightIcon={(props, onClick) => (
                                    <DeleteIcon
                                      {...props}
                                      id={`${idPrefix}-primary-details-delete-selected-gst-certificate`}
                                      onClick={() => {
                                        onClick();
                                        onChange(undefined);
                                      }}
                                      color="iconError"
                                      cursor="pointer"
                                    />
                                  )}
                                />
                              </>
                            );
                          }}
                        />
                      </Box>
                    )}
                  </>
                );
              }}
            />
          </Box>
          <Box marginBottom="6">
            <Controller
              control={vendorPrimaryDetailsFormControl}
              name="isMsmeRegistered"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <>
                    <Inline marginBottom="3">
                      <Box flex="1" marginRight="5">
                        <Text variation="b2">
                          Is MSME Registered?
                          <Text marginLeft="0.5" as="span" color="textError">
                            *
                          </Text>
                        </Text>
                        <RadioGroup
                          value={
                            value !== undefined
                              ? value
                                ? '1'
                                : '0'
                              : undefined
                          }
                          onChange={(val) =>
                            onChange(val === '1' ? true : false)
                          }
                        >
                          <Inline gap="6">
                            <Radio
                              value="1"
                              id={`${idPrefix}-msme-registered-yes`}
                            >
                              <Text variation="b2" color="textMedium">
                                Yes
                              </Text>
                            </Radio>
                            <Radio
                              value="0"
                              id={`${idPrefix}-msme-registered-no`}
                            >
                              <Text variation="b2" color="textMedium">
                                No
                              </Text>
                            </Radio>
                          </Inline>
                        </RadioGroup>
                        {error?.message && (
                          <Text
                            marginTop="1"
                            borderRadius="md"
                            paddingY="0.5"
                            paddingX="1"
                            display="flex"
                            color="textError"
                            variation="c2"
                            bgColor="surfaceErrorLowest"
                          >
                            {error?.message}
                          </Text>
                        )}
                      </Box>
                      {value ? (
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorPrimaryDetailsFormControl}
                            name="msmeNumber"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-primary-details-enter-msme-registration-number`}
                                  label={'MSME Registration Number'}
                                  fullWidth
                                  isRequired
                                  placeholder="E.g. UDYAM-AP-00-0123456"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                      ) : (
                        <Box flex="1" marginRight="5" />
                      )}
                      <Box flex="1" marginRight="5" />
                    </Inline>
                    {value && (
                      <Box width="fitContent">
                        <Controller
                          control={vendorPrimaryDetailsFormControl}
                          name="msmeCert"
                          render={(props) => {
                            const {
                              field: { onChange, value },
                            } = props;
                            return (
                              <>
                                <Text variation="c2" marginBottom="1">
                                  MSME Certificate
                                </Text>
                                <UploadDocument
                                  id={`${idPrefix}-primary-details-msme-certificate`}
                                  showDownloadButton={false}
                                  onClickSelectDocument={(
                                    fileBlobValue,
                                    documentInfo
                                  ) =>
                                    onChange({
                                      fileBlobValue: fileBlobValue,
                                      newDocument: true,
                                      ...documentInfo,
                                    })
                                  }
                                  documentInfo={value}
                                  rightIcon={(props, onClick) => (
                                    <DeleteIcon
                                      {...props}
                                      id={`${idPrefix}-primary-details-delete-selected-msme-certificate`}
                                      onClick={() => {
                                        onClick();
                                        onChange(undefined);
                                      }}
                                      color="iconError"
                                      cursor="pointer"
                                    />
                                  )}
                                />
                              </>
                            );
                          }}
                        />
                      </Box>
                    )}
                  </>
                );
              }}
            />
          </Box>
          <Box>
            <Controller
              control={vendorPrimaryDetailsFormControl}
              name="officeStatus"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <Inline>
                    <Box flex="1" marginRight="5">
                      <Text variation="b2">
                        Office Status?
                        <Text marginLeft="0.5" as="span" color="textError">
                          *
                        </Text>
                      </Text>
                      <RadioGroup value={value} onChange={onChange}>
                        <Inline gap="6">
                          <Radio
                            value="owned"
                            id={`${idPrefix}-office-status-owned`}
                          >
                            <Text variation="b2" color="textMedium">
                              Owned
                            </Text>
                          </Radio>
                          <Radio
                            value="rented"
                            id={`${idPrefix}-office-status-rented`}
                          >
                            <Text variation="b2" color="textMedium">
                              Rented
                            </Text>
                          </Radio>
                        </Inline>
                      </RadioGroup>
                      {error?.message && (
                        <Text
                          marginTop="1"
                          borderRadius="md"
                          paddingY="0.5"
                          paddingX="1"
                          display="flex"
                          color="textError"
                          variation="c2"
                          bgColor="surfaceErrorLowest"
                        >
                          {error?.message}
                        </Text>
                      )}
                    </Box>
                    <Box flex="1" marginRight="5" />
                    <Box flex="1" marginRight="5" />
                  </Inline>
                );
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }, [
    vendorPrimaryDetailsFormControl,
    vendorRegistrationTypeOptions,
    vendorTypeOptions,
    vendorBranchOptions,
    scrollViewRef,
  ]);

  const renderVendorAddressDetailsForm = useMemo(() => {
    return (
      <Box key="addressDetails" ref={scrollViewRef}>
        <Box marginBottom="6">
          <Text variation="t3" marginBottom="3">
            Contact Person Details
          </Text>
          <Inline marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorAddressDetailsFormControl}
                name="contactPersonDetails.name"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-address-details-enter-vendor-name`}
                      label={'Person Name'}
                      fullWidth
                      isRequired
                      placeholder="Enter Full Name"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorAddressDetailsFormControl}
                name="contactPersonDetails.designation"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-address-details-enter-designation`}
                      label={'Designation'}
                      fullWidth
                      isRequired
                      placeholder="Enter designation in company"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5" />
          </Inline>
          <Inline>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorAddressDetailsFormControl}
                name="contactPersonDetails.mobile"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-address-details-enter-mobile-number`}
                      label={'Mobile Number'}
                      fullWidth
                      isRequired
                      placeholder="Enter 10 digit mobile number"
                      leftIcon={() => (
                        <Text variation="b2" color="textHigh">
                          +91
                        </Text>
                      )}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorAddressDetailsFormControl}
                name="contactPersonDetails.phone"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-address-details-enter-phone-number`}
                      label={'Phone Number'}
                      fullWidth
                      placeholder="Enter Phone Number with code"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorAddressDetailsFormControl}
                name="contactPersonDetails.email"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-address-details-enter-email`}
                      label={'Email Id'}
                      fullWidth
                      isRequired
                      placeholder="E.g. abc@xyz.com"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
          </Inline>
        </Box>
        <Box marginBottom="6">
          <Text variation="t3" marginBottom="2">
            Applicant Details
          </Text>
          <Controller
            control={vendorAddressDetailsFormControl}
            name="applicantDetails.sameAsContactPerson"
            render={(props) => {
              const {
                field: { onChange, value },
              } = props;
              const isSelected = value;
              return (
                <>
                  <Inline marginBottom={value ? '0' : '3'}>
                    <Checkbox isSelected={isSelected} onChange={onChange} />
                    <Text variation="b2" marginLeft="1.5">
                      Same as contact person details
                    </Text>
                  </Inline>
                  {!value && (
                    <>
                      <Inline marginBottom="6">
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorAddressDetailsFormControl}
                            name="applicantDetails.name"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-address-details-enter-applicant-vendor-name`}
                                  label={'Person Name'}
                                  fullWidth
                                  isRequired
                                  placeholder="Enter Full Name"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorAddressDetailsFormControl}
                            name="applicantDetails.designation"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-address-details-enter-applicant-designation`}
                                  label={'Designation'}
                                  fullWidth
                                  isRequired
                                  placeholder="Enter designation in company"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                        <Box flex="1" marginRight="5" />
                      </Inline>
                      <Inline>
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorAddressDetailsFormControl}
                            name="applicantDetails.mobile"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-address-details-enter-applicant-mobile-number`}
                                  label={'Mobile Number'}
                                  fullWidth
                                  isRequired
                                  placeholder="Enter 10 digit mobile number"
                                  leftIcon={() => (
                                    <Text variation="b2" color="textHigh">
                                      +91
                                    </Text>
                                  )}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorAddressDetailsFormControl}
                            name="applicantDetails.phone"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-address-details-enter-applicant-phone-number`}
                                  label={'Phone Number'}
                                  fullWidth
                                  placeholder="Enter Phone Number with code"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorAddressDetailsFormControl}
                            name="applicantDetails.email"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-address-details-enter-applicant-email`}
                                  label={'Email Id'}
                                  fullWidth
                                  isRequired
                                  placeholder="E.g. abc@xyz.com"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                      </Inline>
                    </>
                  )}
                </>
              );
            }}
          />
        </Box>
        <Box marginBottom="6">
          <Text variation="t3" marginBottom="3">
            Registered Address
          </Text>
          <Inline marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorAddressDetailsFormControl}
                name="registeredAddress.addressLine1"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-address-details-enter-address-line-1`}
                      label={'Address Line 1'}
                      fullWidth
                      isRequired
                      placeholder="Apartments, Room No., Building Name"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorAddressDetailsFormControl}
                name="registeredAddress.addressLine2"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-address-details-enter-address-line-2`}
                      label={'Address Line 2'}
                      fullWidth
                      placeholder="Street, Area, Locality"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorAddressDetailsFormControl}
                name="registeredAddress.pincode"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-address-details-enter-pincode`}
                      label={'Pincode'}
                      fullWidth
                      isRequired
                      placeholder="Enter Pincode"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
          </Inline>
          <Inline marginBottom="6">
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorAddressDetailsFormControl}
                name="registeredAddress.city"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-address-details-enter-city`}
                      label={'City'}
                      fullWidth
                      isRequired
                      placeholder="Enter City"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorAddressDetailsFormControl}
                name="registeredAddress.state"
                render={(props) => {
                  const {
                    field: { onChange, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <SingleSelect
                      id={`${idPrefix}-address-details-select-state`}
                      options={selectStateOptions}
                      onSave={(option) => {
                        onChange(option.value);
                      }}
                      label="State"
                      placeholder="Select State"
                      isRequired
                      errorMessage={error?.message}
                      value={{ label: value, value: value }}
                    />
                  );
                }}
              />
            </Box>
            <Box flex="1" marginRight="5">
              <Controller
                control={vendorAddressDetailsFormControl}
                name="registeredAddress.phone"
                render={(props) => {
                  const {
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  } = props;
                  return (
                    <TextInput
                      id={`${idPrefix}-address-details-enter-phone-number`}
                      label={'Phone Number'}
                      fullWidth
                      placeholder="Enter Phone Number with code"
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      defaultValue={
                        value !== undefined ? String(value) : undefined
                      }
                    />
                  );
                }}
              />
            </Box>
          </Inline>
          <Controller
            control={vendorAddressDetailsFormControl}
            name="registeredAddress.email"
            render={(props) => {
              const {
                field: { onChange, onBlur, value },
                fieldState: { error },
              } = props;
              return (
                <TextInput
                  id={`${idPrefix}-address-details-email`}
                  label={'Email Id'}
                  placeholder="E.g. abc@xyz.com"
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                  defaultValue={value !== undefined ? String(value) : undefined}
                />
              );
            }}
          />
        </Box>
        <Box marginBottom="6">
          <Text variation="t3" marginBottom="3">
            Business Address
          </Text>
          <Controller
            control={vendorAddressDetailsFormControl}
            name="businessAddress.sameAsRegisteredAddress"
            render={(props) => {
              const {
                field: { onChange, value },
              } = props;
              const isSelected = value;
              return (
                <>
                  <Inline marginBottom={value ? '0' : '3'}>
                    <Checkbox isSelected={isSelected} onChange={onChange} />
                    <Text variation="b2" marginLeft="1.5">
                      Same as registered address
                    </Text>
                  </Inline>
                  {!value && (
                    <>
                      <Inline marginBottom="6">
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorAddressDetailsFormControl}
                            name="businessAddress.addressLine1"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-business-address-details-enter-address-line-1`}
                                  label={'Address Line 1'}
                                  fullWidth
                                  isRequired
                                  placeholder="Apartments, Room No., Building Name"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorAddressDetailsFormControl}
                            name="businessAddress.addressLine2"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-business-address-details-enter-address-line-2`}
                                  label={'Address Line 2'}
                                  fullWidth
                                  placeholder="Street, Area, Locality"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorAddressDetailsFormControl}
                            name="businessAddress.pincode"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-business-address-details-enter-pincode`}
                                  label={'Pincode'}
                                  fullWidth
                                  isRequired
                                  placeholder="Enter Pincode"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                      </Inline>
                      <Inline marginBottom="6">
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorAddressDetailsFormControl}
                            name="businessAddress.city"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-business-address-details-enter-city`}
                                  label={'City'}
                                  fullWidth
                                  isRequired
                                  placeholder="Enter City"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorAddressDetailsFormControl}
                            name="businessAddress.state"
                            render={(props) => {
                              const {
                                field: { onChange, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <SingleSelect
                                  id={`${idPrefix}-business-address-details-select-state`}
                                  options={selectStateOptions}
                                  onSave={(option) => {
                                    onChange(option.value);
                                  }}
                                  label="State"
                                  placeholder="Select State"
                                  isRequired
                                  errorMessage={error?.message}
                                  value={{ label: value, value: value }}
                                />
                              );
                            }}
                          />
                        </Box>
                        <Box flex="1" marginRight="5">
                          <Controller
                            control={vendorAddressDetailsFormControl}
                            name="businessAddress.phone"
                            render={(props) => {
                              const {
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                              } = props;
                              return (
                                <TextInput
                                  id={`${idPrefix}-business-address-details-enter-phone-number`}
                                  label={'Phone Number'}
                                  fullWidth
                                  placeholder="Enter Phone Number with code"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  errorMessage={error?.message}
                                  defaultValue={
                                    value !== undefined
                                      ? String(value)
                                      : undefined
                                  }
                                />
                              );
                            }}
                          />
                        </Box>
                      </Inline>
                      <Controller
                        control={vendorAddressDetailsFormControl}
                        name="businessAddress.email"
                        render={(props) => {
                          const {
                            field: { onChange, onBlur, value },
                            fieldState: { error },
                          } = props;
                          return (
                            <TextInput
                              id={`${idPrefix}-business-address-details-enter-email`}
                              label={'Email Id'}
                              placeholder="E.g. abc@xyz.com"
                              onChange={onChange}
                              onBlur={onBlur}
                              errorMessage={error?.message}
                              defaultValue={
                                value !== undefined ? String(value) : undefined
                              }
                            />
                          );
                        }}
                      />
                    </>
                  )}
                </>
              );
            }}
          />
        </Box>
        <Box width="fitContent">
          <Controller
            control={vendorAddressDetailsFormControl}
            name="addressProofDoc"
            render={(props) => {
              const {
                field: { onChange, value },
                fieldState: { error },
              } = props;
              return (
                <>
                  <Text variation="c2" marginBottom="1">
                    Address Proof
                    <Text marginLeft="0.5" as="span" color="textError">
                      *
                    </Text>
                  </Text>
                  <UploadDocument
                    id={`${idPrefix}-business-address-details-proof`}
                    showDownloadButton={false}
                    onClickSelectDocument={(fileBlobValue, documentInfo) =>
                      onChange({
                        fileBlobValue: fileBlobValue,
                        newDocument: true,
                        ...documentInfo,
                      })
                    }
                    documentInfo={value}
                    rightIcon={(props, onClick) => (
                      <DeleteIcon
                        {...props}
                        id={`${idPrefix}-business-address-details-proof-delete`}
                        onClick={() => {
                          onClick();
                          onChange(undefined);
                        }}
                        color="iconError"
                        cursor="pointer"
                      />
                    )}
                  />
                  {error?.message && (
                    <Text
                      marginTop="1"
                      borderRadius="md"
                      paddingY="0.5"
                      paddingX="1"
                      display="flex"
                      color="textError"
                      variation="c2"
                      bgColor="surfaceErrorLowest"
                    >
                      {error?.message}
                    </Text>
                  )}
                </>
              );
            }}
          />
        </Box>
      </Box>
    );
  }, [vendorAddressDetailsFormControl, selectStateOptions, scrollViewRef]);

  const rendorVendorPaymentDetailsForm = useMemo(() => {
    return (
      <Box key="paymentDetails" height="screen">
        <Text variation="t3" marginBottom="3">
          Payment Details
        </Text>
        <Box display="flex" flexDirection="row" marginBottom="6">
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorPaymentDetailsFormControl}
              name="accountHolderName"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-payment-details-enter-account-holder-name`}
                    label={'Account Holder Name'}
                    fullWidth
                    isRequired
                    placeholder="Same as bank records"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorPaymentDetailsFormControl}
              name="accountNumber"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-payment-details-enter-account-number`}
                    label={'Account Number'}
                    fullWidth
                    isRequired
                    placeholder="Same as bank records"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorPaymentDetailsFormControl}
              name="accountType"
              render={(props) => {
                const {
                  field: { onChange, value },
                  fieldState: { error },
                } = props;
                return (
                  <Box>
                    <Text variation="b2">
                      Account Type?
                      <Text marginLeft="0.5" as="span" color="textError">
                        *
                      </Text>
                    </Text>
                    <RadioGroup
                      aria-label="Choose a date"
                      value={value}
                      onChange={onChange}
                    >
                      <Inline>
                        <Radio
                          value="current"
                          id={`${idPrefix}-account-type-current`}
                        >
                          <Text variation="b2" color="textMedium">
                            Current
                          </Text>
                        </Radio>
                        <Radio
                          value="saving"
                          id={`${idPrefix}-account-type-saving`}
                        >
                          <Text variation="b2" color="textMedium">
                            Savings
                          </Text>
                        </Radio>
                      </Inline>
                    </RadioGroup>
                    {error?.message && (
                      <Text
                        marginTop="1"
                        borderRadius="md"
                        paddingY="0.5"
                        paddingX="1"
                        display="flex"
                        color="textError"
                        variation="c2"
                        bgColor="surfaceErrorLowest"
                      >
                        {error?.message}
                      </Text>
                    )}
                  </Box>
                );
              }}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" marginBottom="6">
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorPaymentDetailsFormControl}
              name="ifscCode"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-payment-details-enter-ifsc-code`}
                    label={'IFSC Code'}
                    fullWidth
                    isRequired
                    maxLength={11}
                    placeholder="Must be 11 characters long"
                    rightIcon={
                      isFetchingIfscForPaymentDetails
                        ? () => <SpinnerIcon size="3" color="iconMedium" />
                        : undefined
                    }
                    onChange={(e) => {
                      handleFetchIfscDetailsOnChange(e);
                      onChange(e);
                    }}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                    fieldInfo="Bank branch identifier in India"
                    isDisabled={isFetchingIfscForPaymentDetails}
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorPaymentDetailsFormControl}
              name="bankName"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-payment-details-enter-bank-name`}
                    label={'Bank Name'}
                    fullWidth
                    isRequired
                    placeholder="Enter Bank name"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    value={value !== undefined ? String(value) : ''}
                    removeMinWidth
                    isDisabled={isFetchingIfscForPaymentDetails}
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorPaymentDetailsFormControl}
              name="branchName"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-payment-details-enter-branch-name`}
                    label={'Branch Name'}
                    fullWidth
                    placeholder="Enter Bank's branch name"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    value={value !== undefined ? String(value) : ''}
                    removeMinWidth
                    isDisabled={isFetchingIfscForPaymentDetails}
                  />
                );
              }}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" marginBottom="6">
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorPaymentDetailsFormControl}
              name="micrCode"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-payment-details-enter-micr-code`}
                    label={'MICR'}
                    fullWidth
                    description="It's printed at the bottom of the cheque"
                    placeholder="Must be 9 characters long"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    value={value !== undefined ? String(value) : ''}
                    removeMinWidth
                    fieldInfo="Bank cheque processing code."
                    isDisabled={isFetchingIfscForPaymentDetails}
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorPaymentDetailsFormControl}
              name="swiftCode"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-payment-details-enter-swift-code`}
                    label={'Swift Code'}
                    placeholder="Should be 8-11 characters long"
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    value={value !== undefined ? String(value) : ''}
                    removeMinWidth
                    fieldInfo="International bank ID"
                    isDisabled={isFetchingIfscForPaymentDetails}
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5" />
        </Box>
        <Box width="fitContent" marginBottom="4">
          <Controller
            control={vendorPaymentDetailsFormControl}
            name="cancelledChequeCopy"
            render={(props) => {
              const {
                field: { onChange, value },
                fieldState: { error },
              } = props;
              return (
                <>
                  <Text variation="c2" marginBottom="1">
                    Copy of Cancelled Cheque
                    <Text marginLeft="0.5" as="span" color="textError">
                      *
                    </Text>
                  </Text>
                  <UploadDocument
                    id={`${idPrefix}-payment-details-cancelled-cheque`}
                    showDownloadButton={false}
                    onClickSelectDocument={(fileBlobValue, documentInfo) =>
                      onChange({
                        fileBlobValue: fileBlobValue,
                        newDocument: true,
                        ...documentInfo,
                      })
                    }
                    documentInfo={value}
                    rightIcon={(props, onClick) => (
                      <DeleteIcon
                        {...props}
                        id={`${idPrefix}-payment-details-delete-selected-cancelled-cheque`}
                        onClick={() => {
                          onClick();
                          onChange(undefined);
                        }}
                        color="iconError"
                        cursor="pointer"
                      />
                    )}
                  />
                  {error?.message && (
                    <Text
                      marginTop="1"
                      borderRadius="md"
                      paddingY="0.5"
                      paddingX="1"
                      display="flex"
                      color="textError"
                      variation="c2"
                      bgColor="surfaceErrorLowest"
                    >
                      {error?.message}
                    </Text>
                  )}
                </>
              );
            }}
          />
        </Box>
      </Box>
    );
  }, [
    vendorPaymentDetailsFormControl,
    isFetchingIfscForPaymentDetails,
    handleFetchIfscDetailsOnChange,
  ]);

  const renderVendorLinksForm = useMemo(() => {
    return (
      <Box key="links" height="screen">
        <Box display="flex" flexDirection="row" marginBottom="6">
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorLinkFormControl}
              name="website"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-link-details-enter-website-link`}
                    label={'Website Link'}
                    fullWidth
                    placeholder="Enter vendor website link"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorLinkFormControl}
              name="facebook"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-link-details-enter-facebook-link`}
                    label={'Facebook Page Link'}
                    fullWidth
                    placeholder="Enter vendor facebook page link"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorLinkFormControl}
              name="instagram"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-link-details-enter-instagram-link`}
                    label={'Instagram Page Link'}
                    fullWidth
                    placeholder="Enter vendor instagram page link"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" marginBottom="6">
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorLinkFormControl}
              name="linkedIn"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-link-details-enter-linkedin-link`}
                    label={'LinkedIn Profile Link'}
                    fullWidth
                    placeholder="Enter vendor LinkedIn profile link"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorLinkFormControl}
              name="twitter"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-link-details-enter-twitter-link`}
                    label={'Twitter Page Link'}
                    fullWidth
                    placeholder="Enter vendor twitter page link"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
          <Box flex="1" marginRight="5">
            <Controller
              control={vendorLinkFormControl}
              name="others"
              render={(props) => {
                const {
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                } = props;
                return (
                  <TextInput
                    id={`${idPrefix}-link-details-enter-other-link`}
                    label={'Any Other Link'}
                    fullWidth
                    placeholder="Enter any other relevant link"
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    defaultValue={
                      value !== undefined ? String(value) : undefined
                    }
                    removeMinWidth
                  />
                );
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }, [vendorLinkFormControl]);

  const renderFormFillingStates = useMemo(() => {
    switch (formFillingStates[currentFormFillingStateIndex].stateId) {
      case 'primaryDetails':
        return renderVendorPrimaryDetailsForm;
      case 'addressDetails':
        return renderVendorAddressDetailsForm;
      case 'paymentDetails':
        return rendorVendorPaymentDetailsForm;
      case 'links':
        return renderVendorLinksForm;
      default:
        return null;
    }
  }, [
    currentFormFillingStateIndex,
    formFillingStates,
    renderVendorLinksForm,
    rendorVendorPaymentDetailsForm,
    renderVendorAddressDetailsForm,
    renderVendorPrimaryDetailsForm,
  ]);

  return (
    <Box height="fitContent">
      <FullScreenHeader
        headingText={
          flowType === 'edit' ? 'Edit Vendor Profile' : 'Add New Vendor'
        }
        renderRight={renderFormFillingStatesIndicator}
        onClickCross={goBack}
        borderBottomWidth="1"
        borderColor="borderSeparator"
        position="sticky"
        top="0"
        zIndex="50"
      />
      <Stack paddingX="5" paddingTop="4" paddingBottom="4" height="fitContent">
        {flowType === 'edit' && renderVendorDetailsFormStateNavitgation}
        {renderFormFillingStates}
      </Stack>
      {flowType === 'edit' ? (
        <Inline
          justifyContent="end"
          backgroundColor="surfaceDefault"
          paddingY="2"
          paddingX="5"
          borderTopWidth="1"
          borderColor="borderSeparator"
          position="sticky"
          bottom="0"
          zIndex="50"
        >
          <Button
            id={`${idPrefix}-cancel-edit`}
            title={'Cancel'}
            type="outlined"
            leftIcon={(props) => <CrossIcon {...props} />}
            marginRight="2"
            onClick={goBack}
            state={createOrUpdateVendorApiLoading ? 'disabled' : undefined}
          />
          <Button
            id={`${idPrefix}-save-edit`}
            title={'Save'}
            leftIcon={(props) => <CheckIcon {...props} />}
            type="filled"
            state={createOrUpdateVendorApiLoading ? 'loading' : undefined}
            onClick={updateVendorDetails}
          />
        </Inline>
      ) : (
        <Box
          display="flex"
          justifyContent="end"
          backgroundColor="surfaceDefault"
          paddingY="2"
          paddingX="5"
          borderTopWidth="1"
          borderColor="borderSeparator"
          position="sticky"
          bottom="0"
          zIndex="50"
        >
          <Box marginRight="2">
            <Button
              id={`${idPrefix}-close-or-previous-form`}
              title={currentFormFillingStateIndex === 0 ? 'Cancel' : 'Previous'}
              type="outlined"
              leftIcon={(props) =>
                currentFormFillingStateIndex === 0 ? (
                  <CrossIcon {...props} />
                ) : (
                  <RightCaratIcon {...props} rotate="180" />
                )
              }
              onClick={selectPreviousFormState}
              state={createOrUpdateVendorApiLoading ? 'disabled' : undefined}
            />
          </Box>
          <Box>
            <Button
              id={`${idPrefix}-next-or-save-vendor`}
              title={
                currentFormFillingStateIndex === formFillingStates.length - 1
                  ? 'Save Vendor'
                  : 'Next'
              }
              leftIcon={
                currentFormFillingStateIndex === formFillingStates.length - 1
                  ? (props) => <CheckIcon {...props} />
                  : undefined
              }
              rightIcon={
                currentFormFillingStateIndex !== formFillingStates.length - 1
                  ? (props) => (
                      <RightCaratIcon size="2.5" color="iconOnSurface" />
                    )
                  : undefined
              }
              type="filled"
              state={createOrUpdateVendorApiLoading ? 'loading' : undefined}
              onClick={validateFormAndNext}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AddOrEditVendor;
