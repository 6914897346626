import { getSyncStoredItem } from '@nbfc-expense-tool/data-store/storage';
import { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '@nbfc-expense-tool/data-store/auth';
import { checkForModuleAccess } from '@nbfc-expense-tool/data-store/permissions';

export function RedirectToHome() {
  return <Navigate to={`/home`} replace />;
}

export function RedirectToDashboard() {
  const { user } = useUser();
  const { dashboard } = checkForModuleAccess(
    user?.roles[0]?.permissions || null
  );
  return <Navigate to={dashboard ? 'dashboard' : 'invoices'} replace />;
}

export function RedirectForDashboard() {
  return <Navigate to={'invoice-analysis'} replace />;
}

export function RedirectForInvoices() {
  return <Navigate to={`created-by-you`} replace />;
}

export function RedirectForVendors() {
  return <Navigate to={`vendors-list/active-vendors`} replace />;
}

//TODO: Not required
export function RedirectToLogin() {
  return <Navigate to={`login`} replace />;
}

export const ProtectedRoutes = ({
  redirectTo = '/auth/login',
  children,
}: {
  redirectTo?: string;
  children?: React.ReactNode;
  redirectBack?: boolean;
}) => {
  const [isLoggedIn] = useState(() => {
    const authToken = getSyncStoredItem('authToken');
    if (authToken) {
      axios.defaults.headers.common['Authorization'] = `${authToken}`;
    }
    return Boolean(authToken);
  });

  if (isLoggedIn) {
    return children || <Outlet />;
  }

  return <Navigate to={`${redirectTo}`} replace />;
};

export const GuestRoutes = ({
  redirectTo = '/',
  children,
}: {
  redirectTo?: string;
  children?: React.ReactNode;
  redirectBack?: boolean;
}) => {
  const [isLoggedIn] = useState(() => {
    const authToken = getSyncStoredItem('authToken');
    return Boolean(authToken);
  });

  if (!isLoggedIn) {
    return children || <Outlet />;
  }

  return <Navigate to={`${redirectTo}`} replace />;
};

export function RedirectForReimbursements() {
  return <Navigate to="created-by-you" replace />;
}
