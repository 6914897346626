import {
  Box,
  Stack,
  Text,
  KeyIcon,
  Button,
  EyeOffIcon,
  EyeIcon,
  toastQueue,
  InfoDialog,
  CheckCircle,
} from '@nbfc-expense-tool/ui';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FormTextInput } from '@nbfc-expense-tool/data-store/forms';
import { resetPassword } from '@nbfc-expense-tool/data-store/utils';

const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const schema = z
  .object({
    password: z
      .string({
        required_error: 'Please enter new password',
      })
      .nonempty('Please enter new password')
      .min(
        8,
        'Password should be minimum 8 characters. It must contain 1 alphabet, 1 number & 1 special character'
      )
      .regex(
        passwordRegex,
        'Password should be minimum 8 characters. It must contain 1 alphabet, 1 number & 1 special character'
      ),
    confirmPassword: z
      .string({
        required_error: 'Please confirm new password',
      })
      .nonempty('Please confirm new password'),
  })
  .superRefine((data, context) => {
    if (data.confirmPassword !== data.password) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Password doesn't match",
        path: ['confirmPassword'],
      });
    }
    return data;
  });

type FormInputs = {
  password: string;
  confirmPassword: string;
};

const ResetPassword: React.FC = () => {
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const idPrefix = 'reset-password';

  const [hidePassword, setHidePassword] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [
    isPasswordResetSuccessModalVisible,
    setIsPasswordResetSuccessModalVisible,
  ] = useState<boolean>(false);

  const form = useForm<FormInputs>({
    resolver: zodResolver(schema),
  });
  const { control, handleSubmit } = form;
  const navigate = useNavigate();

  const submitDetails = async (data: FormInputs) => {
    setLoading(true);
    try {
      await resetPassword({
        token: token as string,
        email: email as string,
        password: data.password,
        password_confirmation: data.confirmPassword,
      });
      setIsPasswordResetSuccessModalVisible(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.status === 422) {
        toastQueue.add(
          {
            title: error.message || 'Something went wrong, Please try again',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
      } else {
        toastQueue.add(
          {
            title: 'Something went wrong, Please try again',
            type: 'error',
          },
          {
            timeout: 2000,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!token || !email) {
      navigate('/auth/login');
    }
  }, [navigate, token, email]);

  return (
    <Box
      display="flex"
      flex="1"
      height="screen"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        display="flex"
        style={{ width: 432 }}
        overflow="hidden"
        paddingRight="0.5"
      >
        <Text variation="h2" color="textHigh">
          CashBook EMS
        </Text>
        <Box
          backgroundColor="surfacePrimary"
          width="4.5"
          rounded="full"
          height="0.5"
          marginTop="3.5"
        />
        <Text variation="h3" color="textHigh" marginTop="3.5">
          Enter New Password
        </Text>
        <Box as="form" onSubmit={handleSubmit(submitDetails)}>
          <Box marginTop="4.5" width="full">
            <FormTextInput
              id={`${idPrefix}-enter-new-password`}
              control={control}
              name="password"
              label="New Password"
              placeholder="Enter new password"
              leftIcon={(props) => <KeyIcon {...props} />}
              type={hidePassword ? 'password' : 'text'}
              rightIcon={(props) =>
                !hidePassword ? (
                  <EyeOffIcon
                    {...props}
                    id={`${idPrefix}-hide`}
                    onClick={() => setHidePassword(true)}
                    cursor="pointer"
                  />
                ) : (
                  <EyeIcon
                    {...props}
                    id={`${idPrefix}-show`}
                    onClick={() => setHidePassword(false)}
                    cursor="pointer"
                  />
                )
              }
              fullWidth
              isRequired
            />
          </Box>
          <Box marginTop="4.5" display="flex">
            <FormTextInput
              id={`${idPrefix}-confirm-new-password`}
              control={control}
              name="confirmPassword"
              label="Confirm new Password"
              placeholder="Confirm new password"
              leftIcon={(props) => <KeyIcon {...props} />}
              type={'password'}
              fullWidth
              isRequired
            />
          </Box>
          <Button
            id={`${idPrefix}-submit`}
            state={loading ? 'loading' : undefined}
            onClick={handleSubmit(submitDetails)}
            size="lg"
            title={'Submit'}
            marginTop="4"
            width="full"
          />
        </Box>
        <InfoDialog
          id={`${idPrefix}-success`}
          isVisible={isPasswordResetSuccessModalVisible}
          title="Confirmation"
          icon={(props) => <CheckCircle {...props} color="iconSuccess" />}
          text="Password has been changed successfully!"
          description="Now you can login with your new password"
          onConfirm={() => {
            setIsPasswordResetSuccessModalVisible(false);
            navigate('/auth/login');
          }}
          confirmLabel="Ok, Got it"
          onClose={() => {
            setIsPasswordResetSuccessModalVisible(false);
          }}
        />
      </Stack>
    </Box>
  );
};

export default ResetPassword;
