import { Box, Inline, Stack, Tabs, Text } from '@nbfc-expense-tool/ui';
import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Item } from 'react-stately';
import InvoiceAnalysis from './InvoiceAnalysis';
import SpendAnalysis from './SpendAnalysis';

const InvoiceTabOptions = [
  {
    title: 'Invoice Analysis',
    path: 'invoice-analysis',
  },
  {
    title: 'Spend Analysis',
    path: 'spend-analysis',
  },
];

export function DashboardLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const activePath = useMemo(() => {
    const activePath = InvoiceTabOptions.find((option) =>
      pathname.includes(option.path)
    );
    return activePath?.path || InvoiceTabOptions[0].path;
  }, [pathname]);

  return (
    <Stack flex="1" paddingX="4" paddingY="5" gap="3">
      <Inline alignItems="center" justifyContent="between">
        <Text variation="h2" as="h2">
          Dashboard
        </Text>
      </Inline>
      <Tabs selectedKey={activePath} onClick={(path: string) => navigate(path)}>
        {InvoiceTabOptions.map((option) => (
          <Item
            key={option.path}
            textValue={option.path}
            title={
              <Box textDecoration="none" style={{ color: 'inherit' }}>
                {option.title}
              </Box>
            }
          >
            <Outlet />
          </Item>
        ))}
      </Tabs>
    </Stack>
  );
}

export { InvoiceAnalysis, SpendAnalysis };
