import {
  ExpenseBreakdownByTypes,
  ExpenseBreakdownRequestPayload,
  SpendStatRequestPayload,
  TypeInvoicesStatPayload,
  getExpenseBreakdownStat,
  getInvoiceApprovalTimeStat,
  getInvoicesPaymentStat,
  getSpendStat,
  getTypedInvoicesStat,
  getWaitingForApprovalStat,
  monthLabels,
} from '@nbfc-expense-tool/data-store/utils';
import {
  getMonthInterval,
  getYearInterval,
} from '@nbfc-expense-tool/util-dates';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';

export type InvoiceApprovalStatResponseType = Array<{
  month: string;
  approval_time: string;
  id: string;
  monthName: string;
  percentageStat?: number;
}>;

type INVOICE_APPROVAL_STAT_TYPES =
  | { type: 'FETCHING_INVOICE_APPROVAL_STAT' }
  | {
      type: 'FETCHED_INVOICE_APPROVAL_STAT';
      payload: InvoiceApprovalStatResponseType;
    }
  | { type: 'FETCHING_INVOICE_APPROVAL_STAT_FAILED'; payload: Error }
  | { type: 'FETCHING_INVOICE_APPROVAL_STAT_WITH_FILTERS' };

type InvoiceApprovalStatState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  approvalStat?: InvoiceApprovalStatResponseType;
  syncingWithFilters?: boolean;
};

const initialInvoiceApprovalStat: InvoiceApprovalStatState = {
  status: 'in_progress',
  error: null,
  approvalStat: [],
  syncingWithFilters: false,
};

const approvalStatReducer = (
  state: InvoiceApprovalStatState,
  action: INVOICE_APPROVAL_STAT_TYPES
): InvoiceApprovalStatState => {
  switch (action.type) {
    case 'FETCHING_INVOICE_APPROVAL_STAT':
      return {
        ...initialInvoiceApprovalStat,
      };
    case 'FETCHING_INVOICE_APPROVAL_STAT_WITH_FILTERS':
      return {
        ...state,
        error: null,
        syncingWithFilters: true,
      };
    case 'FETCHED_INVOICE_APPROVAL_STAT':
      return {
        ...state,
        status: 'success',
        error: null,
        approvalStat: action.payload,
        syncingWithFilters: false,
      };
    case 'FETCHING_INVOICE_APPROVAL_STAT_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        approvalStat: [],
        syncingWithFilters: false,
      };
    default:
      return state;
  }
};

export type DateFilter = {
  dates: [Date, Date];
};

export type OwnInvoicesOverviewFilters = {
  dateFilter?: DateFilter;
  branches?: string[];
};

export function useInvoicesApprovalTimeStat() {
  const [shouldCallApi, setShouldCallApi] = useState<boolean>(true);
  const [state, dispatch] = useReducer(
    approvalStatReducer,
    initialInvoiceApprovalStat
  );
  const initialFilters: OwnInvoicesOverviewFilters = useMemo(() => {
    return {
      dateFilter: { dates: getYearInterval() },
    };
  }, []);

  const { getValues, setValue } = useForm<OwnInvoicesOverviewFilters>({
    defaultValues: initialFilters,
  });

  const params = getValues();

  const fetchInvoicesApprovalTimeCount = useCallback(async () => {
    try {
      const { dateFilter, branches } = params;
      const constructedPayload: TypeInvoicesStatPayload = {};
      if (dateFilter?.dates) {
        constructedPayload['invoice_date_after'] =
          dateFilter.dates[0].toISOString();
        constructedPayload['invoice_date_before'] =
          dateFilter.dates[1].toISOString();
      }
      if (branches?.length) {
        constructedPayload['branch_id'] = branches;
      }

      const response =
        await getInvoiceApprovalTimeStat<InvoiceApprovalStatResponseType>(
          constructedPayload
        );
      const updatedData = monthLabels.map((data) => {
        const approval_time = response.find(
          (avgTime) => data.id === avgTime.month
        )?.approval_time;
        return {
          ...data,
          approval_time: approval_time || '0',
        };
      });
      const dataWithStats = updatedData.map((data, index, array) => {
        let percentage;
        if (index < 1) {
          percentage = 0;
        } else {
          const previousMonthTime = Number(array[index - 1].approval_time);
          percentage =
            ((Number(data.approval_time) - previousMonthTime) /
              previousMonthTime) *
              100 || 0;
        }
        return {
          ...data,
          percentageStat: percentage,
        };
      });

      dispatch({
        type: 'FETCHED_INVOICE_APPROVAL_STAT',
        payload: dataWithStats,
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_INVOICE_APPROVAL_STAT_FAILED',
        payload: e as Error,
      });
    }
  }, [params]);

  useEffect(() => {
    if (shouldCallApi) {
      fetchInvoicesApprovalTimeCount();
      setShouldCallApi(false);
    }
  }, [shouldCallApi, fetchInvoicesApprovalTimeCount]);

  function handleDateChange(values: DateFilter) {
    setShouldCallApi(true);
    setValue('dateFilter', values);
    dispatch({ type: 'FETCHING_INVOICE_APPROVAL_STAT_WITH_FILTERS' });
  }

  function handleParamChange(
    key: keyof OwnInvoicesOverviewFilters,
    value?: DateFilter | string[] | undefined
  ) {
    setShouldCallApi(true);
    setValue(key, value);
    dispatch({ type: 'FETCHING_INVOICE_APPROVAL_STAT_WITH_FILTERS' });
  }

  const hasAppliedFilters = useMemo(() => {
    let isFilterApplied = false;
    if (params.branches?.length && !initialFilters.branches?.length) {
      isFilterApplied = true;
      return isFilterApplied;
    }
    return isFilterApplied;
  }, [params, initialFilters]);

  return {
    error: state.error,
    status: state.status,
    approvalStats: state.approvalStat,
    handleDateChange,
    handleParamChange,
    hasAppliedFilters,
    params,
    syncingData: state.syncingWithFilters,
  };
}

export type DashboardInvoiceStatType = Array<{
  total_count: number;
  total_amount: string;
  invoice_status: string;
  payment_status: string;
}>;

type TypeOfInvoicesCountResponse = {
  data: DashboardInvoiceStatType;
};

type INVOICES_OVERVIEW_TYPES =
  | { type: 'FETCHING_DASHBOARD_INVOICE_OVERVIEW' }
  | {
      type: 'FETCHED_DASHBOARD_INVOICE_OVERVIEW';
      payload: DashboardInvoiceStatType;
    }
  | { type: 'FETCHING_DASHBOARD_INVOICE_OVERVIEW_FAILED'; payload: Error }
  | { type: 'FETCHING_DASHBOARD_INVOICE_OVERVIEW_WITH_FILTERS' };

type InvoiceOverviewState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  overviewStats?: DashboardInvoiceStatType;
  syncingWithFilters?: boolean;
};

const initialInvoiceOverview: InvoiceOverviewState = {
  status: 'in_progress',
  error: null,
  overviewStats: [],
  syncingWithFilters: false,
};

const overviewReducer = (
  state: InvoiceOverviewState,
  action: INVOICES_OVERVIEW_TYPES
): InvoiceOverviewState => {
  switch (action.type) {
    case 'FETCHING_DASHBOARD_INVOICE_OVERVIEW':
      return {
        ...initialInvoiceOverview,
      };
    case 'FETCHING_DASHBOARD_INVOICE_OVERVIEW_WITH_FILTERS':
      return {
        ...state,
        error: null,
        syncingWithFilters: true,
      };
    case 'FETCHED_DASHBOARD_INVOICE_OVERVIEW':
      return {
        ...state,
        status: 'success',
        error: null,
        overviewStats: action.payload,
        syncingWithFilters: false,
      };
    case 'FETCHING_DASHBOARD_INVOICE_OVERVIEW_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        overviewStats: [],
        syncingWithFilters: false,
      };
    default:
      return state;
  }
};

export function useDashboardInvoicesOverview() {
  const [shouldCallApi, setShouldCallApi] = useState<boolean>(true);
  const [state, dispatch] = useReducer(overviewReducer, initialInvoiceOverview);
  const initialFilters: OwnInvoicesOverviewFilters = useMemo(() => {
    return {
      dateFilter: { dates: getMonthInterval() },
    };
  }, []);

  const { getValues, setValue } = useForm<OwnInvoicesOverviewFilters>({
    defaultValues: initialFilters,
  });

  const params = getValues();

  const fetchDashboardInvoicesCount = useCallback(async () => {
    const { dateFilter, branches } = params;
    const constructedPayload: TypeInvoicesStatPayload = {};
    if (dateFilter?.dates) {
      constructedPayload['invoice_date_after'] =
        dateFilter.dates[0].toISOString();
      constructedPayload['invoice_date_before'] =
        dateFilter.dates[1].toISOString();
    }
    if (branches?.length) {
      constructedPayload['branch_id'] = branches;
    }

    const fetchStats = [
      getTypedInvoicesStat<TypeOfInvoicesCountResponse>(constructedPayload),
      getInvoicesPaymentStat<TypeOfInvoicesCountResponse>(constructedPayload),
    ];
    Promise.all(fetchStats)
      .then((responses) => {
        const dataPayload: DashboardInvoiceStatType = [];
        responses.forEach((response) => {
          const { data } = response;
          dataPayload.push(...data);
        });
        dispatch({
          type: 'FETCHED_DASHBOARD_INVOICE_OVERVIEW',
          payload: dataPayload,
        });
      })
      .catch((e) => {
        dispatch({
          type: 'FETCHING_DASHBOARD_INVOICE_OVERVIEW_FAILED',
          payload: e as Error,
        });
      });
  }, [params]);

  useEffect(() => {
    if (shouldCallApi) {
      fetchDashboardInvoicesCount();
      setShouldCallApi(false);
    }
  }, [shouldCallApi, fetchDashboardInvoicesCount]);

  function handleDateChange(values: DateFilter) {
    setShouldCallApi(true);
    setValue('dateFilter', values);
    dispatch({ type: 'FETCHING_DASHBOARD_INVOICE_OVERVIEW_WITH_FILTERS' });
  }

  function handleParamChange(
    key: keyof OwnInvoicesOverviewFilters,
    value?: DateFilter | string[] | undefined
  ) {
    setShouldCallApi(true);
    setValue(key, value);
    dispatch({ type: 'FETCHING_DASHBOARD_INVOICE_OVERVIEW_WITH_FILTERS' });
  }

  const hasAppliedFilters = useMemo(() => {
    let isFilterApplied = false;
    if (params.branches?.length && !initialFilters.branches?.length) {
      isFilterApplied = true;
      return isFilterApplied;
    }
    return isFilterApplied;
  }, [params, initialFilters]);

  return {
    error: state.error,
    status: state.status,
    overview: state.overviewStats,
    handleDateChange,
    handleParamChange,
    hasAppliedFilters,
    params,
    syncingData: state.syncingWithFilters,
  };
}

export type WaitingStatType = {
  invoices_count?: number;
  vendors_count?: number;
};

type WAITING_STAT_TYPES =
  | { type: 'FETCHING_WAITING_STAT' }
  | {
      type: 'FETCHED_WAITING_STAT';
      payload: WaitingStatType;
    }
  | { type: 'FETCHING_WAITING_STAT_FAILED'; payload: Error };

type WaitingStatState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  waitingStats?: WaitingStatType;
};

const initialWaitingStat: WaitingStatState = {
  status: 'in_progress',
  error: null,
  waitingStats: {},
};

const waitingStatReducer = (
  state: WaitingStatState,
  action: WAITING_STAT_TYPES
): WaitingStatState => {
  switch (action.type) {
    case 'FETCHING_WAITING_STAT':
      return {
        ...initialInvoiceOverview,
      };
    case 'FETCHED_WAITING_STAT':
      return {
        ...state,
        status: 'success',
        error: null,
        waitingStats: action.payload,
      };
    case 'FETCHING_WAITING_STAT_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        waitingStats: {},
      };
    default:
      return state;
  }
};

export function useDashboardWaitingStat() {
  const [shouldCallApi, setShouldCallApi] = useState<boolean>(true);
  const [state, dispatch] = useReducer(waitingStatReducer, initialWaitingStat);

  const fetchWaitingStatsCount = useCallback(async () => {
    try {
      const response = await getWaitingForApprovalStat<WaitingStatType>({});
      dispatch({ type: 'FETCHED_WAITING_STAT', payload: response });
    } catch (e) {
      dispatch({
        type: 'FETCHING_WAITING_STAT_FAILED',
        payload: e as Error,
      });
    }
  }, []);

  useEffect(() => {
    if (shouldCallApi) {
      fetchWaitingStatsCount();
      setShouldCallApi(false);
    }
  }, [shouldCallApi, fetchWaitingStatsCount]);

  return {
    error: state.error,
    status: state.status,
    waitingStats: state.waitingStats,
  };
}

export type SpendStatResponseType = Array<{
  month: string;
  total: string;
  id: string;
  monthName: string;
  percentageStat?: number;
}>;

type SPEND_STAT_TYPES =
  | { type: 'FETCHING_SPEND_STAT' }
  | {
      type: 'FETCHED_SPEND_STAT';
      payload: SpendStatResponseType;
    }
  | { type: 'FETCHING_SPEND_STAT_FAILED'; payload: Error }
  | { type: 'FETCHING_SPEND_STAT_WITH_FILTERS' };

type SpendStatState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  spendStats?: SpendStatResponseType;
  syncingWithFilters?: boolean;
};

const initialSpendStat: SpendStatState = {
  status: 'in_progress',
  error: null,
  spendStats: [],
  syncingWithFilters: false,
};

const spendStatReducer = (
  state: SpendStatState,
  action: SPEND_STAT_TYPES
): SpendStatState => {
  switch (action.type) {
    case 'FETCHING_SPEND_STAT':
      return {
        ...initialSpendStat,
      };
    case 'FETCHING_SPEND_STAT_WITH_FILTERS':
      return {
        ...state,
        error: null,
        syncingWithFilters: true,
      };
    case 'FETCHED_SPEND_STAT':
      return {
        ...state,
        status: 'success',
        error: null,
        spendStats: action.payload,
        syncingWithFilters: false,
      };
    case 'FETCHING_SPEND_STAT_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        spendStats: [],
        syncingWithFilters: false,
      };
    default:
      return state;
  }
};

export type OwnSpendFilters = {
  dateFilter?: DateFilter;
  branches?: string[];
  vendors?: string[];
  expenseHeads?: string[];
  expenseParticular?: string[];
};

export function useSpendStat() {
  const [shouldCallApi, setShouldCallApi] = useState<boolean>(true);
  const [state, dispatch] = useReducer(spendStatReducer, initialSpendStat);
  const initialFilters: OwnSpendFilters = useMemo(() => {
    return {
      dateFilter: { dates: getYearInterval() },
    };
  }, []);

  const { getValues, setValue } = useForm<OwnSpendFilters>({
    defaultValues: initialFilters,
  });

  const params = getValues();

  const fetchSpendCount = useCallback(async () => {
    try {
      const { dateFilter, branches, vendors } = params;
      const constructedPayload: SpendStatRequestPayload = {};
      if (dateFilter?.dates) {
        constructedPayload['paid_at_after'] = dateFilter.dates[0].toISOString();
        constructedPayload['paid_at_before'] =
          dateFilter.dates[1].toISOString();
      }
      if (branches?.length) {
        constructedPayload['branch_id'] = branches;
      }
      if (vendors?.length) {
        constructedPayload['vendor_id'] = vendors;
      }

      const response = await getSpendStat<SpendStatResponseType>(
        constructedPayload
      );
      const updatedData = monthLabels.map((data) => {
        const total_spent = response.find(
          (avgTime) => data.id === avgTime.month
        )?.total;
        return {
          ...data,
          total: total_spent || '0',
        };
      });
      const dataWithStats = updatedData.map((data, index, array) => {
        let percentage;
        if (index < 1) {
          percentage = 0;
        } else {
          const previousMonthTime = Number(array[index - 1].total);
          percentage =
            ((Number(data.total) - previousMonthTime) / previousMonthTime) *
              100 || 0;
        }
        return {
          ...data,
          percentageStat: percentage,
        };
      });

      dispatch({
        type: 'FETCHED_SPEND_STAT',
        payload: dataWithStats,
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_SPEND_STAT_FAILED',
        payload: e as Error,
      });
    }
  }, [params]);

  useEffect(() => {
    if (shouldCallApi) {
      fetchSpendCount();
      setShouldCallApi(false);
    }
  }, [shouldCallApi, fetchSpendCount]);

  function handleDateChange(values: DateFilter) {
    setShouldCallApi(true);
    setValue('dateFilter', values);
    dispatch({ type: 'FETCHING_SPEND_STAT_WITH_FILTERS' });
  }

  function handleParamChange(
    key: keyof OwnSpendFilters,
    value?: DateFilter | string[] | undefined
  ) {
    setShouldCallApi(true);
    setValue(key, value);
    dispatch({ type: 'FETCHING_SPEND_STAT_WITH_FILTERS' });
  }

  const hasAppliedFilters = useMemo(() => {
    let isFilterApplied = false;
    if (params.branches?.length && !initialFilters.branches?.length) {
      isFilterApplied = true;
      return isFilterApplied;
    }
    return isFilterApplied;
  }, [params, initialFilters]);

  return {
    error: state.error,
    status: state.status,
    spendStats: state.spendStats,
    handleDateChange,
    handleParamChange,
    hasAppliedFilters,
    params,
    syncingData: state.syncingWithFilters,
  };
}

export type ExpenseBreakdownStatResponseType = Array<{
  total: string;
  name: string;
  percentage: string;
}>;

type TypeOfExpenseBreakdownResponse = {
  data: ExpenseBreakdownStatResponseType;
  total?: string;
  graphData?: ExpenseBreakdownStatResponseType;
};

type EXPENSE_BREAKDOWN_STAT_TYPES =
  | { type: 'FETCHING_EXPENSE_BREAKDOWN_STAT' }
  | {
      type: 'FETCHED_EXPENSE_BREAKDOWN_STAT';
      payload: TypeOfExpenseBreakdownResponse;
    }
  | { type: 'FETCHING_EXPENSE_BREAKDOWN_STAT_FAILED'; payload: Error }
  | { type: 'FETCHING_EXPENSE_BREAKDOWN_STAT_WITH_FILTERS' };

type ExpenseBreakdownState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  expenseBreakdownStats?: ExpenseBreakdownStatResponseType;
  expenseBreakdownGraphData?: ExpenseBreakdownStatResponseType;
  expenseBreakdownTotal?: string;
  syncingWithFilters?: boolean;
};

const initialExpenseBreakdownStat: ExpenseBreakdownState = {
  status: 'in_progress',
  error: null,
  expenseBreakdownStats: [],
  expenseBreakdownGraphData: [],
  syncingWithFilters: false,
  expenseBreakdownTotal: '',
};

const expenseBreakdownStatReducer = (
  state: ExpenseBreakdownState,
  action: EXPENSE_BREAKDOWN_STAT_TYPES
): ExpenseBreakdownState => {
  switch (action.type) {
    case 'FETCHING_EXPENSE_BREAKDOWN_STAT':
      return {
        ...initialExpenseBreakdownStat,
      };
    case 'FETCHING_EXPENSE_BREAKDOWN_STAT_WITH_FILTERS':
      return {
        ...state,
        error: null,
        syncingWithFilters: true,
      };
    case 'FETCHED_EXPENSE_BREAKDOWN_STAT':
      return {
        ...state,
        status: 'success',
        error: null,
        expenseBreakdownStats: action.payload.data,
        expenseBreakdownGraphData: action.payload.graphData,
        expenseBreakdownTotal: action.payload.total,
        syncingWithFilters: false,
      };
    case 'FETCHING_EXPENSE_BREAKDOWN_STAT_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        expenseBreakdownStats: [],
        expenseBreakdownGraphData: [],
        expenseBreakdownTotal: '',
        syncingWithFilters: false,
      };
    default:
      return state;
  }
};

export type OwnExpenseBreakdownFilters = {
  dateFilter: DateFilter;
  by?: ExpenseBreakdownByTypes;
};

export function useExpenseBreakdownStat() {
  const [shouldCallApi, setShouldCallApi] = useState<boolean>(true);
  const [state, dispatch] = useReducer(
    expenseBreakdownStatReducer,
    initialExpenseBreakdownStat
  );
  const initialFilters: OwnExpenseBreakdownFilters = useMemo(() => {
    return {
      dateFilter: { dates: getMonthInterval() },
      by: 'head',
    };
  }, []);

  const { getValues, setValue } = useForm<OwnExpenseBreakdownFilters>({
    defaultValues: initialFilters,
  });

  const params = getValues();

  const fetchExpenseBreakdown = useCallback(async () => {
    try {
      const { dateFilter, by } = params;
      const constructedPayload: ExpenseBreakdownRequestPayload = {};
      if (dateFilter?.dates) {
        constructedPayload['paid_at_after'] = dateFilter.dates[0].toISOString();
        constructedPayload['paid_at_before'] =
          dateFilter.dates[1].toISOString();
      }
      constructedPayload['by'] = by;

      const response =
        await getExpenseBreakdownStat<TypeOfExpenseBreakdownResponse>(
          constructedPayload
        );
      const isdataLengthMoreThanSix = response.data.length > 6;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const structuredData = response?.data?.map((dataObject: any) => {
        const percentage =
          (Number(dataObject.total) / Number(response.total)) * 100;
        const byName = by || 'head';
        return {
          total: dataObject.total,
          name: dataObject[byName]['name'] as string,
          percentage: percentage.toString(),
        };
      });
      let graphData;
      if (isdataLengthMoreThanSix) {
        let totalOfMaxFiveData = 0;
        graphData = structuredData.filter((dataObject, index) => {
          if (index < 5) {
            totalOfMaxFiveData = totalOfMaxFiveData + Number(dataObject.total);
          }
          return index < 5;
        });
        const balTotal = Number(response.total) - totalOfMaxFiveData;
        graphData.push({
          total: balTotal.toString(),
          name: 'Others',
          percentage: ((balTotal / Number(response.total)) * 100).toString(),
        });
      }
      dispatch({
        type: 'FETCHED_EXPENSE_BREAKDOWN_STAT',
        payload: {
          data: structuredData,
          total: response.total,
          graphData: graphData || structuredData,
        },
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_EXPENSE_BREAKDOWN_STAT_FAILED',
        payload: e as Error,
      });
    }
  }, [params]);

  useEffect(() => {
    if (shouldCallApi) {
      fetchExpenseBreakdown();
      setShouldCallApi(false);
    }
  }, [shouldCallApi, fetchExpenseBreakdown]);

  function handleDateChange(values: DateFilter) {
    setShouldCallApi(true);
    setValue('dateFilter', values);
    dispatch({ type: 'FETCHING_EXPENSE_BREAKDOWN_STAT_WITH_FILTERS' });
  }

  function handleParamChange(
    key: keyof OwnExpenseBreakdownFilters,
    value?: ExpenseBreakdownByTypes
  ) {
    setShouldCallApi(true);
    setValue(key, value);
    dispatch({ type: 'FETCHING_EXPENSE_BREAKDOWN_STAT_WITH_FILTERS' });
  }

  return {
    error: state.error,
    status: state.status,
    expenseBreakdownStats: state.expenseBreakdownStats,
    expenseBreakdownTotal: state.expenseBreakdownTotal,
    expenseBreakdownGraphStats: state.expenseBreakdownGraphData,
    handleDateChange,
    handleParamChange,
    params,
    syncingData: state.syncingWithFilters,
  };
}
