import { forwardRef, useState } from 'react';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';
import { Box } from '../box/box';
import { Text } from '../text/text';
import { CheckIcon } from '../icons/icons';

const DefaultTabsElement = 'div';

type StepperTabsOwnProps = {
  defaultValue?: number | string;
  completed?: Array<number | string>;
  items: Array<{
    title: string;
    value: number | string;
    stepIndicator?: React.ReactNode;
  }>;
  orientation?: 'horizontal' | 'vertical';
  removeConnector?: boolean;
  onChange?: (value: number | string) => void;
};

export type StepperProps<
  C extends React.ElementType = typeof DefaultTabsElement
> = PolymorphicComponentPropWithRef<C, StepperTabsOwnProps>;

type StepperComponent = <
  C extends React.ElementType = typeof DefaultTabsElement
>(
  props: StepperProps<C>
) => React.ReactNode;

export const Stepper: StepperComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultTabsElement>(
    {
      items,
      completed,
      defaultValue,
      orientation,
      removeConnector,
      onChange,
    }: StepperProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const [activeStep, setActiveStep] = useState<number | string>(
      defaultValue || items[0].value
    );

    const isVertical = orientation === 'vertical';

    return (
      <Box
        gap="1"
        ref={ref}
        display="flex"
        as={DefaultTabsElement}
        flexDirection={isVertical ? 'col' : 'row'}
      >
        {items.map((step, index) => {
          const isActiveStep = step.value === activeStep;
          const isCompleted = completed?.includes(step.value);
          return (
            <Box
              key={step.value}
              gap="1"
              display="flex"
              flexDirection={isVertical ? 'col' : 'row'}
              alignItems={!isVertical ? 'center' : 'start'}
            >
              <Box
                gap="2"
                as="button"
                minWidth="max"
                cursor="pointer"
                display="flex"
                paddingX="1.5"
                paddingY="1"
                rounded="lg"
                type="button"
                alignItems="center"
                onClick={() => {
                  onChange?.(step.value);
                  setActiveStep(step.value);
                }}
                backgroundColor={
                  isActiveStep && isCompleted
                    ? 'surfaceSuccessLowest'
                    : isActiveStep
                    ? 'borderPrimaryLowest'
                    : 'transparent'
                }
              >
                {step.stepIndicator ? (
                  step.stepIndicator
                ) : (
                  <StepperIndicator
                    backgroundColor={
                      isCompleted
                        ? 'surfaceSuccess'
                        : isActiveStep
                        ? 'surfacePrimary'
                        : 'surfaceNeutralLowest'
                    }
                  >
                    {isCompleted ? (
                      <CheckIcon size="2" color="iconOnSurface" />
                    ) : (
                      <Text
                        variation="c1"
                        color={
                          !isCompleted && !isActiveStep
                            ? 'textMedium'
                            : 'textOnSurface'
                        }
                      >
                        {index + 1}
                      </Text>
                    )}
                  </StepperIndicator>
                )}
                <Text
                  variation={isActiveStep ? 'c1' : 'c2'}
                  color={
                    isActiveStep && isCompleted
                      ? 'textSuccess'
                      : isActiveStep
                      ? 'textPrimary'
                      : 'textMedium'
                  }
                >
                  {step.title}
                </Text>
              </Box>

              {index + 1 === items.length || removeConnector ? null : (
                <StepConnector isVertical={isVertical} />
              )}
            </Box>
          );
        })}
      </Box>
    );
  }
);

function StepConnector({ isVertical }: { isVertical: boolean }) {
  return isVertical ? (
    <Box
      width="3"
      display="flex"
      marginLeft="1.5"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        as="hr"
        backgroundColor="borderOutline"
        style={{
          height: 48,
          width: 2,
        }}
      />
    </Box>
  ) : (
    <Box
      as="hr"
      backgroundColor="borderOutline"
      style={{
        height: 2,
        width: 48,
      }}
    />
  );
}

export function StepperIndicator({
  children,
  backgroundColor,
}: {
  children?: React.ReactNode;
  backgroundColor?: React.ComponentProps<typeof Box>['backgroundColor'];
}) {
  return (
    <Box
      size={'3'}
      rounded="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor={backgroundColor || 'surfaceNeutralLowest'}
    >
      {children}
    </Box>
  );
}
