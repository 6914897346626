/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DateFilter,
  ExpenseBreakdownStatResponseType,
  OwnExpenseBreakdownFilters,
  useBranchesForFilter,
  useExpenseBreakdownStat,
  useSpendStat,
  useVendorsForFilter,
} from '@nbfc-expense-tool/data-store/dashboard';
import {
  ExpenseBreakdownByTypes,
  useDownloadHTMLAsImage,
} from '@nbfc-expense-tool/data-store/utils';
import {
  Amount,
  Box,
  Button,
  COLORS,
  DownloadIcon,
  EmptyState,
  HoverableBox,
  Inline,
  MultiSelect,
  RefreshIcon,
  RightCaratIcon,
  SkeletonRows,
  SpinnerIcon,
  Stack,
  Text,
} from '@nbfc-expense-tool/ui';
import {
  getFinancialQuarter,
  getMonthInterval,
  getQuarterInterval,
  getYearInterval,
} from '@nbfc-expense-tool/util-dates';
import { add, addQuarters, format, sub, subQuarters } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';

const breakdownTypes: { id: ExpenseBreakdownByTypes; label: string }[] = [
  {
    id: 'head',
    label: 'Expense Heads',
  },
  {
    id: 'particular',
    label: 'Particulars',
  },
  {
    id: 'branch',
    label: 'Branches',
  },
  {
    id: 'vendor',
    label: 'Vendors',
  },
];

const options = {
  parsing: {
    xAxisKey: 'month',
    yAxisKey: 'total',
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      border: { dash: [4, 4], color: COLORS.borderSeparator }, // for the grid lines
      grid: {
        color: COLORS.borderErrorLow,
        drawTicks: false,
      },
      ticks: {
        padding: 12,
        callback: function (value: any) {
          const val = Math.abs(value);
          if (val >= 10000000) return `${(value / 10000000).toFixed(2)} Cr`;
          if (val >= 100000) return `${(value / 100000).toFixed(2)} L`;
          return value;
        },
      },
    },
  },
  hoverRadius: 8,
  responsive: true,
  plugins: {
    tooltip: {
      enabled: false,
      displayColors: false,
      padding: 12,
      titleColor: COLORS.textLow,
      bodyColor: COLORS.textHigh,
      footerColor: COLORS.textSuccess,
      callbacks: {
        title: function (context: any) {
          const { raw } = context[0];
          return `${raw['monthName']} Spend`;
        },
        label: function (context: any) {
          const { formattedValue } = context;
          return `₹ ${formattedValue}`;
        },
        footer: function (context: any) {
          const {
            raw,
            dataIndex,
            dataset: { data },
          } = context[0];
          const percentage = raw['percentageStat'];
          if (dataIndex === 0) {
            return ``;
          } else {
            if (percentage === 0) {
              return `Equals ${data[dataIndex - 1].month}`;
            } else if (percentage === Infinity) {
              return `N/A`;
            } else if (percentage > 0) {
              return `${percentage}% greater than ${data[dataIndex - 1].month}`;
            } else {
              return `${percentage}% lesser than ${data[dataIndex - 1].month}`;
            }
          }
        },
      },
      external: function (context: any) {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        const tooltipModel = context.tooltip;

        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem: any) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);
          const footerLines = tooltipModel.footer || [];

          let innerHtml = '<thead>';

          titleLines.forEach(function (title: any) {
            let titleStyle = 'color:' + COLORS.textLow;
            titleStyle +=
              "; font-family: 'Inter', sans-serif; font-size: 12px; font-weight: 400; line-height: 16px; letter-spacing: 0.4px; font-style: normal; text-align: left";
            innerHtml +=
              '<tr><th style="' + titleStyle + '">' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';

          bodyLines.forEach(function (body: any) {
            let style = 'color:' + COLORS.textHigh;
            style +=
              "; font-family: 'Inter', sans-serif; font-size: 16px; font-weight: 500; line-height: 28px; letter-spacing: 0.25px; font-style: normal";
            const span = '<span style="' + style + '">' + body + '</span>';
            innerHtml += '<tr><td>' + span + '</td></tr>';
          });

          footerLines.forEach(function (body: any) {
            const textColor = footerLines[0].includes('Equals')
              ? COLORS.textPrimary
              : footerLines[0].includes('lesser')
              ? COLORS.textError
              : footerLines[0].includes('greater')
              ? COLORS.textSuccess
              : COLORS.textLow;
            const backgroundColor = footerLines[0].includes('Equals')
              ? COLORS.surfacePrimaryLowest
              : footerLines[0].includes('lesser')
              ? COLORS.surfaceErrorLowest
              : footerLines[0].includes('greater')
              ? COLORS.surfaceSuccessLowest
              : COLORS.surfaceDefault;
            let style = 'color:' + textColor;
            style += '; background: ' + backgroundColor;
            style +=
              "; font-family: 'Inter'; font-size: 10px; font-weight: 500; line-height: 12px; letter-spacing: 0.4px; font-style: normal; padding: 2px 4px; border-radius: 4px;";
            const span = '<span style="' + style + '">' + body + '</span>';
            innerHtml += '<tr><td>' + span + '</td></tr>';
          });

          innerHtml += '</tbody>';

          const tableRoot = tooltipEl.querySelector(
            'table'
          ) as HTMLTableElement;
          tableRoot.innerHTML = innerHtml;
        }

        const position = context.chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.transform = 'translate(-50%, -120%)';
        tooltipEl.style.border = '1px solid ' + COLORS.borderSeparator;
        tooltipEl.style.borderRadius = '8px';
        tooltipEl.style.background = COLORS.surfaceDefault;
        tooltipEl.style.left =
          position.left + window.scrollX + tooltipModel.caretX + 'px';
        tooltipEl.style.top =
          position.top + window.scrollY + tooltipModel.caretY + 'px';
        tooltipEl.style.padding = '8px 16px 12px 16px';
        tooltipEl.style.boxShadow = '0px 4px 10px 0px rgba(0, 0, 0, 0.06)';
        tooltipEl.style.pointerEvents = 'none';
      },
    },
  },
};

export const rightLabels = {
  id: 'rightLabels',
  afterDatasetsDraw(chart: any, args: any, pluginOptions: any) {
    if (pluginOptions.display) {
      const {
        ctx,
        scales: { y },
        config,
      } = chart;

      const datasets = config.data.datasets;

      ctx.font = ' 12px sans-serif';
      ctx.fillStyle = COLORS.textMedium;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      chart.getDatasetMeta(0).data.forEach(function (p: any, j: any) {
        ctx.fillText(
          '₹' + Number(datasets[0].data[j].total).toFixed(2),
          p.x + 42,
          y.getPixelForValue(j)
        );
      });
    }
  },
};

export default function SpendAnalysis() {
  const { download } = useDownloadHTMLAsImage();
  const idPrefix = 'spend-analysis';
  const {
    status: spendStatStatus,
    spendStats,
    handleParamChange: handleSpendStatsParamChange,
    params: spendStatParams,
    handleDateChange: handleSpendStatsDateChange,
  } = useSpendStat();
  const data = {
    labels: [],
    datasets: [
      {
        label: 'Invoice Approval Analysis',
        data: spendStats,
        fill: true,
        borderColor: COLORS.surfaceError,
        pointBackgroundColor: COLORS.surfaceError,
        backgroundColor: (context: any) => {
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, 0, 0, bottom);
          gradientBg.addColorStop(0, '#FCE2E2');
          gradientBg.addColorStop(1, '#F4F4F4');
          return gradientBg;
        },
      },
    ],
  };

  const {
    status: expenseBreakdownStatus,
    expenseBreakdownStats,
    expenseBreakdownGraphStats,
    expenseBreakdownTotal,
    handleParamChange: handleExpenseBreakdownParamChange,
    params: expenseBreakdownParams,
    handleDateChange: handleExpenseBreakdownDateChange,
    syncingData: syncingExpenseBreakdownData,
  } = useExpenseBreakdownStat();

  const { status, branches } = useBranchesForFilter();
  const branchForFilters = useMemo(() => {
    return branches?.map((b) => {
      return {
        label: `${b.name} - ${b.branch_code}`,
        value: `${b.id}`,
      };
    });
  }, [branches]);
  const { status: vendorStatus, vendors } = useVendorsForFilter();
  const vendorForFilters = useMemo(() => {
    return vendors?.map((b) => {
      return {
        label: b.name,
        value: `${b.id}`,
      };
    });
  }, [vendors]);
  const [spendStatDate, setspendStatDate] = useState<Date>(new Date());

  const onChangeYear = useCallback(
    (type: 'next' | 'prev') => {
      let updatedDate;
      if (type === 'next') {
        updatedDate = add(spendStatDate, { years: 1 });
      } else {
        updatedDate = sub(spendStatDate, { years: 1 });
      }
      const values = getYearInterval(updatedDate);
      handleSpendStatsDateChange({ dates: values });
      setspendStatDate(updatedDate);
    },
    [spendStatDate, handleSpendStatsDateChange]
  );

  const isLoading = useMemo(() => {
    return (
      spendStatStatus === 'in_progress' ||
      expenseBreakdownStatus === 'in_progress'
    );
  }, [spendStatStatus, expenseBreakdownStatus]);

  const errorOccured = useMemo(() => {
    return (spendStatStatus || expenseBreakdownStatus) === 'failed';
  }, [spendStatStatus, expenseBreakdownStatus]);

  return isLoading || errorOccured ? (
    <Stack paddingTop="10" alignItems="center" justifyContent="center">
      {isLoading ? (
        <Inline gap="4">
          <SpinnerIcon size="3" color="iconMedium" />
          <Text variation="b2">Loading...</Text>
        </Inline>
      ) : (
        <EmptyState
          title="Temporarily Data Unavailable"
          subText="Try to refresh the web page"
          renderButton={() => (
            <Button
              id={`${idPrefix}-refresh-button`}
              title="Refresh"
              leftIcon={() => <RefreshIcon fill="iconOnSurface" />}
              onClick={() => window.location.reload()}
            />
          )}
        />
      )}
    </Stack>
  ) : (
    <Stack paddingTop={'4'} style={{ width: '75%' }}>
      <ExpenseBreakdownView
        total={expenseBreakdownTotal || '0'}
        tableData={expenseBreakdownStats}
        graphData={expenseBreakdownGraphStats}
        handleParamChange={handleExpenseBreakdownParamChange}
        params={expenseBreakdownParams}
        status={expenseBreakdownStatus}
        syncingData={syncingExpenseBreakdownData}
        handleDateChange={handleExpenseBreakdownDateChange}
      />
      <Stack
        paddingX={'4'}
        paddingY={'3'}
        bgColor={'surfaceDefault'}
        borderRadius="lg"
        borderWidth="1"
        borderColor="borderSeparator"
      >
        <Inline
          marginBottom={'4'}
          justifyContent={'between'}
          alignItems={'center'}
        >
          <Text variation={'t3'}>Spend Trend</Text>
          <Inline alignItems={'center'} justifyContent={'between'} gap={'3'}>
            <Inline
              gap="2"
              alignItems={'center'}
              borderRadius="md"
              borderWidth="1"
              borderColor="borderSeparator"
              paddingX={'1.5'}
              paddingY={'0.5'}
            >
              <Box
                id={`${idPrefix}-trend-change-previous-year`}
                as="button"
                backgroundColor="transparent"
                onClick={() => onChangeYear('prev')}
              >
                <RightCaratIcon
                  cursor="pointer"
                  size="2.5"
                  rotate="180"
                  color={'iconLow'}
                />
              </Box>
              <Text color={'textHigh'} variation={'c2'}>
                {'FY: ' + format(spendStatDate, 'yyyy')}
              </Text>
              <Box
                id={`${idPrefix}-trend-change-next-year`}
                as="button"
                backgroundColor="transparent"
                onClick={() => onChangeYear('next')}
              >
                <RightCaratIcon cursor="pointer" size="2.5" color={'iconLow'} />
              </Box>
            </Inline>
            <Box
              id={`${idPrefix}-trend-download-graph`}
              cursor="pointer"
              onClick={() =>
                download(
                  document.getElementById('spendTrendAnalysis') as HTMLElement,
                  `Spend_trend_analysis_${format(new Date(), 'dd-mm-yy')}`
                )
              }
            >
              <DownloadIcon color={'textLow'} size={'3'} />
            </Box>
          </Inline>
        </Inline>
        <Inline marginBottom={'4'} gap={'4'}>
          <MultiSelect
            id={`${idPrefix}-trend-select-branches-filter`}
            label="Branch: All"
            actionBtnTitle="Show Results"
            value={spendStatParams.branches}
            options={branchForFilters}
            loadingOptions={status === 'in_progress'}
            onSave={(values) => {
              handleSpendStatsParamChange('branches', values);
            }}
            onCancel={() => {
              handleSpendStatsParamChange('branches', []);
            }}
            cancelBtnTitle="Reset"
          />
          <MultiSelect
            id={`${idPrefix}-trend-select-vendors-filter`}
            label="Vendor: All"
            actionBtnTitle="Show Results"
            value={spendStatParams.vendors}
            options={vendorForFilters}
            loadingOptions={vendorStatus === 'in_progress'}
            onSave={(values) => {
              handleSpendStatsParamChange('vendors', values);
            }}
            onCancel={() => {
              handleSpendStatsParamChange('vendors', []);
            }}
            cancelBtnTitle="Reset"
          />
        </Inline>
        <Box id="spendTrendAnalysis" backgroundColor={'surfaceDefault'}>
          <Line data={data} options={options} />
        </Box>
      </Stack>
    </Stack>
  );
}

type ExpenseBreakdownViewTypes = {
  total: string;
  tableData: ExpenseBreakdownStatResponseType | undefined;
  graphData: ExpenseBreakdownStatResponseType | undefined;
  handleParamChange: (
    key: keyof OwnExpenseBreakdownFilters,
    value?: ExpenseBreakdownByTypes
  ) => void;
  params: OwnExpenseBreakdownFilters;
  status: 'in_progress' | 'success' | 'failed';
  syncingData: boolean | undefined;
  handleDateChange: (values: DateFilter) => void;
};

const barOptions = {
  indexAxis: 'y' as const,
  borderRadius: 4,
  borderSkipped: false,
  parsing: {
    xAxisKey: 'total',
    yAxisKey: 'name',
  },
  aspectRatio: 3,
  scales: {
    x: {
      display: false,
      grace: '30%',
    },
    y: {
      grid: {
        display: false,
      },
      border: { display: false },
    },
  },
  hoverRadius: 8,
  responsive: true,
  plugins: {
    tooltip: {
      displayColors: false,
      backgroundColor: COLORS.surfaceNeutralHigh,
      borderWidth: 1,
      borderColor: COLORS.borderSeparator,
      padding: {
        top: 12,
        bottom: 6,
        left: 12,
        right: 12,
      },
      titleColor: COLORS.textOnSurface,
      callbacks: {
        title: function (context: any) {
          const {
            raw: { percentage },
          } = context[0];
          return `${Number(percentage).toFixed(2)} %`;
        },
        label: function (context: any) {
          return '';
        },
      },
    },
    rightLabels: {
      display: true,
    },
  },
};

type dateTypes = 'month' | 'quarter' | 'year';

const datetypesObject: { id: dateTypes; label: string }[] = [
  {
    id: 'month',
    label: 'Month',
  },
  {
    id: 'quarter',
    label: 'Quarter',
  },
  {
    id: 'year',
    label: 'Year',
  },
];

function ExpenseBreakdownView({
  total,
  tableData,
  graphData,
  params,
  handleParamChange,
  status,
  handleDateChange,
  syncingData,
}: ExpenseBreakdownViewTypes) {
  const { download } = useDownloadHTMLAsImage();
  const idPrefix = 'spend-analysis-expense-breakdown';
  const [dateTypeSelected, setDateTypeSelected] = useState<dateTypes>('month');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [visualTypeShown, setVisualTypeShown] = useState<'graph' | 'table'>(
    'graph'
  );
  const barData = {
    labels: [],
    datasets: [
      {
        label: 'Expense Breakdown Analysis',
        data: graphData,
        categoryPercentage: 0.6,
        barPercentage: 0.6,
        backgroundColor: [
          COLORS.DataVisualisation1,
          COLORS.DataVisualisation2,
          COLORS.DataVisualisation4,
          COLORS.DataVisualisation3,
          COLORS.DataVisualisation8,
          COLORS.DataVisualisation5,
        ],
      },
    ],
  };

  const changeDate = useCallback(
    (type: 'prev' | 'next' | 'none', dateType: dateTypes) => {
      let updatedDate;
      let dateValues;
      if (type === 'prev') {
        switch (dateType) {
          case 'month': {
            updatedDate = sub(selectedDate, { months: 1 });
            dateValues = getMonthInterval(updatedDate);
            break;
          }
          case 'quarter': {
            updatedDate = subQuarters(selectedDate, 1);
            dateValues = getQuarterInterval(updatedDate);
            break;
          }
          case 'year': {
            updatedDate = sub(selectedDate, { years: 1 });
            dateValues = getYearInterval(updatedDate);
            break;
          }
        }
      } else if (type === 'next') {
        switch (dateType) {
          case 'month': {
            updatedDate = add(selectedDate, { months: 1 });
            dateValues = getMonthInterval(updatedDate);
            break;
          }
          case 'quarter': {
            updatedDate = addQuarters(selectedDate, 1);
            dateValues = getQuarterInterval(updatedDate);
            break;
          }
          case 'year': {
            updatedDate = add(selectedDate, { years: 1 });
            dateValues = getYearInterval(updatedDate);
            break;
          }
        }
      } else {
        updatedDate = new Date();
        switch (dateType) {
          case 'month': {
            dateValues = getMonthInterval(updatedDate);
            break;
          }
          case 'quarter': {
            dateValues = getQuarterInterval(updatedDate);
            break;
          }
          case 'year': {
            dateValues = getYearInterval(updatedDate);
            break;
          }
        }
      }
      handleDateChange({ dates: dateValues });
      setSelectedDate(updatedDate);
    },
    [handleDateChange, selectedDate]
  );

  const changeVisualType = (type: 'graph' | 'table') => {
    if (visualTypeShown !== type) {
      setVisualTypeShown(type);
    }
  };

  const changeSelectedDateType = useCallback(
    (type: dateTypes) => {
      if (dateTypeSelected !== type) {
        setDateTypeSelected(type);
        changeDate('none', type);
      }
    },
    [changeDate, dateTypeSelected]
  );

  return (
    <Stack
      paddingX={'4'}
      paddingY={'3'}
      bgColor={'surfaceDefault'}
      borderRadius="lg"
      marginBottom={'2'}
      borderWidth="1"
      borderColor="borderSeparator"
    >
      <Inline justifyContent={'between'} alignItems={'center'}>
        <Text variation={'t3'}>Spend Breakdown</Text>
        <Inline justifyContent={'between'} alignItems={'center'} gap={'3'}>
          <Inline
            borderRadius="md"
            borderWidth="1"
            borderColor="borderSeparator"
          >
            {datetypesObject.map(({ id, label }, index) => (
              <Box
                id={`${idPrefix}-change-${id}`}
                key={id}
                paddingX={'1.5'}
                paddingY={'0.5'}
                bgColor={
                  dateTypeSelected === id
                    ? 'surfacePrimaryLowest'
                    : 'surfaceDefault'
                }
                borderRightWidth={index !== 2 ? '1' : '0'}
                borderColor="borderSeparator"
                cursor="pointer"
                onClick={() => changeSelectedDateType(id)}
              >
                <Text
                  variation={'c2'}
                  color={dateTypeSelected === id ? 'textPrimary' : 'textMedium'}
                >
                  {label}
                </Text>
              </Box>
            ))}
          </Inline>
          <Box
            cursor="pointer"
            id={`${idPrefix}-download`}
            onClick={() =>
              download(
                document.getElementById(
                  'spendBreakdownAnalysis'
                ) as HTMLElement,
                `Spend_breakdown_analysis_${format(new Date(), 'dd-mm-yy')}`
              )
            }
          >
            <DownloadIcon color={'textLow'} size={'3'} />
          </Box>
        </Inline>
      </Inline>
      <Inline
        marginTop={'3'}
        marginBottom={'4'}
        paddingY={'2'}
        paddingX={'3'}
        bgColor={'backgroundGray'}
        borderRadius="lg"
        justifyContent={'between'}
        alignItems={'center'}
      >
        <Stack gap={'1'}>
          <Text variation={'b2'} color={'textMedium'}>
            Total Expense
          </Text>
          <Amount amount={Number(total)} variation={'h2'} />
        </Stack>
        <Inline
          gap="2"
          alignItems={'center'}
          paddingX={'1.5'}
          paddingY={'0.5'}
          marginRight={'2'}
        >
          <Box
            id={`${idPrefix}-change-previous-date`}
            as="button"
            backgroundColor="transparent"
            onClick={() => changeDate('prev', dateTypeSelected)}
          >
            <RightCaratIcon
              cursor="pointer"
              size="2.5"
              rotate="180"
              color={'iconLow'}
            />
          </Box>
          <Text color={'textPrimary'} variation={'t4'}>
            {dateTypeSelected === 'month'
              ? format(selectedDate, 'MMM, yyyy')
              : dateTypeSelected === 'quarter'
              ? `Q${getFinancialQuarter(selectedDate)}: ${format(
                  params.dateFilter?.dates[0],
                  'MMM yy'
                )} - ${format(params.dateFilter?.dates[1], 'MMM yy')}`
              : dateTypeSelected === 'year'
              ? `FY: ${format(selectedDate, 'yyyy')}`
              : null}
          </Text>
          <Box
            id={`${idPrefix}-change-next-date`}
            as="button"
            backgroundColor="transparent"
            onClick={() => changeDate('next', dateTypeSelected)}
          >
            <RightCaratIcon cursor="pointer" size="2.5" color={'iconLow'} />
          </Box>
        </Inline>
      </Inline>
      <Inline
        alignItems={'center'}
        justifyContent={'between'}
        marginBottom={'3'}
      >
        <Inline alignItems={'center'} gap={'2'}>
          <Text variation={'b2'} color={'textMedium'}>
            Breakdown By:
          </Text>
          {breakdownTypes.map(({ id, label }) => (
            <Box
              cursor="pointer"
              id={`${idPrefix}-breakdown-by-${id}`}
              key={id}
              style={{ borderRadius: 100 }}
              borderWidth="1"
              borderColor={
                id === params.by ? 'borderPrimaryLow' : 'borderSeparator'
              }
              bgColor={
                id === params.by ? 'surfacePrimaryLowest' : 'surfaceDefault'
              }
              paddingY={'1'}
              paddingX={'2'}
              onClick={() => {
                handleParamChange('by', id);
              }}
            >
              <Text
                variation={'c2'}
                color={id === params.by ? 'textPrimary' : 'textMedium'}
              >
                {label}
              </Text>
            </Box>
          ))}
        </Inline>
        <Inline borderRadius="md" borderWidth="1" borderColor="borderSeparator">
          <Box
            id={`${idPrefix}-show-graph`}
            cursor="pointer"
            paddingX={'1.5'}
            paddingY={'0.5'}
            bgColor={
              visualTypeShown === 'graph'
                ? 'surfacePrimaryLowest'
                : 'surfaceDefault'
            }
            onClick={() => changeVisualType('graph')}
          >
            <Text
              variation={'c2'}
              color={visualTypeShown === 'graph' ? 'textPrimary' : 'textMedium'}
            >
              Graph
            </Text>
          </Box>
          <Box width={'px'} bgColor={'borderSeparator'} />
          <Box
            id={`${idPrefix}-show-table`}
            cursor="pointer"
            paddingX={'1.5'}
            paddingY={'0.5'}
            onClick={() => changeVisualType('table')}
            bgColor={
              visualTypeShown === 'table'
                ? 'surfacePrimaryLowest'
                : 'surfaceDefault'
            }
          >
            <Text
              variation={'c2'}
              color={visualTypeShown === 'table' ? 'textPrimary' : 'textMedium'}
            >
              Table
            </Text>
          </Box>
        </Inline>
      </Inline>
      <Box id="spendBreakdownAnalysis">
        {visualTypeShown === 'graph' ? (
          graphData?.length ? (
            <Box bgColor={'surfaceDefault'}>
              <Bar data={barData} options={barOptions} />
            </Box>
          ) : (
            <EmptyState
              title="No Data Found!"
              subText="Create an Invoice to see spend breakdown"
            />
          )
        ) : tableData?.length ? (
          <Box
            style={{ height: 300 }}
            overflowY="scroll"
            bgColor={'surfaceDefault'}
          >
            <Box as="table" width="screen" position="relative">
              <Box
                as="thead"
                bgColor="surfaceNeutralLowest"
                borderWidth="1"
                borderColor="borderSeparator"
              >
                <Box as="tr">
                  <Box
                    as="th"
                    position="sticky"
                    paddingY="1"
                    paddingX="2"
                    style={{
                      width: 52,
                    }}
                    top="0"
                    textAlign="left"
                    bgColor="surfaceNeutralLowest"
                    borderWidth="1"
                    borderColor="borderSeparator"
                  >
                    <Text variation="c1">S No.</Text>
                  </Box>
                  <Box
                    as="th"
                    position="sticky"
                    paddingY="1"
                    paddingX="2"
                    style={{
                      width: 300,
                    }}
                    top="0"
                    textAlign="left"
                    bgColor="surfaceNeutralLowest"
                    borderWidth="1"
                    borderColor="borderSeparator"
                  >
                    <Text variation="c1">Vendor Name</Text>
                  </Box>
                  <Box
                    as="th"
                    position="sticky"
                    paddingY="1"
                    paddingX="2"
                    style={{
                      width: 100,
                    }}
                    top="0"
                    textAlign="left"
                    bgColor="surfaceNeutralLowest"
                    borderWidth="1"
                    borderColor="borderSeparator"
                  >
                    <Text variation="c1">Amount</Text>
                  </Box>
                  <Box
                    as="th"
                    position="sticky"
                    paddingY="1"
                    paddingX="2"
                    style={{
                      width: 90,
                    }}
                    top="0"
                    textAlign="left"
                    bgColor="surfaceNeutralLowest"
                    borderWidth="1"
                    borderColor="borderSeparator"
                  >
                    <Text variation="c1">Percentage</Text>
                  </Box>
                </Box>
              </Box>
              {syncingData || status === 'in_progress' ? (
                <SkeletonRows numOfRows={5} numOfCols={4} />
              ) : (
                <Box as="tbody">
                  {tableData?.length
                    ? tableData.map(({ name, total, percentage }, index) => {
                        return (
                          <React.Fragment key={index}>
                            <Box as="tr" borderTopWidth="1">
                              <Box
                                as="td"
                                paddingX="2"
                                paddingY="1"
                                textAlign="left"
                                borderWidth="1"
                                borderColor="borderSeparator"
                              >
                                <Text variation={'b2'} color={'textMedium'}>
                                  {index + 1}
                                </Text>
                              </Box>
                              <HoverableBox
                                cursor="pointer"
                                paddingX="2"
                                paddingY="1"
                                textAlign="left"
                                borderWidth="1"
                                borderColor="borderSeparator"
                                text={name}
                              >
                                <Text variation={'b2'} color={'textMedium'}>
                                  {name}
                                </Text>
                              </HoverableBox>
                              <Box
                                as="td"
                                paddingX="2"
                                paddingY="1"
                                textAlign="left"
                                borderWidth="1"
                                borderColor="borderSeparator"
                              >
                                <Amount
                                  amount={Number(total)}
                                  color={'textMedium'}
                                  variation={'b2'}
                                />
                              </Box>
                              <Box
                                as="td"
                                paddingX="2"
                                paddingY="1"
                                textAlign="left"
                                borderWidth="1"
                                borderColor="borderSeparator"
                              >
                                <Text variation={'b2'} color={'textMedium'}>
                                  {Number(percentage).toFixed(2) + '%'}
                                </Text>
                              </Box>
                            </Box>
                          </React.Fragment>
                        );
                      })
                    : null}
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <EmptyState
            title="No Data Found!"
            subText="Create an Invoice to see spend breakdown"
          />
        )}
      </Box>
    </Stack>
  );
}
