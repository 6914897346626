import { useUser } from '@nbfc-expense-tool/data-store/auth';
import {
  Operations,
  checkForSinglePermission,
} from '@nbfc-expense-tool/data-store/permissions';
import { Button, CloudUploadIcon } from '@nbfc-expense-tool/ui';
import { useNavigate } from 'react-router-dom';

type BulkImportButtonProps = {
  label?: string;
  entityType: 'vendors' | 'branches' | 'employees';
};

const permissionName: { [key: string]: Operations } = {
  vendors: 'importVendors',
  branches: 'importBranches',
  employees: 'importEmployees',
};

const BulkImportButton: React.FC<BulkImportButtonProps> = ({
  label = 'Bulk Import',
  entityType,
}) => {
  const { user } = useUser();
  const navigate = useNavigate();

  if (
    !checkForSinglePermission(
      permissionName[entityType],
      user?.roles[0]?.permissions || null
    )
  ) {
    return null;
  }

  const naviagteToBulkImportPage = () => {
    switch (entityType) {
      case 'vendors':
        navigate('/home/vendors/bulk-import-vendors');
        break;
      case 'branches':
        navigate('/home/branches/bulk-import-branches');
        break;
      case 'employees':
        navigate('/home/employees/bulk-import-employees');
        break;
      default:
        break;
    }
  };

  return (
    <Button
      id={`bulk-import-${entityType}`}
      type="outlined"
      title={label}
      leftIcon={(props) => <CloudUploadIcon {...props} />}
      onClick={naviagteToBulkImportPage}
    />
  );
};

export default BulkImportButton;
