import {
  Box,
  Stack,
  Text,
  KeyIcon,
  EmailIcon,
  Button,
  RightCaratIcon,
  EyeOffIcon,
  EyeIcon,
  Banner,
} from '@nbfc-expense-tool/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import axios from 'axios';

import { login } from '@nbfc-expense-tool/data-store/utils';
import { setSyncStoredItem } from '@nbfc-expense-tool/data-store/storage';
import {
  userDetails,
  UserDetails as UserDetailsPayloadType,
} from '@nbfc-expense-tool/data-store/jotai-store';
import { FormTextInput } from '@nbfc-expense-tool/data-store/forms';

const schema = z.object({
  email: z
    .string({
      required_error: 'Please enter your company Email ID to continue',
    })
    .nonempty('Please enter your company Email ID to continue')
    .email('Please enter a valid email'),
  password: z
    .string({
      required_error: 'Enter valid password to login',
    })
    .nonempty('Enter valid password to login'),
});

type FormInputs = {
  email: string;
  password: string;
};

type LoginResponse = {
  data: {
    user: UserDetailsPayloadType;
    bearer_token: string;
  };
};

const Login: React.FC = () => {
  const [hidePassword, setHidePassword] = useState(true);
  const [backendError, setBackendError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm<FormInputs>({
    resolver: zodResolver(schema),
  });
  const { control, handleSubmit } = form;
  const navigate = useNavigate();
  const setUserDetails = useSetAtom(userDetails);

  const submitDetails = async (data: FormInputs) => {
    setBackendError('');
    setLoading(true);
    login<LoginResponse>(
      { email: data.email, password: data.password },
      { ignoredStatusCodesForReporting: [422] }
    )
      .then(({ data }) => {
        const { user, bearer_token } = data;
        setSyncStoredItem('authToken', bearer_token);
        setSyncStoredItem('user', user);
        axios.defaults.headers.common['Authorization'] = `${bearer_token}`;
        setUserDetails(user);
        navigate('/');
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setBackendError(err.message);
      });
  };

  return (
    <Box
      display="flex"
      flex="1"
      height="screen"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        display="flex"
        style={{ width: 432 }}
        overflow="hidden"
        paddingRight="0.5"
      >
        <Text variation="h2" color="textHigh">
          Welcome to CashBook EMS
        </Text>
        <Box
          backgroundColor="surfacePrimary"
          width="4.5"
          rounded="full"
          height="0.5"
          marginTop="3.5"
        />
        <Text variation="h3" color="textHigh" marginTop="3.5">
          Login
        </Text>
        {backendError ? (
          <Banner
            bannerType="error"
            bannerText={backendError}
            marginTop="3.5"
          />
        ) : null}
        <Box as="form" onSubmit={handleSubmit(submitDetails)}>
          <Box marginTop="4.5" width="full">
            <FormTextInput
              control={control}
              name="email"
              label="Email ID"
              placeholder="Enter email Id"
              leftIcon={(props) => <EmailIcon {...props} />}
              type="email"
              fullWidth
              isRequired
            />
          </Box>
          <Box marginTop="4.5" display="flex">
            <FormTextInput
              control={control}
              name="password"
              label="Password"
              placeholder="Enter password"
              leftIcon={(props) => <KeyIcon {...props} />}
              type={hidePassword ? 'password' : 'text'}
              rightIcon={(props) =>
                !hidePassword ? (
                  <EyeOffIcon
                    {...props}
                    id="login-password-hide"
                    onClick={() => setHidePassword(true)}
                    cursor="pointer"
                  />
                ) : (
                  <EyeIcon
                    {...props}
                    id="login-password-show"
                    onClick={() => setHidePassword(false)}
                    cursor="pointer"
                  />
                )
              }
              fullWidth
              isRequired
            />
          </Box>
          <Button
            id="login-button"
            state={loading ? 'loading' : undefined}
            onClick={handleSubmit(submitDetails)}
            size="lg"
            title={loading ? 'Logging In' : 'Login'}
            marginTop="4"
            width="full"
            rightIcon={(props) => <RightCaratIcon {...props} />}
          />
          <Text
            id="login-forgot-password"
            variation="lk"
            color="textPrimary"
            cursor="pointer"
            marginTop="4"
            onClick={() => navigate('/auth/forgot-password')}
          >
            Forgot Password?
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default Login;
