const config = {
  appTitle: 'CashBook Spend',

  //Base url
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  appVersion: import.meta.env.PACKAGE_VERSION,
  appEnv: import.meta.env.MODE,
  appName: '@cashbook/spend',
};

export default config;
