import { useUser } from '@nbfc-expense-tool/data-store/auth';
import { checkForSinglePermission } from '@nbfc-expense-tool/data-store/permissions';
import {
  EmployeesPayload,
  ErrorResponse,
  getEmployeesListing,
  getEmployeesReport,
  usePagination,
} from '@nbfc-expense-tool/data-store/utils';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EmployeeListItem } from './types';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { toastQueue } from '@nbfc-expense-tool/ui';

type EmployeesListResponse = {
  data: EmployeeListItem[];
  current_page: number;
  total: number;
  per_page?: number;
  last_page?: number;
};

const initialEmployeesList: EmployeesListState = {
  status: 'in_progress',
  error: null,
  employees: undefined,
  syncingWithFilers: false,
};

type EmployeesListState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  employees?: EmployeeListItem[];
  totalEmployees?: number;
  perPage?: number;
  currentPage?: number;
  syncingWithFilers?: boolean;
  last_page?: number;
};

type EMPLOYEES_LIST_TYPES =
  | { type: 'FETCHING_EMPLOYEES' }
  | { type: 'FETCHED_EMPLOYEES'; payload: EmployeesListResponse }
  | { type: 'FETCHING_EMPLOYEES_FAILED'; payload: Error }
  | { type: 'FETCHING_EMPLOYEES_WITH_FILTERS' };

const reducer = (
  state: EmployeesListState,
  action: EMPLOYEES_LIST_TYPES
): EmployeesListState => {
  switch (action.type) {
    case 'FETCHING_EMPLOYEES':
      return {
        ...state,
        ...initialEmployeesList,
      };
    case 'FETCHING_EMPLOYEES_WITH_FILTERS':
      return {
        ...state,
        error: null,
        syncingWithFilers: true,
      };
    case 'FETCHED_EMPLOYEES':
      return {
        ...state,
        status: 'success',
        error: null,
        syncingWithFilers: false,
        currentPage: action.payload.current_page,
        totalEmployees: action.payload.total,
        employees: action.payload.data,
        perPage: action.payload.per_page,
        last_page: action.payload.last_page,
      };
    case 'FETCHING_EMPLOYEES_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        syncingWithFilers: false,
      };
    default:
      return state;
  }
};

type EmployeesFilters = {
  branches?: string[];
  roles?: string[];
  query?: string;
};

export function useEmployees(initialEmployeesFilter: EmployeesFilters = {}) {
  const [state, dispatch] = useReducer(reducer, initialEmployeesList);
  const [shouldApiCall, setShouldApiCall] = useState<boolean>(true);
  const [reportLoading, setReportLoading] = useState<boolean>(false);

  const {
    current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    nextPage,
    previousPage,
    resetPagination,
  } = usePagination({
    perPage: 10,
    lastPage: state.last_page || 1,
  });

  const { user } = useUser();

  const canAddEmployee = useMemo(() => {
    if (
      !checkForSinglePermission(
        'createEmp',
        user?.roles[0]?.permissions || null
      )
    ) {
      return false;
    }
    return true;
  }, [user]);

  const canUpdateEmployee = useMemo(() => {
    if (
      !checkForSinglePermission(
        'updateEmp',
        user?.roles[0]?.permissions || null
      )
    ) {
      return false;
    }
    return true;
  }, [user]);

  const { getValues, setValue } = useForm<EmployeesFilters>({
    defaultValues: initialEmployeesFilter,
  });

  const params = getValues();

  const exportExcel = useCallback(async () => {
    try {
      const { branches, roles, query } = params;
      const constructedPayload: EmployeesPayload = {};
      if (branches?.length) {
        constructedPayload['branch_id'] = branches;
      }
      if (roles?.length) {
        constructedPayload['role_name'] = roles;
      }
      if (query) {
        constructedPayload['q'] = query;
      }
      setReportLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = await getEmployeesReport<EmployeesListResponse>(
        constructedPayload,

        {
          responseType: 'blob',
          headers: {
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            Accept: '*/*',
          },
        }
      );
      const linkElement = document.createElement('a');
      const url = URL.createObjectURL(data);
      linkElement.href = url;
      linkElement.download = `EmployeeList.xlsx`;
      document.body.appendChild(linkElement);
      linkElement.click();
      setTimeout(function () {
        document.body.removeChild(linkElement);
        window.URL.revokeObjectURL(url);
      }, 0);
      setReportLoading(false);
    } catch (err) {
      const error = err as ErrorResponse;
      toastQueue.add(
        {
          title: error.message || 'Something Went Wrong! Please try again',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
      setReportLoading(false);
    }
  }, [params]);

  const getEmployeesList = useCallback(async () => {
    try {
      const { branches, roles, query } = params;
      const constructedPayload: EmployeesPayload = {};
      if (current) {
        constructedPayload['page'] = current;
      }
      if (branches?.length) {
        constructedPayload['branch_id'] = branches;
      }
      if (roles?.length) {
        constructedPayload['role_name'] = roles;
      }
      if (query) {
        constructedPayload['q'] = query;
      }
      const response = await getEmployeesListing<EmployeesListResponse>(
        constructedPayload
      );
      dispatch({ type: 'FETCHED_EMPLOYEES', payload: response });
    } catch (err) {
      const error = err as Error;
      dispatch({ type: 'FETCHING_EMPLOYEES_FAILED', payload: error });
    }
  }, [current, params]);

  useEffect(() => {
    if (shouldApiCall) {
      getEmployeesList();
      setShouldApiCall(false);
    }
  }, [getEmployeesList, shouldApiCall]);

  const handlePageChange = (type: 'next' | 'previous' | 'reset') => {
    switch (type) {
      case 'next':
        nextPage();
        setShouldApiCall(true);
        dispatch({ type: 'FETCHING_EMPLOYEES_WITH_FILTERS' });
        break;
      case 'previous':
        previousPage();
        setShouldApiCall(true);
        dispatch({ type: 'FETCHING_EMPLOYEES_WITH_FILTERS' });
    }
  };

  function handleParamChange(key: 'branch' | 'role', value: string[]) {
    switch (key) {
      case 'branch':
        resetPagination();
        setShouldApiCall(true);
        setValue('branches', value);
        dispatch({ type: 'FETCHING_EMPLOYEES_WITH_FILTERS' });
        break;
      case 'role':
        resetPagination();
        setShouldApiCall(true);
        setValue('roles', value);
        dispatch({ type: 'FETCHING_EMPLOYEES_WITH_FILTERS' });
        break;
    }
  }

  function handleSearchQuery(value: string) {
    resetPagination();
    setShouldApiCall(true);
    setValue('query', value);
    dispatch({ type: 'FETCHING_EMPLOYEES_WITH_FILTERS' });
  }

  function resetFilters() {
    resetPagination();
    setValue('branches', undefined);
    setValue('query', undefined);
    setValue('roles', undefined);
    if (initialEmployeesFilter) {
      const keys = Object.keys(
        initialEmployeesFilter
      ) as (keyof EmployeesFilters)[];
      keys.forEach((key) => {
        setValue(key, initialEmployeesFilter[key]);
      });
    }
    setShouldApiCall(true);
    dispatch({ type: 'FETCHING_EMPLOYEES' });
  }

  const hasAppliedFilters = useMemo(() => {
    const { branches, roles } = params;
    let hasAppliedFilters = false;
    if (branches?.length && !initialEmployeesFilter.branches?.length) {
      hasAppliedFilters = true;
      return hasAppliedFilters;
    }
    if (roles?.length && !initialEmployeesFilter.roles?.length) {
      hasAppliedFilters = true;
      return hasAppliedFilters;
    }
    if (params.query && !initialEmployeesFilter.query) {
      hasAppliedFilters = true;
      return hasAppliedFilters;
    }
    return hasAppliedFilters;
  }, [params, initialEmployeesFilter]);

  return {
    error: state.error,
    currentPage: current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    params,
    hasAppliedFilters,
    loading: state.status,
    employees: state.employees,
    totalEmployees: state.totalEmployees,
    syncingData: state.syncingWithFilers,
    nextPage,
    previousPage,
    resetFilters,
    handlePageChange,
    handleParamChange,
    handleSearchQuery,
    canAddEmployee,
    canUpdateEmployee,
    exportExcel,
    reportLoading,
  };
}

export const useBulkImportEmployees = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return {
    goBack,
  };
};
