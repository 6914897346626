import {
  BranchListPayload,
  ErrorResponse,
  branchList,
  branchListForFilters,
  branchListReport,
  businessByForFilters,
  rentByForFilters,
  usePagination,
} from '@nbfc-expense-tool/data-store/utils';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { BranchListItem } from './types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { toastQueue } from '@nbfc-expense-tool/ui';

export type BRANCH_FOR_FILTER = {
  id: number;
  name: string;
  branch_code: string;
};

type BranchesForFilterResponse = {
  data: BRANCH_FOR_FILTER[];
};

type BranchesForFilterState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  branches?: BRANCH_FOR_FILTER[];
};
type BRANCHES_LIST_FOR_FILTER_TYPES =
  | { type: 'FETCHING_BRANCHES' }
  | { type: 'FETCHED_BRANCHES'; payload: BranchesForFilterResponse }
  | { type: 'FETCHING_BRANCHES_FAILED'; payload: Error }
  | { type: 'FETCHING_BRANCHES_WITH_FILTERS' };

const initialBranchForFilterList: BranchesForFilterState = {
  status: 'in_progress',
  error: null,
  branches: undefined,
};

const reducer = (
  state: BranchesForFilterState,
  action: BRANCHES_LIST_FOR_FILTER_TYPES
): BranchesForFilterState => {
  switch (action.type) {
    case 'FETCHING_BRANCHES':
      return {
        ...state,
        ...initialBranchForFilterList,
      };
    case 'FETCHED_BRANCHES':
      return {
        ...state,
        status: 'success',
        error: null,
        branches: action.payload.data,
      };
    default:
      return state;
  }
};

export function useBranchesForFilter() {
  const [state, dispatch] = useReducer(reducer, initialBranchForFilterList);

  const getBranchesForFilters = useCallback(async () => {
    const response = await branchListForFilters<BranchesForFilterResponse>();
    dispatch({ type: 'FETCHED_BRANCHES', payload: response });
  }, []);

  useEffect(() => {
    getBranchesForFilters();
  }, [getBranchesForFilters]);

  return {
    status: state.status,
    branches: state.branches,
  };
}

type BranchesListState = {
  status: 'in_progress' | 'success' | 'failed';
  error?: string | Error | null;
  branches?: BranchListItem[];
  totalBranches?: number;
  perPage?: number;
  currentPage?: number;
  syncingWithFilers?: boolean;
  last_page?: number;
};

const initialBranchList: BranchesListState = {
  status: 'in_progress',
  error: null,
  branches: undefined,
  syncingWithFilers: false,
};

type BranchesResponse = {
  data: BranchListItem[];
  current_page: number;
  total: number;
  per_page?: number;
  last_page: number;
};

type BRANCHES_LIST_TYPES =
  | { type: 'FETCHING_BRANCHES' }
  | { type: 'FETCHED_BRANCHES'; payload: BranchesResponse }
  | { type: 'FETCHING_BRANCHES_FAILED'; payload: Error }
  | { type: 'FETCHING_BRANCHES_WITH_FILTERS' };
const branchesReducer = (
  state: BranchesListState,
  action: BRANCHES_LIST_TYPES
): BranchesListState => {
  switch (action.type) {
    case 'FETCHING_BRANCHES':
      return {
        ...state,
        ...initialBranchList,
      };
    case 'FETCHED_BRANCHES':
      return {
        ...state,
        status: 'success',
        error: null,
        syncingWithFilers: false,
        currentPage: action.payload.current_page,
        totalBranches: action.payload.total,
        branches: action.payload.data,
        perPage: action.payload.per_page,
        last_page: action.payload.last_page,
      };
    case 'FETCHING_BRANCHES_FAILED':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
        branches: [],
        totalBranches: 0,
        syncingWithFilers: false,
      };
    case 'FETCHING_BRANCHES_WITH_FILTERS':
      return {
        ...state,
        error: null,
        syncingWithFilers: true,
      };
    default:
      return state;
  }
};

type BranchFilters = {
  state?: string[];
  business_by?: string[];
  rent_by?: string[];
  parent_branch_id?: string[];
  branch_level?: number;
  q?: string;
};

const undefinedVal = undefined;

export function useBranches() {
  const [shouldCallApi, setShouldCallApi] = useState<boolean>(true);
  const [state, dispatch] = useReducer(branchesReducer, initialBranchList);
  const [reportLoading, setReportLoading] = useState<boolean>(false);

  const {
    perPage,
    current,
    lastPage,
    canGoBack,
    canGoNext,
    nextPage,
    previousPage,
    resetPagination,
  } = usePagination({
    perPage: 10,
    lastPage: state.last_page,
  });

  const { getValues, setValue } = useForm<BranchFilters>();

  const params = getValues();

  const exportExcel = useCallback(async () => {
    try {
      setReportLoading(true);
      const constructedPayload: BranchListPayload = {};
      const { q, parent_branch_id, state, branch_level, business_by, rent_by } =
        params;
      if (parent_branch_id?.length) {
        constructedPayload['parent_branch_id'] = parent_branch_id;
      }
      if (state?.length) {
        constructedPayload['address_state'] = state;
      }
      if (branch_level) {
        constructedPayload['branch_level'] = branch_level;
      }
      if (business_by?.length) {
        constructedPayload['business_by'] = business_by;
      }
      if (rent_by?.length) {
        constructedPayload['rent_by'] = rent_by;
      }
      if (q?.length) {
        constructedPayload['q'] = q;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = await branchListReport<BranchesResponse>(
        constructedPayload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            Accept: '*/*',
          },
        }
      );
      const linkElement = document.createElement('a');
      const url = URL.createObjectURL(data);
      linkElement.href = url;
      linkElement.download = `Branchlist.xlsx`;
      document.body.appendChild(linkElement);
      linkElement.click();
      setTimeout(function () {
        document.body.removeChild(linkElement);
        window.URL.revokeObjectURL(url);
      }, 0);
      setReportLoading(false);
    } catch (err) {
      const error = err as ErrorResponse;
      toastQueue.add(
        {
          title: error.message || 'Something Went Wrong! Please try again',
          type: 'error',
        },
        {
          timeout: 2000,
        }
      );
      setReportLoading(false);
    }
  }, [params]);

  const getBranchesList = useCallback(async () => {
    try {
      const constructedPayload: BranchListPayload = {};
      const { q, parent_branch_id, state, branch_level, business_by, rent_by } =
        params;
      if (current) {
        constructedPayload['page'] = current;
      }
      if (parent_branch_id?.length) {
        constructedPayload['parent_branch_id'] = parent_branch_id;
      }
      if (state?.length) {
        constructedPayload['address_state'] = state;
      }
      if (branch_level) {
        constructedPayload['branch_level'] = branch_level;
      }
      if (business_by?.length) {
        constructedPayload['business_by'] = business_by;
      }
      if (rent_by?.length) {
        constructedPayload['rent_by'] = rent_by;
      }
      if (q?.length) {
        constructedPayload['q'] = q;
      }
      const response = await branchList<BranchesResponse>(constructedPayload);
      dispatch({ type: 'FETCHED_BRANCHES', payload: response });
    } catch (err) {
      const error = err as Error;
      dispatch({ type: 'FETCHING_BRANCHES_FAILED', payload: error });
    }
  }, [current, params]);

  useEffect(() => {
    if (shouldCallApi) {
      getBranchesList();
      setShouldCallApi(false);
    }
  }, [getBranchesList, shouldCallApi]);

  const handlePageChange = (type: 'next' | 'previous') => {
    if (type === 'next') {
      nextPage();
    } else {
      previousPage();
    }
    setShouldCallApi(true);
    dispatch({ type: 'FETCHING_BRANCHES_WITH_FILTERS' });
  };

  function resetFilters() {
    resetPagination();
    setValue('state', undefinedVal);
    setValue('business_by', undefinedVal);
    setValue('rent_by', undefinedVal);
    setShouldCallApi(true);
    setValue('q', undefinedVal);
    setValue('parent_branch_id', undefinedVal);
    setValue('branch_level', undefinedVal);
    dispatch({ type: 'FETCHING_BRANCHES' });
  }

  const hasAppliedFilters = useMemo(() => {
    return (
      Boolean(
        params.state?.length ||
          params.branch_level ||
          params.business_by?.length ||
          params.parent_branch_id?.length ||
          params.rent_by?.length ||
          params.q?.length
      ) || state.syncingWithFilers
    );
  }, [params, state.syncingWithFilers]);

  function handleParamChange(
    key: keyof BranchFilters,
    value?: string | string[] | number
  ) {
    resetPagination();
    setValue(key, value);
    setShouldCallApi(true);
    dispatch({ type: 'FETCHING_BRANCHES_WITH_FILTERS' });
  }

  return {
    current,
    lastPage,
    canGoBack,
    canGoNext,
    perPage,
    params,
    hasAppliedFilters,
    loading: state.status,
    branches: state.branches,
    totalBranches: state.totalBranches,
    syncingData: state.syncingWithFilers,
    resetFilters,
    handlePageChange,
    handleParamChange,
    exportExcel,
    reportLoading,
  };
}

type BusinessByForFilterResponse = {
  data: string[];
};
export function useBusinessByForFilter() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [businessByList, setBusinessByList] = useState<string[]>([]);
  const [error, setError] = useState<Error | undefined>(undefined);

  const getBranchesForFilters = useCallback(async () => {
    try {
      const { data } =
        await businessByForFilters<BusinessByForFilterResponse>();
      setBusinessByList(data);
      setIsLoading(false);
    } catch (e) {
      const error = e as Error;
      setIsLoading(false);
      setError(error);
    }
  }, []);

  useEffect(() => {
    getBranchesForFilters();
  }, [getBranchesForFilters]);

  return {
    error,
    isLoading,
    businessByList,
  };
}

type RentByForFilterResponse = {
  data: string[];
};
export function useRentByForFilter() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rentByList, setRentByList] = useState<string[]>([]);
  const [error, setError] = useState<Error | undefined>(undefined);

  const getBranchesForFilters = useCallback(async () => {
    try {
      const { data } = await rentByForFilters<RentByForFilterResponse>();
      setRentByList(data);
      setIsLoading(false);
    } catch (e) {
      const error = e as Error;
      setIsLoading(false);
      setError(error);
    }
  }, []);

  useEffect(() => {
    getBranchesForFilters();
  }, [getBranchesForFilters]);

  return {
    error,
    isLoading,
    rentByList,
  };
}

export const useBulkImportBranches = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return {
    goBack,
  };
};
