/* eslint-disable @typescript-eslint/no-explicit-any */
import { ToastQueue, ToastState, useToastQueue } from '@react-stately/toast';
import {
  AriaToastProps,
  AriaToastRegionProps,
  useToast,
  useToastRegion,
} from '@react-aria/toast';
import { useRef } from 'react';
import ReactDOM from 'react-dom';
import { Box } from '../box/box';
import { Text } from '../text/text';
import { CheckIcon, CrossIcon } from '../icons/icons';
import { Banner } from '../banner/banner';
import { AriaButtonProps } from 'react-aria';

interface MyToast {
  title?: string;
  type: 'success' | 'error' | 'custom';
  renderToastUI?: (x: AriaButtonProps<'button'>) => React.ReactNode;
}

// Create a global toast queue.
export const toastQueue = new ToastQueue<MyToast>({
  maxVisibleToasts: 5,
});

export function GlobalToastRegion(props: any) {
  // Subscribe to it.
  const state = useToastQueue(toastQueue);

  // Render toast region.
  return state.visibleToasts.length > 0
    ? ReactDOM.createPortal(
        <ToastRegion {...props} state={state} />,
        document.body
      )
    : null;
}

interface ToastRegionProps<T> extends AriaToastRegionProps {
  state: ToastState<T>;
}

function ToastRegion<T extends React.ReactNode>({
  state,
  ...props
}: ToastRegionProps<T>) {
  const ref = useRef(null);
  const { regionProps } = useToastRegion(props, state, ref);

  return (
    <Box
      {...regionProps}
      ref={ref}
      position={'fixed'}
      style={{
        right: 56,
        bottom: 100,
      }}
      zIndex="100"
    >
      {state.visibleToasts.map((toast) => (
        <Toast key={toast.key} toast={toast} state={state} />
      ))}
    </Box>
  );
}

interface ToastProps<T> extends AriaToastProps<T> {
  state: ToastState<T>;
}

function Toast<T extends React.ReactNode>({ state, ...props }: ToastProps<T>) {
  const ref = useRef(null);
  const { toastProps, titleProps, closeButtonProps } = useToast(
    props,
    state,
    ref
  );

  const toastContent = props.toast.content as any as MyToast;

  if (toastContent.renderToastUI) {
    return (
      <Box
        {...toastProps}
        ref={ref}
        display={'flex'}
        flex={'1'}
        width={'fitContent'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {toastContent?.renderToastUI(closeButtonProps)}
      </Box>
    );
  }

  if (toastContent.type === 'error' && toastContent.title) {
    return (
      <Box
        {...toastProps}
        ref={ref}
        display={'flex'}
        flex={'1'}
        width={'fitContent'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Banner
          bannerText={toastContent.title}
          bannerType="error"
          onClickClose={closeButtonProps.onPress as any}
        />
      </Box>
    );
  }

  return (
    <Box
      {...toastProps}
      ref={ref}
      bgColor={'surfaceNeutralHigh'}
      display={'flex'}
      flex={'1'}
      width={'fitContent'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box bgColor={'surfaceSuccess'} flex={'1'} display={'flex'}>
        <CheckIcon padding={'1.5'} color={'iconOnSurface'} size={'3'} />
      </Box>
      <Text
        paddingY={'1.5'}
        paddingX={'2'}
        color={'textOnSurface'}
        variation={'c1'}
        {...titleProps}
      >
        {toastContent.title}
      </Text>
      <CrossIcon
        color={'iconLow'}
        size={'3'}
        onClick={closeButtonProps.onPress as any}
        padding={'1.5'}
      />
    </Box>
  );
}
