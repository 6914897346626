import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { configure as configureApis } from '@nbfc-expense-tool/data-store/utils';

import App from './app/app';
import config from './config';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  Tooltip,
  BarElement,
} from 'chart.js';
import { rightLabels } from './app/pages/Dashboard/SpendAnalysis';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Filler,
  Tooltip,
  rightLabels
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

configureApis({
  baseUrl: config.apiBaseUrl as string,
});
