export const setSyncStoredItem = (key: string, value: unknown) => {
  try {
    return localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    return;
  }
};

export const getSyncStoredItem = (key: string) => {
  try {
    const storedItem = localStorage.getItem(key) || '';
    return JSON.parse(storedItem);
  } catch (e) {
    return;
  }
};

export const removeSyncStoredItem = (key: string) => {
  try {
    return localStorage.removeItem(key);
  } catch (e) {
    return;
  }
};
