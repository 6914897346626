import { useBulkImportBranches } from '@nbfc-expense-tool/data-store/dashboard';
import {
  Box,
  BreadcrumbItem,
  Breadcrumbs,
  SecondaryHeader,
  Stack,
} from '@nbfc-expense-tool/ui';
import React from 'react';
import { BulkImportEntities } from '../../Common';

const BulkImportBranches: React.FC = () => {
  const { goBack } = useBulkImportBranches();

  return (
    <Box paddingX="5" paddingY="4">
      <Stack display="flex" flex="1" width="full">
        <Breadcrumbs>
          <BreadcrumbItem isCurrent={false} isFirst onClick={goBack}>
            Branches
          </BreadcrumbItem>
          <BreadcrumbItem isCurrent>Branches Bulk Import</BreadcrumbItem>
        </Breadcrumbs>
        <SecondaryHeader
          onClickBack={goBack}
          headingText="Branches Bulk Import"
        />
      </Stack>
      <BulkImportEntities entityType="branches" marginTop="3" />
    </Box>
  );
};

export default BulkImportBranches;
