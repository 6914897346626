import { Link } from 'react-router-dom';
import { IconParams } from '../../types';
import { Box } from '../box/box';
import { Text } from '../text/text';

/* eslint-disable-next-line */
export interface PanelNavItemProps {
  itemName: string;
  leftIcon?: ({ size }: IconParams) => React.ReactElement;
  expended?: boolean;
  isSelected?: boolean;
  topSaperator?: boolean;
  path: string;
}

export function PanelNavItem({
  path,
  itemName,
  leftIcon,
  expended = true,
  isSelected = false,
  topSaperator = false,
}: PanelNavItemProps) {
  return (
    <>
      {topSaperator && (
        <Box
          borderTopWidth="1"
          display={'flex'}
          borderColor="borderSeparator"
          marginY={'0.5'}
        />
      )}
      <Box
        as={Link}
        to={path}
        paddingY="1.5"
        bgColor={{
          default: isSelected ? 'surfacePrimaryLowest' : 'surfaceDefault',
          hover: !isSelected ? 'surfaceNeutralLowest' : undefined,
        }}
        display={'flex'}
        borderRadius="md"
        borderLeftWidth={isSelected ? '4' : '0'}
        borderColor={isSelected ? 'borderPrimary' : undefined}
        textDecoration="none"
        paddingLeft={isSelected ? '2' : '2.5'}
      >
        {leftIcon && (
          <Box paddingRight={'2'}>
            {leftIcon({
              size: '3',
              color: isSelected ? 'iconPrimary' : 'iconMedium',
            })}
          </Box>
        )}
        {expended ? (
          <Text
            color={isSelected ? 'textPrimary' : 'textMedium'}
            variation="t4"
          >
            {itemName}
          </Text>
        ) : null}
      </Box>
    </>
  );
}
