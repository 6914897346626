import React from 'react';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';

import { forwardRef } from 'react';
import { AlertTriangleIcon, CancelIcon } from '../icons/icons';
import { Box } from '../box/box';
import { Inline, InlineProps } from '../inline/inline';

const DefaultAlertElement = 'div';

export type AlertOwnProps = {
  status: 'error' | 'info' | 'warning' | 'success';
  children: React.ReactNode;
  icon?: React.ReactNode;
  removeIcon?: boolean;
};

export type AlertProps<
  C extends React.ElementType = typeof DefaultAlertElement
> = PolymorphicComponentPropWithRef<C, AlertOwnProps & InlineProps>;

type AlertComponent = <
  C extends React.ElementType = typeof DefaultAlertElement
>(
  props: AlertProps<C>
) => React.ReactNode;

//TODO: Improve
export const Alert: AlertComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultAlertElement>(
    { status, children, icon, removeIcon, ...restProps }: AlertProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    return (
      <Inline
        as={DefaultAlertElement}
        ref={ref}
        paddingX="3"
        paddingY="2"
        gap="3"
        rounded="md"
        borderWidth="1"
        alignItems="center"
        color={
          status === 'warning'
            ? 'textWarning'
            : status === 'error'
            ? 'textError'
            : 'textMedium'
        }
        borderColor={
          status === 'warning'
            ? 'borderWarningLow'
            : status === 'error'
            ? 'borderError'
            : 'borderOutline'
        }
        backgroundColor={
          status === 'warning'
            ? 'surfaceWarningLowest'
            : status === 'error'
            ? 'surfaceErrorLowest'
            : 'surfaceNeutralLowest'
        }
        {...(restProps as InlineProps)}
      >
        {removeIcon ? null : icon ? (
          icon
        ) : status === 'warning' ? (
          <AlertTriangleIcon size="3" />
        ) : status === 'error' ? (
          <CancelIcon size={'3'} />
        ) : null}
        <Box
          flex="1"
          minWidth="0"
          color={
            status === 'warning'
              ? 'textWarning'
              : status === 'error'
              ? 'textError'
              : 'textMedium'
          }
        >
          {children}
        </Box>
      </Inline>
    );
  }
);
