import type { ElementType } from 'react';
import { forwardRef } from 'react';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@nbfc-expense-tool/polymorphic-types';
import { Box, BoxOwnProps } from '../box/box';
import { Text } from '../text/text';
import { TBackgroundColorStyles } from '../avatar/avatar.css';
import { Icon, IconProps } from '../icons/icons';

const DefaultHeaderElement = 'div';

type HeaderOwnProps = {
  headingText: string;
  renderRight?: () => React.ReactElement | null;
  onClickBack?: () => void;
} & TBackgroundColorStyles;

export type SecondaryHeaderProps<
  C extends React.ElementType = typeof DefaultHeaderElement
> = PolymorphicComponentPropWithRef<C, BoxOwnProps & HeaderOwnProps>;

type HeaderComponent = <
  C extends React.ElementType = typeof DefaultHeaderElement
>(
  props: SecondaryHeaderProps<C>
) => React.ReactNode;

export const SecondaryHeader: HeaderComponent = forwardRef(
  <C extends React.ElementType = typeof DefaultHeaderElement>(
    {
      as,
      headingText,
      renderRight,
      onClickBack,
      ...restProps
    }: SecondaryHeaderProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Element: ElementType = as || DefaultHeaderElement;

    return (
      <Box
        {...restProps}
        as={Element}
        ref={ref}
        width={'auto'}
        display={'flex'}
        alignItems="center"
        justifyContent="between"
        flex="1"
        marginTop="0.5"
      >
        <Box display="flex" flex="1" marginRight="3">
          <BackIcon
            color="iconHigh"
            size="4"
            onClick={onClickBack}
            cursor="pointer"
          />
          <Text variation="h3" color="textHigh" marginLeft="3" flex="1">
            {headingText}
          </Text>
        </Box>
        {renderRight ? renderRight() : null}
      </Box>
    );
  }
);

const BackIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.943 5.72386C17.4637 6.24456 17.4637 7.08878 16.943 7.60947L9.88578 14.6667H25.3335C26.0699 14.6667 26.6668 15.2636 26.6668 16C26.6668 16.7364 26.0699 17.3333 25.3335 17.3333H9.88578L16.943 24.3905C17.4637 24.9112 17.4637 25.7554 16.943 26.2761C16.4223 26.7968 15.5781 26.7968 15.0574 26.2761L5.72402 16.9428C5.20332 16.4221 5.20332 15.5779 5.72402 15.0572L15.0574 5.72386C15.5781 5.20316 16.4223 5.20316 16.943 5.72386Z"
      fill="currentColor"
    />
  </Icon>
);

export default SecondaryHeader;
