import { useUser } from '@nbfc-expense-tool/data-store/auth';
import { checkForSinglePermission } from '@nbfc-expense-tool/data-store/permissions';
import { Box, Header, Tabs } from '@nbfc-expense-tool/ui';
import { useEffect, useMemo, useState } from 'react';
import { Item } from 'react-stately';
import { InvoiceApproval } from './InvoiceApproval';
import { VendorApproval } from './VendorApproval';
import { RolesAndPermissions } from './RolesAndPermissions';
import { ReimbursementRules } from './ReimbursementRules';
import { useAtomValue } from 'jotai';
import { tenantConfig } from '@nbfc-expense-tool/data-store/jotai-store';
import { TENANT_CONFIG_KEYS } from '@nbfc-expense-tool/data-store/utils';

const RulesAndPolicies = () => {
  const { user } = useUser();
  const [selectedTab, setSelectedTab] = useState<undefined | string>(undefined);
  const tenantFeatureConfig = useAtomValue(tenantConfig);
  const isReimbursementFeatureAvailableForTenant =
    tenantFeatureConfig &&
    tenantFeatureConfig[TENANT_CONFIG_KEYS.REIMBURSEMENT_FEATURE_ENABLED]
      ?.value;

  const viewInvoiceApprovalAllowed = checkForSinglePermission(
    'readInvoiceApproval',
    user?.roles[0]?.permissions || null
  );
  const viewVendorApprovalAllowed = checkForSinglePermission(
    'readVendorApproval',
    user?.roles[0]?.permissions || null
  );
  const viewRoleAllowed = checkForSinglePermission(
    'readRole',
    user?.roles[0]?.permissions || null
  );

  const viewReimbersementAllowed =
    isReimbursementFeatureAvailableForTenant &&
    checkForSinglePermission(
      'readReimbursementApproval',
      user?.roles[0]?.permissions || null
    );

  const tabOptions = useMemo(() => {
    const options = [
      {
        title: 'Invoice Approval',
        path: 'invoice-approval',
        allowed: viewInvoiceApprovalAllowed,
        element: <InvoiceApproval />,
      },
      {
        title: 'Vendor Approval',
        path: 'vendor-approval',
        allowed: viewVendorApprovalAllowed,
        element: <VendorApproval />,
      },
      {
        title: 'Roles & Permissions',
        path: 'roles-permissions',
        allowed: viewRoleAllowed,
        element: <RolesAndPermissions />,
      },
      {
        title: 'Reimbursements',
        path: 'reimbursement',
        allowed: viewReimbersementAllowed,
        element: <ReimbursementRules />,
      },
    ];
    return options.filter((option) => option.allowed);
  }, [
    viewInvoiceApprovalAllowed,
    viewVendorApprovalAllowed,
    viewRoleAllowed,
    viewReimbersementAllowed,
  ]);

  useEffect(() => {
    if (tabOptions.length === 0) return;
    setSelectedTab(tabOptions[0].path);
  }, [tabOptions]);

  return (
    <Box paddingTop={'4'} paddingX="5">
      <Header headingText="Rules & Policies" paddingBottom={'3'} />
      <Tabs
        gap={'7'}
        selectedKey={selectedTab}
        onClick={(currentTabItem: string) => setSelectedTab(currentTabItem)}
      >
        {tabOptions.map((option) => {
          return (
            <Item
              key={option.path}
              textValue={option.path}
              title={
                <Box textDecoration="none" style={{ color: 'inherit' }}>
                  {option.title}
                </Box>
              }
            >
              {option.element}
            </Item>
          );
        })}
      </Tabs>
    </Box>
  );
};

export default RulesAndPolicies;
